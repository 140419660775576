import { CommonModule } from '@angular/common';
import { CuePlayerModule } from '../cue-player/cue-player.module';
import { EditorPreviewerService } from './services/editor-previewer.service';
import { MaterialModule } from '../../../../../libs/common/src';
import { NgModule } from '@angular/core';
import { components } from './components';

@NgModule({
  declarations: [...components],
  imports: [CommonModule, CuePlayerModule, MaterialModule],
  exports: [...components],
  providers: [EditorPreviewerService],
})
export class EditorPreviewerModule {}
