import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { ROUTE_DIRECTOR_LOGIN } from '../../route-utils';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '../../services/helper/helper.service';

@Component({
  selector: 'openreel-director-forgot-reset-password-form',
  templateUrl: './director-forgot-reset-password-form.component.html',
  styleUrls: ['../director-login-form/director-login-form.component.scss'],
})
export class DirectorForgotPasswordResetComponent implements OnInit {
  passwordHide = true;
  confPasswordHide = true;
  disabled = false;
  password: string;
  email: string;
  token: string;
  confPassword: string;
  loginRoute = '/' + ROUTE_DIRECTOR_LOGIN;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private helperService: HelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.verifyEmailToken();
  }
  async updateUserPassword() {
    this.disabled = false;
    const passwordPattern = new RegExp(this.helperService.passPattern);
    if (!passwordPattern.test(this.password)) {
      this.toastr.error(this.helperService.passErrorMessage, 'Error!');
    } else if (this.password !== this.confPassword) {
      this.toastr.error('Password confirmation does not match', 'Error!');
    } else {
      try {
        this.disabled = true;
        const res = await this.authService
          .updateUserPassword(this.email, this.token, this.password)
          .toPromise();
        this.toastr.success(res.message, 'Success!');
        this.router.navigateByUrl('/' + ROUTE_DIRECTOR_LOGIN);
      } catch (err) {
        this.disabled = false;
        const error =
          err.error && err.error.message ? err.error.message : err.message;
        this.toastr.error(error, 'Error!');
      }
    }
  }
  async verifyEmailToken() {
    this.disabled = true;
    try {
      await this.authService
        .verifyForgotPasswordToken(this.email, this.token)
        .toPromise();
    } catch (err) {
      const error =
        err.error && err.error.message ? err.error.message : err.message;
      this.toastr.error(error, 'Error!');
      this.router.navigateByUrl('/' + ROUTE_DIRECTOR_LOGIN);
    }
    this.disabled = false;
  }
}
