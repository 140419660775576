import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Cleanupable } from '../../../../../../libs/common/src';
import { TimelineItem } from '../../shared/element.interfaces';
import { TimelinesFacade } from '../store/facades/timelines.facade';
import { Durations, Timeline } from '../store/interfaces/timelines.interfaces';

@Injectable()
export class TimelineItemPositionsService extends Cleanupable {
  private durations: Durations;

  private _positions = new Map<string, { x: number; y: number }>();

  private updatePositions = new Subject<void>();
  updatePositions$ = this.updatePositions.asObservable();

  private draggingItems = new Set<TimelineItem>();
  private draggingItemsPrevious = new Set<TimelineItem>();
  private draggingItemClampStart: TimelineItem;
  private draggingItemClampEnd: TimelineItem;
  private draggingStartPositions = new Map<string, { x: number; y: number }>();

  constructor(private readonly timelinesFacade: TimelinesFacade) {
    super();

    this.timelinesFacade.allDurations$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((durations) => (this.durations = durations));
  }

  // Positions
  getPosition(timeline: Timeline, item: TimelineItem) {
    return this._positions.get(this.getItemPositionKey(timeline, item));
  }

  setPosition(
    timeline: Timeline,
    item: TimelineItem,
    position: { x: number; y: number },
    updatePositions = false
  ) {
    this._positions.set(this.getItemPositionKey(timeline, item), position);

    if (updatePositions) {
      this.updatePositions.next();
    }
  }

  // Dragging
  isDragging = (item: TimelineItem) => this.draggingItems.has(item);

  wasDragged = (item: TimelineItem) => this.draggingItemsPrevious.has(item);

  startDragging(
    timeline: Timeline,
    items: TimelineItem[],
    dragItem: TimelineItem
  ) {
    const originalInstance = dragItem.getOriginalInstance();

    this.draggingItemClampStart = originalInstance;
    this.draggingItemClampEnd = originalInstance;

    //  Add dragged item
    this.draggingItemsPrevious.clear();
    this.draggingItems.add(originalInstance);
    this.draggingStartPositions.set(originalInstance.layerId, {
      ...this.getPosition(timeline, originalInstance),
    });

    // NOTE: Special case - main timeline with timelines layer - move all clips at the same time (for shorter timeline)
    if (timeline.type === 'main' && timeline.options.parentLayer !== null) {
      this.draggingItemClampStart = items[0];
      this.draggingItemClampEnd = items[items.length - 1];

      items
        .filter((i) => i.layerId !== originalInstance.layerId)
        .forEach((timelineItem) => {
          const position = this.getPosition(timeline, timelineItem);

          this.draggingItems.add(timelineItem);
          this.draggingStartPositions.set(timelineItem.layerId, {
            ...position,
          });
        });
    }

    // NOTE: Special case - text timeline with timelines layer - move dragged clip + possible virtual clips due to repetitions
    if (timeline.type === 'overlays' && timeline.options.parentLayer !== null) {
      this.draggingItemClampStart = originalInstance;
      this.draggingItemClampEnd = originalInstance;

      items
        .filter((i) => i.layerId !== originalInstance.layerId)
        .forEach((timelineItem) => {
          const position = this.getPosition(timeline, timelineItem);

          this.draggingItems.add(timelineItem);
          this.draggingStartPositions.set(timelineItem.layerId, {
            ...position,
          });
        });
    }
  }

  calcDragPositions(
    timeline: Timeline,
    dragDistance: number,
    containerWidth: number
  ) {
    this.draggingItems.forEach((dragItem) => {
      const dragStartPos = this.getDragStartPosition(dragItem);
      const newX =
        this.calcDragDiff(dragDistance, containerWidth) + dragStartPos.x;

      this.setPosition(
        timeline,
        dragItem,
        {
          x: newX,
          y: dragStartPos.y,
        },
        true
      );
    });
  }

  clearDragging() {
    this.draggingItems.forEach((item) => this.draggingItemsPrevious.add(item));

    this.draggingItems.clear();
    this.draggingStartPositions.clear();
    this.draggingItemClampStart = null;
    this.draggingItemClampEnd = null;
  }

  getDragStartPosition(item: TimelineItem) {
    return this.draggingStartPositions.get(item.layerId);
  }

  resetDragPositions(timeline: Timeline) {
    this.draggingItems.forEach((dragItem: TimelineItem) => {
      this.setPosition(
        timeline,
        dragItem,
        this.draggingStartPositions.get(dragItem.layerId)
      );
    });
  }

  private calcDragDiff(dragDistance: number, containerWidth: number) {
    const firstItemPos = this.draggingStartPositions.get(
      this.draggingItemClampStart.layerId
    );
    const firstItemPosX = firstItemPos.x + dragDistance;
    const clampFirstX = firstItemPosX > 0 ? 0 : Math.abs(firstItemPosX);

    const lastItemPos = this.draggingStartPositions.get(
      this.draggingItemClampEnd.layerId
    );
    const lastItemPosX = lastItemPos.x + dragDistance;
    const lastItemPosEndX =
      lastItemPosX +
      this.getItemWidth(this.draggingItemClampEnd, containerWidth);

    const clampLastX =
      lastItemPosEndX < containerWidth ? 0 : lastItemPosEndX - containerWidth;

    return clampFirstX + dragDistance - clampLastX;
  }

  // Stretching

  // Common
  private getItemWidth(item: TimelineItem, containerWidth: number) {
    const durationRatio = item.duration / this.durations.main;
    return durationRatio * containerWidth;
  }

  private getItemPositionKey(timeline: Timeline, item: TimelineItem) {
    return `${item.layerId}`;
  }
}
