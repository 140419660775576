export const asDecimalString = (n: number): string =>
  n.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const asIntString = (n: number) =>
  n.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
