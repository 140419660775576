import { AppState } from '..';
import { createSelector } from '@ngrx/store';

const assetCacheRootState = (state: AppState) => state.assetCache;

export const cachedAssets = createSelector(
  assetCacheRootState,
  ({ cachedAssets }) => cachedAssets
);

export const cachedObjects = createSelector(
  assetCacheRootState,
  ({ cachedObjects }) => cachedObjects
);
