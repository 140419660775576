import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import {
  IStreamingLibService,
  IStreamingParticipant,
  SubjectSession,
  TwilioTokenResponse,
} from '../../interfaces';
import { AudioStreamService, VideoStreamService } from '../../media';
import { CommonApiService } from '../common-api/common-api.service';
import { NetworkQualityService } from '../network-quality/network-quality.service';
import { SessionConfigService } from '../session/session-config.service';
import { BaseStreamingService } from '../streaming/base-streaming.service';
import { SubjectSessionService } from './subject-session.service';

@Injectable()
export class SubjectStreamingService extends BaseStreamingService<
  SubjectSession
> {

  public remoteParticipants$: Observable<IStreamingParticipant[]>;

  constructor(
    audioSource: AudioStreamService,
    videoSource: VideoStreamService,
    twilioProvider: IStreamingLibService,
    session: SubjectSessionService,
    sessionConfig: SessionConfigService,
    networkQualityService: NetworkQualityService,
    private api: CommonApiService
  ) {
    super(
      audioSource,
      videoSource,
      twilioProvider,
      session,
      sessionConfig,
      networkQualityService
    );

    this.remoteParticipants$ = combineLatest([
      this.videoStreamingInstance$,
      session.participants$,
      session.myParticipant$
    ]).pipe(
      map(data => data[0]),
      filter(streaming => streaming != null),
      switchMap(streaming => streaming.remoteParticipants$),
      map(participants => participants.filter(participant => session.getParticipantByIdentity(participant.identity)?.isPinned))
    );
  }

  async getTokenInfo(): Promise<TwilioTokenResponse> {
    return await this.api
      .getTwilioToken({
        identity: this.sessionInfo.session.identity,
        login_id: this.sessionInfo.session.login_id.toString(),
        session_id: this.sessionInfo.session.session_id.toString(),
      })
      .toPromise();
  }
}
