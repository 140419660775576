import {
  selectProjectAssets,
  selectProjectColors,
  selectProjectFonts,
  selectProjectResources,
} from '../selectors/project.selectors';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssetsFacade {
  assets$ = this.store.select(selectProjectAssets);

  colors$ = this.store.select(selectProjectColors);

  fonts$ = this.store.select(selectProjectFonts);

  clips$ = this.store
    .select(selectProjectAssets)
    .pipe(map((data) => data?.filter((asset) => asset.type === 'clip')));

  images$ = this.store
    .select(selectProjectAssets)
    .pipe(map((data) => data?.filter((asset) => asset.type === 'image')));

  resources$ = this.store.select(selectProjectResources);

  constructor(private store: Store) {}
}
