import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  openAudioStream,
  openVideoStream,
} from '../helpers/media-devices.helper';
import { AdvancedMediaTrackConstraints } from '../interfaces/advanced-mediatrack-constraints.interface';

@Injectable()
export class MediaDevicesService {
  devices$ = new BehaviorSubject<MediaDeviceInfo[]>([]);

  private updateAfterStreamOpen = false;

  constructor() {
    this.fetchDevices();
    this.listenToDeviceChanges();
  }

  async openVideoStream(
    constraints?: AdvancedMediaTrackConstraints
  ): Promise<MediaStream> {
    const stream = await openVideoStream(constraints);

    if (this.updateAfterStreamOpen) {
      await this.fetchDevices();
    }

    return stream;
  }

  async openAudioStream(deviceId?: string): Promise<MediaStream> {
    const stream = await openAudioStream(deviceId);

    if (this.updateAfterStreamOpen) {
      await this.fetchDevices();
    }

    return stream;
  }

  private async fetchDevices(): Promise<void> {
    try {
      if(navigator.mediaDevices){
        const devices = await navigator.mediaDevices.enumerateDevices();
        const filtered = devices.filter(
          (device) => device.deviceId && device.label
        );
        this.updateAfterStreamOpen = devices.length !== filtered.length;
        this.devices$.next(filtered);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private listenToDeviceChanges(): void {
    navigator.mediaDevices.ondevicechange = () => {
      this.fetchDevices();
    };
  }
}
