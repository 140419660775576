import {
  ProjectDto,
  ProjectVideoDto,
} from '../../../api/project/project.interfaces';
import { createAction, props } from '@ngrx/store';

import { WorkflowDataDto } from '../../../api/workflow.interfaces';
import { AfterUpdate } from './../interfaces/editor.interface';
import {RenderProgress} from '@openreel/common';
import { WorkflowSteps } from '../interfaces/steps.interface';

const actionType = '[Project]';

export const loadProjectAPI = createAction(
  `${actionType} Load API`,
  props<{ projectId: number }>()
);

export const loadProjectSuccess = createAction(
  `${actionType} Load Success`,
  props<{ data: ProjectDto }>()
);

export const loadProjectFinished = createAction(
  `${actionType} Project Load Finished`
);

export const loadProjectFailure = createAction(`${actionType} Load Failure`);

export const createProjectAPI = createAction(
  `${actionType} Create API`,
  props<{
    captureProjectId: number;
    templateId: number;
    captureVideoIds: number[];
  }>()
);

export const createProjectSuccess = createAction(
  `${actionType} Create Success`,
  props<{ data: ProjectDto }>()
);

export const createProjectFailure = createAction(
  `${actionType} Create Failure`
);

// Editor steps actions
export const updateProjectNameAPI = createAction(
  `${actionType} Update Name API`,
  props<{ name: string; afterUpdate: AfterUpdate }>()
);

export const updateProjectStylingAPI = createAction(
  `${actionType} Update Styling API`,
  props<{ afterUpdate: AfterUpdate }>()
);

export const updateProjectBrandKitAPI = createAction(
  `${actionType} Update Brand Kit API`,
  props<{ brandKitId: number | null; afterUpdate: AfterUpdate }>()
);

export const updateProjectIntroOutroAPI = createAction(
  `${actionType} Update Outro API`,
  props<{ intro: boolean; enabled: boolean; afterUpdate: AfterUpdate }>()
);

export const updateProjectVideoElementsAPI = createAction(
  `${actionType} Update Video Elements API`,
  props<{ afterUpdate: AfterUpdate }>()
);

export const updateProjectWorkflowAPI = createAction(
  `${actionType} Update Project Workflow API`,
  props<{ currentStep: WorkflowSteps; data: WorkflowDataDto }>()
);

export const updateProjectSuccess = createAction(
  `${actionType} Update Success`,
  props<{ data: ProjectDto }>()
);

export const updateProjectFailure = createAction(
  `${actionType} Update Failure`
);

export const closeProject = createAction(`${actionType} Close Project`);

export const saveDraftWorkflowData = createAction(
  `${actionType} Save Draft Workflow Data`,
  props<{ draftWorkflowData: WorkflowDataDto }>()
);

// Render actions
export const renderProject = createAction(`${actionType} Render`);

export const renderProjectAccepted = createAction(
  `${actionType} Render Accepted`,
  props<{ videoData: ProjectVideoDto }>()
);

export const cancelProjectRender = createAction(`${actionType} Cancel Render`);

export const cancelProjectRenderAccepted = createAction(
  `${actionType} Cancel Render Accepted`
);

export const setRenderProgress = createAction(
  `${actionType} Set Render Progress`,
  props<{ renderProgress: RenderProgress }>()
);
