import * as fromActions from '../actions/editor.actions';
import * as fromSelectors from '../selectors/editor.selectors';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class EditorFacade {
  editor$ = this.store.select(fromSelectors.selectEditor);

  currentStep$ = this.store.select(fromSelectors.selectCurrentStep);
  maxStep$ = this.store.select(fromSelectors.selectMaxStep);
  steps$ = this.store.select(fromSelectors.selectSteps);

  saved$ = this.store.select(fromSelectors.selectLastActionSaved);

  constructor(private store: Store) {}

  nextStep() {
    this.store.dispatch(fromActions.nextStep());
  }

  setStep(step: number) {
    this.store.dispatch(fromActions.setStep({ step }));
  }

  setChildStep(childStep: number) {
    this.store.dispatch(fromActions.setChildStep({ childStep }));
  }
}
