export const EVT_WORKFLOW_RENDER_PROGRESS = 'workflow-render-progress';
export const EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID = 'join-to-room-via-capture-project-id';
export const getRoomByCaptureProjectId = (captureProjectId): string => 'capture-project-id-' + captureProjectId;

export interface RenderProgress {
  state: RenderState;
  progress: number;
  projectId: number;
  signedUrl?: string;
  captureProjectId?: number;
}

export interface WorkflowProject {
  captureProjectId: number,
  createdBy: number,
  id: number,
  name: string,
  step: number,
  templateId: number,
  workflow: never,
  render: RenderProgress
}

export type RenderState = 'rendering' | 'done' | 'failed' | 'cancelled';
