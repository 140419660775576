import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectSocketService } from '../../socket/subject-socket.service';
import {
  StartVideo,
  StopVideo,
  UploadVideoEnd,
  TranscodeVideoS3UploadComplete,
  AckStartVideo,
  CheckRecordingStatus,
  AckStopVideo,
  AckStartUploading,
  VideoUploadStatus,
  UploadVideoEndApp,
  AckUploadVideoEndApp,
  CancelVideoUpload,
  EVT_D2S_START_RECORDING,
  EVT_D2S_STOP_RECORDING,
  EVT_D2S_UPLOAD_VIDEO_END,
  EVT_D2S_TRANSCODE_VIDEO_END,
  EVT_D2S_CANCEL_UPLOAD,
  EVT_S2D_START_RECORDING_ACK,
  EVT_S2D_CHECK_RECORDING,
  EVT_S2D_STOP_RECORDING_ACK,
  EVT_S2D_START_UPLOADING,
  EVT_S2D_UPLOAD_STATUS,
  EVT_S2D_UPLOAD_DONE_APP,
  EVT_S2D_CANCEL_UPLOAD_ACK,
} from '../../../interfaces/socket-events';

@Injectable()
export class SocketExtensionRecordingService {
  startVideo$: Observable<StartVideo>;
  stopVideo$: Observable<StopVideo>;
  uploadVideoEnd$: Observable<UploadVideoEnd>;
  transcodeVideoEnd$: Observable<TranscodeVideoS3UploadComplete>;
  cancelVideoUpload$: Observable<CancelVideoUpload>;

  constructor(private socket: SubjectSocketService) {
    this.startVideo$ = this.socket.getMySocketEventByName(
      EVT_D2S_START_RECORDING
    );
    this.stopVideo$ = this.socket.getMySocketEventByName(
      EVT_D2S_STOP_RECORDING
    );
    this.uploadVideoEnd$ = this.socket.getMySocketEventByName(
      EVT_D2S_UPLOAD_VIDEO_END
    );
    this.transcodeVideoEnd$ = this.socket.getMySocketEventByName(
      EVT_D2S_TRANSCODE_VIDEO_END
    );
    this.cancelVideoUpload$ = this.socket.getMySocketEventByName(
      EVT_D2S_CANCEL_UPLOAD
    );
  }

  sendAckVideoStart(value: Partial<AckStartVideo>) {
    this.socket.emitSocket({
      eventName: EVT_S2D_START_RECORDING_ACK,
      data: value,
    });
  }

  sendRecordingStatus(status: CheckRecordingStatus) {
    this.socket.emitSocket({
      eventName: EVT_S2D_CHECK_RECORDING,
      data: status,
    });
  }

  sendAckForRecordStop(value: AckStopVideo) {
    this.socket.emitSocket({
      eventName: EVT_S2D_STOP_RECORDING_ACK,
      data: value,
    });
  }

  sendAckStartUploading(value: AckStartUploading) {
    this.socket.emitSocket({
      eventName: EVT_S2D_START_UPLOADING,
      data: value,
    });
  }

  sendUploadVideoStatus(value: VideoUploadStatus) {
    this.socket.emitSocket({ eventName: EVT_S2D_UPLOAD_STATUS, data: value });
  }

  async sendVideoUploadEnd(value: UploadVideoEndApp) {
    return await this.socket.sendAndWaitAck<
      UploadVideoEndApp,
      AckUploadVideoEndApp
    >({ eventName: EVT_S2D_UPLOAD_DONE_APP, data: value });
  }
  async sendAckCancelUpload(stat: '0' | '1', videoid: number) {
    this.socket.emitSocket({
      data: {
        stat,
        videoid,
        status: '2',
      },
      eventName: EVT_S2D_CANCEL_UPLOAD_ACK,
    });
  }
}
