import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { first, flatMap } from 'rxjs/operators';
import { GlobalService } from '../services/global/global.service';
import { isSubjectSessionHolder } from '../interfaces/session-holder.interface';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AddTokenInterceptor<T> implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private global: GlobalService
  ) {}

  intercept(
    request: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    if (
      request.headers.has('device-token') ||
      request.headers.has('email-token') ||
      request.headers.has('access-token')
    ) {
      return next.handle(request);
    }
    const currentComponent = this.global.activeComponent$.value;
    if (isSubjectSessionHolder(currentComponent)) {
      const cloned = request.clone({
        setHeaders: {
          'device-token': currentComponent.session.session.auth_token,
        },
      });
      return next.handle(cloned);
    } else {
      return this.authService.token$.pipe(
        first(),
        flatMap((token) => {
          let authReq = request;
          if (token) {
            const headers = {};
            if (this.authService.role === 'collaborator')
              headers['email-token'] = token;
            else headers['access-token'] = token;

            authReq = request.clone({
              setHeaders: headers,
            });
          }
          return next.handle(authReq);
        })
      );
    }
  }
}
