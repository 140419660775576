<div class="flex-col h-full" *ngIf="(selectedProject$ | async) === null">
  <div class="line-height-36 m-bottom-12">My Projects</div>
  <div class="scroll-container flex-auto overflow-y-scroll p-right-20">
    <div class="content-grid p-right-12">
      <div
        class="project p-12 relative b-radius-8"
        *ngFor="let project of projects$ | async"
        (click)="selectProject(project.id)"
      >
        <div class="flex-col h-full">
          <div
            class="image-container flex-auto m-bottom-8"
            [style.background-image]="
              project.project_thumb | backgroundImageFallback
            "
          ></div>
          <div class="flex-none">
            <div class="text-12 text-weight-500">
              {{ project.name }}
            </div>
            <div class="text-10">Project</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<openreel-select-files-projects-clips
  *ngIf="(selectedProject$ | async) !== null"
  [canGoBack]="canGoBack"
  (back)="selectProject(null)"
>
</openreel-select-files-projects-clips>
