import * as fromActions from './../actions/timelines.actions';
import * as fromProjectActions from '../../../store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { TimelinesFacade } from '../facades/timelines.facade';
import { PodcastWorkflowBuilder } from '../../../shared/builders/workflow-podcast.builder';
import { AlertService } from '../../../core/services/alert.service';
import { EMPTY, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { OrAssetsFile } from '../../../../api/workflow.interfaces';
import { TemplateSettingsFacade } from '../../../store/facades/template-settings.facade';
import { cloneDeep } from 'lodash-es';
import {
  SelectFilesDialogComponent,
  SelectFilesDialogResult,
} from '../../../select-files/components/select-files-dialog/select-files-dialog.component';
import { ClipTypes } from '../../../select-files/store/interfaces/clip-selection.interfaces';
import { ClipAddEvent } from '../interfaces/timelines.interfaces';
import {
  checkOverlaysForElementsOutOfBounds,
  updateTimelineData,
} from '../helpers/effects.helpers';
import { WorkflowSteps } from '../../../store/interfaces/steps.interface';

@Injectable()
export class MainClipsTimelinesEffects {
  moveMainClipInsideChildTimeline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveItem),
      filter(({ event }) => event.timeline.type === 'main'),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ event }, workflow]) => {
        const workflowWriter = new PodcastWorkflowBuilder(workflow);
        const updatedItems = workflowWriter.moveMainClipInsideChildTimeline(
          event
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: workflowWriter.toWorkflow(),
          }),
          fromActions.updateTimelineItems({
            items: updatedItems,
          }),
        ];
      })
    )
  );

  switchTimelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.switchTimelines),
      withLatestFrom(this.projectFacade.workflow$),
      map(([{ event }, workflow]) => {
        const workflowWriter = new PodcastWorkflowBuilder(workflow);
        workflowWriter.switchMainClipTimelines(event);

        return fromProjectActions.updateProjectWorkflowAPI({
          currentStep: WorkflowSteps.VideoClips,
          data: workflowWriter.toWorkflow(),
        });
      })
    )
  );

  replaceMainClip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.replaceMainClips),
      withLatestFrom(
        this.projectFacade.captureProjectId$,
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      switchMap(([{ event }, captureProjectId, timelines, workflow]) => {
        const excludeRecordings = timelines
          .filter((t) => t.type === 'main')
          .map(
            (timeline) => (timeline.data[0].asset.file as OrAssetsFile).path
          );

        const dialogRef = this.dialog.open(SelectFilesDialogComponent, {
          panelClass: 'no-p',
          data: {
            hideSidebar: true,
            maxSelectedClips: 1,
            selectedType: {
              type: ClipTypes.MyProjects,
              selectedProjectId: captureProjectId,
              excludeRecordings,
            },
          },
        });

        return dialogRef.afterClosed().pipe(
          switchMap((dialogResult: SelectFilesDialogResult) => {
            if (!dialogResult || dialogResult.selectedRecordings.length === 0) {
              return of({
                canAdd: false,
                timelines: null,
                timelinesLayer: null,
                workflowWriter: null,
              });
            }

            const {
              ovra_session_videos_id,
              video_length,
              video_name,
            } = dialogResult.selectedRecordings[0];

            const clip: ClipAddEvent = {
              assetFileId: ovra_session_videos_id,
              assetProviderType: 'or-recordings',
              duration: video_length * 1000,
              name: video_name,
            };

            const workflowWriter = new PodcastWorkflowBuilder(workflow);
            const {
              timelinesLayer,
              maxDuration,
            } = workflowWriter.replaceMainClip(event, clip);

            return checkOverlaysForElementsOutOfBounds(
              maxDuration,
              timelines.filter((t) => t.type !== 'main'),
              this.alertService
            ).pipe(
              switchMap(({ canAdd }) =>
                of({ canAdd, timelines, timelinesLayer, workflowWriter })
              )
            );
          }),
          switchMap(({ canAdd, timelines, timelinesLayer, workflowWriter }) => {
            if (!canAdd) {
              return EMPTY;
            }

            const workflowAfterUpdate = workflowWriter.toWorkflow();

            const updatedTimelines = cloneDeep(timelines);
            updateTimelineData(
              updatedTimelines,
              workflowAfterUpdate,
              this.templateSettings
            );

            return [
              fromProjectActions.updateProjectWorkflowAPI({
                currentStep: WorkflowSteps.VideoClips,
                data: workflowAfterUpdate,
              }),
              fromActions.updateTimelines({ updatedTimelines }),
              fromActions.editSplits(),
            ];
          })
        );
      })
    )
  );

  removeMainClipPair$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeMainClip),
      filter(({ event }) => event.timeline.options.parentLayer !== null),
      withLatestFrom(
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      switchMap(([{ event }, timelines, workflow]) => {
        const workflowWriter = new PodcastWorkflowBuilder(workflow);
        workflowWriter.removeMainClipPair(event);

        const workflowAfterUpdate = workflowWriter.toWorkflow();

        const updatedTimelines = cloneDeep(timelines);
        updateTimelineData(
          updatedTimelines,
          workflowAfterUpdate,
          this.templateSettings
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: workflowAfterUpdate,
          }),
          fromActions.updateTimelines({
            updatedTimelines,
          }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly timelinesFacade: TimelinesFacade,
    private readonly projectFacade: ProjectFacade,
    private readonly templateSettings: TemplateSettingsFacade,
    private readonly alertService: AlertService,
    private readonly dialog: MatDialog
  ) {}
}
