import * as fromActions from './../actions/clip-selection.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { filter, map, switchMap } from 'rxjs/operators';
import { ProjectService } from '../../../../../../../libs/common/src';
import { HttpParams } from '@angular/common/http';
import { from } from 'rxjs';

@Injectable()
export class ProjectEffects {
  loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProjects),
      switchMap((action) =>
        this.projectService
          .getProjectList({
            limit: 20,
            page: 1,
            orderField: 'createdAt',
            orderSort: 'DESC',
          })
          .pipe(
            map((projects) => projects.filter((p) => p.total_clips > 0)),
            map((projects) => fromActions.loadProjectsSuccess({ projects }))
          )
      )
    )
  );

  selectProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.selectProject),
      filter((action) => action.selectedProjectId !== null),
      map(({ selectedProjectId }) =>
        fromActions.loadProjectRecordings({ selectedProjectId })
      )
    )
  );

  loadRecordings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProjectRecordings),
      switchMap((action) => {
        const params = new HttpParams()
          .append('page', '0')
          .append('perPage', '20');

        return from(
          this.projectService.getProjectRecordings(
            action.selectedProjectId,
            params
          )
        ).pipe(
          map((recordings) =>
            fromActions.loadProjectRecordingsSuccess({
              recordings: recordings.rows,
            })
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private readonly projectService: ProjectService
  ) {}
}
