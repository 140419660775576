import * as fromActions from './../actions/timelines.actions';
import * as fromProjectActions from '../../../store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { TimelinesFacade } from '../facades/timelines.facade';
import { WorkflowBuilder } from '../../../shared/builders/workflow.builder';
import { AlertService } from '../../../core/services/alert.service';
import { of } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import { TemplateSettingsFacade } from '../../../store/facades/template-settings.facade';
import {
  checkOverlaysForElementsOutOfBounds,
  updateTimelineData,
} from '../helpers/effects.helpers';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { WorkflowSteps } from '../../../store/interfaces/steps.interface';
import { DuplicateClipCommand } from '../../../shared/builders/duplicate-clip.command';

@Injectable()
export class MainClipsEffects {
  moveMainClip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveMainClip),
      withLatestFrom(
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      switchMap(([action, timelines, workflow]) => {
        const { item, previousIndex, currentIndex } = action.event;

        const workflowWriter = new WorkflowBuilder(workflow);
        workflowWriter.reorderMainClips(
          item.layerId,
          previousIndex,
          currentIndex
        );

        const workflowAfterUpdate = workflowWriter.toWorkflow();

        const updatedTimelines = cloneDeep(
          timelines.filter((t) => t.type === 'main')
        );
        updateTimelineData(
          updatedTimelines,
          workflowAfterUpdate,
          this.templateSettings
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: workflowAfterUpdate,
          }),
          fromActions.updateTimelines({ updatedTimelines }),
        ];
      })
    )
  );

  removeMainClip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeMainClip),
      filter(({ event }) => event.timeline.options.parentLayer === null),
      withLatestFrom(
        this.timelinesFacade.timelines$,
        this.timelinesFacade.duration$,
        this.projectFacade.workflow$
      ),
      switchMap(([action, timelines, duration, workflow]) => {
        const { item } = action.event;
        const newDuration = duration - item.duration;

        return checkOverlaysForElementsOutOfBounds(
          newDuration,
          timelines.filter((t) => t.type !== 'main'),
          this.alertService
        ).pipe(
          switchMap((result) =>
            of({ ...result, action, duration, timelines, workflow })
          )
        );
      }),
      filter(({ canAdd }) => canAdd),
      switchMap(({ action, duration, timelines, workflow }) => {
        const { item } = action.event;

        const workflowWriter = new WorkflowBuilder(workflow);
        workflowWriter.removeMainClip(item.layerId);

        const workflowAfterUpdate = workflowWriter.toWorkflow();

        const updatedTimelines = cloneDeep(timelines);
        updateTimelineData(
          updatedTimelines,
          workflowAfterUpdate,
          this.templateSettings
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: workflowAfterUpdate,
          }),
          fromActions.updateTimelines({ updatedTimelines }),
        ];
      })
    )
  );

  duplicateClip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.duplicateClip),
      withLatestFrom(
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      switchMap(([{ event }, timelines, workflow]) => {
        const { updatedWorkflow } = new DuplicateClipCommand(workflow).run(
          event
        );

        const updatedTimelines = cloneDeep(timelines);
        updateTimelineData(
          updatedTimelines,
          updatedWorkflow,
          this.templateSettings
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelines({ updatedTimelines }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly timelinesFacade: TimelinesFacade,
    private readonly projectFacade: ProjectFacade,
    private readonly templateSettings: TemplateSettingsFacade,
    private readonly alertService: AlertService
  ) {}
}
