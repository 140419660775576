import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import { LayerOptions, VideoLayer } from '../../../api/workflow.interfaces';
import { BaseCommand } from './base.command';

export class UpdateClipTrimsCommand extends BaseCommand<TimelineInterfaces.UpdateClipTrims> {
  run(event: TimelineInterfaces.UpdateClipTrims) {
    const { clipIndex, timelinesToUpdate } = this.getItemsForUpdate(event);

    timelinesToUpdate.forEach((timelineToUpdate, index) => {
      const clipToUpdate = timelineToUpdate.layers[clipIndex] as LayerOptions &
        VideoLayer;
      const clipToUpdateAsset = this.getAsset(clipToUpdate.assetId);

      const oldDuration = clipToUpdateAsset.trimTo - clipToUpdateAsset.trimFrom;

      clipToUpdateAsset.trimFrom = event.split.videos[index].trimFrom;
      clipToUpdateAsset.trimTo = event.split.videos[index].trimTo;

      const newDuration = clipToUpdateAsset.trimTo - clipToUpdateAsset.trimFrom;
      const diffDuration = newDuration - oldDuration;

      clipToUpdate.visibility.endAt =
        clipToUpdate.visibility.startAt + newDuration;

      for (let i = clipIndex + 1; i < timelineToUpdate.layers.length; i++) {
        timelineToUpdate.layers[i].visibility.startAt += diffDuration;
        timelineToUpdate.layers[i].visibility.endAt += diffDuration;
      }
    });

    if (event.timeline.type === 'main') {
      this.removeLayersOutOfBounds();
    }

    return {
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
