import { TeleprompterScript } from './interfaces';
// all teleprompter related interfaces/types should go this file
export enum CaptureTeleprompterStatus {
  'PENDING' = 'pending', // state to represent transition between states (for.ex: waiting for socket ack). Actions are disabled
  'STOPPED' = 'stopped', // initial state, script box is off. Actions: Start
  'COUNTING' = 'counting', // script box is on & about to start scrolling. Actions: [NONE]
  'PLAYING' = 'playing', // script box is on & scrolling. Actions: Pause, Stop
  'PAUSED' = 'paused', // script box is on & paused during scrolling. Actions: Resume, Stop
  'IDLE' = 'idle', // script box is on & done scrolling. Actions: Restart, Stop
}

//////////////////////////////////////////
/// TELEPROMPTER SOCKETS
//////////////////////////////////////////
export enum CaptureTeleprompterCommand {
  'LOAD' = 'load',
  'PLAY' = 'play',
  'CLOSE' = 'close',
  'PAUSE' = 'pause',
  'RESUME' = 'resume',
  'RESTART' = 'restart',
  'IDLE' = 'idle',
}
export interface TeleprompterSocketRequest {
  command: CaptureTeleprompterCommand;
  SessionID: number;
  script_content: {
    scriptData: string[];
    timerData: number[];
  };
  script_title: string;
  scroll_speed: number;
  font_size: string;
  scriptContentLink: string;
  content_change: number;
  tele_script_id: number;
  txtColor: string;
  value: string;

  // nextgen properties
  countDown: number;
  fontSize: number;
  split: string;
  speed: number;
  background: string;
  keepOnScreen: boolean;
  enabledStatus: {
    [identity: string]: boolean;
  };
}

export interface TeleprompterSocketAckRequest {
  command: CaptureTeleprompterCommand;
}

export interface TeleprompterInfo {
  isFullScreen?: boolean;
}
export interface AckTeleprompterInfo {
  teleprompterTotalLines: number;
  teleprompterCurrentLine: number;
  teleprompterWidth: number;
  teleprompterHeight: number;
}
export class TeleprompterPresetsDto {
  speed: number;
  font_size: number;
  screen_setup: string;
  background: string;
}
export interface TeleprompterUpdateInfo {
  script: TeleprompterScript;
  settings: TeleprompterPresetsDto;
  commandRequest: TeleprompterSocketRequest;
}
export interface PresetDevice {
  label: string;
  icon: string;
  isMobile: boolean;
  fullScreen: { width: number; height: number };
  halfScreen: { width: number; height: number };
}
export const TeleprompterPresetDevices: PresetDevice[] = [
  {
    label: 'PC (1920 X 1080)',
    icon: 'desktop_windows',
    fullScreen: { width: 1012, height: 300 },
    halfScreen: { width: 491, height: 200 },
    isMobile: false,
  },
  {
    label: 'Mac 13 inch (1440 X 900)',
    icon: 'laptop_mac',
    fullScreen: { width: 725, height: 300 },
    halfScreen: { width: 347, height: 200 },
    isMobile: false,
  },
  {
    label: 'Laptop (1920 X 1080)',
    icon: 'laptop',
    fullScreen: { width: 1012, height: 300 },
    halfScreen: { width: 491, height: 200 },
    isMobile: false,
  },

  {
    label: 'iPhone',
    icon: 'phone_iphone',
    fullScreen: { width: 443, height: 244 },
    halfScreen: { width: 443, height: 244 },
    isMobile: true,
  },
  {
    label: 'iPad',
    icon: 'tablet_mac',
    fullScreen: { width: 770, height: 703 },
    halfScreen: { width: 770, height: 703 },
    isMobile: true,
  },
];

export const POPUP_PADDING = 48;
export const TELEPROMPTER_MARGIN = 15;
export const TELEPROMPTER_PADDING = 15;
export const POPUP_BASE_WIDTH = 800;
export const POPUP_BASE_HEIGHT = 410;
export const SUBJECT_DEFAULT_TELEPROMPTER_HEIGHT = 300;
export const SUBJECT_DEFAULT_TELEPROMPTER_HALF_HEIGHT = 200;
