import { teleprompterOptions } from './../teleprompter-options';
import { Cleanupable } from '../../../classes/cleanupable';
import { Subscription, BehaviorSubject, timer } from 'rxjs';
import { Component, ViewChild, ElementRef } from '@angular/core';

import { CaptureTeleprompterStatus } from '../../../interfaces/teleprompter.interface';
import { WordCount } from './../../../pipes/word-count.pipe';
import {
  AnimationBuilder,
  AnimationPlayer,
  useAnimation,
} from '@angular/animations';
import { ScrollAnimation } from './scroll.animation';
import { take } from 'rxjs/operators';

export interface SubjectTeleprompterOptions {
  countDown: number;
  fontSize: number;
  speed: number;
  split: string;
  background: string;
  content: string;
  keepOnScreen: boolean;
  contentChange: boolean;
  totalLines?: number;
  currentLine?: number;
}

interface AnimationParams {
  startTop: number;
  endTop: number;
  time: number;
}

/**
 * Teleprompter tool. Used both by subject side and director side (as preview).
 */

@Component({
  selector: 'openreel-subject-teleprompter',
  templateUrl: './subject-teleprompter.component.html',
  styleUrls: ['./subject-teleprompter.component.scss'],
})
export class SubjectTeleprompterComponent extends Cleanupable {
  // the current state of the teleprompter
  status$ = new BehaviorSubject<CaptureTeleprompterStatus>(
    CaptureTeleprompterStatus.PENDING
  );
  options: SubjectTeleprompterOptions;
  background = 'white_in_black';
  fontSize = 30;
  perLineTime = 0;
  initTop = 0;
  text = '';
  scrollAnimationProgress = false;
  teleprompterCountDown: Subscription;
  teleprompterEndDelay: Subscription;
  @ViewChild('wrapper') wrapperEl: ElementRef<HTMLDivElement>;
  @ViewChild('content') contentEl: ElementRef<HTMLDivElement>;
  private teleprompterAnimationPlayer: AnimationPlayer;
  contentChangePause = false; //whether teleprompter pause or not during on the fly change

  constructor(private animationBuilder: AnimationBuilder) {
    super();
  }

  setupPresentation(autoStart: boolean) {
    if (this.options.background) {
      this.background = this.options.background;
    }
    if (this.options.fontSize) {
      this.fontSize = this.options.fontSize;
    }
    if (this.options.contentChange) {
      //if on the fly changes store the previous position
      const teleprompterPosition = this.getTeleprompterPosition();
      this.options.currentLine = teleprompterPosition.currentLine;
      this.options.totalLines = teleprompterPosition.totalLines;
    }
    this.scrollAnimationProgress = false;
    //if there is any active teleprompter destroy first
    this.destroyAllTeleprompterEvent();
    this.text = this.options.content;
    this.status$.next(CaptureTeleprompterStatus.PENDING);
    if (autoStart) {
      this.status$.next(CaptureTeleprompterStatus.COUNTING);
      //countdown timer
      this.teleprompterCountDown = timer(
        this.options.countDown * 1000
      ).subscribe(() => {
        if (this.options.contentChange) {
          this.checkCursorPosition();
        }
        this.startScrollAnimation();
      });
    }
  }
  startScrollAnimation() {
    //create animation for scroll
    this.initTop = this.contentEl.nativeElement.getBoundingClientRect().top;
    const animationFactory = this.animationBuilder.build([
      useAnimation(ScrollAnimation, {
        params: this.getAnimationParams(),
      }),
    ]);
    this.teleprompterAnimationPlayer = animationFactory.create(
      this.contentEl.nativeElement
    );
    this.teleprompterAnimationPlayer.onStart(() => {
      if (this.contentChangePause) {
        // if it's on the fly change during pause.
        // pause the teleprompter after initialize scroll animation
        this.pause();
      } else {
        this.scrollAnimationProgress = true;
        this.status$.next(CaptureTeleprompterStatus.PLAYING);
      }
    });
    this.teleprompterAnimationPlayer.onDone(() => {
      if (this.scrollAnimationProgress) {
        this.teleprompterScrollEnd();
      }
    });
    this.teleprompterAnimationPlayer.play();
  }
  getAnimationParams(): AnimationParams {
    const currentLine = this.options.currentLine || 0;
    const totalLines = this.options.totalLines || 0;
    const bottom = this.wrapperEl.nativeElement.offsetHeight / 2;
    let totalScrollTime = this.getTotalScrollTime();
    let startTop = this.wrapperEl.nativeElement.clientHeight / 2; //default start from middle
    const endTop = this.contentEl.nativeElement.scrollHeight - bottom;

    if (currentLine !== 0) {
      //teleprompter start from specific position
      const teleprompterPosition = this.getTeleprompterPosition();
      const newCurrentLine =
        (currentLine / totalLines) * teleprompterPosition.totalLines;
      totalScrollTime =
        (teleprompterPosition.totalLines - newCurrentLine) * this.perLineTime;
      startTop = startTop - newCurrentLine * this.fontSize;
    }
    return { startTop: startTop, endTop: endTop, time: totalScrollTime };
  }
  getTeleprompterPosition() {
    const currentTop = this.contentEl.nativeElement.getBoundingClientRect().top;
    let currentLine = this.initTop ? (this.initTop - currentTop) / this.fontSize : 0;
    const totalLines =
      this.contentEl.nativeElement.scrollHeight / this.fontSize;
    if (currentLine > totalLines) currentLine = totalLines;
    return { currentLine, totalLines };
  }
  teleprompterScrollEnd() {
    if (this.options.keepOnScreen) {
      this.goIdle();
    } else {
      this.removeTeleprompterScreen();
    }
  }
  getTotalScrollTime() {
    const elContent = this.contentEl.nativeElement;
    //calculate the number of lines (totalheight/lineheight)
    const contentHeight = elContent.scrollHeight;
    const totalLines = contentHeight / this.fontSize;
    const wordsCount = new WordCount().transform(elContent.textContent);
    const speedSetting = teleprompterOptions.speeds.find(
      (speed) => speed.value === this.options.speed
    );
    const wordsPerMinute = speedSetting ? speedSetting.wordsPerMinute : 100;
    const totalScrollTime = (wordsCount / wordsPerMinute) * 60;
    this.perLineTime = totalScrollTime / totalLines;
    return totalScrollTime;
  }

  async loadTeleprompter(
    options: SubjectTeleprompterOptions,
    autoStart = true
  ) {
    this.options = options;
    const currentStatus = await this.getStatus();
    if (
      (this.options.currentLine || this.options.contentChange) 
      &&
      currentStatus === CaptureTeleprompterStatus.PAUSED
    ) {
      this.contentChangePause = true;
    } else {
      this.contentChangePause = false;
    }
    this.setupPresentation(autoStart);
  }
  checkCursorPosition() {
    const currentCursorPosition = this.contentEl.nativeElement.querySelector(
      '.currentPosition'
    );
    //check if director send any cursor position
    if (currentCursorPosition instanceof HTMLElement) {
      const cursorTop = currentCursorPosition.offsetTop;
      this.options.currentLine = cursorTop / this.fontSize;
      this.options.totalLines = this.getTeleprompterPosition().totalLines;
    }
  }
  pause() {
    this.teleprompterAnimationPlayer.pause();
    this.status$.next(CaptureTeleprompterStatus.PAUSED);
  }

  resume() {
    this.teleprompterAnimationPlayer.play();
    this.status$.next(CaptureTeleprompterStatus.PLAYING);
  }

  stop() {
    this.scrollAnimationProgress = false;
    this.status$.next(CaptureTeleprompterStatus.STOPPED);
    this.destroyAllTeleprompterEvent();
  }

  destroyAllTeleprompterEvent() {
    this.text = null;
    if (this.teleprompterAnimationPlayer) {
      this.teleprompterAnimationPlayer.destroy();
      this.teleprompterAnimationPlayer = null;
    }
    if (this.teleprompterCountDown) this.teleprompterCountDown.unsubscribe();
    if (this.teleprompterEndDelay) this.teleprompterEndDelay.unsubscribe();
  }

  goIdle() {
    this.status$.next(CaptureTeleprompterStatus.IDLE);
  }

  removeTeleprompterScreen() {
    this.teleprompterEndDelay = timer(this.perLineTime * 1000).subscribe(() => {
      this.stop();
    });
  }
  private async getStatus() {
    return await this.status$.pipe(take(1)).toPromise();
  }
}
