import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import {
  LayerOptions,
  LottieLayer,
  TimelineType,
  VideoLayer,
} from '../../../api/workflow.interfaces';
import {
  LayerDataChangedEvent,
  TimelineItem,
  timelineItemFactory,
} from '../element.interfaces';

import { BrandKitDto } from '../../../api/brand-kit/brand-kit.interfaces';
import { cloneDeep } from 'lodash';

import { WorkflowReaderService } from '../../core/services/workflow-reader.service';
import { WorkflowBaseBuilder } from './workflow-base.builder';

export class WorkflowBuilder extends WorkflowBaseBuilder {
  // Intro outro
  saveIntroOutro(intro: boolean, enabled: boolean) {
    const sectionId = intro ? 'intro' : 'outro';
    const section = intro
      ? this.source.sections.intro
      : this.source.sections.outro;

    section.enabled = enabled;

    const mainTimeline = this.source.timelines.find((t) => t.type === 'main');
    if (!enabled) {
      mainTimeline.layers = mainTimeline.layers.filter(
        (l) => l.type === 'section' && l.sectionId !== sectionId
      );
    } else {
      const sectionLayer = mainTimeline.layers.find(
        (l) => l.type === 'section' && l.sectionId === sectionId
      );
      if (!sectionLayer) {
        mainTimeline.layers.splice(intro ? 0 : mainTimeline.layers.length, 0, {
          type: 'section',
          sectionId,
        });
      }
    }
  }

  updateIntroOutroData(
    intro: boolean,
    item: TimelineItem,
    data: LayerDataChangedEvent
  ) {
    const section = intro
      ? this.source.sections.intro
      : this.source.sections.outro;

    const layerToUpdate = section.timelines
      .find((t) => t.type === 'main')
      .layers.find((l) => l.layerId === item.layerId) as LottieLayer &
      LayerOptions;

    this.updateLottieLayerAssetStyles(data);
    layerToUpdate.data = data.values[0];

    return new TimelineItem(layerToUpdate, item.asset, item.type);
  }

  // Brand kits
  addOrUpdateLogoFromBrandKit(brandKit: BrandKitDto) {
    this.source.assets = this.source.assets.map((asset) => {
      if (!asset.isLogoAsset) {
        return asset;
      }

      return {
        ...asset,
        file: {
          ...brandKit.assets.logo.file,
        },
        isBrandKitSelected: true,
      };
    });
  }

  removeLogoFromBrandKit() {
    this.source.assets = this.source.assets.map((asset) => {
      if (!asset.isLogoAsset) {
        return asset;
      }

      return {
        ...asset,
        isBrandKitSelected: false,
      };
    });
  }

  addOrUpdateWatermarkFromBrandKit(brandKit: BrandKitDto) {
    throw new Error('Not implemented');

    // if (!brandKit.assets.watermark) return;

    // if (this.source.features.watermark.assetId) {
    //   this.removeAssets(this.source.features.watermark.assetId);
    // }

    // const asset = this.addImageAsset(
    //   brandKit.assets.watermark.file.path as number
    // );

    // this.source.features.watermark = {
    //   ...this.source.features.watermark,
    //   enabled: true,
    //   assetId: asset.id,
    // };

    // this.addOrUpdateWatermark(this.source.features.watermark);
  }

  // Watermark
  addOrUpdateWatermark(changes: LayerDataChangedEvent) {
    this.removeWatermark();
    this.enableWatermark();

    const watermarkLayer = WorkflowReaderService.getWatermarkLayer(this.source)
      .layer;

    if (watermarkLayer.type === 'lottie') {
      this.updateLottieLayerAssetStyles(changes);
      watermarkLayer.data = changes.values[0];
    }

    const hiddenTimeline = this.getTimeline('hidden', true);
    hiddenTimeline.layers.push(watermarkLayer);
  }

  toggleWatermark(enabled: boolean) {
    if (enabled) {
      this.enableWatermark();
    } else {
      this.removeWatermark();
    }
  }

  // Clips, overlays
  removeMainClip(layerId: string) {
    const mainTimeline = this.getTimeline('main');
    if (!mainTimeline || mainTimeline.layers.length === 0) {
      return;
    }

    const layerToRemove = mainTimeline.layers.find(
      (layer) => layer.layerId === layerId
    ) as VideoLayer;

    mainTimeline.layers = mainTimeline.layers.filter(
      (l) => l.layerId !== layerId
    );
    this.removeAssets(layerToRemove.assetId);

    this.removeLayersOutOfBounds();
    this.recalculateVisibility(mainTimeline);
  }

  reorderMainClips(
    layerId: string,
    previousIndex: number,
    currentIndex: number
  ) {
    const mainTimeline = this.getTimeline('main');
    if (!mainTimeline || mainTimeline.layers.length === 0) {
      return;
    }

    const layerToMove = mainTimeline.layers.find(
      (l) => l.type === 'video' && l.layerId === layerId
    );

    const newLayers = cloneDeep(mainTimeline.layers);
    newLayers.splice(previousIndex, 1);
    newLayers.splice(currentIndex, 0, layerToMove);
    mainTimeline.layers = newLayers;

    this.recalculateVisibility(mainTimeline);
  }

  moveOverlay(
    event: TimelineInterfaces.ItemMovedEvent,
    type: TimelineType
  ): TimelineInterfaces.UpdatedItem {
    const timeline = this.getTimeline(type);
    if (!timeline || timeline.layers.length === 0) {
      return;
    }

    const layerToUpdate = timeline.layers.find(
      (l) => l.layerId === event.item.layerId
    );

    if (layerToUpdate.visibility) {
      layerToUpdate.visibility = {
        ...layerToUpdate.visibility,
        startAt: event.startAt,
        endAt:
          event.startAt -
          layerToUpdate.visibility.startAt +
          layerToUpdate.visibility.endAt,
      };
    } else {
      layerToUpdate.visibility = {
        startAt: event.startAt,
      };
    }

    return {
      timelineIndex: 0,
      item: timelineItemFactory(layerToUpdate, type, this.source),
    };
  }

  removeOverlay(event: TimelineInterfaces.TimelineItemEvent) {
    const timeline = this.getTimeline(event.timeline.type);
    if (!timeline || timeline.layers.length === 0) {
      return;
    }

    const layerToRemove = timeline.layers.find(
      (l) => l.layerId === event.item.layerId
    );
    timeline.layers = timeline.layers.filter(
      (l) => l.layerId !== event.item.layerId
    );

    // Remove styles
    const stylesToRemove = [];
    if (layerToRemove.type === 'lottie') {
      stylesToRemove.push(
        ...Object.keys(layerToRemove.data).map(
          (key: string) => layerToRemove.data[key].styleId
        )
      );
    }
    if (layerToRemove.type === 'sublayers') {
      layerToRemove.children.forEach((childLayer: LottieLayer) => {
        stylesToRemove.push(
          ...Object.keys(childLayer.data).map(
            (key: string) => childLayer.data[key].styleId
          )
        );
      });
    }

    this.source.styles = this.source.styles.filter(
      (style) => !stylesToRemove.includes(style.id)
    );

    // Remove assets
    if (event.timeline.type === 'b-roll') {
      this.removeAssets([(layerToRemove as VideoLayer).assetId]);
    }
  }

  resetStyles() {
    if (this.source.styles?.length) {
      this.source.styles = this.source.styles.map((style) => ({
        ...style,
        color: null,
        colorIndex: style.defaultColorIndex,
        fontIndex: 0,
      }));
    }
  }

  private removeWatermark() {
    this.source.features.watermark.enabled = false;
    this.createMainSectionIfNotexists();
    const hiddenTimeline = this.getTimeline('hidden', true);
    hiddenTimeline.layers = hiddenTimeline.layers.filter(
      (layer) => layer.layerId !== 'watermark'
    );
  }

  private enableWatermark() {
    this.source.features.watermark.enabled = true;
  }
}
