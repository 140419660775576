<div class="folder-header text-12 text-weight-500 p-bottom-16">
  MY FILES
</div>
<ul class="m-top-16">
  <li class="m-bottom-16" *ngFor="let option of options">
    <button
      disableRipple
      mat-button
      class="text-weight-400 no-overlay p-0"
      [class.text-color-primary]="option.type === selectedType"
      (click)="onSelect(option)"
    >
      {{ option.name }}
    </button>
  </li>
</ul>
