import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DirectorUserDetails,
  NewVideoRequest,
  NewVideoResponse,
  CameraPresetCreate,
  CameraPresetCreateResponse,
  VersionControlRequest,
  VersionControlResponse,
  VideoType,
  SignedUrl,
} from '../../../interfaces';
import {
  UpdateDeviceInfoResponse,
  UpdateDeviceInfoRequest,
  GetAllDeviceDetailsRequest,
  GetAllDeviceDetailsResponse,
  DeleteVideoResponse,
} from '../../../interfaces/interfaces';

import { commonenv } from '../../../environments/environment';
import { SanitizeFilenameService } from '../sanitize-filename.service';

const URL_DIRECTOR_USER_DETAILS = 'fetch-user-details';
const URL_UPDATE_CONNECTED_DEVICE = 'update-connected-device';
const URL_GET_ALL_DEVICE_DETAILS = 'get-all-device-details';
const URL_REMOVE_CAMERA_PRESET = 'remove-camera-preset';
const URL_ADD_CAMERA_PRESET = 'load-camera-preset';
const URL_VERSION_CONTROL = 'version-control';

@Injectable({
  providedIn: 'root',
})
export class DirectorApiService {
  urls = {
    videoCreate: `${commonenv.nextGenApiUrl}videos/`,
    shortlist: (id) => `${commonenv.nextGenApiUrl}videos/${id}/shortlist`,
    video_delete: (id) => `${commonenv.nextGenApiUrl}videos/${id}`,
  };
  constructor(
    private httpClient: HttpClient,
    private sanitizeFilenameService: SanitizeFilenameService
  ) {}

  updateConnectedDevice(request: UpdateDeviceInfoRequest) {
    return this.httpClient.post<UpdateDeviceInfoResponse>(
      URL_UPDATE_CONNECTED_DEVICE,
      request
    );
  }

  getAllDeviceDetails(request: GetAllDeviceDetailsRequest) {
    return this.httpClient.post<GetAllDeviceDetailsResponse>(
      URL_GET_ALL_DEVICE_DETAILS,
      request
    );
  }

  createNewVideo(request: NewVideoRequest) {

    /**
     * Patch video names
     */
    request.videos = request.videos.map((video) => {
      video.video_name = this.sanitizeFilenameService.sanitize(
        video.video_name
      );
      return video;
    });

    return this.httpClient.post<NewVideoResponse>(this.urls.videoCreate, request);
  }

  deleteVideo(videoId: number) {
    return this.httpClient
      .delete<DeleteVideoResponse>(this.urls.video_delete(videoId))
      .toPromise();
  }

  transcodeVideo(videoId: number, type: string) {
    return this.httpClient
      .post<DeleteVideoResponse>(
        `${commonenv.nextGenApiUrl}videos/${videoId}/transcode`,
        { transcode_type: type }
      )
      .toPromise();
  }
  getUserDetails() {
    return this.httpClient.post<DirectorUserDetails>(
      URL_DIRECTOR_USER_DETAILS,
      {}
    );
  }

  removeCameraPreset(presetId: number, sessionId: number) {
    return this.httpClient
      .post<void>(URL_REMOVE_CAMERA_PRESET, {
        ovra_cameraSetting_presets_id: presetId,
        session_id: sessionId,
      })
      .toPromise();
  }

  addCameraPreset(preset: CameraPresetCreate) {
    return this.httpClient
      .post<CameraPresetCreateResponse>(URL_ADD_CAMERA_PRESET, preset)
      .toPromise();
  }

  async getVideoDownloadUrl(
    videoId: number,
    type = VideoType.ORIGINAL,
    responseType = 'download'
  ): Promise<string> {
    return (await this.getVideoSignedUrlWithExpiry(videoId, type, responseType)).url;
  }

  async getVideoSignedUrlWithExpiry(
    videoId: number,
    type = VideoType.ORIGINAL,
    responseType = 'download'
  ): Promise<SignedUrl> {
    return (
      await this.httpClient
        .get<SignedUrl>(
          `${commonenv.nextGenApiUrl}videos/${videoId}/download-url?video_type=${type}&response_type=${responseType}`
        )
        .toPromise()
    );
  }

  async getPublicVideoId(videoId: number): Promise<string> {
    return (
      await this.httpClient
        .get<{ data: { public_id: string } }>(
          `${commonenv.nextGenApiUrl}videos/${videoId}/public-id`
        )
        .toPromise()
    ).data.public_id;
  }

  async getAppVersionControl(
    data: VersionControlRequest
  ): Promise<VersionControlResponse> {
    const resp = await this.httpClient
      .post<VersionControlResponse>(URL_VERSION_CONTROL, data)
      .toPromise();
    return resp;
  }

  shortlist(recordingId: number) {
    return this.httpClient
      .patch(this.urls.shortlist(recordingId), {})
      .toPromise();
  }
}
