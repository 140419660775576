import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'wordCount' })
export class WordCount implements PipeTransform {
  transform(text: string) {
    if (text && text.trim()) {
      const tempDivElement = document.createElement('div');
      tempDivElement.innerHTML = text;

      text = tempDivElement.textContent || tempDivElement.innerText || '';
      text = text.trim();
      return text
        .split(/\W/)
        .filter((word) => !!word)
        .filter((word) => word.length > 1).length;
    }
    return 0;
  }
}
