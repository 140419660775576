import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild,
} from '@angular/core';
import { Cleanupable, PlayerState, Video } from '@openreel/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { WorkflowPlayerComponent } from '../../../shared/components/workflow-player/workflow-player.component';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { EditorFrame } from '../../editor-frame.interface';

@Component({
  selector: 'openreel-editor-frame-preview',
  templateUrl: './editor-frame-preview.component.html',
  styleUrls: ['./editor-frame-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFramePreviewComponent
  extends Cleanupable
  implements EditorFrame, AfterViewInit {
  @ViewChild(WorkflowPlayerComponent)
  private readonly playerComponent: WorkflowPlayerComponent;
  private readonly isPlaying = new BehaviorSubject<boolean>(false);
  private readonly loaded = new BehaviorSubject<boolean>(false);
  private readonly video = new Subject<Video>();

  loaded$ = this.loaded.asObservable().pipe(distinctUntilChanged());
  isPlaying$ = this.isPlaying.asObservable().pipe(distinctUntilChanged());
  video$ = this.video.asObservable();

  constructor(private readonly projectFacade: ProjectFacade) {
    super();
  }

  ngAfterViewInit() {
    this.projectFacade.data$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(({ data }) => {
        if (data?.video?.signedUrl) {
          this.video.next({
            mainStream: data?.video?.signedUrl,
            contentType: 'video/mp4',
          });
        }
      });
  }

  save() {
    this.projectFacade.closeProject();
  }

  async onVideoDetails() {
    this.loaded.next(true);
  }

  onPlay() {
    this.playerComponent.play();
  }

  onPause() {
    this.playerComponent.pause();
  }

  onStop() {
    this.playerComponent.stop();
  }

  onPlayerState(state: PlayerState) {
    this.isPlaying.next(state === 'playing');
  }
}
