import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import { LottieLayer } from '../../../api/workflow.interfaces';
import { BaseCommand } from './base.command';
import { TimelineItem, timelineItemFactory } from '../element.interfaces';
import { cloneDeep } from 'lodash-es';

export class UpdateTextCommand extends BaseCommand<
  TimelineInterfaces.UpdateTextOverlayDataEvent,
  TimelineItem
> {
  run(event: TimelineInterfaces.UpdateTextOverlayDataEvent) {
    const timeline = this.getTimeline('overlays');
    if (!timeline || timeline.layers.length === 0) {
      return;
    }

    const layerToUpdate = timeline.layers.find(
      (l) => l.layerId === event.item.layerId
    );

    const newData = cloneDeep(event.data);
    this.updateLottieLayerAssetStyles(newData);

    if (layerToUpdate.type === 'lottie') {
      layerToUpdate.data = newData.values[0];
    }

    if (layerToUpdate.type === 'sublayers') {
      layerToUpdate.children.forEach(
        (childLayer: LottieLayer, index: number) => {
          childLayer.data = newData.values[index];
        }
      );
    }

    return {
      result: timelineItemFactory(layerToUpdate, 'overlays', this.source),
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
