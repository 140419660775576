import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ElectronService } from '../services/electron/electron.service';
import { AudioStreamService } from './services/audio-stream.service';
import { MediaDevicesService } from './services/media-devices.service';
import { ScreenShareElectronService } from './services/screenshare-electron.service';
import { ScreenShareWebService } from './services/screenshare-web.service';
import { ScreenShareService } from './services/screenshare.service';
import { VideoStreamService } from './services/video-stream.service';
import { WebcamService } from './services/webcam.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    MediaDevicesService,
    WebcamService,
    AudioStreamService,
    VideoStreamService,
    {
      provide: ScreenShareService,
      useFactory: (electronService: ElectronService) =>
        electronService.isElectron
          ? new ScreenShareElectronService(electronService)
          : new ScreenShareWebService(),
      deps: [ElectronService],
    },
  ],
})
export class MediaStreamModule {}
