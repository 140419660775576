import { Component, Input, OnInit } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';

import { BrandKitsFacade } from '../../../store/facades/brand-kits.facade';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { UiControl } from '../../services/layer.service';

@Component({
  selector: 'openreel-wf-layer-item-logo',
  templateUrl: './layer-item-logo.component.html',
})
export class LayerItemLogoComponent implements OnInit {
  private uploadedAssetFileId: number;

  @Input() uiControl: UiControl;
  @Input() control: FormControl;

  selectedIndex: number;

  brandKitHasLogo$: Observable<boolean>;

  constructor(private readonly brandKitsFacade: BrandKitsFacade) {}

  ngOnInit() {
    this.selectedIndex = this.control.value.isBrandKitSelected ? 1 : 0;

    this.brandKitHasLogo$ = this.brandKitsFacade.current$.pipe(
      map((brandKit) => !!brandKit?.assets.logo)
    );
  }

  onSelectionChange(index: number) {
    this.brandKitsFacade.current$
      .pipe(
        take(1),
        filter((brandKit) => !!brandKit?.assets?.logo)
      )
      .subscribe((brandKit) => {
        const existingAssetId = this.control.value.assetId;

        const isBrandKitSelected = index === 1;
        if (isBrandKitSelected) {
          const logo = brandKit.assets.logo;
          this.uploadedAssetFileId = this.control.value.assetFileId;

          this.control.patchValue({
            assetId: null,
            assetFileId: null,
            newAssetFileId: logo.file.path,
            isBrandKitSelected: true,
          });
        } else {
          this.control.patchValue({
            assetId: null,
            assetFileId: this.uploadedAssetFileId,
            newAssetFileId: this.uploadedAssetFileId,
            removedAssetId: existingAssetId,
            isBrandKitSelected: false,
          });
        }
      });
  }
}
