import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';
import * as Interfaces from './template.interfaces';

@Injectable({
  providedIn: 'root',
})
export class TemplateService {
  private urls = {
    byId: (templateId) =>
      `${commonenv.nextGenApiUrl}workflows/templates/${templateId}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getTemplateById(templateId: number) {
    return this.httpClient.get<Interfaces.TemplateDto>(
      this.urls.byId(templateId)
    );
  }
}
