import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Cleanupable, Recording } from '../../../../../../../libs/common/src';
import { ClipSelectionFacade } from '../../store/facades/clip-selection.facade';
import {
  ClipTypes,
  DialogOptions,
} from '../../store/interfaces/clip-selection.interfaces';

export interface SelectFilesDialogData extends DialogOptions {}

export interface SelectFilesDialogResult {
  selectedRecordings: Recording[];
}

@Component({
  selector: 'openreel-select-files-dialog',
  templateUrl: './select-files-dialog.component.html',
  styleUrls: ['./select-files-dialog.component.scss'],
})
export class SelectFilesDialogComponent
  extends Cleanupable
  implements OnInit, OnDestroy {
  selectedType: ClipTypes;
  ClipTypesEnum = ClipTypes;

  selectedRecordings: Recording[];

  hideSidebar$ = this.clipSelectionFacade.hideSidebar$;

  constructor(
    public readonly dialogRef: MatDialogRef<
      SelectFilesDialogData,
      SelectFilesDialogResult
    >,
    @Inject(MAT_DIALOG_DATA) public data: SelectFilesDialogData,
    private readonly clipSelectionFacade: ClipSelectionFacade
  ) {
    super();
  }

  ngOnInit(): void {
    this.selectedType = this.data?.selectedType?.type || ClipTypes.MyProjects;

    this.clipSelectionFacade.setDialogOptions(this.data);

    this.clipSelectionFacade.selectedRecordings$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.selectedRecordings = data;
      });
  }

  ngOnDestroy() {
    this.clipSelectionFacade.cleanupSelection();
  }

  typeSelected(type: ClipTypes) {
    this.selectedType = type;
  }

  addSelectedRecordings() {
    this.dialogRef.close({ selectedRecordings: this.selectedRecordings });
  }

  close() {
    this.dialogRef.close({ selectedRecordings: [] });
  }
}
