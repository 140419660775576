export enum WorkflowSteps {
  Name = 0,
  Styling = 1,
  IntroOutro = 2,
  VideoElements = 3,
  VideoClips = 4,
}

export interface Step {
  order: number;
  icon: string;
  text: string;
  route: string;
  childRoutes?: string[];
}

export const DEFAULT_STEPS: Step[] = [
  {
    order: WorkflowSteps.Name,
    icon: 'notebook',
    text: 'Name Video',
    route: 'name',
  },
  {
    order: WorkflowSteps.Styling,
    icon: 'checkmark-filled',
    text: 'Style Video',
    route: 'style',
    childRoutes: ['', 'brand-kits'],
  },
  {
    order: WorkflowSteps.IntroOutro,
    icon: 'intro-outro',
    text: 'Intro & Outro',
    route: 'intro-outro',
    childRoutes: ['intro', 'outro'],
  },
  {
    order: WorkflowSteps.VideoElements,
    icon: 'video-elements',
    text: 'Video Elements',
    route: 'elements',
  },
  {
    order: WorkflowSteps.VideoClips,
    icon: 'play-icon',
    text: 'Video Clips',
    route: 'clips',
  },
];
