import { Injectable } from '@angular/core';
import { DirectorSessionService, DirectorSocketService } from '../../services';
import { ChatApiService } from './chat-api.service';
import { ChatBaseService } from './chat-base.service';

@Injectable()
export class DirectorChatService extends ChatBaseService {
  constructor(
    socket: DirectorSocketService,
    session: DirectorSessionService,
    api: ChatApiService
  ) {
    super(socket, session, api);
  }
}
