import { ProjectData, Recording } from '../../../../../../../libs/common/src';
import { AppState } from '../../../store';

export const featureKey = 'clips-selection';

export enum ClipTypes {
  MyProjects,
}

export interface MyProjectsSelectionOptions {
  type: ClipTypes.MyProjects;
  selectedProjectId?: number;
  excludeRecordings?: number[];
}

export type ClipTypeSelection = MyProjectsSelectionOptions;

export interface Option {
  type: ClipTypes;
  name: string;
}

export interface DialogOptions {
  hideSidebar?: boolean;
  maxSelectedClips?: number;
  selectedType?: ClipTypeSelection;
}

export interface ClipSelectionFeatureState {
  options?: DialogOptions;
  projects: ProjectData[];
  selectedProjectId: number;

  recordings: Recording[];
  selectedRecordings: Recording[];
}

export interface State extends AppState {
  [featureKey]: ClipSelectionFeatureState;
}
