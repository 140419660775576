import { DirectorSocketService } from '../../socket/director-socket.service';
import {
  TogglePinStatus,
  EVT_TOGGLE_PIN_STATUS,
} from '../../../interfaces/socket-events';
import { MyIdentitySendType } from '../../../interfaces/socket-lib-service.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class SocketDirectorExtensionControlService {

  constructor(private socket: DirectorSocketService) { }

  sendTogglePinStatus(id: number, status: boolean) {
    const data: TogglePinStatus = {
      ovra_user_id: id.toString(),
      status
    };
    this.socket.emitSocket(
      EVT_TOGGLE_PIN_STATUS,
      data,
      MyIdentitySendType.NO_IDENTITY
    )
  }
}
