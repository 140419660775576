import { Injectable } from '@angular/core';
import { SubjectSessionService, SubjectSocketService } from '../../services';
import { ChatApiService } from './chat-api.service';
import { ChatBaseService } from './chat-base.service';

@Injectable()
export class SubjectChatService extends ChatBaseService {
  constructor(
    socket: SubjectSocketService,
    session: SubjectSessionService,
    api: ChatApiService
  ) {
    super(socket, session, api);
  }
}
