const MIME_TYPES = new Map<string, string>([
  ['png', 'image/png'],
  ['jpg', 'image/jpeg'],
  ['jpeg', 'image/jpeg'],
]);

export const getMimeTypeFromExtension = (fileExtension: string) =>
  MIME_TYPES.get(fileExtension);

export const splitNameAndExtension = (fileName: string): [string, string] => {
  const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/;
  const extension = FILE_EXTENSION_REGEX.exec(fileName)[1];
  const name = fileName.slice(0, -(extension.length + 1));
  return [name, extension];
};
