import { WorkflowDataDto } from '../../../api/workflow.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

interface CommandResult<R> {
  result?: R;
  updatedWorkflow: WorkflowDataDto;
}

export abstract class BaseCommand<T, R = null> extends WorkflowBaseBuilder {
  abstract run(event: T): CommandResult<R>;
}
