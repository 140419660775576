import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Cleanupable } from '@openreel/common';
import { takeUntil } from 'rxjs/operators';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { AfterUpdate } from '../../../store/interfaces/editor.interface';
import { EditorFrame } from '../../editor-frame.interface';

@Component({
  selector: 'openreel-editor-frame-name',
  templateUrl: './editor-frame-name.component.html',
  styleUrls: ['./editor-frame-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFrameNameComponent
  extends Cleanupable
  implements EditorFrame, OnInit {
  form: FormGroup;

  saving = false;

  constructor(
    private projectFacade: ProjectFacade,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();

    this.projectFacade.data$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.form.patchValue({ name: data.data.name });
      });
  }

  submitForm() {
    this.save();
  }

  save(afterUpdate: AfterUpdate = AfterUpdate.NextStep) {
    if (!this.form.valid) {
      return;
    }

    this.saving = true;
    this.projectFacade.updateProjectName(
      this.form.controls.name.value,
      afterUpdate
    );
  }

  private createForm() {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
  }
}
