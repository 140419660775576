import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import {
  LayerFormPresetComponent,
  LayerFormPresetOptions,
} from '../../../../layers/components/layer-form-preset/layer-form-preset.component';
import {
  ItemSelectPanesActionsService,
  SAVE_DEBOUNCE_TIME,
} from '../../../services/panes-actions.service';
import { PaneBaseComponent } from '../pane-base.component';

@Component({
  selector: 'openreel-wf-pane-overlay-colors',
  templateUrl: './pane-overlay-colors.component.html',
})
export class PaneOverlayColorsComponent
  extends PaneBaseComponent
  implements AfterViewInit {
  @ViewChild(LayerFormPresetComponent) form: LayerFormPresetComponent;

  options: LayerFormPresetOptions = {
    newUI: {
      enabled: true,
      fields: 'color',
    },
  };

  constructor(
    private readonly itemSelectPanesActions: ItemSelectPanesActionsService
  ) {
    super();
  }

  ngAfterViewInit() {
    this.form.valueChanges
      .asObservable()
      .pipe(debounceTime(SAVE_DEBOUNCE_TIME))
      .subscribe((data) =>
        this.itemSelectPanesActions.saveTextData(this.item, this.timeline, data)
      );
  }
}
