<div class="input-bg-white input-primary-underline">
  <mat-form-field class="w-full" [formGroup]="control">
    <mat-label>{{ uiControl.label }}</mat-label>
    <input matInput formControlName="value" />
    <mat-error>
      <div *ngFor="let error of validationErrors()">
        {{ error }}
      </div>
    </mat-error>
  </mat-form-field>
  <mat-form-field class="font text-12" appearance="outline">
    <mat-select
      [value]="selectedFontIndex"
      (valueChange)="onFontChange($event)"
    >
      <mat-option
        *ngFor="let font of fonts$ | async; let fontIndex = index"
        [value]="fontIndex"
      >
        {{ font.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
