import { createAction, props } from '@ngrx/store';
import { TimelineType } from '../../../../api/workflow.interfaces';
import { TimelineItem } from '../../../shared/element.interfaces';
import {
  ClipAddEvent,
  TextOverlayAddEvent,
  ClipMoveEvent,
  ItemMovedEvent,
  Timeline,
  OpenTextOverlayDataDialogEvent,
  StretchTextOverlayEvent,
  TimelineItemEvent,
  UpdateTextOverlayDataEvent,
  UpdatedItem,
  UpdateTextOverlayMetadataEvent,
  TrimmerAction,
} from '../interfaces/timelines.interfaces';

const actionType = '[Timelines]';

export const loadTimelinesSuccess = createAction(
  `${actionType} Load Timelines Success`,
  props<{
    timelines: Timeline[];
    introDuration: number;
    outroDuration: number;
  }>()
);

export const openSelectFilesDialog = createAction(
  `${actionType} Open Select Files Dialog`,
  props<{ timelineType: TimelineType }>()
);

export const updateTimelines = createAction(
  `${actionType} Update Timelines Data`,
  props<{ updatedTimelines: Timeline[] }>()
);

export const updateTimelineItems = createAction(
  `${actionType} Update Timeline Items`,
  props<{ items: UpdatedItem[] }>()
);

// Main Clips
export const addMainClip = createAction(
  `${actionType} Add Main Clip`,
  props<{ timelineType: TimelineType; event: ClipAddEvent[] }>()
);

export const moveMainClip = createAction(
  `${actionType} Move Main Clip`,
  props<{ event: ClipMoveEvent }>()
);

export const removeMainClip = createAction(
  `${actionType} Remove Main Clip`,
  props<{ event: TimelineItemEvent }>()
);

export const switchTimelines = createAction(
  `${actionType} Switch Timelines`,
  props<{ event: TimelineItemEvent }>()
);

export const replaceMainClips = createAction(
  `${actionType} Replace Main Clips`,
  props<{ event: TimelineItemEvent }>()
);

// Trimmer interaction
export const editSplits = createAction(`${actionType} Edit Splits`);

export const editClipTrim = createAction(
  `${actionType} Edit Clip Trim`,
  props<{ event: TimelineItemEvent }>()
);

export const saveTrimmerData = createAction(
  `${actionType} Save Trimmer Data To Timelines`,
  props<{
    trimmmerAction: TrimmerAction;
    timeline: Timeline;
    item?: TimelineItem;
  }>()
);

export const addClipsAfterTrimmer = createAction(
  `${actionType} Add Clips After Trimmer`,
  props<{ clips: ClipAddEvent[] }>()
);

// Video Overlays
export const addVideoOverlay = createAction(
  `${actionType} Add Video Overlay`,
  props<{ timelineType: TimelineType; event: ClipAddEvent[] }>()
);

export const changeVideoOverlayType = createAction(
  `${actionType} Change Video Overlay Type`,
  props<{ event: TimelineItemEvent }>()
);

// Text Overlays
export const addTextOverlay = createAction(
  `${actionType} Add Text Overlay`,
  props<{ event: TextOverlayAddEvent }>()
);

export const openTextOverlayDataDialog = createAction(
  `${actionType} Open Text Overlay Data Dialog`,
  props<{ event: OpenTextOverlayDataDialogEvent }>()
);

export const stretchTextOverlay = createAction(
  `${actionType} Stretch Text Overlay`,
  props<{ event: StretchTextOverlayEvent }>()
);

export const updateTextOverlayData = createAction(
  `${actionType} Update Text Overlay Data`,
  props<{ event: UpdateTextOverlayDataEvent }>()
);
export const updateTextOverlayMetadata = createAction(
  `${actionType} Update Text Overlay Metadata`,
  props<{ event: UpdateTextOverlayMetadataEvent }>()
);

// Video/Text Overlays actions
export const removeOverlay = createAction(
  `${actionType} Remove Overlay`,
  props<{ event: TimelineItemEvent }>()
);

// Common actions
export const duplicateClip = createAction(
  `${actionType} Duplicate Clip`,
  props<{ event: TimelineItemEvent }>()
);

export const moveItem = createAction(
  `${actionType} Move Item`,
  props<{ event: ItemMovedEvent }>()
);
