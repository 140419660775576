import { AppState } from './../../store';
import { combineReducers, Action, createFeatureSelector } from '@ngrx/store';
import * as fromReducer from './reducers/timelines.reducer';
import * as fromTrimmerReducer from './reducers/trimmer.reducer';

export const featureKey = 'timelines';

export interface TimelinesFeatureState {
  [fromReducer.key]: fromReducer.TimelinesState;
  [fromTrimmerReducer.key]: fromTrimmerReducer.TrimmerState;
}

export interface State extends AppState {
  [featureKey]: TimelinesFeatureState;
}

export function reducers(
  state: TimelinesFeatureState | undefined,
  action: Action
) {
  return combineReducers({
    [fromReducer.key]: fromReducer.reducerFn,
    [fromTrimmerReducer.key]: fromTrimmerReducer.reducerFn,
  })(state, action);
}

export const selectTimelinesFeatureState = createFeatureSelector<
  State,
  TimelinesFeatureState
>(featureKey);
