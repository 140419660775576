/// <reference types="dom-mediacapture-record" />

import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { DBSchema, deleteDB, IDBPDatabase, openDB } from 'idb';
import { getOriginalSeekableBlob } from '../../../utils';
import {
  ILocalRecorderService,
  RecordingState,
} from './local-recorder-base.service';

export declare interface MediaRecorderDataAvailableEvent extends Event {
  data: Blob;
}

// This is needed for ts-ebml to work
(window as Window &
  typeof globalThis & { Buffer: typeof Buffer }).Buffer = Buffer;

interface ChunkRecordingSchema extends DBSchema {
  chunks: {
    key: number;
    value: Blob;
  };
}

const DB_NAME_PREFIX = 'recording-chunk-';
const DB_VERSION = 1;
const TIMESLICE = 5000;
const BITS_PER_RESOLUTION_PER_FPS = 250;
const MIME_TYPE_VIDEO_WEBM_VP8 = 'video/webm; codecs=vp8,opus';
const MIME_TYPE_VIDEO_WEBM_VP9 = 'video/webm; codecs=vp9,opus';
const MIME_TYPE_VIDEO_MP4_H264 = 'video/mp4; codecs="avc1.4d002a"';
@Injectable()
export class LocalRecorderService extends ILocalRecorderService {
  private mediaRecorder: MediaRecorder;
  private db: IDBPDatabase<ChunkRecordingSchema>;

  private preferedCodec = MIME_TYPE_VIDEO_WEBM_VP8;
  constructor() {
    super();
  }

  // eslint-disable-next-line max-lines-per-function
  protected async doStartRecording() {
    throw new Error('not implemented');
  }

  protected async doStartRecordingStream(stream: MediaStream) {
    const settings = stream.getVideoTracks()[0].getSettings();
    const videoBitsPerSecond =
      BITS_PER_RESOLUTION_PER_FPS * settings.height * settings.frameRate;
    console.log(
      'Effective recording resolution/fps/videoBitsPerSecond: ' +
        settings.height +
        '/' +
        settings.frameRate +
        '/' +
        videoBitsPerSecond
    );

    const options: MediaRecorderOptions = {
      videoBitsPerSecond,
      audioBitsPerSecond: 128 * 1024,
      mimeType: this.preferedCodec
        ? this.preferedCodec
        : MediaRecorder.isTypeSupported(MIME_TYPE_VIDEO_WEBM_VP9)
        ? MIME_TYPE_VIDEO_WEBM_VP9
        : MIME_TYPE_VIDEO_WEBM_VP8,
    };

    this.mediaRecorder = new MediaRecorder(stream, options);
    this.mediaRecorder.onstop = () => this.handleStop();
    this.mediaRecorder.onerror = (err) => this.handleError(err);
    this.mediaRecorder.ondataavailable = (evt) => this.handleDataAvailable(evt);
    this.mediaRecorder.onstart = () => this.handleStart();
    this.mediaRecorder.start(TIMESLICE);
  }

  private async handleStart() {
    this.recordingState$.next(RecordingState.RECORDING);
    if (this.db) {
      console.warn('Forcefully closing last database');
      this.closeLastDB();
    }
    this.initDbForRecordingChunk();
  }

  private async initDbForRecordingChunk() {
    this.db = await openDB(DB_NAME_PREFIX + this.lastFileName, DB_VERSION, {
      upgrade: (db) => {
        db.createObjectStore('chunks', { autoIncrement: true });
        console.log('Database upgraded for ' + this.lastFileName);
      },
    });
  }

  private closeLastDB() {
    this.db.close();
    delete this.db;
  }

  protected async handleDataAvailable(evt: MediaRecorderDataAvailableEvent) {
    if (!this.db) {
      await this.initDbForRecordingChunk();
    }
    await this.db.put('chunks', evt.data);
  }

  protected handleStop() {
    this.recordingState$.next(RecordingState.IDLE);
    this.closeLastDB();
  }

  private handleError(err: MediaRecorderErrorEvent) {
    this.recordingState$.next(RecordingState.IDLE);
    console.error('Error during recording: ');
    console.log(err);
  }

  protected async getNewFileName(): Promise<string> {
    return new Date().getTime().toString() + '.webm';
  }

  protected async doStopRecording() {
    this.mediaRecorder.stop();
  }

  async getFileSizeMB(): Promise<number> {
    return 0;
  }

  async getFileLengthSeconds(): Promise<number> {
    return -1;
  }

  async getFileData(localFileName: string): Promise<Blob> {
    const db = await openDB<ChunkRecordingSchema>(
      DB_NAME_PREFIX + localFileName,
      DB_VERSION
    );
    const allChunks = await db.getAll('chunks');
    db.close();
    try {
      const ret = await getOriginalSeekableBlob(allChunks);
      return ret.refinedBlob;
    } catch (err) {
      console.log(err);
    }
    console.warn('Sending non-seekable video');
    const ret = new Blob(allChunks);
    return ret;
  }
  async removeFileData(localFileName: string) {
    try {
      await deleteDB(DB_NAME_PREFIX + localFileName);
    } catch (err) {
      console.warn('Error deleting database for ' + localFileName);
    }
  }

  getFileNameForUpload(): string {
    return this.lastFileName;
  }
}
