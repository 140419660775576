import { Component, HostListener, OnInit } from '@angular/core';
import { MouseEventsService } from './core/services/mouse-events.service';
import { RecordingUploadService } from './core/services/recording-upload.service';
import { TitleService } from './core/services/title.service';

@Component({
  selector: 'openreel-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private mouseDownTarget: HTMLElement;
  private mouseUpTarget: HTMLElement;

  @HostListener('document:mousedown', ['$event'])
  documentMouseDown = (event: MouseEvent) =>
    (this.mouseDownTarget = event.target as HTMLElement);

  @HostListener('document:mouseup', ['$event'])
  documentMouseUp = (event: MouseEvent) => {
    this.mouseUpTarget = event.target as HTMLElement;
    this.mouseEventsService.setClickTargets(
      this.mouseDownTarget ?? this.mouseUpTarget,
      this.mouseUpTarget
    );
  };

  constructor(
    public readonly uploadService: RecordingUploadService,
    private readonly titleService: TitleService,
    private readonly mouseEventsService: MouseEventsService
  ) {}

  ngOnInit() {
    this.titleService.init();
    this.uploadService.uploadAllStoredRecordings();
  }
}
