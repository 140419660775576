import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import { SessionBase } from '../../interfaces/interfaces';
import { AudioDevice } from '../../media/interfaces/audio-device.interface';
import { VideoDevice } from '../../media/interfaces/video-device.interface';
import { VideoSource } from '../../media/interfaces/video-source.interface';
import { AudioStreamService } from '../../media/services/audio-stream.service';
import { VideoStreamService } from '../../media/services/video-stream.service';

const SELECTED_VIDEO_AUDIO_SOURCES = 'selected_video_audio_sources';

interface SelectedSources {
  videoDevice: VideoDevice;
  audioDevice: AudioDevice;
  audioInputEnabled: boolean;
  audioOutputEnabled: boolean;
  videoEnabled: boolean;
}

/**
 * Audio/Video settings for current session. This lives globally, so it has to
 * be manually reset() after use.
 */
@Injectable({
  providedIn: 'root',
})
export class SessionConfigService extends Cleanupable {
  /**
   * Selected video source. If you want to change video source in runtime you
   * should call setNewVideoSource because it handles errors properly.
   */
  selectedVideoSource$: BehaviorSubject<VideoDevice>;
  /**
   * Selected audio source. If you want to change audio source in runtime you
   * should call .next on this property
   */
  selectedAudioSource$: BehaviorSubject<AudioDevice>;
  /**
   * Session info available before session is initialized. This data is used to
   * initialize session in SessionBaseService
   */
  storedSessionInfo$ = new BehaviorSubject<SessionBase>(null);
  /**
   * Indicates whether the audio is muted or not.
   */
  audioInputEnabled$: BehaviorSubject<boolean>;
  /**
   * If this is false, all participants are muted, and you shouldn't be able to
   * hear anyone in the session. This is used by directors to avoid audio
   * loopback.
   */
  audioOutputEnabled$: BehaviorSubject<boolean>;

  videoEnabled$: BehaviorSubject<boolean>;

  constructor(
    private readonly videoStreamService: VideoStreamService,
    private readonly audioStreamService: AudioStreamService
  ) {
    super();

    // load video/audio sources from local storage if available
    // we would like to avoid re-confirming video/audio sources on page refreshes
    const sources: SelectedSources = JSON.parse(
      localStorage.getItem(SELECTED_VIDEO_AUDIO_SOURCES)
    );
    this.selectedAudioSource$ = new BehaviorSubject(
      sources?.audioDevice || null
    );
    this.selectedVideoSource$ = new BehaviorSubject(
      sources?.videoDevice || null
    );
    this.audioInputEnabled$ = new BehaviorSubject(
      sources?.audioInputEnabled === false ? false : true
    );
    this.audioOutputEnabled$ = new BehaviorSubject<boolean>(
      sources?.audioOutputEnabled === false ? false : true
    );
    this.videoEnabled$ = new BehaviorSubject<boolean>(
      sources?.videoEnabled === false ? false : true
    );

    // persist audio/video sources to local storage
    // we would like to avoid re-confirming video/audio sources on page refreshes
    this.subscriptions.push(
      combineLatest([
        this.selectedAudioSource$,
        this.selectedVideoSource$,
        this.audioInputEnabled$,
        this.audioOutputEnabled$,
        this.videoEnabled$,
      ])
        .subscribe(
          ([
            audioDevice,
            videoDevice,
            audioInputEnabled,
            audioOutputEnabled,
            videoEnabled,
          ]) => {
            console.log('LS AUDIO: ', audioInputEnabled, audioOutputEnabled);
            if (
              !audioDevice &&
              !videoDevice &&
              audioInputEnabled &&
              audioOutputEnabled
            ) {
              localStorage.removeItem(SELECTED_VIDEO_AUDIO_SOURCES);
            } else {
              const selected: SelectedSources = {
                audioDevice,
                videoDevice:
                  videoDevice?.source === VideoSource.WEBCAM
                    ? videoDevice
                    : null,
                audioInputEnabled,
                audioOutputEnabled,
                videoEnabled
              };
              localStorage.setItem(
                SELECTED_VIDEO_AUDIO_SOURCES,
                JSON.stringify(selected)
              );
            }
          }
        )
    );
  }

  private async onNewVideoSourceSet(newVideoSource: VideoDevice) {
    if (newVideoSource) {
      try {
        await this.videoStreamService.openStream(newVideoSource);
      } catch (err) {
        this.selectedVideoSource$.next(null);
        console.error(err);
      }
    } else {
      this.videoStreamService.closeStream();
    }
  }

  private async onNewAudioSourceSet(audioDevice: AudioDevice) {
    if (audioDevice) {
      try {
        await this.audioStreamService.openStream(audioDevice);
      } catch (err) {
        this.selectedAudioSource$.next(null);
        console.error(err);
      }
    } else {
      this.audioStreamService.closeStream();
    }
  }

  listenToSourceUpdates() {
    this.subscriptions.push(
      this.selectedVideoSource$
        .pipe(
          // concatMap because we would like to process (close/open) streams in order
          // otherwise closing stream immediately after calling open on it won't work
          // as it is async
          concatMap((video) => this.onNewVideoSourceSet(video))
        )
        .subscribe(),
      this.selectedAudioSource$
        .pipe(
          // concatMap because we would like to process (close/open) streams in order
          // otherwise closing stream immediately after calling open on it won't work
          // as it is async
          concatMap((audio) => this.onNewAudioSourceSet(audio))
        )
        .subscribe()
    );
  }

  setNewVideoDevice(newDevice: VideoDevice) {
    this.selectedVideoSource$.next(newDevice);
  }

  setNewAudioDevice(newDevice: AudioDevice) {
    this.selectedAudioSource$.next(newDevice);
  }

  setMediaDevices(audioDevice: AudioDevice, videoDevice: VideoDevice) {
    this.selectedAudioSource$.next(audioDevice);
    this.selectedVideoSource$.next(videoDevice);
  }

  setAudioInput(enabled: boolean) {
    this.audioInputEnabled$.next(enabled);
  }

  setAudioOutput(enabled: boolean) {
    this.audioOutputEnabled$.next(enabled);
  }

  setVideoEnabled(enabled: boolean) {
    this.videoEnabled$.next(enabled);
  }

  reset() {
    this.selectedAudioSource$.next(null);
    this.selectedVideoSource$.next(null);
    this.storedSessionInfo$.next(null);
    this.audioInputEnabled$.next(true);
    this.audioOutputEnabled$.next(true);
    this.videoEnabled$.next(true);
  }
}
