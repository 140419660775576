import { ToastrService } from 'ngx-toastr';
import { Component, Output, EventEmitter } from '@angular/core';
import { User } from '../../models';
import { AuthService } from '../../services/auth/auth.service';
import { ROUTE_FORGOT_PASSWORD } from '../../route-utils';


@Component({
  selector: 'openreel-director-login-form',
  templateUrl: './director-login-form.component.html',
  styleUrls: ['./director-login-form.component.scss'],
})
export class DirectorLoginComponent {
  public user: User = new User();
  hide = true;
  disabled = false;
  forgotPasswordRoute = '/'+ROUTE_FORGOT_PASSWORD;

  @Output()
  loginEvent = new EventEmitter();

  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  async loginClick() {
    this.disabled = true;
    try {
      const res = await this.authService
        .login(this.user.username, this.user.password, true)
        .toPromise();
      if (res.status === 1) {
        this.loginEvent.emit();
        this.toastr.success(res.message, 'Success!');
      } else {
        throw new Error('Unable to Login: ' + res.message);
      }
    } catch (err) {
      const error =
        err.error && err.error.message ? err.error.message : err.message;
      this.toastr.error(error, 'Error!');
    }
    this.disabled = false;
  }
}
