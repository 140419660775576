import { fromEvent, merge, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import * as EBML from 'ts-ebml';

export function onCancel<T>(
  onCancelCallback
): MonoTypeOperatorFunction<T> {
  return (observable) =>
    new Observable((observer) => {
      let completed = false;
      let errored = false;
      const subscription = observable.subscribe({
        next: (v) => observer.next(v),
        error: (e) => {
          errored = true;
          observer.error(e);
        },
        complete: () => {
          completed = true;
          observer.complete();
        },
      });
      return () => {
        subscription.unsubscribe();
        if (!completed && !errored) {
          onCancelCallback();
        }
      };
    });
}

// NOT WORKING ATM
export function isPackaged() {
  return (
    window
      .require('electron')
      .remote.process.mainModule.filename.indexOf('.asar') !== -1
  );
}

export function isRunningHotReload() {
  if ((window as Window & typeof globalThis & { forceNoHotReload: boolean }).forceNoHotReload) {
    return false;
  }
  return (
    document.URL.toLowerCase().startsWith('http://localhost') ||
    document.URL.toLowerCase().startsWith('https://localhost')
  );
}

export function fixPathForPackaging(path: string) {
  if (this.isPackaged()) {
    return path.replace('.asar', '.asar.unpacked');
  } else {
    return path;
  }
}

export function getNumericValuesOfEnum(someEnum): number[] {
  const ret: number[] = [];
  for (const key of Object.keys(someEnum)) {
    const n = parseInt(key, 10);
    if (!isNaN(n)) {
      ret.push(n);
    }
  }
  return ret;
}

export function isOnWeb() {
  return !window.require;
}

export function safeGetValue<T>(fn: () => T, errorValue: T): T {
  try {
    return fn();
  } catch (err) {
    // console.warn('Error safe getting the value: ', err.message);
    return errorValue;
  }
}

export function objToBoolString(value: unknown): '0' | '1' {
  return value ? '1' : '0';
}

export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export async function waitForKeyToAppear<T>(
  obj: T | (() => T),
  key: keyof T,
  timeout: number
): Promise<void> {
  const started = new Date().getTime();
  const getVal: () => T = () => {
    if (typeof obj === 'function') {
      return (obj as () => T)();
    } else {
      return obj;
    }
  };
  while (!Object.keys(getVal()).includes(key as string)) {
    if (new Date().getTime() - started > timeout) {
      throw new Error('Timeout reached');
    }
    await wait(1);
  }
}

export function saveByteArray(blob: Blob, name: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

export async function getOriginalSeekableBlob(
  dataChunk: Blob[]
): Promise<{ refinedBlob: Blob; duration: number }> {
  const blob = new Blob(dataChunk, {
    type: 'video/webm',
  });
  const fileReader = new FileReader();
  const m1 = fromEvent<ProgressEvent<FileReader>>(fileReader, 'load').pipe(
    map((evt) => evt.target.result as ArrayBuffer)
  );
  const m2 = fromEvent<ProgressEvent<FileReader>>(fileReader, 'error').pipe(
    map((err) => {
      throw err;
    })
  );
  const p = merge(m1, m2).pipe(first()).toPromise();

  fileReader.readAsArrayBuffer(blob);
  return getSeekableWebmBlob(await p);
}

function getSeekableWebmBlob(
  buffer: ArrayBuffer
): { refinedBlob: Blob; duration: number } {
  const decoder = new EBML.Decoder();
  const reader = new EBML.Reader();
  const tools = EBML.tools;
  reader.drop_default_duration = false;
  const elms = decoder.decode(buffer);
  elms.forEach((elm) => {
    reader.read(elm);
  });
  const duration = reader.duration;
  reader.stop();
  const refinedMetadataBuf = tools.makeMetadataSeekable(
    reader.metadatas,
    reader.duration,
    reader.cues
  );
  const body = buffer.slice(reader.metadataSize);
  const refinedWebM = new Blob([refinedMetadataBuf, body], {
    type: 'video/webm',
  });
  return { refinedBlob: refinedWebM, duration: duration };
}

export function containsOther(objA: unknown, objB: unknown) {
  if (!objA) {
    return false;
  }

  if (typeof objA !== 'object' || typeof objB !== 'object') {
    return false;
  }

  if (objA === objB) {
    return true;
  }

  const aProps = Object.getOwnPropertyNames(objA);

  let isEqual = true;
  aProps.forEach((prop) => {
    if (objA[prop] !== objB[prop]) {
      isEqual = false;
      return;
    }
  });

  return isEqual;
}

export function isIosDevice(identity: string): boolean {
  if (identity && identity.startsWith('ios_')) {
    return true;
  } else {
    return false;
  }
}

export function isIosSmartMobile(): boolean {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
      .includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
}
