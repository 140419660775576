import * as Handlebars from 'handlebars';

import { Observable, of } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

import { AssetFetchService } from './asset-fetch.service';
import { Asset } from '../../../api/workflow.interfaces';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileTemplatingService {
  private readonly templateCache: {
    [key: string]: HandlebarsTemplateDelegate;
  } = {};

  constructor(private readonly assetFetchService: AssetFetchService) {}

  apply<T>(fileAsset: Asset, values: unknown): Observable<T> {
    return of(fileAsset).pipe(
      concatMap((asset) => this.getCachedOrFetch(asset)),
      map((template) => JSON.parse(template(values)) as T)
    );
  }

  private getCachedOrFetch(
    fileAsset: Asset
  ): Observable<HandlebarsTemplateDelegate> {
    if (fileAsset.id in this.templateCache) {
      return of(this.templateCache[fileAsset.id]);
    } else {
      return this.assetFetchService
        .fetchContentAsText(fileAsset.file.provider, fileAsset.file.path)
        .pipe(
          map((content) => Handlebars.compile(content)),
          tap((template) => (this.templateCache[fileAsset.id] = template))
        );
    }
  }
}
