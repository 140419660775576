import { Project, Resources } from '../interfaces/project.interface';

import { AppState } from '..';
import { ProjectDto } from '../../../api/project/project.interfaces';
import { Sections, WorkflowDataDto } from '../../../api/workflow.interfaces';
import { createSelector } from '@ngrx/store';

export const selectProject = createSelector(
  (state: AppState) => state.project,
  (project: Project) => project
);

export const selectProjectId = createSelector(
  selectProject,
  (project: Project) => project.data.id
);

export const selectProjectLoadFinished = createSelector(
  selectProject,
  (project: Project) => project.loadFinished
);

export const selectProjectWorkflow = createSelector(
  (state: AppState) => state.project,
  (project: Project) => project.data?.workflow
);

export const selectProjectTemplateType = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.type
);

export const selectProjectSections = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.sections
);

export const selectProjectIntroOutroTimelines = createSelector(
  selectProjectSections,
  (sections: Sections, props: { intro: boolean }) =>
    props.intro ? sections?.intro : sections?.outro
);

export const selectProjectAssets = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.assets
);

export const selectProjectFeatures = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.features
);

export const selectProjectMainTimeline = createSelector(
  selectProjectWorkflow,
  (workflow: WorkflowDataDto) => workflow?.timelines
);

// Draft selectors
export const selectProjectWorkflowDraftData = createSelector(
  (state: AppState) => state.project,
  (project: Project) => project.draftData
);

export const selectProjectFeaturesDraftData = createSelector(
  selectProjectWorkflowDraftData,
  (data: ProjectDto) => data.workflow?.features
);

export const selectProjectWorkflowDraftSections = createSelector(
  selectProjectWorkflowDraftData,
  (data: ProjectDto) => data.workflow?.sections
);

export const selectProjectDraftAssets = createSelector(
  selectProjectWorkflowDraftData,
  (project: ProjectDto) => project.workflow?.assets
);

export const selectProjectDraftMainTimelines = createSelector(
  selectProjectWorkflowDraftData,
  (project: ProjectDto) => project.workflow?.timelines
);

export const selectProjectDraftIntroOutroTimelines = createSelector(
  selectProjectWorkflowDraftSections,
  (sections: Sections, props: { intro: boolean }) =>
    props.intro ? sections?.intro.timelines : sections?.outro.timelines
);

export const selectProjectDraftMainSection = createSelector(
  selectProjectWorkflowDraftSections,
  (sections: Sections) => sections.main
);

export const selectRenderProgress = createSelector(
  (state: AppState) => state.project,
  (project: Project) => project?.renderProgress
);

export const selectIsRenderInProgress = createSelector(
  (state: AppState) => state.project,
  (project: Project) => project?.renderProgress?.state === 'rendering'
);

export const selectProjectStyles = createSelector(
  selectProject,
  (project: Project) => project.data?.workflow?.styles
);

export const selectProjectFonts = createSelector(
  selectProject,
  (project: Project) => project.data?.workflow?.fonts
);

export const selectProjectColors = createSelector(
  selectProject,
  (project: Project) => project.data?.workflow?.colors
);

export const selectProjectResources = createSelector(
  selectProjectAssets,
  selectProjectFonts,
  selectProjectColors,
  selectProjectStyles,
  (assets, fonts, colors, styles): Resources => ({
    assets,
    fonts,
    colors,
    styles,
  })
);
