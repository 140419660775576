import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { WorkflowDataDto } from '../../../api/workflow.interfaces';

export const selectTemplateWorkflow = (state: AppState) =>
  state.template?.data?.workflow;

export const selectTemplateType = createSelector(
  selectTemplateWorkflow,
  (state: WorkflowDataDto) => state?.type
);

export const selectTemplateColors = (state: AppState) =>
  state.template?.data?.workflow?.colors;
