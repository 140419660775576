import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OpenreelCommonModule } from '../../openreel-common.module';
import { BubbleChatComponent } from './bubble-chat/bubble-chat.component';
import { ChatApiService } from './chat-api.service';
import { ChatListComponent } from './chat-list/chat-list.component';
import { SubjectChatService } from './subject-chat.service';
import { DirectorChatService } from './director-chat.service';

@NgModule({
  declarations: [ChatListComponent, BubbleChatComponent],
  imports: [CommonModule, OpenreelCommonModule, ReactiveFormsModule],
  exports: [ChatListComponent, BubbleChatComponent],
  providers: [DirectorChatService, SubjectChatService, ChatApiService],
})
export class ChatModule {}
