import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Cleanupable, UploadFileInfo } from '@openreel/common';

@Component({
  selector: 'recording-upload-indicator',
  templateUrl: './recording-upload-indicator.component.html',
  styleUrls: ['./recording-upload-indicator.component.scss'],
})
export class RecordingUploadIndicatorComponent extends Cleanupable {
  @Input() queue$ = new BehaviorSubject<UploadFileInfo[]>([]);

  isNaN = isNaN;
}
