import * as fromActions from './../actions/timelines.actions';
import * as fromTrimmerActions from '../actions/trimmer.actions';
import * as fromProjectActions from '../../../store/actions/project.actions';
import * as fromGlobalActions from '../../../store/actions/global.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  ClipAddEvent,
  TrimmerAction,
} from '../interfaces/timelines.interfaces';
import { TimelinesFacade } from '../facades/timelines.facade';
import { TrimmerFacade } from '../facades/trimmer.facade';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { WorkflowSteps } from '../../../store/interfaces/steps.interface';
import {
  OrAssetsFile,
  TimelinesLayer,
} from '../../../../api/workflow.interfaces';
import { TrimGroup } from '../interfaces/trimmer.interfaces';
import {
  findPlaceForNewOverlay,
  getSplitsForTrimmer,
} from '../helpers/timelines.helpers';
import { PodcastWorkflowBuilder } from '../../../shared/builders/workflow-podcast.builder';
import { cloneDeep } from 'lodash-es';
import { updateTimelineData } from '../helpers/effects.helpers';
import { TemplateSettingsFacade } from '../../../store/facades/template-settings.facade';
import { UpdateClipTrimsCommand } from '../../../shared/builders/update-clip-trims.command';
import { AlertService } from '../../../core/services/alert.service';
import { AddVideoOverlaysCommand } from '../../../shared/builders/add-video-overlays.command';
import { AddClipsCommand } from '../../../shared/builders/add-clips.command';

@Injectable()
export class TrimmerEffects {
  trimFinished$ = createEffect(() =>
    this.trimmerFacade.finished$.pipe(
      filter((value) => value),
      map(() => fromTrimmerActions.trimClipGroupsFinished())
    )
  );

  selectClipsForTrimmer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addMainClip, fromActions.addVideoOverlay),
      withLatestFrom(this.timelinesFacade.timelines$),
      switchMap(([action, timelines]) => {
        const timeline = timelines.find((t) => t.type === action.timelineType);

        const trimGroups: TrimGroup[] = [];

        action.event.forEach((selectedClip) => {
          const trimGroup = {
            videos: [],
            splits: [{ videos: [] }],
          };

          const videoData = {
            assetFileId: selectedClip.assetFileId,
            assetProviderType: selectedClip.assetProviderType,
            duration: selectedClip.duration,
            name: selectedClip.name,
          };

          trimGroup.videos.push(videoData);
          trimGroup.splits[0].videos.push({
            video: videoData,
            trimFrom: 0,
            trimTo: selectedClip.duration,
          });

          trimGroups.push(trimGroup);
        });

        return [
          fromActions.saveTrimmerData({
            trimmmerAction: TrimmerAction.AddClips,
            timeline,
          }),
          fromTrimmerActions.trimClipGroups({
            allowSplits: true,
            trimGroups,
          }),
        ];
      })
    )
  );

  editSplits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editSplits),
      withLatestFrom(
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      filter(([_, timelines]) =>
        timelines.some(
          (t) => t.type === 'main' && t.options.parentLayer !== null
        )
      ),
      switchMap(([_, timelines, workflow]) => {
        const mainTimelines = timelines.filter((t) => t.type === 'main');
        const timelinesLayer = mainTimelines[0].options
          .parentLayer as TimelinesLayer;

        const { videos, splits } = getSplitsForTrimmer(
          timelinesLayer,
          workflow
        );

        const trimGroup: TrimGroup = {
          videos,
          splits,
        };

        return [
          fromActions.saveTrimmerData({
            trimmmerAction: TrimmerAction.EditSplits,
            timeline: mainTimelines[0],
          }),
          fromTrimmerActions.trimClipGroups({
            allowSplits: true,
            trimGroups: [trimGroup],
          }),
        ];
      })
    )
  );

  editTrim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.editClipTrim),
      withLatestFrom(this.timelinesFacade.timelines$),
      switchMap(([{ event }, timelines]) => {
        const clipFile = event.item.asset.file as OrAssetsFile;

        const trimGroup: TrimGroup = {
          videos: [],
          splits: [{ videos: [] }],
        };

        if (!event.timeline.options.parentLayer) {
          const videoData = {
            assetFileId: clipFile.path,
            assetProviderType: clipFile.provider,
            duration: event.item.asset.data.duration,
            name: event.item.asset.data.name,
          };

          // Single timeline
          trimGroup.videos.push(videoData);
          trimGroup.splits[0].videos.push({
            video: videoData,
            trimFrom: event.item.asset.trimFrom,
            trimTo: event.item.asset.trimTo,
          });
        } else {
          // Pair of timelines
          const selectedIndex = event.timeline.data.findIndex(
            (i) => i.layerId === event.item.layerId
          );
          const pairTimelines = timelines.filter(
            (t) => t.type === event.timeline.type
          );
          pairTimelines.forEach((t) => {
            const pairItem = t.data[selectedIndex];
            const pairItemFile = pairItem.asset.file as OrAssetsFile;

            const videoData = {
              assetFileId: pairItemFile.path,
              assetProviderType: pairItemFile.provider,
              duration: pairItem.asset.data.duration,
              name: pairItem.asset.data.name,
            };

            trimGroup.videos.push(videoData);
            trimGroup.splits[0].videos.push({
              video: videoData,
              trimFrom: pairItem.asset.trimFrom,
              trimTo: pairItem.asset.trimTo,
            });
          });
        }

        return [
          fromActions.saveTrimmerData({
            trimmmerAction: TrimmerAction.EditTrim,
            timeline: event.timeline,
            item: event.item,
          }),
          fromTrimmerActions.trimClipGroups({
            allowSplits: false,
            trimGroups: [trimGroup],
          }),
        ];
      })
    )
  );

  updateSplits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTrimmerActions.trimClipGroupsFinished),
      withLatestFrom(
        this.trimmerFacade.data$,
        this.timelinesFacade.sentToTrimmer$
      ),
      filter(
        ([action, trimmer, sentToTrimmer]) =>
          sentToTrimmer.trimmmerAction === TrimmerAction.EditSplits
      ),
      withLatestFrom(
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      switchMap(([[action, trimmer], timelines, workflow]) => {
        const workflowWriter = new PodcastWorkflowBuilder(workflow);
        workflowWriter.updateSplitsOnMainTimeline(trimmer.trimGroups[0].splits);

        const workflowAfterUpdate = workflowWriter.toWorkflow();

        const updatedTimelines = cloneDeep(timelines);
        updateTimelineData(
          updatedTimelines,
          workflowAfterUpdate,
          this.templateSettings
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: workflowAfterUpdate,
          }),
          fromActions.updateTimelines({ updatedTimelines }),
          fromTrimmerActions.trimCleanup(),
        ];
      })
    )
  );

  updateTrim$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTrimmerActions.trimClipGroupsFinished),
      withLatestFrom(
        this.trimmerFacade.data$,
        this.timelinesFacade.sentToTrimmer$,
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      filter(
        ([action, trimmer, sentToTrimmer]) =>
          sentToTrimmer.trimmmerAction === TrimmerAction.EditTrim
      ),
      switchMap(([action, trimmer, sentToTrimmer, timelines, workflow]) => {
        const { updatedWorkflow } = new UpdateClipTrimsCommand(workflow).run({
          item: sentToTrimmer.selectedItem,
          timeline: sentToTrimmer.timeline,
          split: trimmer.trimGroups[0].splits[0],
        });

        const updatedTimelines = cloneDeep(timelines);
        updateTimelineData(
          updatedTimelines,
          updatedWorkflow,
          this.templateSettings
        );

        return [
          fromTrimmerActions.trimCleanup(),
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelines({ updatedTimelines }),
        ];
      })
    )
  );

  addMainClipsAfterTrimmer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTrimmerActions.trimClipGroupsFinished),
      withLatestFrom(
        this.trimmerFacade.data$,
        this.timelinesFacade.sentToTrimmer$
      ),
      filter(
        ([action, trimmer, sentToTrimmer]) =>
          sentToTrimmer.trimmmerAction === TrimmerAction.AddClips &&
          sentToTrimmer.timeline.type === 'main'
      ),
      map(([_, trimmer]) => {
        const clips: ClipAddEvent[] = [];
        trimmer.trimGroups.forEach((group) => {
          group.splits.forEach((split) => {
            clips.push({
              ...split.videos[0].video,
              trimFrom: split.videos[0].trimFrom,
              trimTo: split.videos[0].trimTo,
            });
          });
        });

        return fromActions.addClipsAfterTrimmer({ clips });
      })
    )
  );

  addVideoOverlaysAfterTrimmer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTrimmerActions.trimClipGroupsFinished),
      withLatestFrom(
        this.trimmerFacade.data$,
        this.timelinesFacade.sentToTrimmer$
      ),
      filter(
        ([action, trimmer, sentToTrimmer]) =>
          sentToTrimmer.trimmmerAction === TrimmerAction.AddClips &&
          sentToTrimmer.timeline.type === 'b-roll'
      ),
      withLatestFrom(
        this.trimmerFacade.finishedDuration$,
        this.timelinesFacade.videoOverlaysTimeline$,
        this.timelinesFacade.duration$
      ),
      map(
        ([[_, trimmer], finishedDuration, videoOverlaysTimeline, duration]) => {
          const videoOverlays = videoOverlaysTimeline?.data || [];
          let startAt = findPlaceForNewOverlay(
            videoOverlays,
            duration,
            finishedDuration
          );

          if (startAt === null) {
            this.alertService.error(
              'Not enough space to place video overlays after trimming.'
            );
            return fromGlobalActions.noOp();
          }

          const clips: ClipAddEvent[] = [];
          trimmer.trimGroups.forEach((group) => {
            group.splits.forEach((split) => {
              clips.push({
                ...split.videos[0].video,
                startAt: startAt,
                trimFrom: split.videos[0].trimFrom,
                trimTo: split.videos[0].trimTo,
              });

              startAt += split.videos[0].trimTo - split.videos[0].trimFrom + 1;
            });
          });

          return fromActions.addClipsAfterTrimmer({ clips });
        }
      )
    )
  );

  addClipsAfterTrimmer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addClipsAfterTrimmer),
      withLatestFrom(
        this.timelinesFacade.sentToTrimmer$,
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      switchMap(([{ clips }, sentToTrimmer, timelines, workflow]) => {
        const { updatedWorkflow } =
          sentToTrimmer.timeline.type === 'main'
            ? new AddClipsCommand(workflow).run(clips)
            : new AddVideoOverlaysCommand(workflow).run(clips);

        const updatedTimelines = cloneDeep(timelines);
        updateTimelineData(
          updatedTimelines,
          updatedWorkflow,
          this.templateSettings
        );

        return [
          fromTrimmerActions.trimCleanup(),
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelines({ updatedTimelines }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly timelinesFacade: TimelinesFacade,
    private readonly projectFacade: ProjectFacade,
    private readonly trimmerFacade: TrimmerFacade,
    private readonly templateSettings: TemplateSettingsFacade,
    private readonly alertService: AlertService
  ) {}
}
