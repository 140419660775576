import { CachedAsset, CachedObject } from '../interfaces/asset-cache.interface';
import { createAction, props } from '@ngrx/store';

const actionType = '[Asset Cache]';

export const loadAsset = createAction(
  `${actionType} Load Asset`,
  props<{
    id: string | number;
    provider: 'url' | 'or-assets' | 'or-recordings';
  }>()
);

export const fetchAssetUrl = createAction(
  `${actionType} Fetch Asset URL`,
  props<{
    id: string | number;
    provider: 'url' | 'or-assets' | 'or-recordings';
  }>()
);

export const cacheAsset = createAction(
  `${actionType} Cache Asset`,
  props<CachedAsset>()
);

export const removeCachedAsset = createAction(
  `${actionType} Remove Cached Asset`,
  props<{
    id: string | number;
    provider: 'url' | 'or-assets' | 'or-recordings';
  }>()
);

export const loadObject = createAction(
  `${actionType} Load Object`,
  props<{ url: string; dataType: string }>()
);

export const downloadObject = createAction(
  `${actionType} Download Object`,
  props<{ url: string; dataType: string }>()
);

export const cacheObject = createAction(
  `${actionType} Cache Object`,
  props<CachedObject>()
);

export const removeCachedObject = createAction(
  `${actionType} Remove Cached Object`,
  props<{ url: string }>()
);
