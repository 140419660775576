import * as fromActions from '../actions/project.actions';
import * as fromEditorActions from './../actions/editor.actions';
import * as fromTimelinesActions from './../../timelines/store/actions/timelines.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ProjectDto,
  UpdateProjectRequest,
} from './../../../api/project/project.interfaces';
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { Action } from '@ngrx/store';
import { BrandKitsFacade } from '../facades/brand-kits.facade';
import { EditorFacade } from '../facades/editor.facade';
import { Injectable } from '@angular/core';
import { ProjectFacade } from '../facades/project.facade';
import { ProjectService } from '../../../api/project/project.service';
import { WorkflowBuilder } from '../../shared/builders/workflow.builder';
import { cloneDeep } from 'lodash-es';
import { EMPTY } from 'rxjs';
import { AlertService } from '../../core/services/alert.service';
import { WorkflowSteps } from '../interfaces/steps.interface';
import { AfterUpdate } from '../interfaces/editor.interface';
import { environment } from '../../../environments/environment';
import { getRouteProjectDetails } from '@openreel/common';
import { TemplateSettingsFacade } from '../facades/template-settings.facade';
import { PreviewFacade } from '../facades/preview.facade';

@Injectable()
export class ProjectEffects {
  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createProjectAPI),
      switchMap(({ captureProjectId, templateId, captureVideoIds }) =>
        this.projectService
          .createProject({ captureProjectId, templateId, captureVideoIds })
          .pipe(
            switchMap((data) => [fromActions.createProjectSuccess({ data })]),
            catchError((error) =>
              this.logError(error, 'Unable to create the project')
            )
          )
      )
    )
  );

  loadProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadProjectAPI),
      switchMap(({ projectId }) =>
        this.projectService.getProjectById(projectId).pipe(
          map((data) => fromActions.loadProjectSuccess({ data })),
          catchError((error) => this.logError(error, 'Unable to load project'))
        )
      )
    )
  );

  closeProject$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.closeProject),
        switchMap(() => this.projectFacade.captureProjectId$),
        map(
          (captureProjectId) =>
            (window.location.href = `${
              environment.nextGenAppUrl
            }#${getRouteProjectDetails(captureProjectId)}`)
        )
      ),
    { dispatch: false }
  );

  updateProjectWorkflow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectWorkflowAPI),
      withLatestFrom(this.projectFacade.data$, this.editorFacade.maxStep$),
      switchMap(([action, { data }, maxStep]) => {
        const dataToSave = {
          ...data,
          workflow: {
            ...action.data,
          },
        };

        return this.projectService
          .updateProjectById({
            ...this.mapToUpdateRequest(
              dataToSave,
              maxStep,
              action.currentStep,
              action.currentStep,
              AfterUpdate.None
            ),
          })
          .pipe(
            switchMap((response) =>
              this.getUpdateSuccessActions(response, AfterUpdate.None)
            ),
            catchError((error) => this.logError(error))
          );
      })
    )
  );

  updateProjectName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectNameAPI),
      withLatestFrom(this.projectFacade.data$, this.editorFacade.maxStep$),
      switchMap(([action, { data }, maxStep]) => {
        const nextStep = this.templateSettingsFacade.getNextStep(
          WorkflowSteps.Name
        );

        return this.projectService
          .updateProjectById({
            ...this.mapToUpdateRequest(
              data,
              maxStep,
              nextStep.order,
              WorkflowSteps.Name,
              action.afterUpdate
            ),
            name: action.name,
          })
          .pipe(
            switchMap((response) =>
              this.getUpdateSuccessActions(response, action.afterUpdate)
            ),
            catchError((error) => this.logError(error))
          );
      })
    )
  );

  updateProjectStyling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectStylingAPI),
      withLatestFrom(this.projectFacade.data$, this.editorFacade.maxStep$),
      switchMap(([action, { data }, maxStep]) =>
        this.projectService
          .updateProjectById(
            this.mapToUpdateRequest(
              data,
              maxStep,
              WorkflowSteps.IntroOutro,
              WorkflowSteps.Styling,
              action.afterUpdate
            )
          )
          .pipe(
            switchMap((response) =>
              this.getUpdateSuccessActions(response, action.afterUpdate)
            ),
            catchError((error) => this.logError(error))
          )
      )
    )
  );

  addProjectBrandKit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectBrandKitAPI),
      filter(({ brandKitId }) => !!brandKitId),
      switchMap(({ brandKitId, afterUpdate }) =>
        this.brandKitsFacade.all$.pipe(
          take(1),
          map((brandKits) => brandKits.find((kit) => kit.id === brandKitId)),
          map((brandKit) => ({ brandKit, afterUpdate }))
        )
      ),
      withLatestFrom(this.projectFacade.data$, this.editorFacade.maxStep$),
      switchMap(([{ brandKit, afterUpdate }, { data }, maxStep]) => {
        const dataToSave = { ...data };

        const workflowBuilder = new WorkflowBuilder(dataToSave.workflow);
        workflowBuilder.addOrUpdateWatermarkFromBrandKit(brandKit);
        workflowBuilder.addOrUpdateLogoFromBrandKit(brandKit);
        workflowBuilder.resetStyles();

        dataToSave.workflow = workflowBuilder.toWorkflow();

        return this.projectService
          .updateProjectById({
            ...this.mapToUpdateRequest(
              dataToSave,
              maxStep,
              WorkflowSteps.IntroOutro,
              WorkflowSteps.Styling,
              afterUpdate
            ),
            brandKitId: brandKit.id,
            workflow: {
              ...dataToSave.workflow,
              colors: brandKit.assets?.colors ?? [],
              fonts: brandKit.assets?.font ? [brandKit.assets?.font] : [],
            },
          })
          .pipe(
            switchMap((response) =>
              this.getUpdateSuccessActions(response, afterUpdate)
            ),
            catchError((error) => this.logError(error))
          );
      })
    )
  );

  removeProjectBrandKit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectBrandKitAPI),
      filter(({ brandKitId }) => !brandKitId),
      withLatestFrom(
        this.projectFacade.data$,
        this.editorFacade.maxStep$,
        this.projectFacade.templateWorkflow$
      ),
      switchMap(([action, { data }, maxStep, { colors, fonts }]) => {
        const dataToSave = { ...data };

        const workflowBuilder = new WorkflowBuilder(dataToSave.workflow);
        workflowBuilder.removeLogoFromBrandKit();

        dataToSave.workflow = workflowBuilder.toWorkflow();

        return this.projectService
          .updateProjectById({
            ...this.mapToUpdateRequest(
              dataToSave,
              maxStep,
              WorkflowSteps.IntroOutro,
              WorkflowSteps.Styling,
              action.afterUpdate
            ),
            brandKitId: null,
            workflow: {
              ...dataToSave.workflow,
              colors: colors ?? [],
              fonts: fonts ?? [],
            },
          })
          .pipe(
            switchMap((response) =>
              this.getUpdateSuccessActions(response, action.afterUpdate)
            ),
            catchError((error) => this.logError(error))
          );
      })
    )
  );

  updateProjectIntroOutro$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectIntroOutroAPI),
      withLatestFrom(
        this.projectFacade.data$,
        this.previewFacade.draftData$,
        this.editorFacade.maxStep$
      ),
      switchMap(
        ([{ intro, enabled, afterUpdate }, { data }, draftData, maxStep]) => {
          const dataToSave = cloneDeep(enabled ? draftData : data);

          const workflowBuilder = new WorkflowBuilder(dataToSave.workflow);
          workflowBuilder.saveIntroOutro(intro, enabled);
          dataToSave.workflow = workflowBuilder.toWorkflow();

          const nextStep = intro
            ? WorkflowSteps.IntroOutro
            : WorkflowSteps.VideoElements;
          return this.projectService
            .updateProjectById(
              this.mapToUpdateRequest(
                dataToSave,
                maxStep,
                nextStep,
                WorkflowSteps.IntroOutro,
                afterUpdate
              )
            )
            .pipe(
              switchMap((response) =>
                this.getUpdateSuccessActions(
                  response,
                  afterUpdate,
                  intro ? 1 : null
                )
              ),
              catchError((error) => this.logError(error))
            );
        }
      )
    )
  );

  updateProjectVideoElements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateProjectVideoElementsAPI),
      withLatestFrom(this.previewFacade.draftData$, this.editorFacade.maxStep$),
      switchMap(([action, draftData, maxStep]) => {
        const dataToSave = draftData;

        return this.projectService
          .updateProjectById(
            this.mapToUpdateRequest(
              dataToSave,
              maxStep,
              WorkflowSteps.VideoClips,
              WorkflowSteps.VideoElements,
              action.afterUpdate
            )
          )
          .pipe(
            switchMap((response) => {
              const successActions = this.getUpdateSuccessActions(
                response,
                action.afterUpdate
              );

              const mainSection = response.workflow.sections.main;
              const mainTimeline = mainSection.timelines.find(
                (t) => t.type === 'main'
              );
              const mainTimelineHasLayers = mainTimeline.layers.length > 0;

              if (mainTimelineHasLayers && maxStep < WorkflowSteps.VideoClips) {
                successActions.push(fromTimelinesActions.editSplits());
              }

              return successActions;
            }),
            catchError((error) => this.logError(error))
          );
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly projectService: ProjectService,
    private readonly projectFacade: ProjectFacade,
    private readonly previewFacade: PreviewFacade,
    private readonly brandKitsFacade: BrandKitsFacade,
    private readonly editorFacade: EditorFacade,
    private readonly templateSettingsFacade: TemplateSettingsFacade,
    private readonly alertService: AlertService
  ) {}

  private getUpdateSuccessActions(
    data: ProjectDto,
    afterUpdate: AfterUpdate,
    childStep?: number
  ) {
    const actions = [fromActions.updateProjectSuccess({ data })] as Action[];
    if (afterUpdate === AfterUpdate.NextStep) {
      if (childStep) {
        actions.push(fromEditorActions.setChildStep({ childStep }));
      } else {
        actions.push(fromEditorActions.nextStep());
      }
    }

    if (afterUpdate === AfterUpdate.CloseProject) {
      actions.push(fromActions.closeProject());
    }
    return actions;
  }

  private logError(error, errorText: string = 'Unable to update the project') {
    this.alertService.error(errorText);
    console.error(error);
    return EMPTY;
  }

  private mapToUpdateRequest(
    data: ProjectDto,
    currentMaxStep: number,
    step: number,
    currentStep: number,
    afterUpdate: AfterUpdate
  ): UpdateProjectRequest {
    const tempStep =
      afterUpdate === AfterUpdate.CloseProject ? currentStep : step;

    const nextStep = Math.max(currentMaxStep, tempStep);

    return {
      id: data.id,
      captureProjectId: data.captureProjectId,
      name: data.name,
      step: nextStep,
      brandKitId: data.brandKitId,
      workflow: data.workflow,
    };
  }
}
