import { Component, OnInit } from '@angular/core';
import { Cleanupable } from '@openreel/common/utils';
import { takeUntil } from 'rxjs/operators';
import { Editor } from '../../../store/interfaces/editor.interface';
import { EditorFacade } from '../../../store/facades/editor.facade';
import { Step } from '../../../store/interfaces/steps.interface';

@Component({
  selector: 'openreel-wf-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends Cleanupable implements OnInit {
  buttons: Step[];

  editorState: Editor;

  constructor(private readonly editorFacade: EditorFacade) {
    super();
  }

  ngOnInit() {
    this.editorFacade.editor$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((editor) => (this.editorState = editor));

    this.editorFacade.steps$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((steps) => (this.buttons = steps));
  }

  onStepSelect(step: number) {
    if (step > this.editorState.maxStep) {
      return;
    }

    this.editorFacade.setStep(step);
  }
}
