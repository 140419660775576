import { DirectorApiService, SignedUrl, VideoType } from '@openreel/common';
import { EMPTY, Observable, from, of } from 'rxjs';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import { concatMap, map } from 'rxjs/operators';

import { AssetsService } from '../../../api/assets/assets.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AssetFetchService {
  private httpClient: HttpClient;
  constructor(
    private readonly assetsService: AssetsService,
    private readonly directorApiService: DirectorApiService
  ) {
    this.httpClient = new HttpClient(
      new HttpXhrBackend({ build: () => new XMLHttpRequest() })
    );
  }

  fetchUrl(
    provider: 'url' | 'or-assets' | 'or-recordings',
    id: number | string
  ): Observable<SignedUrl> {
    if (provider === 'url') {
      return of({ url: id.toString(), expiresAt: null });
    } else if (provider === 'or-assets') {
      return this.assetsService
        .getAssetUrlById(Number(id))
        .pipe(map((asset) => asset.readUrl));
    } else if (provider === 'or-recordings') {
      return from(
        this.directorApiService.getVideoSignedUrlWithExpiry(
          Number(id),
          VideoType.SD,
          'play'
        )
      );
    } else {
      return EMPTY;
    }
  }

  fetchContentAsText(
    provider: 'url' | 'or-assets' | 'or-recordings',
    id: number | string
  ): Observable<string> {
    return this.fetchUrl(provider, id).pipe(
      concatMap((signedUrl) =>
        this.httpClient.get(signedUrl.url, {
          responseType: 'text',
        })
      )
    );
  }
}
