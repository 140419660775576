import { Injectable, Type } from '@angular/core';
import { TimelineItem } from '../../shared/element.interfaces';
import { Timeline } from '../../timelines/store/interfaces/timelines.interfaces';
import { PaneClipOptionsComponent } from '../components/panes/clip-options/pane-clip-options.component';
import { PaneOverlayColorsComponent } from '../components/panes/overlay-colors/pane-overlay-colors.component';
import { PaneOverlayDuplicateComponent } from '../components/panes/overlay-duplicate/pane-overlay-duplicate.component';
import { PaneOverlayTextComponent } from '../components/panes/overlay-text/pane-overlay-text.component';
import { PaneBaseComponent } from '../components/panes/pane-base.component';
import { PaneVideoOverlayOptionsComponent } from '../components/panes/video-overlay-options/pane-video-overlay-options.component';
import { ItemSelectPanesActionsService } from './panes-actions.service';

export enum ToolbarButtonType {
  Pane,
  Command,
}

export interface ToolbarButton {
  caption: string;
  icon: string;
  type: ToolbarButtonType;
  pane?: Type<PaneBaseComponent>;
  command?: (item: TimelineItem, timeline: Timeline) => Promise<boolean>;
}

@Injectable()
export class ItemSelectPanesService {
  constructor(
    private readonly itemSelectPanesActionsService: ItemSelectPanesActionsService
  ) {}

  getAvailableButtons(selectedItem: TimelineItem) {
    const buttons: ToolbarButton[] = [];
    switch (selectedItem.type) {
      case 'main':
        buttons.push(
          {
            caption: 'Options',
            icon: 'cog-outline',
            type: ToolbarButtonType.Pane,
            pane: PaneClipOptionsComponent,
          },
          {
            caption: 'Trash',
            icon: 'trash',
            type: ToolbarButtonType.Command,
            command: (item: TimelineItem, timeline: Timeline) =>
              this.itemSelectPanesActionsService.removeMainClip(item, timeline),
          }
        );
        break;
      case 'b-roll':
        buttons.push(
          {
            caption: 'Options',
            icon: 'cog-outline',
            type: ToolbarButtonType.Pane,
            pane: PaneVideoOverlayOptionsComponent,
          },
          {
            caption: 'Trash',
            icon: 'trash',
            type: ToolbarButtonType.Command,
            command: (item: TimelineItem, timeline: Timeline) =>
              this.itemSelectPanesActionsService.removeOverlay(item, timeline),
          }
        );
        break;
      case 'overlays':
        buttons.push(
          {
            caption: 'Text',
            icon: 'text',
            type: ToolbarButtonType.Pane,
            pane: PaneOverlayTextComponent,
          },
          {
            caption: 'Colors',
            icon: 'eye-dropper',
            type: ToolbarButtonType.Pane,
            pane: PaneOverlayColorsComponent,
          },
          {
            caption: 'Duplicate',
            icon: 'projects',
            type: ToolbarButtonType.Pane,
            pane: PaneOverlayDuplicateComponent,
          },
          {
            caption: 'Trash',
            icon: 'trash',
            type: ToolbarButtonType.Command,
            command: (item: TimelineItem, timeline: Timeline) =>
              this.itemSelectPanesActionsService.removeOverlay(item, timeline),
          }
        );
        break;
    }

    return buttons;
  }
}
