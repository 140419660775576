<mat-toolbar
  @headerAnimation
  color="accent"
  class="or-toolbar flex flex-align-center flex-justify-between"
>
  <div>
    <a class="flex" [routerLink]="'/'">
      <mat-icon svgIcon="wf-blue-gradient" class="mat-icon-32"></mat-icon>
    </a>
    <span class="f-normal-16 m-left-40">{{ title$ | async }}</span>
    <span
      class="f-normal-16 m-left-40 flex flex-align-center success"
      data-testid="test-progress-saved"
      *ngIf="saved$ | async"
    >
      <mat-icon svgIcon="wf-check" class="mat-icon-14 m-right-8"></mat-icon>
      Progress Saved
    </span>
  </div>
  <div class="or-toolbar-buttons">
    <button
      mat-button
      class="mat-button-pill-sm mat-button-dark"
      (click)="save()"
    >
      Save & Close
    </button>
  </div>
</mat-toolbar>
