import * as fromTemplateActions from '../actions/template.actions';
import * as fromEditorActions from '../actions/editor.actions';
import * as fromProjectActions from '../actions/project.actions';

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class EditorEffects {
  loadTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTemplateActions.loadTemplateSuccess),
      switchMap((template) => [
        fromEditorActions.setStepsForTemplate({
          templateType: template.data.workflow.type,
        }),
        fromProjectActions.loadProjectFinished(),
      ])
    )
  );

  constructor(private readonly actions$: Actions) {}
}
