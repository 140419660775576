import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionOtpLoginRequest, SessionResponse } from '../../../interfaces';
import { commonenv } from '@openreel/common/env/environment';

const URL_OTP_LOGIN = 'session-otp-login';
const DOWNLOAD_GDPR = 'send-gdpr-email';
const PURGE_GDPR = 'device-log-delete-request';
const DELETE_EMAIL_TOKEN = `${commonenv.nextGenApiUrl}invitations/delete-email-token`;

@Injectable({
  providedIn: 'root',
})
export class SubjectApiService {
  constructor(private httpClient: HttpClient) {}

  joinOtp(request: SessionOtpLoginRequest) {
    return this.httpClient.post<SessionResponse>(URL_OTP_LOGIN, request);
  }
  downloadGDPR(request) {
    return this.httpClient.post(DOWNLOAD_GDPR, request);
  }
  purgeGDPR(request) {
    return this.httpClient.post(PURGE_GDPR, request);
  }
  deleteEmailToken(emailToken) {
    return this.httpClient.post(DELETE_EMAIL_TOKEN, {
      emailToken,
    });
  }
}
