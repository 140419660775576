<div class="m-bottom-12" *ngIf="canGoBack">
  <button disableRipple mat-button class="no-overlay p-0" (click)="goBack()">
    <mat-icon svgIcon="left-arrow"></mat-icon>
    Back to My Projects
  </button>
</div>
<div
  class="scroll-container flex-auto overflow-y-scroll p-right-20"
  [class.m-top-48]="!canGoBack"
>
  <div class="content-grid">
    <openreel-wf-recording
      *ngFor="let recording of recordings$ | async"
      [recording]="recording"
      [selected]="isRecordingSelected(recording)"
      (recordingSelected)="selectRecording(recording, $event)"
    ></openreel-wf-recording>
  </div>
</div>
