import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';
import {
  LottieLayerData,
  TextOverlayFeature,
  TextOverlayFeatureLottieProperties,
  Visibility,
} from '../../../api/workflow.interfaces';
import { v4 as uuidv4 } from 'uuid';

export class AddTextCommand extends BaseCommand<TimelineInterfaces.TextOverlayAddEvent> {
  run(event: TimelineInterfaces.TextOverlayAddEvent) {
    this.createMainSectionIfNotexists();
    const overlaysTimeline = this.getTimeline('overlays', true);

    const assetId =
      event.textOverlay.properties.type === 'lottie'
        ? event.textOverlay.properties.assetId
        : event.textOverlay.properties.items[0].assetId;

    const animationAsset = this.getAsset(assetId.toString());
    const { data, styles } = this.getDefaultPresetData(animationAsset);

    const visibility = {
      startAt: event.startAt,
      endAt: event.startAt + animationAsset.data.duration,
    };

    let newLayer = null;
    if (event.textOverlay.properties.type === 'sublayers') {
      newLayer = {
        type: 'sublayers',
        layerId: uuidv4(),
        templateId: event.textOverlay.id,
        visibility,
        children: event.textOverlay.properties.items.map((item) =>
          this.createLottieLayer(event.textOverlay, item, data, item.visibility)
        ),
      };
    } else {
      newLayer = this.createLottieLayer(
        event.textOverlay,
        event.textOverlay.properties,
        data,
        visibility
      );
    }

    overlaysTimeline.layers.push(newLayer);

    if (!this.source.styles) {
      this.source.styles = [];
    }
    this.source.styles.push(...styles);

    return {
      updatedWorkflow: this.toWorkflow(),
    };
  }

  private createLottieLayer(
    textOverlay: TextOverlayFeature,
    properties: TextOverlayFeatureLottieProperties,
    data: { [key: string]: LottieLayerData },
    visibility: Visibility
  ) {
    return {
      layerId: uuidv4(),
      templateId: textOverlay.id,
      type: properties.type,
      assetId: properties.assetId,
      renderer: properties.renderer,
      visibility,
      data,
      bounds: properties.bounds,
      transitions: textOverlay.transitions,
    };
  }
}
