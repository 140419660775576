import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import { LayerOptions, LottieLayer } from '../../../api/workflow.interfaces';
import { BaseCommand } from './base.command';
import { TimelineItem, timelineItemFactory } from '../element.interfaces';

export class StretchTextCommand extends BaseCommand<
  TimelineInterfaces.StretchTextOverlayEvent,
  TimelineItem
> {
  run(event: TimelineInterfaces.StretchTextOverlayEvent) {
    const timeline = this.getTimeline('overlays');

    const layerToUpdate = timeline.layers.find(
      (l) => l.layerId === event.item.layerId
    ) as LottieLayer & LayerOptions;

    layerToUpdate.visibility.endAt =
      layerToUpdate.visibility.startAt + event.newDuration;

    return {
      result: timelineItemFactory(layerToUpdate, 'overlays', this.source),
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
