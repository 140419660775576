import { RecordingType } from '../extension';
import { VideoStreamResolution } from '../media';

export interface StreamSettingCapability {
  max: number;
  min: number;
  step: number;
}

export function mapStreamSettingCapability(
  from: StreamSettingCapability,
  to: StreamSettingCapability,
  value: number
) {
  if (
    // TODO: This happenes when one of the properties are not supported (for example contrast), refactor required
    from === undefined ||
    from.min === undefined ||
    from.max === undefined ||
    to === undefined ||
    to.min === undefined ||
    to.max === undefined ||
    value === undefined
  )
    return 0;

  const ret =
    ((value - from.min) * (to.max - to.min)) / (from.max - from.min) + to.min;
  return Math.round(ret / to.step) * to.step;
  // console.log(`${from.min},${from.max} - ${to.min},${to.max} = ${value} -> ${ret}`);
  // return ret;
}

export function mapStreamSettingCapabilityToZero(
  currentSpace: StreamSettingCapability,
  value: number
): number {
  return mapStreamSettingCapability(
    currentSpace,
    {
      min: 0,
      max: currentSpace.max - currentSpace.min,
      step: currentSpace.step,
    },
    value
  );
}

export function mapStreamSettingCapabilityFromZero(
  targetSpace: StreamSettingCapability,
  value: number
): number {
  return mapStreamSettingCapability(
    {
      min: 0,
      max: targetSpace.max - targetSpace.min,
      step: targetSpace.step,
    },
    targetSpace,
    value
  );
}

// TODO: Split each interface in it's own file
export interface SessionOtpLoginRequest {
  app_version: string;
  device_model: string;
  device_os: string;
  device_type: string;
  device_type_data: string;
  device_unique_id: string;
  extra_data: unknown;
  identity: string | null;
  token: string;
  otp: string;
  tos_approval: 1;
  name: string;
}

export class SessionBase {
  account_id: number;
  session_id: number;
  status: number;
  session_name: string;
  created_at: Date;
  identity: string;
  telepromter_scripts: TeleprompterScript[];
  recordings: Recording[];
  project_id?: number;
  project_name?: string;
  start_time: Date;
  end_time: Date;
}

export class SubjectSession extends SessionBase {
  ovra_sessions_id: number;
  login_id: number;
  videorecordcount: number;
  videouploadcount: number;
  invited_by: string;
  invite_by_email: string;
  total_web_users: number;
  app_user_name: string;
  app_user_email: string;
  app_user_phone: string;
  app_auto_join: number;
  recordings: Recording[];
  auth_token: string;
}

export function isSubjectSession(
  session: SessionBase
): session is SubjectSession {
  return !!(session && (session as SubjectSession).auth_token);
}

export class CameraPresetCreate {
  // FPS
  cSp_fps_value: string;
  // ISO
  cSp_iso_value: string;
  // true = manual exposure, false = auto exposure
  cSp_autoExposure_status: '0' | '1';
  // true = auto focus, false = manual focus
  cSp_autoFocus_status: '1';
  // false = landscape, true = portrait
  cSp_cameraPotrait_status: '0';
  // exposure value
  cSp_exposure_value: string;
  // focus value (1 - 100)
  cSp_focus_value: '50';
  // white balance value (1 - 100)
  cSp_whiteBalance_value: string;
  // contrast value (1 - 100)
  cSp_contrast_value: string;
  session_id: number;
  // name of the preset to create
  cSp_title: string;
  // true = auto exposure, false = manual exposure
  is_autoexposure: 0 | 1;
  // true = auto contrast, false = manual contrast
  is_autocontrast: 0 | 1;
  // true = auto iso, false = manual iso
  is_autoiso: 0 | 1;
  // true = auto white balance, false = manual white balance
  is_autotemperature: 0 | 1;
  // true = auto focus, false = manual focus
  is_autofocus: 1;
  // who knows
  mbit: number;
  // true = stabilize, false = don't stabilize
  stabilize: '0';
  // tint value (1 - 100)
  tint: '-1';
  // saturation value -1 = auto, (1 - 100)
  saturation: '-1';
  // vibrance value -1 = auto, (1 - 100)
  vibrance: '-1';
  // shadow value -1 = auto, (1 - 100)
  shadow: '-1';
  // highlight value -1 = auto, (1 - 100)
  highlight: '-1';
  // who knows
  color_overlay_color: '-1';
  // who knows
  color_overlay_value: '-1';
  // 0 = no flashlight
  flashlight: 0;
  // 0 = no zoom
  zoom: 0;
  // in khz
  audio_sample_rate: '44.1';
  // height
  resolution: string;
  aspect_ratio: 'Ratio169';
  log_mode: -1;
  focus_peaking: 0;
}

export interface CameraPreset extends CameraPresetCreate {
  ovra_cameraSetting_presets_id: number;
}

export interface CameraPresetCreateResponse {
  ovra_cameraSetting_presets_id: number;
}

export class DirectorSession extends SessionBase {
  session_otp: string;
  invited_users: InvitedUser[];
  camera_presets: CameraPreset[];
}

export class SingleSessionInList extends SessionBase {
  creator: {
    email: string;
    fullname: string;
  };
}

export class SessionData<SessionType extends SessionBase> {
  message: string;
  status: number;
  data: {
    sessiondetails: SessionType;
    company_slug_name: string;
  };
}

export interface SessionResponse {
  result: SubjectSession;
}

export interface AppUser<T> {
  auth_token: string;
  role: 'director' | 'collaborator';
  data: T;
}

export interface DirectorUserDetails {
  user: {
    token: string;
    user_details: {
      account_id: number;
      fullname: string;
      email: string;
      id: number;
      thumb_image?: string;
    };
  };
}

export interface TwilioTokenRequest {
  identity?: string;
  login_id?: string;
  session_id: string;
}

export interface TwilioTokenResponse {
  identity: string;
  token: string;
  record_settings: {
    is_start_record: number;
    is_stop_record: number;
    is_focus: number;
    is_exposure: number;
  };
}

export interface VideoUploadResponse {
  file_size: number;
  video_length: number;
  video_filename: string;
  status: number;
  transcoding_status: string;
  resolution: string;
}

export enum VideoTranscodingStatus {
  Processing = 'processing',
  Queued = 'queued',
  Ready = 'ready',
  Failed = 'failed',
}

export enum VideoShowableStatus {
  Processing = 'Processing',
  Queued = 'Queued',
  Ready = 'Ready',
  Failed = 'Failed',
  ReadyAndProcessing = 'Ready & Processing',
  Mp4Ready = 'MP4 Ready',
}

export interface VideoChild {
  ovra_session_videos_id: number;
  high_config_transcoded: string;
  transcoding_status: VideoTranscodingStatus;
  height: number;
  width: number;
  file_size: number;
  s3_path: string;
}

export interface Recording {
  batch: string;
  bit_rate: string;
  camera_data_log: string; // json string
  corrupt: number;
  created_at: string;
  device_name: string;
  extra_info: string;
  file_size: number;
  file_to_keep: string;
  fps: string;
  hub_spot_integrated: string;
  identity: string;
  ovra_session_videos_id: number;
  ovra_session_videos_s3_children: [];
  resolution: string;
  s3_integrated: string;
  s3_path: string;
  session_id: number;
  sprite_thumb: string;
  status: number;
  thumb: string;
  transcoding_status: 'queued' | 'processing' | 'ready';
  updated_at: string;
  video_filename: string;
  video_length: number;
  video_name: string;
  video_note: string;
  video_type: number;
  videos_child?: VideoChild[];
  upload_percentage?: number;
  transcoding_percentage?: number;
  shortlisted: '0' | '1';
  session?: SessionBase;
  transcode_type?: 'sd_transcoding' | 'hq_transcoding';
  sd_transcoding_percentage?: number;
  hq_transcoding_percentage?: number;
  preview_status?: VideoTranscodingStatus;
  hq_status?: VideoTranscodingStatus;
  video_showable_status?: VideoShowableStatus;
  video_status?: VideoTranscodingStatus;

  // properties used by components/templates
  selected?: boolean;
  favourite?: boolean;
}

export interface SignedUrl {
  url: string;
  expiresAt: Date;
}

export enum VideoType {
  'ORIGINAL' = 'original',
  'SD' = 'sd',
  'HD' = 'hd',
}
export enum ClipBulkAction {
  'SELECT' = 'Select All',
  'UNSELECT' = 'Unselect All',
  'DOWNLOAD_ORIGINAL' = 'Download Original',
  'DOWNLOAD_MP4' = 'Download MP4',
  'DELETE' = 'Delete',
  'TRANSCODE_HQ' = 'Request MP4',
  'UPLOAD' = 'Upload',
  'CANCEL_UPLOAD' = 'Cancel Upload',

  // This should be always the last element
  // Check: BulkActionComponent@OnInit
  'PODCAST_WORKFLOW' = 'Podcast Workflow',
}

export interface NewVideoInfo {
  video_name: string;
  identity: string;
  video_type: number;
  admin_ovra_id: number;
  resolution: string;
  fps: string;
  bit_rate: string;
  device_name: string;
}

export interface NewVideoRequest {
  session_id: number;
  videos: NewVideoInfo[];
}

export interface NewVideoResponseVideo {
  ovra_session_videos_id: number;
  session_id: number;
  video_name: string;
  device_name: string;
  resolution: string;
  fps: string;
  bit_rate: string;
  status: number;
  is_Deleted: number;
  identity: string;
  video_type: number;
  admin_ovra_id: number;
  created_at: Date;
  batch: number;
  is_dummy: string;
  camera_data_log: string;
  updated_at: Date;
}

export interface NewVideoResponseSessionOptions {
  id: number;
  ovra_sessions_id: number;
  admin_feed: number;
  admin_talkback_audio: number;
  admin_talkback: number;
  admin_feed_data: number;
  start_stop: number;
  timer: number;
  timer_value: number;
  sound: number;
  allow_multiple_device: number;
  cam_option: number;
  audio_feed: number;
  max_app_connect: number;
  is_zoom: number;
  is_flashlight: number;
  is_remote_feed_mirror: number;
  admin_feed_behave: number;
  is_talkback_dev_specific: number;
  auto_join_session: number;
  self_record_set: string;
  media_region: string;
  room_codec: string;
  tele_countdown: number;
  created_at: Date;
  updated_at: Date;
}

export interface NewVideoResponse {
  status: number;
  videos: NewVideoResponseVideo[];
  sessionoptions: NewVideoResponseSessionOptions;
}

export interface DeleteVideoResponse {
  status: number;
}

export interface TeleprompterScriptRequest {
  command: string;
  SessionID: number;
  script_content: {
    scriptData: string[];
    timerData: number[];
  };
  scroll_speed: number;
  font_size: string;
  content_change: number;
  tele_script_id: number;
  txtColor: number;
  // nextgen properties
  fontSize: number;
  split: string;
  speed: number;
  background: string;
}

export interface TeleprompterScriptResponse {
  status: number;
  ovra_teleprompter_script_id: number;
}

export enum TeleprompterScriptProgressStatus {
  Ready = 'ready',
  NeedsWork = 'needs_work',
}

export interface TeleprompterScriptEdit {
  title: string;
  content: string;
  scroll_speed: number;
  ts_backgroundOption?:
    | 'solid'
    | 'video_full'
    | 'video_left'
    | 'video_right'
    | 'overlay';
  ts_backgroundColor?: string;
  ts_fontsize: number;
  session_id?: number;
  progress_status?: TeleprompterScriptProgressStatus;
}

export interface TeleprompterScriptCreate extends TeleprompterScriptEdit {
  project_id?: number;
}

export interface TeleprompterScript extends TeleprompterScriptCreate {
  ovra_teleprompter_script_id: number;
}

/**
 * All possible roles for user. They are sorted according to the importance so,
 * lowest number are more important then higher numbers
 *
 * @export
 * @enum {number}
 */
export enum UserRoleType {
  Internal = 'admin',
  Collaborator = 'operator',
  Watcher = 'watcher',
  Subject = 'subject',
}

export const UserRoleTypeHumanReadable = {
  [UserRoleType.Internal]: 'Director',
  [UserRoleType.Collaborator]: 'Collaborator',
  [UserRoleType.Watcher]: 'Watcher',
  [UserRoleType.Subject]: 'Subject',
};

export interface Video {
  mainBlob?: Blob;
  mainStream?: string;
  secondaryStream?: string;
  contentType?: string;
  duration?: number;
  recordingType?: RecordingType; // required for multi stream
}

export interface SingleInvitation {
  name: string;
  email: string;
  type: UserRoleType;
}

export interface SessionListResponse {
  sessions: {
    count: number;
    rows: SingleSessionInList[];
  };
}

export interface OvraSessionDeviceLog {
  id: number;
  ovra_sessions_id: number;
  session_id: number;
  account_id: number;
  otp: string;
  device_model: string;
  device_os: string;
  name: string;
  tos_approval: number;
  app_version: string;
  device_unique_id: string;
  ip: string;
  location: string;
  device_type: string;
  extra_data: string;
  created_at: Date;
  updated_at: Date;
}

export interface DeviceInfoResult {
  id: number;
  device_log_id: number;
  token: string;
  date_array: string;
  duration: number;
  start_battery_level: number;
  end_battery_level: number;
  start_temperature: string;
  end_temperature: string;
  device_accept_by: string;
  device_support: string;
  internet_speed: string;
  created_at: Date;
  updated_at: Date;
  ovra_session_device_log: OvraSessionDeviceLog;
}
export interface CameraValueRanges {
  exposureRange?: StreamSettingCapability;
  isoRange?: StreamSettingCapability;
  colorTemperatureRange?: StreamSettingCapability;
  contrastRange?: StreamSettingCapability;
}

export interface DeviceLogChild {
  id: number;
  device_log_id: string;
  token: string;
  device_support: string;
  created_at: Date;
}

export interface UpdateDeviceInfoResponse {
  devices: {
    [identity: string]: {
      camera_details: DeviceInfoData;
      devicedetails: string;
    };
  };
  device_log_child: DeviceLogChild;
}

export interface UpdateDeviceInfoRequest {
  session_id: number;
  devices: {
    identity: string;
    camera_type: string;
    frame_rates: string;
  }[];
  identity: string;
  login_id: string;
  device_support: string;
  device_accept_by: string;
}

export interface GetAllDeviceDetailsRequest {
  identity: string;
  session_id: number;
}

export interface GetAllDeviceDetailsResponse {
  devicedata: DeviceInfoData[];
}

export interface CameraInfo {
  focus_lock_unlock_support: '0' | '1';
  manual_focus_support: '0' | '1';
  exposure_lock_unlock_support: '0' | '1';
  // includes fps info as well
  resolution_support: VideoStreamResolution[];
  tap_exposure_support: '0' | '1';
  temperature_support: '0' | '1';
  manual_exposure_support: '0' | '1';
  tap_focus_support: '0' | '1';
  iso_support: '0' | '1';
  contrast_support?: '0' | '1';
  ranges?: CameraValueRanges;
  fps_support?: string;
}
export interface ExtraDeviceInfo {}
export interface DeviceInfo {
  storage: string;
  battery_level: string;
  front_cam?: CameraInfo;
  back_cam?: CameraInfo;
  extra_info: ExtraDeviceInfo;
}
export interface DeviceInfoData {
  id: number;
  session_id: number;
  identity: string;
  camera_type: number;
  frame_rates: string;
  selected_fps: string;
  exposure: string;
  exposure_type: string;
  iso: string;
  iso_type: string;
  is_autoexposure: number;
  is_autoiso: number;
  ae_lock: number;
  mbit: string;
  stabilize: string;
  af_lock: number;
  temperature: string;
  focus: string;
  is_autotemperature: number;
  is_autofocus: number;
  // THIS IS NOT IMPLEMENTED IN BACKEND
  is_autocontrast: number;
  contrast: string;
  tint: string;
  saturation: string;
  vibrance: string;
  shadow: string;
  highlight: string;
  is_join_room: number;
  device_login_id: number;
  color_overlay_color: string;
  color_overlay_value: string;
  flashlight: number;
  zoom: number;
  audio_sample_rate: '44.1';
  resolution: string;
  aspect_ratio: string;
  log_mode: number;
  portrait_mode: number;
  portrait_resolution: string;
  portrait_sel_res: string;
  portrait_fps: string;
  focus_peaking: number;
  mobile_feed: number;
  mobile_mic_options: number;
  created_at: Date;
  updated_at: Date;
}

export interface DeviceInfoResponse {
  status: number;
  result: DeviceInfoResult;
  devicedata: DeviceInfoData;
}

export interface DeviceInfoRequest {
  device_id: string;
  session_id: number;
}

export interface RemoteSessionDetailsRequest {
  session_id: number;
  identity: string;
  login_id: number;
}

export interface InvitedUsersDetails {
  id: number;
  email: string;
  fullname: string;
  contact: string;
  image: string;
  thumb_image: string;
}

export interface InvitedUser {
  ovra_sessionUser_mapping_id: number;
  account_users_id?: number;
  sum_role: UserRoleType;
  sum_nature: string;
  sum_addedBy_user: number;
  camera_control_access: number;
  user_email: string;
  user_type: number;
  invited_users_details: InvitedUsersDetails | null;
  is_audible: boolean;
  is_pinned: boolean;
  app_user_name: string;
  is_online: boolean;
}

export function getIdForInvitedUser(invitedUser: InvitedUser) {
  return (
    invitedUser &&
    (invitedUser.account_users_id || invitedUser.ovra_sessionUser_mapping_id)
  );
}

export interface RemoteSessionDetailsResponse {
  status: number;
  sessionresult: {
    ovra_sessions_id: number;
    account_id: number;
    session_id: number;
    status: number;
    is_self: number;
    user_id: number;
    session_category_id: number;
    estd_startDate: string;
    session_objective: string;
    session_name: string;
    appUser_name: string;
    appUser_email: string;
    appUser_phone: string;
    session_otp: string;
    start_time: Date;
    created_at: Date;
    session_lastUpdated_user: number;
    session_updatedAt: Date;
    session_isDeleted: number;
    is_demo_session: number;
    invited_users: InvitedUser[];
  };
  devicedata: DeviceInfoData;
}

export interface CreateSessionResponse {
  data: {
    session_id: number;
  };
  message: string;
  stat: number;
}

export interface SessionRecordingsResponse {
  data: {
    count: number;
    rows: Recording[];
  };
}

export interface IPopupComponent {
  isPopup: true;
  isLightThemed?: boolean;
  onSessionReceived?(session: SessionBase): void;
}

export function isPopupComponent(comp: {
  isPopup: boolean;
}): comp is IPopupComponent {
  return comp.isPopup;
}

export interface SessionSettingsResult {
  id: number;
  ovra_sessions_id: number;
  admin_feed: number;
  admin_talkback_audio: number;
  admin_talkback: number;
  admin_feed_data: number;
  start_stop: number;
  timer: number;
  timer_value: number;
  sound: number;
  allow_multiple_device: number;
  cam_option: number;
  audio_feed: number;
  max_app_connect: number;
  is_zoom: number;
  is_flashlight: number;
  is_remote_feed_mirror: number;
  admin_feed_behave: number;
  is_talkback_dev_specific: number;
  auto_join_session: number;
  self_record_set: string;
  media_region: string;
  room_codec: string;
  tele_countdown: number;
  created_at: Date;
  updated_at: Date;
}

export interface SessionSettingsResponse {
  status: number;
  result: SessionSettingsResult;
  invite_users: InvitedUser[];
}

export interface EditableSessionSettings {
  allow_multiple_device: number;
  tele_countdown: number;
  timer: number;
  timer_value: number;
}

export interface SessionSettingsUpdateRequest {
  session_id: string;
  updatedata: EditableSessionSettings;
}
export interface DirectorSelfRecordData {
  name: string;
  identity: string;
  resolution: string;
  fps: string;
  deviceName: string;
  videoName?: string;
  videoId?: number;
  selected?: boolean;
}
export interface VersionControlRequest {
  device: string;
  version: string;
  app_name: string;
}
export interface VersionControlResponse {
  status: number;
  data: {
    app_name: string;
    ios: { version: string };
  };
}

export interface InvitationsValidateDto {
  token: string;
  email: string;
  password: string;
}
export interface IUserDetails {
  loggedin_fullname: string;
  account_id: number;
  email: string;
  user_id: number;
  company_name: string;
  site_user_slug: string;
  image: string;
  is_root_admin: boolean;
}
export interface IUserDetailsSrv {
  loggedin_fullname: string;
  account_id: number;
  email: string;
  user_id: number;
  company_details: {
    name: string;
    site_user_slug: string;
  };
  image: string;
  is_root_admin: boolean;
  token: string;
  auth_token?: string;
}

export class InvitationsValidateResponseDto {
  email_token: string;
  user_email: string;
  account_id: number;
  session_id: number;
  data: IUserDetailsSrv;
  user: unknown;
  status: number;
}

export interface ILoginRespDto {
  status: number;
  statusCode: number;
  message: string;
  data?: IUserDetailsSrv;
  err?: string;
}

export class InvitationsValidationResult {
  success: boolean;
  message?: string;
  data?: InvitationsValidateResponseDto;
}

export interface PublicVideo {
  url: string;
  quality_type: string;
  session_name: string;
  video_name: string;
}

/**
 * Api response status
 */
export enum ApiResponseStatus {
  success = 1,
  fail = 0,
}

export enum BinaryStringFields {
  true = '1',
  false = '0',
}
