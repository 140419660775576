import { StreamSettingCapability } from '../../interfaces';
import { AdvancedConstraintSet } from '../interfaces/advanced-constraint-set.interface';
import { AdvancedMediaTrackCapabilities } from '../interfaces/advanced-mediatrack-capabilities.interface';
import { VideoConstraints } from '../interfaces/video-constraints.interface';
import {
  Resolution,
  VideoStreamResolution,
} from '../interfaces/video-stream-resolution.interface';
import { getSupportedConstraints } from './media-devices.helper';

/**
 * Supported resolution list (Always forcing a 16:9 aspect ratio)
 */
export const expectedResolutions: Resolution[] = [
  // { width: 3840, height: 2160 },
  { width: 1920, height: 1080 },
  { width: 1280, height: 720 },
  { width: 852, height: 480 },
  { width: 640, height: 360 }
];

/**
 * Supported frames per second list.
 */
export const expectedFps: number[] = [60, 30, 24, 15];

/**
 * Supported director resolution list
 */
export const expectedDirectorResolutions: Resolution[] = [
  { width: 852, height: 480, label: '480p' },
  { width: 1280, height: 720, label: '720p' },
  { width: 1920, height: 1080, label: '1080p' },
  { width: 3840, height: 2160, label: '4k' },
];

/**
 * Supported director frames per second list.
 */
export const expectedDirectorFps: number[] = [30, 60, 24];

/**
 * Default resolution
 */
export const defaultResolution: Resolution = expectedResolutions[expectedResolutions.length - 2];

/**
 * Default FPS
 */
export const defaultFps: number = expectedFps[1];

/**
 * All resolution/fps combinations
 */
export const allResolutions = (): VideoStreamResolution[] =>
  expectedResolutions.map(({ height }) => ({
    value: height,
    fps: expectedFps,
  }));

export const heightFromString = (str: string): number =>
  str === '4k' ? 1080 : parseInt(str, 10);

export const findResolution = (height: number): Resolution => {
  const result = expectedResolutions.find((r) => r.height === height);
  return result || defaultResolution;
};

export const findFps = (fps: number): number => {
  const result = expectedFps.find((f) => f === fps);
  return result || defaultFps;
};

/**
 * Supported aspect ratio list.
 */
export const expectedAspectRatioRange: ConstrainULongRange = {
  min: 1.6,
  max: 1.8,
  ideal: 16 / 9,
};

/**
 * Supported resolution range.
 */
export const expectedResolutionRange: ConstrainULongRange = {
  min: 240,
  max: 1080,
  ideal: 1080,
};

/**
 * Supported fps range.
 */
export const expectedFpsRange: ConstrainULongRange = {
  min: 15,
  max: 60,
  ideal: 60,
};

/**
 * Default exposure range for director's slider
 */
export const expectedExposureRange: StreamSettingCapability = {
  min: -4,
  max: 4,
  step: 0.1,
};

/**
 * Default iso range for director's slider
 */
export const expectedIsoRange: StreamSettingCapability = {
  min: 200,
  max: 1600,
  step: 100,
};

/**
 * Default contrast range for director's slider
 */
export const expectedContrastRange: StreamSettingCapability = {
  min: -150,
  max: 150,
  step: 10,
};

/**
 * Default color temperature range for director's slider
 */
export const expectedColorTemperatureRange: StreamSettingCapability = {
  min: 1000,
  max: 11000,
  step: 500,
};

export function streamSettingCapabilityClone(
  val: StreamSettingCapability
): StreamSettingCapability {
  return {
    max: val.max,
    min: val.min,
    step: val.step,
  };
}

export const findTrackCapabilities = async (track: MediaStreamTrack) => {
  const supportedConstraints = getSupportedConstraints();
  const capabilities = (track.getCapabilities
    ? track.getCapabilities()
    : {}) as AdvancedMediaTrackCapabilities;

  const result = {
    supportsExposureLock: supportedConstraints.exposureTime || false,
    supportedManualExposureRange: supportedConstraints.exposureTime
      ? adjustManualExposureTimeRange(capabilities.exposureTime)
      : undefined,
    supportsExposureCoordinate: false, // This was already like this in the original implementation
    supportedManualIsoRange: supportedConstraints.exposureCompensation
      ? capabilities.exposureCompensation
      : undefined,
    supportedManualContrastRange: supportedConstraints.contrast
      ? capabilities.contrast
      : undefined,
    supportedManualColorTemperatureRange: supportedConstraints.colorTemperature
      ? capabilities.colorTemperature
      : undefined,
    supportedManualExposureRangeDirector: supportedConstraints.exposureTime
      ? expectedExposureRange
      : undefined,
    supportedManualIsoRangeDirector: supportedConstraints.exposureCompensation
      ? expectedIsoRange
      : undefined,
    supportedManualContrastRangeDirector: supportedConstraints.contrast
      ? expectedContrastRange
      : undefined,
    supportedManualColorTemperatureRangeDirector: supportedConstraints.colorTemperature
      ? expectedColorTemperatureRange
      : undefined,
  };

  return result;
};

/**
 * Utility function to check if requestedValue is within range. Optionally
 * you can control if you want to throw if number is outside of range.
 * @param range
 * @param requestedValue
 */
export const checkCapabilityRange = (
  range: StreamSettingCapability | undefined,
  requestedValue: number
) =>
  range ? requestedValue >= range.min && requestedValue <= range.max : false;

const adjustManualExposureTimeRange = (
  exposureTime?: StreamSettingCapability
): StreamSettingCapability => {
  if (exposureTime) {
    const ret = streamSettingCapabilityClone(exposureTime);
    // Highest exposure value is too high, and useless most of the time.
    if (ret.max / 10 > ret.min) {
      ret.max /= 10;
    }
    return ret;
  }
  return exposureTime;
};

export const defaultScreenShareConstraints: MediaTrackConstraints = {
  height: defaultResolution.height,
  width: defaultResolution.width,
  frameRate: defaultFps,
};

export const asScreenShareConstraints = (
  constraints: VideoConstraints
): MediaTrackConstraints => ({
  width: constraints.width,
  height: constraints.height,
  frameRate: constraints.fps,
});

export const defaultWebcamConstraints: MediaTrackConstraints = {

  height: defaultResolution.height,
  width: defaultResolution.width ,
  frameRate: defaultFps,
};

export const asWebcamConstraints = (
  constraints: VideoConstraints
): MediaTrackConstraints => {
  const supportedConstraints = getSupportedConstraints();
  const mediaTrackConstraints: MediaTrackConstraints = {
    width: constraints.width,
    height: constraints.height,
    frameRate: constraints.fps,
  };

  const advancedConstraints: AdvancedConstraintSet = {};
  if (supportedConstraints.exposureTime) {
    if (constraints.exposure !== undefined) {
      advancedConstraints.exposureTime = constraints.exposure;
      advancedConstraints.exposureMode = 'manual';

      if (supportedConstraints.exposureCompensation) {
        advancedConstraints.exposureCompensation =
          constraints.iso !== undefined ? constraints.iso : undefined;
      }
    } else {
      advancedConstraints.exposureMode = 'continuous';
    }
  }
  if (supportedConstraints.contrast) {
    advancedConstraints.contrast =
      constraints.contrast !== undefined
        ? constraints.contrast
        : (expectedContrastRange.min + expectedContrastRange.max) / 2;
  }
  if (supportedConstraints.colorTemperature) {
    if (constraints.colorTemperature !== undefined) {
      advancedConstraints.whiteBalanceMode = 'manual';
      advancedConstraints.colorTemperature = constraints.colorTemperature;
    } else {
      advancedConstraints.whiteBalanceMode = 'continuous';
    }
  }
  return mediaTrackConstraints;
};

export const getWidthForResolution = (height: number): number =>
  expectedResolutions.find((res) => res.height === height).width;
