import * as fromActions from './../actions/brand-kits.actions';
import * as fromProjectActions from './../actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';

import { BrandKitService } from '../../../api/brand-kit/brand-kit.service';
import { BrandKitsFacade } from '../facades/brand-kits.facade';
import { Injectable } from '@angular/core';

@Injectable()
export class BrandKitsEffects {
  listBrandKits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.listBrandKitsAPI),
      switchMap(() =>
        this.brandKitService.getBrandKits().pipe(
          map((all) => fromActions.listBrandKitsSuccess({ all })),
          catchError((error) => of(error))
        )
      )
    )
  );

  removeCurrentBrandKit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromProjectActions.loadProjectSuccess,
        fromProjectActions.updateProjectSuccess
      ),
      filter(({ data }) => !data.brandKitId),
      map(() => fromActions.removeCurrentBrandKit())
    )
  );

  loadCurrentBrandKit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromProjectActions.loadProjectSuccess,
        fromProjectActions.updateProjectSuccess
      ),
      withLatestFrom(this.brandKitsFacade.current$, this.brandKitsFacade.all$),
      filter(
        ([{ data }, current]) =>
          data.brandKitId && data.brandKitId !== current?.id
      ),
      map(([{ data }, , all]) => ({
        data,
        existing: all.find((bk) => bk.id === data.brandKitId),
      })),
      switchMap(({ data, existing }) =>
        existing
          ? of(fromActions.loadCurrentBrandKitSuccess({ current: existing }))
          : this.brandKitService
              .getBrandKitById(data.brandKitId)
              .pipe(
                map((current) =>
                  fromActions.loadCurrentBrandKitSuccess({ current })
                )
              )
      ),
      catchError((error) => of(error))
    )
  );

  constructor(
    private readonly brandKitService: BrandKitService,
    private readonly brandKitsFacade: BrandKitsFacade,
    private readonly actions$: Actions
  ) {}
}
