import { createAction, props } from '@ngrx/store';
import { ProjectData, Recording } from '../../../../../../../libs/common/src';
import { DialogOptions } from '../interfaces/clip-selection.interfaces';

const actionType = '[SelectClips]';

export const cleanupSelection = createAction(`${actionType} Cleanup Selection`);

export const setDialogOptions = createAction(
  `${actionType} Set Dialog Options`,
  props<{ options: DialogOptions }>()
);

// My Projects
export const loadProjects = createAction(`${actionType} Load Projects`);
export const loadProjectsSuccess = createAction(
  `${actionType} Load Projects Success`,
  props<{
    projects: ProjectData[];
  }>()
);

export const selectProject = createAction(
  `${actionType} Select Project`,
  props<{ selectedProjectId: number }>()
);

export const loadProjectRecordings = createAction(
  `${actionType} Load Project Recordings`,
  props<{ selectedProjectId: number }>()
);
export const loadProjectRecordingsSuccess = createAction(
  `${actionType} Load Project Recordings Success`,
  props<{ recordings: Recording[] }>()
);

export const selectProjectRecording = createAction(
  `${actionType} Select Project Recording`,
  props<{ recording: Recording; selected: boolean }>()
);
