import { Injectable } from '@angular/core';
import {
  AuthService,
  DirectorApiService,
  UploadFileNetworkStatus,
  UploadService,
} from '@openreel/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecordingUploadService {
  get queue$() {
    return this.uploadService.queue$;
  }

  constructor(
    private readonly directorApi: DirectorApiService,
    private readonly uploadService: UploadService,
    private readonly authService: AuthService
  ) {}

  async upload(
    sessionId: number,
    name: string,
    blob: Blob
  ): Promise<UploadFileNetworkStatus[]> {
    const response = await this.directorApi
      .createNewVideo({
        session_id: sessionId,
        videos: [{
          admin_ovra_id: 0,
          bit_rate: '',
          device_name: '',
          fps: null,
          identity: 'web_123',
          resolution: null,
          video_name: name,
          video_type: 1
        }]
      }).toPromise();

    const statusPromises: Promise<UploadFileNetworkStatus>[] = [];

    response.videos.forEach((responseVideo) => {
      const status$ = new BehaviorSubject<UploadFileNetworkStatus>({
        isUploading: false,
        percentage: NaN,
        totalMB: 0,
        uploadedMB: 0,
        isCanceled: false,
        hasFailed: false,
      });
      statusPromises.push(status$.toPromise());

      const videoId = responseVideo.ovra_session_videos_id;
      const metadata = {
        sessionId: sessionId,
        accessToken: this.authService.getUserDetails().auth_token,
        identity: 'web_123',
        localFileName: name,
        resolution: null,
        videoId: videoId,
        fileNameForUpload: `${name}`,
      };

      this.uploadService.saveRecordingMetadata(metadata);
      this.uploadService.queue$.next([
        ...this.uploadService.queue$.value,
        {
          localFileData: blob,
          metadata,
          status$,
        },
      ]);
    });

    return Promise.all(statusPromises);
  }

  async uploadAllStoredRecordings() {
    await this.uploadService.uploadAllStoredRecordings();
  }
}
