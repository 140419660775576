import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Cleanupable } from '../../classes/cleanupable';

/**
 * Service that provides you with audio rms power level every now-and-then.
 * By default service will emit a number through {@link AudioMeterService#volume$}
 * every 50ms (in order to be a bit less power-heavy). This component is
 * supposed to be provider per-component, so don't provide it globally, instead
 * you should only include it when necessary, or even, spawn it directly using
 * new keyword (don't forget to call {@link AudioMeterService#OnDestroy} though).
 *
 * @export
 * @class AudioMeterService
 * @extends {Cleanupable}
 * @implements {OnDestroy}
 */
@Injectable()
export class AudioMeterService extends Cleanupable implements OnDestroy {
  timerSubscription: Subscription;
  worker: Worker;
  audioContext: AudioContext;
  analyser;
  dataArray: Uint8Array;
  bufferLength: number;
  volume$ = new BehaviorSubject<number>(0);
  public participant;

  constructor() {
    super();
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.fftSize = 1024;
    this.bufferLength = this.analyser.fftSize;

    this.timerSubscription = interval(500).subscribe((x) => {
      this.dataArray = new Uint8Array(this.bufferLength);

      this.analyser.getByteTimeDomainData(this.dataArray);

      // Returns value from 0...127
      const max = Math.max(128-Math.min(...this.dataArray), Math.max(...this.dataArray)-128);

      const volume = 100.0*max/128;
      this.volume$.next(volume);

      if (this.participant) {
        this.participant.audioLevel$.next(volume);
      }
    });
  }

  async ngOnDestroy() {
    console.log('AudioMeterService Destroy');
    super.ngOnDestroy();
    try {
    } catch {}
  }
  async hookAudioStream(stream, participant) {
    this.analyser.disconnect();
    if (participant) {
      this.participant = participant;
    }

    if (stream instanceof MediaStream) {
      stream = this.audioContext.createMediaStreamSource(stream);
    }
    if (stream instanceof MediaStreamTrack) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(stream);
      stream = this.audioContext.createMediaStreamSource(mediaStream);
    }
    if (stream) stream.connect(this.analyser);
  }
  public getVolume$() {
    return this.volume$;
  }

  public close(audioStreamProcessor) {
      audioStreamProcessor.close();
  }
}
