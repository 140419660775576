import { UserRoleType } from './../../interfaces/interfaces';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  NewVideoRequest,
  MultiSendSocketCompleteInfo,
  DirectorSelfRecordData
} from '../../interfaces';
import {
  take,
  first,
  filter,
  shareReplay,
  map,
  tap,
} from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import { DirectorSessionService } from './director-session.service';
import { DirectorApiService } from './director-api/director-api.service';
import { SocketDirectorExtensionRecordingService } from './socket-extensions/socket-director-extension-recording.service';
import { StartVideo, VideoStatus } from '../../interfaces/socket-events';
import { SocketDirectorExtensionControlService } from './socket-extensions/socket-director-extension-control.service';
import {
  OpenreelParticipant,
} from '../session/openreel-participant';
import { DirectorRecordingService } from './director-recording.service';
import { SessionSettingsDto } from '../session/session.interfaces';
export enum PossibleSessionState {
  CONNECTING,
  IDLE,
  PRERECORDING,
  RECORDING,
}

export interface SessionStateBase {
  state: PossibleSessionState;
}

export interface SessionStateConnecting extends SessionStateBase {
  state: PossibleSessionState.CONNECTING;
}

export interface SessionStateRecording extends SessionStateBase {
  state: PossibleSessionState.RECORDING;
  recordingIdentities: string[];
  recordingTimers: BehaviorSubject<string>[];
  videoId: number;
}

export interface SessionStatePrerecording extends SessionStateBase {
  state: PossibleSessionState.PRERECORDING;
  recordingIdentities: string[];
  recordingTimers: BehaviorSubject<string>[];
  videoId: number;
}

export interface SessionStateIdle extends SessionStateBase {
  state: PossibleSessionState.IDLE;
}

export type SessionState =
  | SessionStateIdle
  | SessionStateRecording
  | SessionStateConnecting
  | SessionStatePrerecording;

export function isStateConnecting(
  state: SessionState
): state is SessionStateConnecting {
  return state.state === PossibleSessionState.CONNECTING;
}

export function isStateIdle(state: SessionState): state is SessionStateIdle {
  return state.state === PossibleSessionState.IDLE;
}

export function isStateRecording(
  state: SessionState
): state is SessionStateRecording {
  return state.state === PossibleSessionState.RECORDING;
}

export function isStatePrerecording(
  state: SessionState
): state is SessionStatePrerecording {
  return state.state === PossibleSessionState.PRERECORDING;
}

const COLLABORATORS = [UserRoleType.Internal, UserRoleType.Collaborator];

@Injectable()
export class DirectorSessionControlsService extends Cleanupable {
  constructor(
    private api: DirectorApiService,
    private socketRecord: SocketDirectorExtensionRecordingService,
    private socketControl: SocketDirectorExtensionControlService,
    private session: DirectorSessionService,
    private directorRecordingService: DirectorRecordingService,
  ) {
    super();
    this.subscriptions.push(
      this.socketRecord.listenToRecordingStatus().subscribe((status) => {
        // we would like to cover the following three cases:
        // - state is RECORDING & identity exists in recordingIdentities => update recording timer
        // - state is RECORDING & identity does not exist in recordingIdentities (director just joined)
        //    => add this identity to recordingIdentities and update recording timer
        // - state is PRERECORDING => change state to RECORDING, add this identity to recordingIdentities and update recording timer
        if (!isStateRecording(this.currentState$.value)) {
          this.currentState$.next({
            state: PossibleSessionState.RECORDING,
            videoId: 0,
            recordingIdentities: [status.from],
            recordingTimers: [status.from].map(() => new BehaviorSubject('00:00:00')),
          });
        }
        const state = this.currentState$.value as SessionStateRecording;
        if (state.recordingIdentities) {
          let indexOf = state.recordingIdentities.indexOf(status.from);
          if (indexOf === -1) {
            state.recordingIdentities.push(status.from);
            state.recordingTimers.push(new BehaviorSubject(status.data.time));
            indexOf = state.recordingIdentities.length - 1;
          }
          state.recordingTimers[indexOf].next(status.data.time);
        }
      })

    );

    this.subscribeRecording();
  }
  public collaborators = COLLABORATORS;

  currentState$ = new BehaviorSubject<SessionState>({
    state: PossibleSessionState.CONNECTING,
  });
  grid = false;

  public requestedControl = false;
  public isCollaborator$ = this.session.myParticipant$.pipe(
    filter(me => me != null),
    map(me => COLLABORATORS.includes(me.role)),
    shareReplay()
  );

  public isWatcher$ = this.session.myParticipant$.pipe(
    filter(me => me != null),
    map(me => UserRoleType.Watcher === me.role),
    shareReplay()
  );

  public canAccessChat$ = this.isCollaborator$;
  public isInternal$ = this.session.myParticipant$.pipe(
    filter(me => me != null),
    map(me => me.role === UserRoleType.Internal)
  );

  public canAccessPrivateChat$ = this.session.myParticipant$.pipe(
    filter(me => me != null),
    map(me => COLLABORATORS.includes(me.role) && me.isPinned),
    shareReplay()
  );
  public canManipulateClips$ = this.isCollaborator$;
  public canAccessTeleprompter$ = this.isCollaborator$;
  public canManipulateSession$ = this.isCollaborator$;

  public canRecord$ = combineLatest([
    this.isCollaborator$,
    this.currentState$
  ]).pipe(
    tap(console.log),
    map(([isCollab, state]) => isCollab && isStateIdle(state))
  );

  public canStopRecording$ = combineLatest([
    this.isCollaborator$,
    this.currentState$
  ]).pipe(
    map(([isCollab, state]) => isCollab && isStateRecording(state))
  );

  public ifCollaborator(callback: () => void) {
    return this.isCollaborator$.pipe(
      take(1),
      filter(isCollab => isCollab)
    ).subscribe(callback);
  }

  togglePinStatus(identity: string, status: boolean) {
    const id = OpenreelParticipant.getMappingFromIdentity(identity);
    this.socketControl.sendTogglePinStatus(id, status);
  }

  async startRecording(
    recordingIdentities: string[],
    finishedIdentities$: Subject<MultiSendSocketCompleteInfo>,
    sessionSettings: SessionSettingsDto,
    directors: DirectorSelfRecordData[],
    count$: Observable<number>
  ) {
    this.canRecord$.pipe(
      take(1),
      filter(canRecord => canRecord)
    ).subscribe(async () => {
      const videoInfo = await this.initializeRemoteRecordingState(recordingIdentities, directors);
      const videoNameObj: { [index: string]: string } = {};
      const beforeRecordListen: { [index: string]: '1' | '0' } = {};
      const videoIdObj: { [index: string]: number } = {};
      for (const video of videoInfo.videos) {
        videoNameObj[video.identity] = video.video_name;
        beforeRecordListen[video.identity] = '1';
        videoIdObj[video.identity] = video.ovra_session_videos_id;
      }
      const recordStartRequests = recordingIdentities.reduce((obj, identity) => {
        const orParticipant = this.session.getRemoteParticipantByIdentity(identity);
        const ret: StartVideo = {
          EmployeeID: '123456',
          VideoNameObj: videoNameObj,
          VideoSize: orParticipant.videoProperties.resolution.toString(),
          admin_talkback: 0,
          before_record_listen_audio: beforeRecordListen,
          file_size: 0,
          fps: orParticipant.videoProperties.fps,
          resolution: orParticipant.videoProperties.resolution,
          sound: 1,
          start_stop: 0,
          status: VideoStatus.RECORDING,
          timer: sessionSettings.recording_countdown_enabled ? sessionSettings.countdown_value : 0,
          uploadDuringRecording: sessionSettings.upload_during_recording,
          videoIdObj,
        };
        obj[identity] = ret;
        return obj;
      }, {} as { [identity: string]: StartVideo });
      this.currentState$.next({
        state: PossibleSessionState.PRERECORDING,
        videoId: 0,
        recordingIdentities,
        recordingTimers: recordingIdentities.map(() => new BehaviorSubject('00:00:00')),
      });
      try {
        //director start recording
        if (directors.length > 0) {
          const selfRecordData = directors[0];
          selfRecordData.videoId = videoIdObj[selfRecordData.identity];
          count$.toPromise().then(() => {
            this.directorRecordingService.startRecording(selfRecordData);
          });
        }
        await this.socketRecord.startRecording(recordStartRequests, recordingIdentities, finishedIdentities$);
        this.currentState$.next({
          state: PossibleSessionState.RECORDING,
          videoId: 0,
          recordingIdentities,
          recordingTimers: recordingIdentities.map(() => new BehaviorSubject('00:00:00')),
        });
        
      } catch (err) {
        this.currentState$.next({
          state: PossibleSessionState.RECORDING,
          videoId: 0,
          recordingIdentities,
          recordingTimers: recordingIdentities.map(() => new BehaviorSubject('00:00:00')),
        });
      }
    });
  }

  private async initializeRemoteRecordingState(recordingIdentities: string[], directors: DirectorSelfRecordData[]) {
    const request: NewVideoRequest = {
      session_id: this.session.session.session_id,
      videos: recordingIdentities.map((id) => {
        const orParticipant = this.session.getRemoteParticipantByIdentity(id);
        return {
          admin_ovra_id: 0,
          bit_rate: '',
          device_name: orParticipant.deviceName,
          fps: orParticipant.videoProperties.fps.toString(),
          identity: id,
          resolution: orParticipant.videoProperties.resolution.toString(),
          video_name: orParticipant.videoName,
          video_type: 0,
        };
      }),
    };
    if (directors.length > 0) {
      const selfRecord = directors[0];
      request.videos.push({
        admin_ovra_id: 0,
        bit_rate: '',
        device_name: selfRecord.deviceName,
        fps: selfRecord.fps,
        identity: selfRecord.identity,
        resolution: selfRecord.resolution.toString(),
        video_name: selfRecord.videoName,
        video_type: 1,
      });
    }
    return this.api
      .createNewVideo(request)
      .pipe(take(1))
      .toPromise();
  }
  async stopRecording(
    finishedIdentities$: Subject<MultiSendSocketCompleteInfo>
  ) {
    if (isStatePrerecording(this.currentState$.value)) {
      await this.waitForState(PossibleSessionState.RECORDING);
    }
    if (isStateRecording(this.currentState$.value)) {
      this.socketRecord.stopRecording(
        { EmployeeID: '123456', status: VideoStatus.RECORDED, videoid: 0 },
        this.currentState$.value.recordingIdentities,
        finishedIdentities$
      );
      this.currentState$.next({
        state: PossibleSessionState.IDLE,
      });
    }
  }

  // used to clean up recording state for subjects who got disconnected during recording
  // we do so without affecting other subjects' recording, thus state should remain RECORDING
  // except for when there is no subject being recording, in which case state changes to IDLE
  async stopRecordingForIdentities(identities: string[]) {
    if (isStatePrerecording(this.currentState$.value)) {
      await this.waitForState(PossibleSessionState.RECORDING);
    }

    if (isStateRecording(this.currentState$.value)) {
      const sessionStateRecording = this.currentState$.value;
      const remainingIdentities = [];
      const remainingTimers = [];
      for (let i = 0; i < sessionStateRecording.recordingIdentities.length; i++) {
        const identity = sessionStateRecording.recordingIdentities[i];
        const timer = sessionStateRecording.recordingTimers[i];
        const shouldRemove = identities.indexOf(identity) !== - 1;
        if (!shouldRemove) {
          remainingIdentities.push(identity);
          remainingTimers.push(timer);
        }
      }
      sessionStateRecording.recordingIdentities = remainingIdentities;
      sessionStateRecording.recordingTimers = remainingTimers;

      if (remainingIdentities.length === 0) {
        this.currentState$.next({
          state: PossibleSessionState.IDLE,
        });
      }
    }
  }

  connectingFinished() {
    this.currentState$.next({ state: PossibleSessionState.IDLE });
  }
  async waitForState(state: PossibleSessionState) {
    await this.currentState$
      .pipe(
        filter((s) => s.state === state),
        first()
      )
      .toPromise();
  }

  subscribeRecording() {
    this.subscriptions.push(
      this.socketRecord.listenToRecordingStart().subscribe(req => {
        if(req.session.toString() === this.session.session.session_id.toString()) {
          const recordingIdentities = [req.from];
          this.currentState$.next({
            state: PossibleSessionState.PRERECORDING,
            videoId: 0,
            recordingIdentities,
            recordingTimers: recordingIdentities.map(() => new BehaviorSubject('00:00:00')),
          });
        }
      }),
      this.socketRecord.listenToRecordingStop().subscribe(req => {
        if (req.session.toString() === this.session.session.session_id.toString()) {
          this.currentState$.next({
            state: PossibleSessionState.IDLE
          })
        }
      })
    )
  }
}
