import { Cleanupable } from '../../classes';
import { Subject, Observable } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import {
  ReceiveSocketMessageInfo,
  ISocketIoLib,
  ISocketIoLibService,
} from '../../interfaces';

import { OnDestroy } from '@angular/core';

export abstract class BaseSocketService extends Cleanupable
  implements OnDestroy {
  static ackTimeoutTime = 10000;
  socket: ISocketIoLib;
  private anySocketEvent = new Subject<ReceiveSocketMessageInfo<unknown>>();
  public anySocketEvent$ = this.anySocketEvent.asObservable().pipe(
    share()
  );

  constructor(socketProvider: ISocketIoLibService) {
    super();
    this.socket = socketProvider.createSocket();
    this.socket.on('*any*', (eventName, data) => {
      const socketEvt = {
        from: data.identity,
        session: data.SessionID,
        data,
        eventName,
      };
      this.anySocketEvent.next(socketEvt);
    });
  }

  connect(url: string) {
    this.socket.connect(url);
  }

  getSocketEventByName<T>(eventName: string) {
    return this.anySocketEvent$.pipe(
      filter((evt) => {
        const ret = evt.eventName === eventName;
        return ret;
      })
    ) as Observable<ReceiveSocketMessageInfo<T>>;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.socket.disconnect();
  }

  // Common way to send messages on socket. Useful for services which need to
  // send messages in the same way as subject or director (for example, chat)
  abstract basicSocketEmit<SendValueType>(event: string, value: SendValueType): void;
}
