import { ColorAsset, FontAsset } from '../../../../api/workflow.interfaces';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { Alignment } from '../../enums/text.enums';

@Component({
  selector: 'openreel-text-input-toolbar',
  templateUrl: './text-input-toolbar.component.html',
  styleUrls: ['./text-input-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextInputToolbarComponent {
  @Input() colors: ColorAsset[] = [];
  @Input() fonts: FontAsset[] = [];
  @Input() enableAlignment = false;
  @Input() selectedColor: ColorAsset;
  @Input() selectedFont: FontAsset;
  @Input() selectedAlignment = Alignment.Left;

  @Output() selectedColorChange = new EventEmitter<ColorAsset>();
  @Output() selectedFontChange = new EventEmitter<FontAsset>();
  @Output() selectedAlignmentChange = new EventEmitter<Alignment>();

  AlignmentEnum = Alignment;

  selectFontColor(color: ColorAsset) {
    if (this.colors.indexOf(color) === -1) {
      return;
    }

    this.selectedColor = color;
    this.selectedColorChange.emit(this.selectedColor);
  }

  selectAlignment(alignment: Alignment) {
    this.selectedAlignment = alignment;
    this.selectedAlignmentChange.emit(this.selectedAlignment);
  }

  selectFont(font: FontAsset) {
    if (this.fonts.indexOf(font) === -1) {
      return;
    }

    this.selectedFont = font;
    this.selectedFontChange.emit(this.selectedFont);
  }
}
