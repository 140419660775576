import { Component, OnInit } from '@angular/core';
import { Cleanupable } from 'libs/common/src/classes';
import { BehaviorSubject } from 'rxjs';
import { NotificationsService } from '../notifications.service';
import { NotificationsGroup } from '../dtos/notifications-group.dto';

@Component({
  selector: 'openreel-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent extends Cleanupable implements OnInit {
  constructor(
    protected notificationsService: NotificationsService,
    // protected changeDetectorRef: ChangeDetectorRef
  ) {
    super()
  }

  public groups$: BehaviorSubject<NotificationsGroup[]>;
  public groups;
  public loading = false;

  public groupIcons = {
    Sessions: 'camera',
    Teams: 'camera_alt',
    Misc: 'camera'
  };

  ngOnInit(): void {
    this.loading = true;
    this.groups$ = this.notificationsService.groups$;
    this.subscriptions.push(
      this.notificationsService.fetch().subscribe((result) => {
        this.loading = false;
        this.groups = result;
        // this.changeDetectorRef.detectChanges();
      })
    );
  }

  markAllAsRead() {
    // throw new Error('Not implemented');
  }

  markAsRead(id: number) {
    this.notificationsService.markAsRead(id).subscribe();
  }
}
