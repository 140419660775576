import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Cleanupable } from '../../classes';
import { ReceiveSocketMessageInfo, SessionBase } from '../../interfaces';
import { BaseSocketService, SessionBaseService } from '../../services';
import { ChatApiService } from './chat-api.service';
import {
  ChatIdAndName,
  ChatTypingInfo,
  CHAT_MESSAGE_EVT,
  CHAT_TYPING_EVT,
  SingleChatMessage,
  SingleChatMessageSend,
} from './chat.interface';
import { Conversation, ConversationList } from './conversation.interface';

export abstract class ChatBaseService extends Cleanupable {
  allConversations$ = new BehaviorSubject<ConversationList>({});
  selectedConversation$ = new BehaviorSubject<Conversation>(null);
  isLoading$ = new BehaviorSubject<boolean>(false);
  page = 1;
  limit = 50;
  nextPage = false;
  conversationId: number;

  constructor(
    private socket: BaseSocketService,
    private session: SessionBaseService<SessionBase>,
    private api: ChatApiService
  ) {
    super();
    this.subscriptions.push(
      this.socket.anySocketEvent$.subscribe(
        (
          msgInfo: ReceiveSocketMessageInfo<SingleChatMessage | ChatTypingInfo>
        ) => {
          if (msgInfo.eventName === CHAT_MESSAGE_EVT) {
            this.handleChatMessage(msgInfo.data as SingleChatMessage, false);
          } else if (msgInfo.eventName === CHAT_TYPING_EVT) {
            this.handleTyping(msgInfo.data as ChatTypingInfo);
          }
        }
      )
    );
    this.subscriptions.push(
      this.session.myParticipant$.pipe(filter((p) => !!p)).subscribe((p) => {
        this.myId = p.loginId;
      })
    );

    this.getOrCreateConversation(0);
  }

  private typingSubscriptions: {
    [conversationId: number]: { [personId: number]: Subscription };
  } = {};
  typings$ = new BehaviorSubject<{
    [conversationId: number]: BehaviorSubject<ChatIdAndName[]>;
  }>({});

  myId: number;

  private static transformMessage(message: SingleChatMessage) {
    message.sender = parseInt(message.sender.toString(), 10);
    message.receiver = parseInt(message.receiver.toString(), 10);
    message.created_at = new Date(message.created_at);
  }

  private static getConversationIdFromMessage(
    message: { sender: number; receiver: number },
    isMessageFromMe = false
  ) {
    if (isMessageFromMe) {
      return message.receiver;
    }
    if (message.receiver === 0) {
      return 0;
    } else {
      return message.sender;
    }
  }

  private async initConversation(conversationId: number) {
    await this.session.waitForInitialize();
    this.page = 1;
    this.conversationId = conversationId;
    await this.fetchConversation();
  }

  private async fetchConversation() {
    const response = await this.api
      .getConversation(
        this.session.session.session_id,
        this.conversationId,
        this.page,
        this.limit
      )
      .toPromise();
    this.isLoading$.next(false);
    if (response.messages.length > 0) {
      this.nextPage = true;
      const conv = this.getOrCreateConversation(this.conversationId);
      response.messages.forEach((m) => {
        ChatBaseService.transformMessage(m);
        m.is_read = '1';
      });
      const uniqMessages = response.messages.filter(
        (msg) =>
          msg.id != null &&
          !conv.value.find((existingMessage) => existingMessage.id === msg.id)
      );
      conv.next([...uniqMessages.reverse(), ...conv.value]);
    } else {
      this.nextPage = false;
    }
  }
  paginateChat() {
    if (this.nextPage) {
      this.page++;
      this.fetchConversation();
    }
  }

  getOrCreateConversation(conversationId: number) {
    this.isLoading$.next(true);
    if (!(conversationId in this.allConversations$.value)) {
      const conversationsClone = {
        ...this.allConversations$.value,
      };
      conversationsClone[conversationId] = new BehaviorSubject<
        SingleChatMessage[]
      >([]);
      this.allConversations$.next(conversationsClone);

      this.initConversation(conversationId);
      const typingsClone = {
        ...this.typings$.value,
      };
      typingsClone[conversationId] = new BehaviorSubject<ChatIdAndName[]>([]);
      this.typings$.next(typingsClone);
    } else {
      this.isLoading$.next(false);
    }
    return this.allConversations$.value[conversationId];
  }

  private handleChatMessage(
    message: SingleChatMessage,
    isMessageFromMe: boolean
  ) {
    if (!message.sender || !message.receiver) {
      return;
    }

    ChatBaseService.transformMessage(message);

    if (
      message.receiver !== 0 &&
      message.receiver !== this.myId &&
      message.sender !== this.myId
    ) {
      return;
    }

    message.is_read = '0';
    const convId = ChatBaseService.getConversationIdFromMessage(
      message,
      isMessageFromMe
    );
    const currentConversation$ = this.getOrCreateConversation(convId);
    currentConversation$.next([...currentConversation$.value, message]);
    this.removeTyping(convId, message.sender);
  }

  private removeTypingSubscription(conversationId: number, personId: number) {
    if (
      this.typingSubscriptions[conversationId] &&
      this.typingSubscriptions[conversationId][personId]
    ) {
      this.typingSubscriptions[conversationId][personId].unsubscribe();
      delete this.typingSubscriptions[conversationId][personId];
    }
  }

  private removeTyping(conversationId: number, personId: number) {
    const conversationTypings = this.typings$.value[conversationId];
    if (conversationTypings) {
      const foundTyping = conversationTypings.value.findIndex(
        (typing) => typing.id === personId
      );
      if (foundTyping !== -1) {
        conversationTypings.next(
          conversationTypings.value.filter((typings) => typings.id !== personId)
        );
      }
    }
    this.removeTypingSubscription(conversationId, personId);
  }

  private handleTypingTimeout(typingInfo: ChatTypingInfo) {
    const convId = ChatBaseService.getConversationIdFromMessage(typingInfo);
    this.removeTyping(convId, typingInfo.sender);
  }

  private handleTyping(typingInfo: ChatTypingInfo) {
    typingInfo.receiver = parseInt(typingInfo.receiver.toString(), 10);
    typingInfo.sender = parseInt(typingInfo.sender.toString(), 10);
    const convId = ChatBaseService.getConversationIdFromMessage(typingInfo);
    // Only do this if we have the conversation in array,
    // this makes it se that we don't get 'typing' in conversation
    // we never opened.
    const conversationTypings = this.typings$.value[convId];
    if (conversationTypings) {
      const typingIndex = conversationTypings.value.findIndex(
        (typing) => typing.id === typingInfo.sender
      );
      if (typingIndex === -1) {
        conversationTypings.next([
          ...conversationTypings.value,
          {
            id: typingInfo.sender,
            name: typingInfo.name,
          },
        ]);
      }

      // Typing timeout. Automatically remove 'typing' status after 2 seconds
      this.removeTypingSubscription(convId, typingInfo.sender);
      if (!this.typingSubscriptions[convId]) {
        this.typingSubscriptions[convId] = {};
      }
      const subscription = timer(2000).subscribe(() => {
        this.handleTypingTimeout(typingInfo);
      });
      this.typingSubscriptions[convId][typingInfo.sender] = subscription;
      this.subscriptions.push(subscription);
    }
  }

  sendMessage(text: string, receiver: number) {
    const message: SingleChatMessageSend = {
      created_at: new Date(),
      sender: this.myId.toString(),
      receiver: receiver.toString(),
      sender_name: this.session.myParticipant$.value.name,
      sender_type: 'remote',
      receiver_type: 'remote',
      senderdetails: {
        fullname: this.session.myParticipant$.value.name,
      },
      text,
      file: '',
      thumb_image: '',
    };
    this.socket.basicSocketEmit(CHAT_MESSAGE_EVT, message);
    this.handleChatMessage(
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(message as any),
        // file_chat: '',
        // mysql_file_id: -1,
        // is_read: '0',
        // read_by_nor: [],
        // read_by_or: [],
        // read_by_remote: [],
        // sender_email: '',
        // session_id,
      },
      true
    );
  }

  sendTyping(receiver: number) {
    const typing: ChatTypingInfo = {
      name: this.session.myParticipant$.value.name,
      receiver,
      sender: this.myId,
    };
    this.socket.basicSocketEmit(CHAT_TYPING_EVT, typing);
  }

  setSelectedConversation(conversation: Conversation) {
    if (
      (!this.selectedConversation$.value && conversation) ||
      (this.selectedConversation$.value && !conversation) ||
      this.selectedConversation$.value.id !== conversation.id
    ) {
      this.selectedConversation$.next(conversation);
    }
  }
}
