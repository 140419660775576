<div
  class="m-top-40 p-bottom-40 flex-col flex-align-center"
  *ngIf="{
    isPlaying: isPlaying$ | async,
    loaded: loaded$ | async
  } as previewer"
>
  <h1 class="m-bottom-30">Video Preview</h1>
  <div class="player-wrapper">
    <div
      class="no-player flex flex-justify-center flex-align-center"
      [hidden]="previewer.loaded"
    >
      <span>
        No Video Clip
      </span>
    </div>
    <div [hidden]="!previewer.loaded">
      <openreel-wf-player
        [video$]="video$"
        [options]="{ controls: true, loop: true, fluid: true }"
        (details)="onVideoDetails()"
        (state)="onPlayerState($event)"
      ></openreel-wf-player>
      <div class="flex flex-justify-center flex-align-end m-top-20">
        <button
          mat-icon-button
          disableRipple
          type="button"
          [title]="previewer.isPlaying ? 'Pause' : 'Play'"
          class="mat-btn-34 m-right-4"
          (click)="previewer.isPlaying ? onPause() : onPlay()"
        >
          <mat-icon
            class="mat-icon-34"
            [svgIcon]="
              previewer.isPlaying ? 'teleprompter-pause' : 'play-circle'
            "
          ></mat-icon>
        </button>
        <button
          mat-icon-button
          disableRipple
          type="button"
          title="Stop"
          class="mat-btn-22"
          (click)="onStop()"
        >
          <mat-icon class="mat-icon-22" svgIcon="stop-record-circle"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
