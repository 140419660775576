import { Injectable } from '@angular/core';
import { ProjectFacade } from './project.facade';
import {
  TemplateType,
  TimelinesLayer,
  TimelineType,
  WorkflowDataDto,
} from '../../../api/workflow.interfaces';
import { TimelineItem } from '../../shared/element.interfaces';
import {
  Timeline,
  TimelineDef,
} from '../../timelines/store/interfaces/timelines.interfaces';
import { getTimelineDuration } from '../../timelines/store/helpers/timelines.helpers';
import { EditorFacade } from './editor.facade';
import { Step, WorkflowSteps } from '../interfaces/steps.interface';
import { isNil } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class TemplateSettingsFacade {
  private templateType: TemplateType;

  private steps: Step[];

  constructor(
    private readonly projectFacade: ProjectFacade,
    private readonly editorFacade: EditorFacade
  ) {
    this.projectFacade.templateType$.subscribe(
      (templateType) => (this.templateType = templateType)
    );

    this.editorFacade.steps$.subscribe((steps) => (this.steps = steps));
  }

  get elements() {
    return {
      captions: true,
      watermark: true,
      narration: false,
    };
  }

  getNextStep(currentStep: WorkflowSteps) {
    const currentIndex = this.steps.findIndex(
      (step) => step.order === currentStep
    );
    if (currentIndex === -1 || currentIndex === this.steps.length - 1) {
      return null;
    }

    return this.steps[currentIndex + 1];
  }

  getTimelines(source: WorkflowDataDto): TimelineDef[] {
    return this.templateType === 'standard'
      ? [
          { type: 'overlays', options: { timelineIndex: 0 } },
          { type: 'b-roll', options: { timelineIndex: 0 } },
          { type: 'main', options: { timelineIndex: 0 } },
        ]
      : [
          { type: 'overlays', options: { timelineIndex: 0 } },
          { type: 'main', options: { timelineIndex: 0 } },
          { type: 'main', options: { timelineIndex: 1 } },
        ];
  }

  getTimelineFromWorkflow(
    source: WorkflowDataDto,
    type: string,
    timelineIndex: number
  ) {
    let timeline = source.sections.main.timelines.find((t) => t.type === type);
    let parentLayer = null;

    if (source.type === 'podcast' && type === 'main') {
      parentLayer = timeline.layers[0] as TimelinesLayer;
      timeline = parentLayer.children[timelineIndex];
    }

    return { timeline, parentLayer };
  }

  isTimelineFixed = (timelineType: TimelineType) =>
    this.templateType === 'standard' && timelineType === 'main';

  canShowAddItemsMenu = (timelineType: TimelineType) =>
    this.templateType === 'standard' || timelineType === 'overlays';

  canSwapSides = (timeline: Timeline) => !isNil(timeline.options.parentLayer);

  canReplaceClip = (timeline: Timeline) => !isNil(timeline.options.parentLayer);

  canDragItem(timeline: Timeline, item: TimelineItem, mainDuration: number) {
    if (this.templateType === 'standard') {
      return true;
    }

    if (timeline.type !== 'main') {
      return true;
    }

    return getTimelineDuration(timeline.data) !== mainDuration;
  }

  canDeleteItem(timeline: Timeline, item: TimelineItem) {
    if (timeline.type !== 'main' || timeline.data.length > 1) {
      return true;
    }

    return this.templateType !== 'podcast';
  }
}
