import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { filter, first, map, take, takeUntil, tap } from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import {
  CancelVideoUpload,
  StartVideo,
  VideoStatus,
} from '../../interfaces/socket-events';
import { SessionConfigService } from '../session/session-config.service';
import { RecordingMetadata } from '../upload/dto/upload.dto';
import { UploadService } from '../upload/upload.service';
import { ILocalRecorderService } from './local-recording/local-recorder-base.service';
import { SocketExtensionRecordingService } from './socket-extensions/socket-extension-recording.service';
import { SubjectSessionService } from './subject-session.service';

export enum RecorderState {
  IDLE = 'Not Recording',
  PRERECORDING = 'Initializing',
  RECORDING = 'Recording',
  STOPPING_RECORDING = 'Stopping',
}

// listens to sockets that give command to start recording. It starts recording
// process and monitors it. In case 'cancel' command has been received through
// socket, it will be handled here as well. Once recording is finished, it
// prepares the recorder blob and sends it to upload service, where it waits in
// queue for uploading.
@Injectable()
export class LocalRecorderHandlerService extends Cleanupable
  implements OnDestroy {
  constructor(
    private recorder: ILocalRecorderService,
    private socketRecording: SocketExtensionRecordingService,
    private session: SubjectSessionService,
    private upload: UploadService,
    private sessionConfig: SessionConfigService
  ) {
    super();
    this.subscriptions.push(
      this.socketRecording.startVideo$.subscribe((req) => {
        this.handleStartRecording(req);
      })
    );
    this.subscriptions.push(
      this.socketRecording.stopVideo$.subscribe(() => {
        this.handleStopRecording();
      })
    );
    this.subscriptions.push(
      this.socketRecording.cancelVideoUpload$.subscribe((req) => {
        this.handleCancelUpload(req);
      })
    );
  }

  currentVideoId: number;
  currentVideoName: string;
  showCountdown: boolean;
  countdownValue: number;
  currentStatus$ = new BehaviorSubject<RecorderState>(RecorderState.IDLE);
  recordCountdown$ = new Subject<number>();
  currentTimer$ = new BehaviorSubject<string>('00:00:00');
  private currentVideoMetadata: RecordingMetadata;

  private async handleCancelUpload(evt: CancelVideoUpload) {
    try {
      await this.upload.cancelVideoUpload(evt.videoid);
      this.socketRecording.sendAckCancelUpload('1', evt.videoid);
    } catch (err) {
      this.socketRecording.sendAckCancelUpload('0', evt.videoid);
      console.warn('Error canceling the upload: ' + err.message);
    }
  }

  protected async handlePreRecording(timeoutSeconds: number) {
    console.log('Handle pre recording');
    delete this.currentVideoMetadata;
    this.currentStatus$.next(RecorderState.PRERECORDING);
    const timerObservable = timer(0, 1000).pipe(
      take(timeoutSeconds + 1),
      map((t) => timeoutSeconds - t),
      tap((t) => {
        console.log('Recording starts in ' + t);
        this.currentTimer$.next(`00:00:0${t}`);
      })
    );
    timerObservable.subscribe(this.recordCountdown$);
    await timerObservable.toPromise();
  }

  protected startTimeSending() {
    this.currentStatus$.next(RecorderState.RECORDING);
    const recordingTimeStart = new Date().getTime();
    timer(0, 1000)
      .pipe(
        takeUntil(
          this.currentStatus$.pipe(
            filter((status) => status !== RecorderState.RECORDING)
          )
        )
      )
      .subscribe(() => {
        const totalSeconds = Math.floor(
          (new Date().getTime() - recordingTimeStart) / 1000
        );
        const seconds = totalSeconds % 60;
        const minutes = Math.floor(totalSeconds / 60) % 60;
        const hours = Math.floor(totalSeconds / 60 / 60);
        const time =
          hours.toString().padStart(2, '0') +
          ':' +
          minutes.toString().padStart(2, '0') +
          ':' +
          seconds.toString().padStart(2, '0');
        this.socketRecording.sendRecordingStatus({
          videoid: this.currentVideoId,
          time,
        });
        this.currentTimer$.next(time);
      });
  }

  async handleStartRecording(req: StartVideo) {
    console.log(
      'Handle start recording. Resolution ' + req.resolution + ' fps ' + req.fps
    );
    try {
      if (this.currentStatus$.value !== RecorderState.IDLE) {
        throw new Error(
          'Recorder state not idle. It is: ' +
            RecorderState[this.currentStatus$.value]
        );
      }
      this.currentVideoId = req.videoIdObj[this.session.session.identity];
      this.currentVideoName = req.VideoNameObj[this.session.session.identity];
      this.showCountdown = req.timer > 0;
      this.countdownValue = req.timer;
      this.socketRecording.sendAckVideoStart({
        VideoName: this.currentVideoName,
        file_size: 0,
        resolution: parseInt(req.resolution, 10),
        stat: '1',
        status: VideoStatus.RECORDING,
        videoid: this.currentVideoId,
      });
      await this.handlePreRecording(
        this.showCountdown ? this.countdownValue : 0
      );
      this.startTimeSending();

      await this.recorder.startRecording(
        parseInt(req.resolution, 10),
        parseInt(req.fps, 10),
        this.sessionConfig.selectedAudioSource$.value,
        this.sessionConfig.selectedVideoSource$.value
      );
      this.currentVideoMetadata = this.getRecordingMetadata();
      this.upload.saveRecordingMetadata(this.currentVideoMetadata);
    } catch (err) {
      this.socketRecording.sendAckVideoStart({
        stat: '0',
      });
      throw err;
    }
  }
  private async startUploading() {
    console.log('Start uploading');
    this.socketRecording.sendAckStartUploading({
      stat: '1',
      status: VideoStatus.UPLOADING,
      videoid: this.currentVideoId,
    });
    try {
      const uploadInfo = await this.upload.uploadVideoRecording(
        this.currentVideoMetadata
      );
      const status = await uploadInfo.status$.toPromise();
      if (!status.isCanceled) {
        await this.socketRecording.sendVideoUploadEnd({
          iscorrupted: '0',
          isdeleted: '0',
          videoid: this.currentVideoId,
        });
      }
    } catch (err) {
      this.socketRecording.sendVideoUploadEnd({
        iscorrupted: '1',
        isdeleted: '1',
        videoid: this.currentVideoId,
      });
      throw err;
    }
  }

  private async handleStopRecording(upload: boolean = true) {
    console.log('Handle stop recording');
    if (this.currentStatus$.value === RecorderState.PRERECORDING) {
      await this.waitForState(RecorderState.RECORDING);
    }
    if (this.currentStatus$.value === RecorderState.RECORDING) {
      this.currentStatus$.next(RecorderState.STOPPING_RECORDING);
      await this.recorder.stopRecording();
      this.socketRecording.sendAckForRecordStop({
        VideoName: this.currentVideoName,
        file_size: await this.recorder.getFileSizeMB(),
        stat: '1',
        status: VideoStatus.RECORDED,
        video_length: await this.recorder.getFileLengthSeconds(),
        videoid: this.currentVideoId,
      });
      this.currentStatus$.next(RecorderState.IDLE);
      this.currentTimer$.next('00:00:00');

      if (upload) {
        await this.startUploading();
      }

      console.log('Recording finished');
    } else {
      console.warn(
        'Cannot stop recording because stopping is already in process'
      );
    }
  }

  async stopRecording(upload: boolean = true) {
    await this.handleStopRecording(upload);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.recorder.stopRecording();
  }
  private getRecordingMetadata() {
    const metadata: RecordingMetadata = {
      sessionId: this.session.session.session_id,
      deviceToken: this.session.session.auth_token,
      identity: this.session.session.identity,
      localFileName: this.recorder.lastFileName,
      resolution: this.recorder.lastFileResolution,
      videoId: this.currentVideoId,
      fileNameForUpload: this.currentVideoName + '.webm',
    };
    return metadata;
  }

  private async waitForState(requestedState: RecorderState) {
    await this.cancelablePromise(
      this.currentStatus$.pipe(
        first(),
        filter((state) => state === requestedState)
      )
    );
  }
}
