import { AppState } from '..';
import { FontAsset } from '../../../api/workflow.interfaces';

export const selectAllBrandKits = (state: AppState) => state.brandKits?.all;

export const selectBrandKitsFonts = (state: AppState): FontAsset[] =>
  state.brandKits?.all
    ?.reduce((acc, val) => acc.concat(val.assets.font), [])
    .filter((f) => !!f);

export const selectAllBrandKitsLoading = (state: AppState) =>
  state.brandKits?.loadingAll === true;

export const selectCurrentBrandKit = (state: AppState) =>
  state.brandKits?.current;
