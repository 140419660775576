import {
  Asset,
  LayerOptions,
  LottieLayer,
  OrAssetsFile,
  Timelines,
  TimelinesLayer,
  VideoLayer,
  WorkflowDataDto,
} from '../../../../api/workflow.interfaces';
import { cloneDeep } from 'lodash-es';
import { TimelineItem } from '../../../shared/element.interfaces';
import { Timeline } from '../interfaces/timelines.interfaces';
import { VideoData, VideoSplit } from '../interfaces/trimmer.interfaces';

export const getIntroOutroDurations = (
  workflow: WorkflowDataDto
): [number, number] => {
  const getSectionDuration = (section: Timelines, assets: Asset[]) => {
    if (!section || !section.enabled) {
      return 0;
    }

    const mainTimeline = section?.timelines.find((t) => t.type === 'main');
    if (!mainTimeline) {
      return 0;
    }

    let duration = 0;
    for (const layer of mainTimeline.layers) {
      const asset = assets.find(
        (a) => a.id === (layer as VideoLayer | LottieLayer).assetId
      );
      duration += asset.data.duration;
    }

    return duration;
  };

  return [
    getSectionDuration(workflow.sections.intro, workflow.assets),
    getSectionDuration(workflow.sections.outro, workflow.assets),
  ];
};

export const getTimelineDuration = (items: TimelineItem[]) =>
  items.reduce((prev, curr) => prev + curr?.duration, 0);

export const getTimelinesDuration = (timelines: Timeline[]) => {
  const mainTimelines = timelines.filter((t) => t.type === 'main');

  return mainTimelines.reduce(
    (prev, curr) => Math.max(prev, getTimelineDuration(curr.data)),
    0
  );
};

export const findPlaceForNewOverlay = (
  items: TimelineItem[],
  totalDuration: number,
  newItemDuration: number
) => {
  const sortedItems = cloneDeep(items);
  sortedItems.sort((a, b) => a.startAt - b.startAt);

  let currentTime = 0;
  for (const item of sortedItems) {
    if (item.startAt - currentTime > newItemDuration) {
      return currentTime + 1;
    }
    currentTime = item.startAt + item.duration;
  }

  if (totalDuration - currentTime > newItemDuration) {
    return currentTime + 1;
  }

  return null;
};

export const findOverlap = (
  items: TimelineItem[],
  itemToCheck: TimelineItem,
  newStartAt: number,
  newEndAt: number
): TimelineItem | false => {
  const sortedItems = [...items].sort((a, b) => a.startAt - b.startAt);
  for (const item of sortedItems) {
    if (item.layerId === itemToCheck.layerId) {
      continue;
    }

    const itemStartAt = item.startAt;
    const itemEndAt = itemStartAt + item.duration;
    if (
      (newStartAt >= itemStartAt && newStartAt <= itemEndAt) ||
      (newEndAt >= itemStartAt && newEndAt <= itemEndAt) ||
      (newStartAt <= itemStartAt && newEndAt >= itemEndAt)
    ) {
      return item;
    }
  }

  return false;
};

export const checkForOverlaps = (
  items: { startAt: number; endAt: number }[],
  timelineDuration: number
) => {
  if (items.length === 0) {
    return false;
  }

  for (let i = 0; i < items.length; i++) {
    if (
      items[i].startAt >= items[i].endAt ||
      items[i].endAt > timelineDuration
    ) {
      return false;
    }

    if (i > 0 && items[i].startAt <= items[i - 1].endAt) {
      return false;
    }
  }

  return true;
};

export const computeRepeatItemStartAt = ({
  endAt,
  layerDuration,
  repeatableEvery,
  index,
}: {
  endAt: number;
  layerDuration: number;
  repeatableEvery: number;
  index: number;
}) => {
  const segmentDuration = repeatableEvery + layerDuration;
  return endAt + index * segmentDuration - layerDuration;
};

export const getSplitsForTrimmer = (
  timelinesLayer: LayerOptions & TimelinesLayer,
  workflow: WorkflowDataDto
) => {
  const videos: VideoData[] = [];
  const splits: VideoSplit[] = [];

  const parentLayer = workflow.sections.main.timelines
    .find((t) => t.type === 'main')
    .layers.find(
      (l) => l.layerId === timelinesLayer.layerId && l.type === 'timelines'
    ) as TimelinesLayer;

  const numberOfSplits = parentLayer.children[0].layers.length;
  const longestTrimToPerSliceArray = new Array(numberOfSplits).fill(0);

  timelinesLayer.children.forEach((timeline) => {
    timeline.layers.forEach((layer: VideoLayer, index) => {
      const asset = workflow.assets.find((a) => a.id === layer.assetId);

      longestTrimToPerSliceArray[index] = Math.max(
        asset.trimTo,
        longestTrimToPerSliceArray[index]
      );
    });
  });

  for (let i = 0; i < numberOfSplits; i++) {
    const split: VideoSplit = {
      videos: [],
    };

    timelinesLayer.children.forEach((timeline) => {
      const layer = timeline.layers[i] as VideoLayer;
      const asset = workflow.assets.find((a) => a.id === layer.assetId);
      const assetFile = asset.file as OrAssetsFile;

      split.videos.push({
        video: {
          assetFileId: assetFile.path,
          assetProviderType: assetFile.provider,
          duration: asset.data.duration,
          name: asset.data.name,
        },
        trimFrom: asset.trimFrom,
        trimTo: longestTrimToPerSliceArray[i],
      });

      if (i === 0) {
        videos.push({
          assetFileId: assetFile.path,
          assetProviderType: assetFile.provider,
          duration: asset.data.duration,
          name: asset.data.name,
        });
      }
    });

    splits.push(split);
  }

  return { videos, splits };
};

export const computeRepeatItemRepeatCount = ({
  startAt,
  layerDuration,
  repeatableEvery,
  timelineDuration,
}: {
  startAt: number;
  layerDuration: number;
  repeatableEvery: number;
  timelineDuration: number;
}) => {
  const endAt = startAt + layerDuration;
  const segmentDuration = repeatableEvery + layerDuration;
  return Math.floor((timelineDuration - endAt) / segmentDuration);
};

export const computeVirtualLayers = (
  items: TimelineItem[],
  timelineDuration: number
) => {
  const virtualTimelineItems: TimelineItem[] = [];
  items.forEach((item) => {
    if (item.layer.visibility?.repeatableEvery > 0) {
      const repeatCount = computeRepeatItemRepeatCount({
        startAt: item.startAt,
        layerDuration: item.duration,
        repeatableEvery: item.layer.visibility.repeatableEvery,
        timelineDuration: timelineDuration,
      });

      for (let index = 1; index <= repeatCount; index++) {
        const virtualTimelineItem = cloneDeep(item);
        const startAt = computeRepeatItemStartAt({
          endAt: item.endAt,
          layerDuration: item.duration,
          repeatableEvery: item.layer.visibility.repeatableEvery,
          index,
        });
        virtualTimelineItem.layer.visibility = {
          ...virtualTimelineItem.layer.visibility,
          repeatableEvery: undefined,
          startAt: startAt,
          endAt: startAt + item.duration,
        };
        virtualTimelineItem.originalInstance = item;
        virtualTimelineItem.layer.layerId = `${item.layer.layerId}__virtual_${index}`;
        virtualTimelineItems.push(virtualTimelineItem);
      }
    }
  });

  return virtualTimelineItems;
};
