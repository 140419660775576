import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AlertComponent,
  AlertDialogData,
  AlertDialogResult,
  AlertType,
} from '../../shared/components/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private readonly dialog: MatDialog) {}

  info(message: string) {
    this.dialog.open<AlertComponent, AlertDialogData>(AlertComponent, {
      backdropClass: 'blurred',
      data: {
        message,
        type: AlertType.Info,
      },
    });
  }

  error(message: string) {
    this.dialog.open<AlertComponent, AlertDialogData>(AlertComponent, {
      backdropClass: 'blurred',
      data: {
        message,
        type: AlertType.Error,
      },
    });
  }

  async confirm(
    message: string,
    options?: { confirmButtonText: string; rejectButtonText: string }
  ) {
    const result = await this.dialog
      .open<AlertComponent, AlertDialogData, AlertDialogResult>(
        AlertComponent,
        {
          backdropClass: 'blurred',
          data: {
            message,
            type: AlertType.Confirmation,
            confirmButtonText: options?.confirmButtonText,
            rejectButtonText: options?.rejectButtonText,
          },
        }
      )
      .afterClosed()
      .toPromise();

    return result?.value;
  }
}
