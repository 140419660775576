<div
  class="relative"
  [class.p-bottom-16]="enableFontSelection && !(isAnyBrandKitSelected$ | async)"
>
  <mat-form-field
    [appearance]="appearance"
    class="w-full"
    [formGroup]="control"
  >
    <mat-label>{{ uiControl.label }}</mat-label>
    <input matInput formControlName="value" />
    <mat-error>
      <div *ngFor="let error of validationErrors()">
        {{ error }}
      </div>
    </mat-error>
  </mat-form-field>
  <openreel-text-input-toolbar
    *ngIf="enableFontSelection && !(isAnyBrandKitSelected$ | async)"
    class="absolute absolute-center-h top-46"
    [colors]="colors$ | async"
    [fonts]="fonts$ | async"
    [selectedColor]="(colors$ | async)[control.value.colorIndex || 0]"
    [selectedFont]="(fonts$ | async)[control.value.fontIndex || 0]"
    (selectedColorChange)="onColorChange($event)"
    (selectedFontChange)="onFontChange($event)"
  >
  </openreel-text-input-toolbar>
</div>
