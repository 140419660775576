import { cloneDeep } from 'lodash-es';
import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';

export class AddVideoOverlaysCommand extends BaseCommand<
  TimelineInterfaces.ClipAddEvent[]
> {
  run(event: TimelineInterfaces.ClipAddEvent[]) {
    const timeline = this.getTimeline('b-roll', true);
    const clipsToAdd = cloneDeep(event);

    const isPip =
      this.source.features?.videoOverlays?.pip?.defaultValue || false;
    const bounds = this.source.features?.videoOverlays?.pip?.bounds;

    clipsToAdd.forEach((clip) => {
      clip.isPip = isPip;
      clip.bounds = isPip ? bounds : null;
    });

    this.addClips(timeline, clipsToAdd);

    return {
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
