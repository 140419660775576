import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum AlertType {
  Info,
  Error,
  Confirmation,
}

export interface AlertDialogData {
  type: AlertType;
  message: string;
  confirmButtonText?: string;
  rejectButtonText?: string;
}

export interface AlertDialogResult {
  value?: boolean;
}

@Component({
  selector: 'openreel-wf-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  AlertTypeEnum = AlertType;

  constructor(
    public dialogRef: MatDialogRef<AlertComponent, AlertDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData
  ) {}

  ok = () => this.dialogRef.close();
  yes = () => this.dialogRef.close({ value: true });
  no = () => this.dialogRef.close({ value: false });
}
