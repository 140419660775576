import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OpenreelCommonDirectivesModule } from '../../../../../libs/common/src/directives/openreel-common-directives.module';
import { OpenreelCommonPipesModule } from '../../../../../libs/common/src/pipes/openreel-common-pipes.module';
import { SharedModule } from '../shared/shared.module';
import { components, entryComponents } from './components';
import { ProjectEffects } from './store/effects/projects.effects';
import { featureKey } from './store/interfaces/clip-selection.interfaces';
import { reducerFn } from './store/reducers/clip-selection.reducer';

@NgModule({
  declarations: [...components],
  imports: [
    SharedModule,
    OpenreelCommonDirectivesModule,
    OpenreelCommonPipesModule,
    StoreModule.forFeature(featureKey, reducerFn),
    EffectsModule.forFeature([ProjectEffects]),
  ],
  entryComponents: [...entryComponents],
})
export class SelectFilesModule {}
