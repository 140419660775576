import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  SessionData,
  DirectorSession,
  SessionListResponse,
  CreateSessionResponse,
  SessionRecordingsResponse,
} from '../../interfaces/interfaces';
import { Member } from '../../interfaces/team.interface';
import { TeleprompterPresetsDto } from '../../interfaces/teleprompter.interface';
import {
  SessionSettingsDto,
  SessionProjectDto,
  SessionArchivedData,
} from './session.interfaces';

interface UpdateSessionRequest {
  session_name?: string;
  internal_members?: Member[];
}
@Injectable({
  providedIn: 'root',
})
export class SessionApiService {
  public urls = {
    // index: commonenv.apiUrl + 'all_sessions',
    index: commonenv.nextGenApiUrl + 'sessions/query',
    get: commonenv.apiUrl + 'session-room-data',
    create: commonenv.nextGenApiUrl + 'sessions/create',
    getArchivedSessions: commonenv.nextGenApiUrl + 'sessions/archived',
    update: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}`,
    delete: (sessionId) => `${commonenv.nextGenApiUrl}sessions/${sessionId}`,
    getSessionRecordings: (sessionId) =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/recordings`,
    getSessionSettings: (sessionId) =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/settings`,
    putSessionSettings: (sessionId) =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/settings`,
    getTeleprompterPresets: (sessionId) =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/teleprompter-presets`,
    putTeleprompterPresets: (sessionId) =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/teleprompter-presets`,
    getSessionProjectData: (sessionId) =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/project`,
    getRestoreSessions: (sessionId) =>
      `${commonenv.nextGenApiUrl}sessions/${sessionId}/restore`,
  };
  constructor(public httpClient: HttpClient) {}

  getSessionData(sessionId): Observable<SessionData<DirectorSession>> {
    const data = {
      session_id: sessionId,
    };

    return this.httpClient.post<SessionData<DirectorSession>>(
      this.urls.get,
      data
    );
  }
  index(
    text: string = null,
    page = 1,
    limit = 10,
    startDate = '',
    endDate = '',
    status = ['', '1', '2', '3', '0', '-1'],
    period = ''
  ) {
    const data = {
      limit,
      page,
      search_string: text,
      start_date: !startDate ? '' : startDate,
      end_date: !endDate ? '' : endDate,
      status,
      period,
    };
    return this.httpClient.post(this.urls.index, data) as Observable<
      SessionListResponse
    >;
  }

  async createSession(
    sessionName: string,
    members: Member[],
    project_id
  ): Promise<number> {
    const response = await this.httpClient
      .post<CreateSessionResponse>(this.urls.create, {
        is_safari_support: 0,
        session_id: '',
        session_name: sessionName,
        project_id: project_id,
        members: members,
      })
      .toPromise();

    return response.data.session_id;
  }

  async updateSession(session_id: number, data: UpdateSessionRequest) {
    return await this.httpClient
      .put(this.urls.update(session_id), data)
      .toPromise();
  }

  deleteSession(session_id: number, isTrash: string) {
    return this.httpClient.delete(this.urls.delete(session_id), {
      params: { is_trash: isTrash },
    });
  }

  getSessionRecordings(
    sessionId: number,
    params: HttpParams
  ): Promise<SessionRecordingsResponse> {
    return this.httpClient
      .get<SessionRecordingsResponse>(
        this.urls.getSessionRecordings(sessionId),
        { params }
      )
      .toPromise();
  }

  async getSessionSettings(sessionId: number): Promise<SessionSettingsDto> {
    return this.httpClient
      .get<SessionSettingsDto>(this.urls.getSessionSettings(sessionId))
      .toPromise();
  }

  async updateSessionSettings(
    sessionId: number,
    payload: SessionSettingsDto
  ): Promise<SessionSettingsDto> {
    return this.httpClient
      .put<SessionSettingsDto>(this.urls.putSessionSettings(sessionId), payload)
      .toPromise();
  }

  async getTeleprompterPresets(
    sessionId: number
  ): Promise<TeleprompterPresetsDto> {
    return this.httpClient
      .get<TeleprompterPresetsDto>(this.urls.getTeleprompterPresets(sessionId))
      .toPromise();
  }

  async updateTeleprompterPresets(
    sessionId: number,
    payload: TeleprompterPresetsDto
  ): Promise<TeleprompterPresetsDto> {
    return this.httpClient
      .put<TeleprompterPresetsDto>(
        this.urls.putTeleprompterPresets(sessionId),
        payload
      )
      .toPromise();
  }

  async getSessionProjectData(sessionId: number) {
    return this.httpClient
      .get<SessionProjectDto>(this.urls.getSessionProjectData(sessionId))
      .toPromise();
  }

  getProjectArchivedSessions(projectId: number) {
    return this.httpClient.get<SessionArchivedData[]>(
      this.urls.getArchivedSessions,
      {
        params: { project_id: projectId.toString() },
      }
    );
  }
  restoreSession(sessionId: number) {
    return this.httpClient.put(this.urls.getRestoreSessions(sessionId), {});
  }
}
