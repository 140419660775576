import { Component, ViewEncapsulation } from '@angular/core';

import { PlayerComponent } from '@openreel/common';

@Component({
  selector: 'openreel-wf-player',
  templateUrl: './workflow-player.component.html',
  styleUrls: ['./workflow-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkflowPlayerComponent extends PlayerComponent {}
