import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import {
  LayerOptions,
  OrAssetsFile,
  VideoLayer,
} from '../../../api/workflow.interfaces';
import { BaseCommand } from './base.command';

export class DuplicateClipCommand extends BaseCommand<TimelineInterfaces.TimelineItemEvent> {
  run(event: TimelineInterfaces.TimelineItemEvent) {
    const { clipIndex, timelinesToUpdate } = this.getItemsForUpdate(event);

    timelinesToUpdate.forEach((timelineToUpdate) => {
      const clipToDuplicate = timelineToUpdate.layers[
        clipIndex
      ] as LayerOptions & VideoLayer;
      const clipToDuplicateAsset = this.getAsset(clipToDuplicate.assetId);
      const clipToDuplicateAssetFile = clipToDuplicateAsset.file as OrAssetsFile;

      const newClip: TimelineInterfaces.ClipAddEvent = {
        assetFileId: clipToDuplicateAssetFile.path,
        assetProviderType: clipToDuplicateAssetFile.provider,
        duration: clipToDuplicateAsset.data.duration,
        name: clipToDuplicateAsset.data.name,
        trimFrom: clipToDuplicateAsset.trimFrom,
        trimTo: clipToDuplicateAsset.trimTo,
      };
      this.insertClips(timelineToUpdate, [newClip], clipIndex + 1);
      this.recalculateVisibility(timelineToUpdate);
    });

    return {
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
