<ng-container *ngIf="queue$">
  <div
    *ngIf="queue$ | async as uploads"
    class="upload-indicator-holder"
  >
    <div
      class="mat-app-background dark-theme single-upload-indicator"
      *ngFor="let upload of uploads"
    >
      <ng-container *ngIf="upload.status$ | async as status">
        <div>
          <span class="indicator-text">
            <mat-icon>
              {{ status.isUploading ? 'cloud_upload' : (status.hasFailed ? 'error' : 'pause') }}
            </mat-icon>
            <ng-container *ngIf="upload.metadata.retryNumber && !status.hasFailed">
              (retry: {{upload.metadata.retryNumber }})
            </ng-container>
            {{ upload.metadata.fileNameForUpload }}
          </span>
        </div>
        <mat-progress-bar
          *ngIf="!status.hasFailed"
          color="primary"
          [mode]="isNaN(status.percentage) ? 'indeterminate' : 'determinate'"
          [value]="status.percentage * 100">
        </mat-progress-bar>
      </ng-container>
    </div>
  </div>
</ng-container>
