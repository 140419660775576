import * as fromActions from '../actions/brand-kits.actions';
import * as fromSelectors from '../selectors/brand-kits.selectors';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class BrandKitsFacade {
  all$ = this.store.select(fromSelectors.selectAllBrandKits);
  fonts$ = this.store.select(fromSelectors.selectBrandKitsFonts);
  loadingAll$ = this.store.select(fromSelectors.selectAllBrandKitsLoading);

  current$ = this.store.select(fromSelectors.selectCurrentBrandKit);

  constructor(private store: Store) {}

  listBrandKits() {
    this.store.dispatch(fromActions.listBrandKitsAPI());
  }
}
