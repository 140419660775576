import { Injectable } from '@angular/core';
import { DirectorSocketService } from '../../socket/director-socket.service';
import {
  EVT_D2S_SET_RESOLUTION,
  SetResolutionRequest,
  EVT_D2S_SET_FPS,
  SetFramerateRequest,
  EVT_S2D_SET_FRAMERATE_ACK,
  EVT_S2D_SET_RESOLUTION_ACK,
  EVT_D2S_FLIP_CAMERA,
  EVT_S2D_FLIP_CAMERA_ACK,
  EVT_D2S_SET_EXPOSURE,
  SetExposureRequest,
  EVT_S2D_SET_EXPOSURE_ACK,
  SetIsoRequest,
  EVT_D2S_SET_ISO,
  EVT_S2D_SET_ISO_ACK,
  SetContrastRequest,
  EVT_D2S_SET_CONTRAST,
  EVT_D2S_FOCUS_POINT,
  ExposureFocusPointRequest,
  MicSettingsReqest,
  EVT_S2D_SET_CONTRAST_ACK,
  EVT_S2D_FOCUS_POINT_ACK,
  SetColorTemperatureRequest,
  EVT_D2S_SET_COLOR_TEMPERATURE,
  EVT_S2D_SET_COLOR_TEMPERATURE_ACK,
  SetAutoExposureRequest,
  EVT_D2S_SET_AUTO_EXPOSURE,
  CameraPreset,
  EVT_S2D_DEVICE_JOIN,
  EVT_D2S_DEVICE_JOIN,
  EVT_D2S_SET_ALL_CAMERA_SETTINGS,
  EVT_S2D_APP_BACKGROUND,
  EVT_D2S_APP_BACKGROUND,
  DeviceJoinRequest,
  UpdateDeviceInfoRequest,
  DeviceInfoData,
  SetAllCameraSettingsRequest,
  EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
  SetAllCameraSettingsResponse,
  AppInputAudio,
  EVT_D2S_INPUT_AUDIO,
  EVT_S2D_INPUT_AUDIO_ACK,
  EVT_S2D_SET_AUTO_EXPOSURE_IOS_ACK,
  AudioIdentity,
  EVT_D2S_AUDIO_IDENTITY,
  EVT_S2D_AUDIO_IDENTITY_ACK,
  DeviceHardwarePerformanceAck,
  EVT_D2S_DEVICE_HARDWARE_PERFORMANCE,
  EVT_S2D_DEVICE_HARDWARE_PERFORMANCE_ACK,
  DeviceNetworkSpeedAck,
  EVT_D2S_DEVICE_NETWORK_SPEED,
  EVT_S2D_DEVICE_NETWORK_SPEED_ACK,
  EVT_D2S_EXPOSURE_POINT,
  EVT_S2D_EXPOSURE_POINT_ACK,
  EVT_D2S_MIC_SETTINGS,
  EVT_S2D_MIC_SETTINGS_ACK,
  EVT_D2S_SHOW_GRID,
  EVT_S2D_SHOW_GRID_ACK,
  SetShowGridRequest,
  ResolutionSetAck,
  FrameRateSetAck,
  AckResponse,
  UserRoleType,
  EVT_D2S_VIDEO_IDENTITY,
  MyIdentitySendType,
  EVT_D2D_DIRECTOR_MUTE,
} from '../../../interfaces';
import {
  findFps,
  findResolution,
  heightFromString,
  VideoConstraints,
} from '../../../media';
import { DirectorApiService } from '../director-api/director-api.service';
import { Cleanupable } from '../../../classes/cleanupable';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { DirectorSessionService } from '../director-session.service';
import { RemoteOpenreelParticipant } from '../../session/openreel-participant';
import { map, take } from 'rxjs/operators';

@Injectable()
export class SocketDirectorExtensionDeviceService extends Cleanupable {
  private settingChanging = new BehaviorSubject<
    keyof VideoConstraints | 'resolution'
  >(null);
  public settingChanging$ = this.settingChanging.asObservable();
  public muteChange$: Observable<{ participantId: number }>;

  constructor(
    private socket: DirectorSocketService,
    private directorApiService: DirectorApiService,
    private directorSession: DirectorSessionService
  ) {
    super();
    this.startListeningToDevices();

    this.muteChange$ = socket
      .getSocketEventByName(EVT_D2D_DIRECTOR_MUTE)
      .pipe(map((s) => s.data as { participantId: number }));
  }

  private async startListeningToDevices() {
    this.subscriptions.push(
      this.socket
        .getSocketEventByName<DeviceJoinRequest>(EVT_S2D_DEVICE_JOIN)
        .subscribe((evt) => {
          this.handleDeviceJoined(evt.data, evt.from);
        })
    );

    this.subscriptions.push(
      this.socket
        .getSocketEventByName(EVT_S2D_APP_BACKGROUND)
        .subscribe((evt) => {
          this.socket.emitSocketTo(EVT_D2S_APP_BACKGROUND, evt.data, evt.from);
        })
    );

    await this.directorSession.waitForInitialize();
    this.subscriptions.push(
      this.directorSession.newParticipant$.subscribe((p) =>
        this.handleNewParticipant(p)
      )
    );
  }

  private async handleNewParticipant(
    newParticipant: RemoteOpenreelParticipant
  ) {
    this.refreshIosVideoIdentity();
  }

  async changeResolutionTo(
    identity: string,
    width: string,
    height: string
  ): Promise<ResolutionSetAck> {
    const data: SetResolutionRequest = {
      value: height,
      width,
      is_portrait_mode_on: false,
    };
    this.socket.emitSocketToArr(EVT_D2S_SET_RESOLUTION, data, [identity]);
    this.settingChanging.next('resolution');
    const result = await this.socket.waitForAckFrom<ResolutionSetAck>(
      identity,
      EVT_S2D_SET_RESOLUTION_ACK
    );
    this.settingChanging.next(null);
    return result;
  }

  async changeFpsTo(
    identity: string,
    newFps: string
  ): Promise<FrameRateSetAck> {
    const data: SetFramerateRequest = {
      frame_rate: newFps,
    };
    this.socket.emitSocketToArr(EVT_D2S_SET_FPS, data, [identity]);
    this.settingChanging.next('fps');
    const response = await this.socket.waitForAckFrom<FrameRateSetAck>(
      identity,
      EVT_S2D_SET_FRAMERATE_ACK
    );
    this.settingChanging.next(null);
    return response;
  }

  async flipCamera(identity: string) {
    this.socket.emitSocketToArr(EVT_D2S_FLIP_CAMERA, {}, [identity]);
  }

  listenToFlipCamera() {
    return this.socket.getSocketEventByName<AckResponse>(
      EVT_S2D_FLIP_CAMERA_ACK
    );
  }

  async setShowGridLines(identity: string, showGridLines: boolean) {
    this.socket.emitSocketToArr<SetShowGridRequest>(
      EVT_D2S_SHOW_GRID,
      { showGridLines },
      [identity]
    );
    await this.socket.waitForAckFrom(identity, EVT_S2D_SHOW_GRID_ACK);
  }

  async changeCameraSetting(
    setting: keyof VideoConstraints,
    newValue: number,
    identity: string
  ) {
    this.settingChanging.next(setting);
    if (setting === 'exposure') {
      await this.changeExposureSetting(newValue, identity);
    } else if (setting === 'iso') {
      await this.changeIsoSetting(newValue, identity);
    } else if (setting === 'colorTemperature') {
      await this.changeColorTemperatureSetting(newValue, identity);
    } else if (setting === 'contrast') {
      await this.changeContrastSetting(newValue, identity);
    } else if (setting === 'fps') {
      await this.changeFpsTo(identity, newValue.toString());
    } else if (setting === 'height') {
      const res = findResolution(newValue);
      await this.changeResolutionTo(
        identity,
        res.width.toString(),
        res.height.toString()
      );
    }
    this.settingChanging.next(null);
  }

  async setAutoExposure(isAuto: boolean, identity: string) {
    this.socket.emitSocketToArr<SetAutoExposureRequest>(
      EVT_D2S_SET_AUTO_EXPOSURE,
      {
        value: isAuto ? '1' : '0',
      },
      [identity]
    );
    this.settingChanging.next('exposure');
    await this.socket.waitForAckFrom(
      identity,
      EVT_S2D_SET_AUTO_EXPOSURE_IOS_ACK
    );
    this.settingChanging.next(null);
  }

  async changeExposureSetting(newValue: number, identity: string) {
    this.socket.emitSocketToArr<SetExposureRequest>(
      EVT_D2S_SET_EXPOSURE,
      {
        value: newValue.toString(),
      },
      [identity]
    );
    await this.socket.waitForAckFrom(identity, EVT_S2D_SET_EXPOSURE_ACK);
  }

  async changeIsoSetting(newValue: number, identity: string) {
    this.socket.emitSocketToArr<SetIsoRequest>(
      EVT_D2S_SET_ISO,
      {
        value: newValue.toString(),
      },
      [identity]
    );
    await this.socket.waitForAckFrom(identity, EVT_S2D_SET_ISO_ACK);
  }

  async changeContrastSetting(newValue: number, identity: string) {
    this.socket.emitSocketToArr<SetContrastRequest>(
      EVT_D2S_SET_CONTRAST,
      {
        value: newValue.toString(),
      },
      [identity]
    );
    await this.socket.waitForAckFrom(identity, EVT_S2D_SET_CONTRAST_ACK);
  }

  async changeColorTemperatureSetting(newValue: number, identity: string) {
    this.socket.emitSocketToArr<SetColorTemperatureRequest>(
      EVT_D2S_SET_COLOR_TEMPERATURE,
      {
        value: newValue.toString(),
      },
      [identity]
    );
    await this.socket.waitForAckFrom(
      identity,
      EVT_S2D_SET_COLOR_TEMPERATURE_ACK
    );
  }

  private async handleDeviceJoined(evt: DeviceJoinRequest, identity: string) {
    console.log('Device joined');
    const sessionId = this.socket.directorSession$.value.session;
    const myIdentity = this.socket.directorSession$.value.identity;
    const request: UpdateDeviceInfoRequest = {
      session_id: +sessionId,
      devices: [
        {
          identity: identity,
          camera_type: '0',
          frame_rates: '30',
        },
      ],
      identity: identity,
      login_id: evt.login_id,
      device_accept_by: myIdentity,
      device_support: JSON.stringify(evt),
    };

    const updateResponse = await this.directorApiService
      .updateConnectedDevice(request)
      .toPromise();

    this.socket.emitSocketToArr(
      EVT_D2S_DEVICE_JOIN,
      {
        stat: '1',
        device_log_child_id: updateResponse.device_log_child.id,
      },
      [identity]
    );

    const deviceInfo = await this.directorApiService
      .getAllDeviceDetails({
        session_id: +sessionId,
        identity: identity,
      })
      .toPromise();

    await timer(5000).toPromise();

    const deviceCameraData = deviceInfo.devicedata[0];
    await this.setAllCameraParamsFromDeviceInfo(deviceCameraData, identity);
  }

  async setAllCameraParamsFromDeviceInfo(
    deviceInfo: DeviceInfoData,
    identity: string
  ) {
    const res = findResolution(heightFromString(deviceInfo.resolution));
    const fps = findFps(parseInt(deviceInfo.selected_fps, 10));
    const request: SetAllCameraSettingsRequest = {
      audio_sample_rate: '44.1',
      autoExposure: !deviceInfo.is_autoexposure ? deviceInfo.exposure : '',
      autoFocus: '',
      contrast: !deviceInfo.is_autocontrast ? deviceInfo.contrast : '',
      crop_factor: 'Ratio169',
      filter_camera_data: {
        saturation: '-1',
        vibrance: '-1',
        shadow: '-1',
        highlight: '-1',
        color_overlay: {
          color: '-1',
          value: '-1',
        },
        gamma: -1,
      },
      flashlight: 0,
      focus: -1,
      focus_peaking: 0,
      fps: fps.toString(),
      iso: !deviceInfo.is_autoiso ? deviceInfo.iso : '',
      mbit: '12',
      mobile_feed: 0,
      mobile_mic_options: 0,
      potrait: '',
      resolution: res.height.toString(),
      width: res.width.toString(),
      stabilize: '0',
      tint: '-1',
      white_balance: !deviceInfo.is_autotemperature
        ? parseFloat(deviceInfo.temperature)
        : -1,
      zoom: 0,
    };
    this.socket.emitSocketToArr(EVT_D2S_SET_ALL_CAMERA_SETTINGS, request, [
      identity,
    ]);
    await this.socket.waitForAckFrom(
      identity,
      EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
      15000
    );
  }

  async setAllCameraParamsFromPreset(preset: CameraPreset, identity: string) {
    const res = findResolution(heightFromString(preset.resolution));
    const fps = findFps(parseInt(preset.cSp_fps_value, 10));
    const request: SetAllCameraSettingsRequest = {
      audio_sample_rate: '44.1',
      autoExposure: !preset.is_autoexposure ? preset.cSp_exposure_value : '',
      autoFocus: '',
      crop_factor: 'Ratio169',
      filter_camera_data: {
        saturation: '-1',
        vibrance: '-1',
        shadow: '-1',
        highlight: '-1',
        color_overlay: {
          color: '-1',
          value: '-1',
        },
        gamma: -1,
      },
      flashlight: 0,
      focus: -1,
      focus_peaking: 0,
      fps: fps.toString(),
      iso: !preset.is_autoiso ? preset.cSp_iso_value : '',
      mbit: '12',
      mobile_feed: 0,
      mobile_mic_options: 0,
      potrait: '',
      resolution: res.height.toString(),
      width: res.width.toString(),
      stabilize: '0',
      tint: '-1',
      white_balance: !preset.is_autotemperature
        ? parseFloat(preset.cSp_whiteBalance_value)
        : -1,
      zoom: 0,
      contrast: !preset.is_autocontrast ? preset.cSp_contrast_value : '',
    };
    this.socket.emitSocketToArr(EVT_D2S_SET_ALL_CAMERA_SETTINGS, request, [
      identity,
    ]);
    return await this.socket.waitForAckFrom<SetAllCameraSettingsResponse>(
      identity,
      EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
      15000
    );
  }

  async setInputAudioTo(enabled: boolean, identity: string) {
    const data: AppInputAudio = {
      data: enabled ? 1 : 0,
    };
    this.socket.emitSocketToArr(EVT_D2S_INPUT_AUDIO, data, [identity]);
    try {
      await this.socket.waitForAckFrom(identity, EVT_S2D_INPUT_AUDIO_ACK);
    } catch (err) {
      console.warn('Error muting microphone', err);
    }
  }

  sendInputAudioAck(stat: number) {
    this.socket.emitSocket(
      EVT_S2D_INPUT_AUDIO_ACK,
      { stat: stat.toString() },
      MyIdentitySendType.NO_IDENTITY
    );
  }

  refreshAudioOutputOf(allParticipants: RemoteOpenreelParticipant[]) {
    for (const participant of allParticipants) {
      this.setAudioOutputTo(
        participant.identity,
        allParticipants,
        participant.audioProperties.isAudioOutputEnabled$.value
      );
    }
  }

  refreshIosVideoIdentity() {
    const allParticipants = this.directorSession.getOnlineParticipants();
    const iosDevices: string[] = [];
    const directorIdentities: string[] = [];
    allParticipants.forEach((participant) => {
      if (participant.isIosDevice) {
        iosDevices.push(participant.identity);
      } else if (
        participant.isPinned &&
        participant.role !== UserRoleType.Subject
      ) {
        directorIdentities.push(participant.identity);
      }
    });
    this.socket.emitSocketToArr(
      EVT_D2S_VIDEO_IDENTITY,
      {
        videoIdentityArr: directorIdentities,
      },
      iosDevices
    );
  }

  setAudioOutputTo(
    identity: string,
    allParticipants: RemoteOpenreelParticipant[],
    isEnabled: boolean
  ) {
    let audioIdentityArr: string[] = [];
    audioIdentityArr = allParticipants
      .filter((p) => p.isPinned)
      .map((p) => p.identity);
    const selfParticipant = this.directorSession.getParticipantByIdentity(
      this.directorSession.session.identity
    );
    //check self pinned or not
    if (selfParticipant && selfParticipant.isPinned)
      audioIdentityArr.push(selfParticipant.identity);
    const data: AudioIdentity = {
      admin_feed: 1,
      audioIdentityArr: isEnabled ? audioIdentityArr : [],
      update_before_record_listen_audio: 1,
    };
    this.socket.emitSocketToArr(EVT_D2S_AUDIO_IDENTITY, data, [identity]);
  }

  async toggleAudioOutputOf(
    allParticipants: RemoteOpenreelParticipant[],
    controlParticipant: RemoteOpenreelParticipant
  ) {
    const enabled = await controlParticipant.audioProperties.isAudioOutputEnabled$
      .pipe(take(1))
      .toPromise();
    try {
      this.setAudioOutputTo(
        controlParticipant.identity,
        allParticipants,
        !enabled
      );
      await this.socket.waitForAckFrom(
        controlParticipant.identity,
        EVT_S2D_AUDIO_IDENTITY_ACK
      );
    } catch (err) {
      console.warn('Error toggling output', err);
    }
  }

  async getDeviceHardwarePerformance(
    identity: string
  ): Promise<DeviceHardwarePerformanceAck> {
    this.socket.emitSocketTo(EVT_D2S_DEVICE_HARDWARE_PERFORMANCE, {}, identity);

    try {
      return await this.socket.waitForAckFrom<DeviceHardwarePerformanceAck>(
        identity,
        EVT_S2D_DEVICE_HARDWARE_PERFORMANCE_ACK
      );
    } catch (err) {
      console.warn('Error getting device hardware performance', err);
    }
  }

  async getDeviceNetworkSpeed(
    identity: string
  ): Promise<DeviceNetworkSpeedAck> {
    this.socket.emitSocketTo(EVT_D2S_DEVICE_NETWORK_SPEED, {}, identity);

    try {
      return await this.socket.waitForAckFrom<DeviceNetworkSpeedAck>(
        identity,
        EVT_S2D_DEVICE_NETWORK_SPEED_ACK,
        20000
      );
    } catch (err) {
      console.warn('Error getting device network speed', err);
    }
  }

  async tapFocusPoint(data: ExposureFocusPointRequest, identity: string) {
    this.socket.emitSocketToArr<ExposureFocusPointRequest>(
      EVT_D2S_FOCUS_POINT,
      data,
      [identity]
    );
    await this.socket.waitForAckFrom(identity, EVT_S2D_FOCUS_POINT_ACK);
  }

  async changeMicSettings(data: MicSettingsReqest, identity: string) {
    this.socket.emitSocketToArr<MicSettingsReqest>(EVT_D2S_MIC_SETTINGS, data, [
      identity,
    ]);
    await this.socket.waitForAckFrom(identity, EVT_S2D_MIC_SETTINGS_ACK);
  }

  async muteDirector(participantId: number) {
    this.socket.emitSocket(
      EVT_D2D_DIRECTOR_MUTE,
      { participantId },
      MyIdentitySendType.NO_IDENTITY
    );
  }

  async tapExposurePoint(data: ExposureFocusPointRequest, identity: string) {
    this.socket.emitSocketToArr<ExposureFocusPointRequest>(
      EVT_D2S_EXPOSURE_POINT,
      data,
      [identity]
    );
    await this.socket.waitForAckFrom(identity, EVT_S2D_EXPOSURE_POINT_ACK);
  }

  async resetCameraFilter(resolution: string, fps: string, identity: string) {
    const res = findResolution(heightFromString(resolution));
    const newFps = findFps(parseInt(fps, 10));
    const request: SetAllCameraSettingsRequest = {
      audio_sample_rate: '44.1',
      autoExposure: '',
      autoFocus: '',
      crop_factor: 'Ratio169',
      filter_camera_data: {
        saturation: '-1',
        vibrance: '-1',
        shadow: '-1',
        highlight: '-1',
        color_overlay: {
          color: '-1',
          value: '-1',
        },
        gamma: -1,
      },
      flashlight: 0,
      focus: -1,
      focus_peaking: 0,
      fps: newFps.toString(),
      iso: '',
      mbit: '12',
      mobile_feed: 0,
      mobile_mic_options: 0,
      potrait: '',
      resolution: res.height.toString(),
      width: res.width.toString(),
      stabilize: '0',
      tint: '-1',
      white_balance: -1,
      zoom: 0,
      contrast: '',
    };
    this.socket.emitSocketToArr(EVT_D2S_SET_ALL_CAMERA_SETTINGS, request, [
      identity,
    ]);
    return await this.socket.waitForAckFrom<SetAllCameraSettingsResponse>(
      identity,
      EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
      15000
    );
  }
}
