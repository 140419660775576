import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TimelineItem } from '../../shared/element.interfaces';
import { Timeline } from '../../timelines/store/interfaces/timelines.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ItemSelectService {
  private itemSelected = new Subject<{
    timeline: Timeline;
    item: TimelineItem;
  }>();
  itemSelected$ = this.itemSelected.asObservable();

  clearItem() {
    this.itemSelected.next(null);
  }

  selectItem(timeline: Timeline, item: TimelineItem) {
    this.itemSelected.next({ timeline, item });
  }
}
