import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CueControlsComponent } from './cue-controls/cue-controls.component';
import { CueLoaderComponent } from './cue-loader/cue-loader.component';
import { CuePlayerComponent } from './cue-player/cue-player.component';
import { CuePlayerControllerComponent } from './cue-player-timelines/cue-player-controller/cue-player-controller.component';
import { CuePlayerImageComponent } from './cue-player-image/cue-player-image.component';
import { CuePlayerLottieComponent } from './cue-player-lottie/cue-player-lottie.component';
import { CuePlayerMainComponent } from './cue-player-timelines/cue-player-controller-main/cue-player-controller-main.component';
import { CuePlayerTimelinesComponent } from './cue-player-timelines/cue-player-timelines.component';
import { CuePlayerVideoComponent } from './cue-player-video/cue-player-video.component';
import { LoaderModule } from '../shared/modules/loader/loader.module';

@NgModule({
  imports: [CommonModule, LoaderModule],
  declarations: [
    CuePlayerComponent,
    CuePlayerMainComponent,
    CuePlayerVideoComponent,
    CuePlayerLottieComponent,
    CuePlayerImageComponent,
    CuePlayerTimelinesComponent,
    CuePlayerControllerComponent,
    CueControlsComponent,
    CueLoaderComponent,
  ],
  exports: [CuePlayerComponent, CuePlayerLottieComponent, CuePlayerControllerComponent],
})
export class CuePlayerModule {}
