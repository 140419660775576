import { HttpClient, HttpUploadProgressEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { S3 } from 'aws-sdk';
import * as AWSx from 'aws-sdk/global';
import { BehaviorSubject, Subscription } from 'rxjs';
import { commonenv } from '../../environments/environment';
import { DeleteVideoResponse } from '../../interfaces/interfaces';
import { HelperService } from '../helper/helper.service';
import { ILocalRecorderService } from '../subject/local-recording/local-recorder-base.service';
import {
  RecordingMetadata,
  UploadCredentials,
  UploadDetailsResponse,
  UploadFileInfo,
  UploadFileNetworkStatus,
  UploadMultipartFileDetails,
} from './dto/upload.dto';
import * as Evaporate from 'evaporate';

const REQUEST_UPLOAD_DETAILS = 'upload-details';
const URL_TRANSCODE_REQUEST = 'transcode';
const MAX_RETRIES = 5;

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private http: HttpClient,
    private recorder: ILocalRecorderService,
    private helper: HelperService
  ) {
    this.queue$.subscribe((newQueue) => {
      setTimeout(() => {
        this.handleQueueChange(newQueue);
      }, 5000);
    });
  }

  queue$ = new BehaviorSubject<UploadFileInfo[]>([]);
  uploadSubscriptions: { [videoId: number]: Subscription } = {};

  public static recordingMetadataEqual(
    a: RecordingMetadata,
    b: RecordingMetadata
  ) {
    return (
      (a.deviceToken === b.deviceToken ||
        a.accessToken === b.accessToken ||
        a.emailToken === b.emailToken) &&
      a.identity === b.identity &&
      a.localFileName === b.localFileName &&
      a.resolution === b.resolution &&
      a.sessionId === b.sessionId &&
      a.videoId === b.videoId
    );
  }

  private handleQueueChange(newQueue: UploadFileInfo[]) {
    const filtered = newQueue.filter((q) => this.doesMetadataExist(q.metadata));
    if (filtered.length !== newQueue.length) {
      console.warn(
        'Trimmed some upload videos in upload queue ',
        newQueue,
        filtered
      );
      this.queue$.next(filtered);
      return;
    }
    // console.log("Upload queue changed");
    let nextToUpload: UploadFileInfo = null;
    for (let i = 0; i < newQueue.length; i++) {
      if (!newQueue[i].status$.value.hasFailed) {
        nextToUpload = newQueue[i];
        break;
      }
    }

    if (nextToUpload && !nextToUpload.status$.value.isUploading) {
      this.doUploadVideoRecording(nextToUpload);
    }
  }

  cancelAllUploads() {
    while (this.queue$.value.length > 0) {
      this.cancelVideoUpload(this.queue$.value[0].metadata.videoId);
    }
  }

  async uploadVideoRecording(metadata: RecordingMetadata) {
    console.log('Metadata', metadata);
    if (!metadata) {
      throw new Error('Metadata must be provided for upload');
    }

    for (const info of this.queue$.value) {
      if (UploadService.recordingMetadataEqual(info.metadata, metadata)) {
        if (info.status$.value.hasFailed) {
          info.status$.next({
            ...info.status$.value,
            hasFailed: false,
          });
          info.metadata.retryNumber = 0;
          this.queue$.next([
            info,
            ...this.queue$.value.filter(
              (testFileInfo) => testFileInfo !== info
            ),
          ]); // add on top of the queue
        } else {
          console.warn(
            'Uploading already exists for videoId: ' + metadata.videoId
          );
        }
        return info;
      }
    }
    let localFileData: Blob;
    try {
      localFileData = await this.recorder.getFileData(metadata.localFileName);
    } catch (err) {
      throw new Error(`Unable to obtain local file data: ${err.message}`);
    }
    const ret: UploadFileInfo = {
      localFileData,
      metadata,
      status$: new BehaviorSubject<UploadFileNetworkStatus>({
        isUploading: false,
        percentage: NaN,
        totalMB: 0,
        uploadedMB: 0,
        isCanceled: false,
        hasFailed: false,
      }),
    };
    this.queue$.next([...this.queue$.value, ret]);
    return ret;
  }

  private async uploadMultipartEvaporate(
    fileDetails: UploadMultipartFileDetails,
    credentials: UploadCredentials,
    fileInfo: UploadFileInfo
  ) {
    // AWS SDK typing is stupid: https://github.com/aws/aws-sdk-js/issues/1729
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const AWS = AWSx as any;

    const evaporate = await Evaporate.create({
      signerUrl: `${commonenv.nextGenApiUrl}videos/sign`,
      signHeaders: { 'device-token': fileInfo.metadata.deviceToken },
      bucket: fileDetails.bucket,
      awsSignatureVersion: '4',
      computeContentMd5: true,
      logging: true,
      cryptoMd5Method: function (data) {
        return AWS.util.crypto.md5(data, 'base64');
      },
      cryptoHexEncodedHash256: function (data) {
        return AWS.util.crypto.sha256(data, 'hex');
      },
    });

    evaporate
      .add({
        name: fileDetails.filePath,
        file: fileDetails.file,
        progress: fileDetails.progressCallback,
        complete: fileDetails.completeCallback,
        uploadInitiated: fileDetails.uploadInitCallback,
      })
      .then(console.log, console.error);
  }

  private async uploadMultipart(
    fileDetails: UploadMultipartFileDetails,
    credentials: UploadCredentials,
    fileInfo: UploadFileInfo
  ) {
    const useEvaporate = false;

    if (useEvaporate) {
      await this.uploadMultipartEvaporate(fileDetails, credentials, fileInfo);
    } else {
      await this.uploadMultipartAws(fileDetails, credentials, fileInfo);
    }
  }
  private async uploadMultipartAws(
    fileDetails: UploadMultipartFileDetails,
    credentials: UploadCredentials,
    fileInfo: UploadFileInfo
  ) {
    // AWS SDK typing is stupid: https://github.com/aws/aws-sdk-js/issues/1729
    const s3 = new S3({
      accessKeyId: credentials.accessKeyId,
      secretAccessKey: credentials.secretAccessKey,
      sessionToken: credentials.sessionToken,
      region: fileDetails.region,
      logger: console,
    });
    const upload = s3.upload(
      {
        Bucket: fileDetails.bucket,
        Key: fileDetails.filePath,
        Body: fileDetails.file,
      },
      {
        partSize: 5 * 1024 * 1024,
        queueSize: 4,
      }
    );

    upload.on('httpUploadProgress', (progress) => {
      fileDetails.progressCallback(progress.loaded / progress.total);
    });
    upload.send(async (err) => {
      if (err) {
        this.handleUploadError(fileInfo, err);
        await new Promise((resolve) => setTimeout(resolve, 5000));

        return;
      }

      fileDetails.completeCallback();
    });
  }

  private async doUploadVideoRecording(fileInfo: UploadFileInfo) {
    fileInfo.status$.next({
      ...fileInfo.status$.value,
      isUploading: true,
      hasFailed: false,
    });

    const videoId = fileInfo.metadata.videoId;
    const url = `${commonenv.nextGenApiUrl}videos/${videoId}/${REQUEST_UPLOAD_DETAILS}/`;

    let details: UploadDetailsResponse;
    try {
      let headers;
      if (fileInfo.metadata.accessToken)
        headers = { 'access-token': fileInfo.metadata.accessToken };
      else if(fileInfo.metadata.emailToken)
        headers = { 'email-token': fileInfo.metadata.emailToken };

      details = await this.http
        .post<UploadDetailsResponse>(url, {
          name: fileInfo.metadata.fileNameForUpload,
        }, { headers })
        .toPromise();
    } catch (error) {
      this.handleUploadError(fileInfo, error);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      return;
    }

    const file = new File(
      [fileInfo.localFileData],
      fileInfo.metadata.fileNameForUpload
    );

    this.uploadMultipart(
      {
        file: file,
        filePath: details.path,
        bucket: details.bucket,
        region: details.region,

        progressCallback: (progress) => {
          fileInfo.status$.next({
            ...fileInfo.status$.value,
            percentage: progress,
          });
        },
        completeCallback: () => this.handleUploadComplete(fileInfo),
      },
      {
        accessKeyId: details.accessKeyId,
        secretAccessKey: details.secretAccessKey,
        sessionToken: details.sessionToken,
      },
      fileInfo
    );
  }

  private handleUploadError(fileInfo: UploadFileInfo, error: Error) {
    const retryNumber = !fileInfo.metadata.retryNumber
      ? 1
      : fileInfo.metadata.retryNumber + 1;
    fileInfo.metadata.retryNumber = retryNumber;
    this.updateRecordingMetadata(fileInfo.metadata);

    fileInfo.status$.next({
      ...fileInfo.status$.value,
      percentage: NaN,
      hasFailed: retryNumber >= MAX_RETRIES,
      isUploading: false,
    });
    console.error('retryNumber: ' + retryNumber, error);

    this.queue$.next(this.queue$.value);
  }

  private handleUploadProgress(
    fileInfo: UploadFileInfo,
    evt: HttpUploadProgressEvent
  ) {
    fileInfo.status$.next({
      ...fileInfo.status$.value,
      totalMB: evt.total / 1024,
      uploadedMB: evt.loaded / 1024,
      percentage: evt.loaded / evt.total,
    });
  }

  private handleUploadComplete(fileInfo: UploadFileInfo) {
    // TODO: Move the transcoding request somewhere else
    this.initTranscodeRequest(fileInfo.metadata);
    this.removeRecordingMetadata(fileInfo.metadata);
    fileInfo.status$.complete();
    this.queue$.next(
      this.queue$.value.filter((testFileInfo) => fileInfo !== testFileInfo)
    );
  }

  cancelVideoUpload(videoId: number) {
    const subscription = this.uploadSubscriptions[videoId];
    if (!subscription) {
      throw new Error('Video not found');
    }
    if (subscription.closed) {
      throw new Error('Video is not being uploaded');
    }
    const videoUpload = this.queue$.value.find(
      (f) => f.metadata.videoId === videoId
    );
    if (!videoUpload) {
      throw new Error('Video upload not found');
    }
    subscription.unsubscribe();
    videoUpload.status$.next({
      ...videoUpload.status$.value,
      isCanceled: true,
    });
    this.handleUploadComplete(videoUpload);
  }

  async uploadAllStoredRecordings() {
    try {
      const allMetadata = this.getAllRecordingMetadata();
      const promises = allMetadata.map((m) => this.uploadVideoRecording(m));
      await Promise.all(promises);
    } catch (error) {
      console.error(
        'A problem ocurred while uploading all stored recordings',
        error
      );
      Sentry.captureException(error);
    }
  }

  saveRecordingMetadata(metadata: RecordingMetadata) {
    if (this.doesMetadataExist(metadata)) {
      throw new Error('Cannot save metadata because recording already exists');
    }
    const allMetadata = this.getAllRecordingMetadata();
    localStorage.setItem(
      'recording-metadata',
      JSON.stringify([...allMetadata, metadata])
    );
  }

  private updateRecordingMetadata(metadata: RecordingMetadata) {
    const metadataIndex = this.findMetadata(metadata);
    if (metadataIndex === -1)
      throw new Error(
        'Cannot update metadata because the recording does not exist.'
      );

    const allMetadata = this.getAllRecordingMetadata();
    allMetadata[metadataIndex] = metadata;

    localStorage.setItem('recording-metadata', JSON.stringify(allMetadata));
  }

  private findMetadata(metadata: RecordingMetadata) {
    const allMetadata = this.getAllRecordingMetadata();
    const foundMetadataIdx = allMetadata.findIndex((r) =>
      UploadService.recordingMetadataEqual(metadata, r)
    );
    return foundMetadataIdx;
  }

  private doesMetadataExist(metadata: RecordingMetadata) {
    return this.findMetadata(metadata) !== -1;
  }

  public removeRecordingMetadata(metadata: RecordingMetadata) {
    this.recorder.removeFileData(metadata.localFileName);
    if (!this.doesMetadataExist(metadata)) {
      throw new Error('Could not delete metadata because it was not found');
    }
    localStorage.setItem(
      'recording-metadata',
      JSON.stringify(
        this.getAllRecordingMetadata().filter(
          (r) => !UploadService.recordingMetadataEqual(r, metadata)
        )
      )
    );

    this.queue$.next(
      this.queue$.value.filter(
        (testFileInfo) =>
          !UploadService.recordingMetadataEqual(metadata, testFileInfo.metadata)
      )
    );
  }

  public getAllRecordingMetadata(): RecordingMetadata[] {
    return JSON.parse(localStorage.getItem('recording-metadata')) || [];
  }

  private updateUploadID(fileInfo: UploadFileInfo, uploadId: string) {
    const url = `${commonenv.nextGenApiUrl}videos/${fileInfo.metadata.videoId}`;
    const file_size = this.helper.bytesToSize(fileInfo.localFileData.size);
    let headers;
    if (fileInfo.metadata.accessToken)
      headers = { 'access-token': fileInfo.metadata.accessToken };
    this.http
      .patch(url, { upload_id: uploadId, file_size: file_size.toString() }, { headers })
      .toPromise();
  }

  private initTranscodeRequest(metadata: RecordingMetadata) {
    const url = `${commonenv.nextGenApiUrl}videos/${metadata.videoId}/${URL_TRANSCODE_REQUEST}/`;
    let headers;
    if (metadata.accessToken)
      headers = { 'access-token': metadata.accessToken };

    this.http
      .post<DeleteVideoResponse>(url, { transcode_type: 'sd' }, { headers })
      .toPromise();
  }
}
