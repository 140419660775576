import * as fromActions from './../actions/timelines.actions';
import * as fromTrimmerActions from './../actions/trimmer.actions';
import * as fromProjectActions from './../../../store/actions/project.actions';

import { createReducer, on } from '@ngrx/store';
import { Timeline, TrimmerAction } from './../interfaces/timelines.interfaces';
import { cloneDeep } from 'lodash-es';
import { getTimelinesDuration } from '../helpers/timelines.helpers';
import { TimelineItem } from '../../../shared/element.interfaces';

export const key = 'timelines';

export interface TimelinesState {
  timelines: Timeline[];
  introDuration: number;
  outroDuration: number;
  duration: number;
  sentToTrimmer?: {
    trimmmerAction: TrimmerAction;
    timeline: Timeline;
    selectedItem?: TimelineItem;
  };
}

export const initialState: TimelinesState = {
  timelines: [],
  introDuration: 0,
  outroDuration: 0,
  duration: 0,
};

const reducer = createReducer(
  initialState,
  on(fromProjectActions.loadProjectSuccess, () => initialState),

  on(
    fromActions.loadTimelinesSuccess,
    (state, { timelines, introDuration, outroDuration }) => ({
      ...state,
      timelines,
      introDuration,
      outroDuration,
      duration: getTimelinesDuration(timelines),
    })
  ),

  on(fromActions.moveMainClip, (state, action) => {
    const { item, previousIndex, currentIndex } = action.event;
    const timelines = cloneDeep(state.timelines);
    const timeline = timelines.find((t) => t.type === 'main');

    timeline.data.splice(previousIndex, 1);
    timeline.data.splice(currentIndex, 0, item);

    return {
      ...state,
      timelines,
    };
  }),

  on(fromActions.switchTimelines, (state) => {
    const timelines = cloneDeep(state.timelines);
    [timelines[0], timelines[1]] = [timelines[1], timelines[0]];

    timelines[0].options.timelineIndex = 0;
    timelines[1].options.timelineIndex = 1;

    return {
      ...state,
      timelines,
    };
  }),

  // Actions that update entire timelines
  on(fromActions.updateTimelines, (state, { updatedTimelines }) => {
    const timelines = cloneDeep(state.timelines);
    timelines.forEach((timeline) => {
      const updatedTimeline = updatedTimelines.find(
        (ut) =>
          ut.type === timeline.type &&
          ut.options.timelineIndex === timeline.options.timelineIndex
      );
      if (updatedTimeline) {
        timeline.options.parentLayer = updatedTimeline.options.parentLayer;
        timeline.data = updatedTimeline.data;
      }
    });

    return {
      ...state,
      timelines,
      duration: getTimelinesDuration(timelines),
    };
  }),

  // Actions that update items
  on(fromActions.updateTimelineItems, (state, { items }) => {
    if (items.length === 0) {
      return { ...state };
    }

    const timelines = cloneDeep(state.timelines);
    const timelinesToUpdate = timelines.filter(
      (t) => t.type === items[0].item.type
    );

    items.forEach(({ timelineIndex, item }) => {
      const indexToUpdate = timelinesToUpdate[timelineIndex].data.findIndex(
        (d) => d.layerId === item.layerId
      );
      timelinesToUpdate[timelineIndex].data[indexToUpdate] = item;
    });

    return {
      ...state,
      timelines,
    };
  }),

  // Trimmer interaction
  on(
    fromActions.saveTrimmerData,
    (state, { trimmmerAction, timeline, item }) => ({
      ...state,
      sentToTrimmer: {
        trimmmerAction,
        timeline,
        selectedItem: item,
      },
    })
  ),
  on(fromTrimmerActions.trimCleanup, (state) => ({
    ...state,
    sentToTrimmer: null,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
