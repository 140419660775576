import { Injectable } from '@angular/core';
import { Observable, of, Subject, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { INativeLibService } from '../../interfaces';
import { InternetSpeedService } from '../internet-speed/internet-speed.service';
import { NetworkQualityService } from '../network-quality/network-quality.service';
import {
  HardwareConsumption,
  NetworkSpeed,
  PerformanceService,
} from './performance.interface';

@Injectable({
  providedIn: 'root',
})
export class LocalPerformanceService implements PerformanceService {
  hardwareConsumption$: Subject<HardwareConsumption>;
  networkSpeed$: Observable<NetworkSpeed>;

  constructor(
    private nativeLib: INativeLibService,
    private internetSpeedService: InternetSpeedService,
    private networkQualityService: NetworkQualityService
  ) {
    this.hardwareConsumption$ = new Subject<HardwareConsumption>();
    this.networkSpeed$ = zip(
      this.internetSpeedService.uploadSpeed$,
      this.internetSpeedService.downloadSpeed$
    ).pipe(
      map(([uploadSpeed, downloadSpeed]) => ({
        upload: uploadSpeed.mbps,
        download: downloadSpeed.mbps,
      })),
      catchError((error) => {
        console.log(error.message);
        return of({});
      })
    );
  }

  fetchHardwarePerformance() {
    this.getHardwareConsumption().then((consumption) => {
      this.hardwareConsumption$.next(consumption);
    });
  }

  fetchNetworkSpeed() {
    this.internetSpeedService.getDownloadSpeed();
    this.internetSpeedService.getUploadSpeed();
  }

  private async getNativeLibValue(
    nativeLibProperty: keyof INativeLibService,
    isPercent = false
  ): Promise<number> {
    try {
      let value = await (this.nativeLib[nativeLibProperty]() as Promise<
        number
      >);
      if (isPercent) {
        value *= 100;
      }
      return value;
    } catch (err) {
      return undefined;
    }
  }

  private async getHardwareConsumption(): Promise<HardwareConsumption> {
    const cpuSpeed = await this.getNativeLibValue('getCpuSpeedGHz');
    const cpuCount = await this.getNativeLibValue('getCpuCores');
    const cpuUsage = await this.getNativeLibValue('getCpuUsage', true);
    const cpuAppUsage = await this.getNativeLibValue('getCpuUsage', true);
    const memory = await this.getNativeLibValue('getMemoryGB');
    const memoryAppUsage = await this.getNativeLibValue(
      'getMemoryAppUsage',
      true
    );
    const memoryUsage = await this.getNativeLibValue('getMemoryUsage', true);

    return {
      cpuSpeed,
      cpuCount,
      cpuUsage,
      cpuAppUsage,
      memory,
      memoryAppUsage,
      memoryUsage,
      networkQuality: this.networkQualityService.networkQuality,
    };
  }
}
