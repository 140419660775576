import { TextInputComponent } from './text-input/text-input.component';
import { TextInputToolbarComponent } from './text-input-toolbar/text-input-toolbar.component';
import { UploaderComponent } from './uploader/uploader.component';
import { WorkflowPlayerComponent } from './workflow-player/workflow-player.component';
import { WorkflowTrimmerComponent } from './workflow-trimmer/workflow-trimmer.component';
import { RecordingUploadIndicatorComponent } from './recording-upload-indicator/recording-upload-indicator.component';
import { AlertComponent } from './alert/alert.component';

export const components = [
  UploaderComponent,
  TextInputComponent,
  TextInputToolbarComponent,
  WorkflowPlayerComponent,
  WorkflowTrimmerComponent,
  RecordingUploadIndicatorComponent,
  AlertComponent,
];

export const entryComponents = [AlertComponent];
