import { Component, OnChanges, OnInit } from '@angular/core';
import { LayerService } from '../../services/layer.service';

import { AssetsFacade } from '../../../store/facades/assets.facade';
import { FormBuilder } from '@angular/forms';
import { LayerFormPresetComponent } from '../layer-form-preset/layer-form-preset.component';
import { first } from 'rxjs/operators';
import { LayerDataChangedEvent } from '../../../shared/element.interfaces';
import { Resources } from '../../../store/interfaces/project.interface';

@Component({
  selector: 'openreel-wf-layer-form-preset-colors',
  templateUrl: './layer-form-preset.component.html',
  styleUrls: ['./layer-form-preset.component.scss'],
  providers: [
    {
      provide: LayerFormPresetComponent,
      useExisting: LayerFormPresetColorsComponent,
    },
  ],
})
export class LayerFormPresetColorsComponent
  extends LayerFormPresetComponent
  implements OnInit, OnChanges {
  constructor(
    protected readonly layerService: LayerService,
    protected readonly formBuilder: FormBuilder,
    protected readonly assetsFacade: AssetsFacade
  ) {
    super(layerService, formBuilder, assetsFacade);
  }

  getData = (): LayerDataChangedEvent => {
    // NOTE: each child of sublayer type has its own set of colors
    // So right now, when saving colors we need to update colors on all children of the sublayer
    // even though we're just showing a single set of colors
    let resourcesLocal: Resources;
    this.assetsFacade.resources$.pipe(first()).subscribe((resources) => {
      resourcesLocal = resources;
    });

    const allLayers = this.layerService.toFormArray(
      this.item.preset,
      this.item.data,
      resourcesLocal
    );

    const allLayersData = this.layerService.toData(this.item, allLayers);
    const firstLayerData = this.layerService.toData(this.item, this.groupArray);

    allLayersData.values[0] = { ...firstLayerData.values[0] };
    allLayersData.assetChanges[0] = { ...firstLayerData.assetChanges[0] };
    allLayersData.styleChanges[0] = { ...firstLayerData.styleChanges[0] };

    for (let i = 1; i < allLayersData.values.length; i++) {
      allLayersData.styleChanges[i] = { ...allLayersData.styleChanges[0] };
    }

    return allLayersData;
  };

  // NOTE: colors are shared between child layers so we only care about first layer data
  protected getItemData = () => [this.item.data[0]];
}
