import {
  Component,
  ViewChild,
  AfterViewInit,
  Input,
  NgZone,
  ChangeDetectorRef,
} from '@angular/core';

@Component({
  selector: 'openreel-single-video',
  templateUrl: './single-video.component.html',
  styleUrls: ['./single-video.component.scss'],
})
export class SingleVideoComponent implements AfterViewInit {
  /**
   *
   *
   * Input/Output
   *
   *
   */

  /**
   *  Preview or Streaming?
   */
  @Input()
  public type = 'streaming';

  @Input()
  public mirrored = false;

  @Input()
  public forceSrcObj = false;

  /**
   * Playback Input
   */
  private _playbackState;
  @Input() set playbackState(s) {
    this._playbackState = s;
    if (!this.video || !this.video.nativeElement) {
      return;
    }
    if (s === 'play') {
      this.video.nativeElement.play();
    } else {
      this.video.nativeElement.pause();
    }
  }
  get playbackState() {
    return this._playbackState;
  }

  /**
   * Stream Input
   */
  private _stream;
  @Input() set stream(s) {
    this._stream = s;
    this.setStream();
  }
  get stream() {
    return this._stream;
  }

  private _time = 0;
  @Input() set time(s) {
    this._time = s;
    this.setTime();
  }
  get time() {
    return this._time;
  }

  /**
   * Minimum and maximum time seekable in the video
   */
  private _range = [0, 10000000];
  @Input() set range(r) {
    this._range = r;
  }
  get range() {
    return this._range;
  }

  // End Input/Output
  ///////////////////////////////

  @ViewChild('video') video;

  constructor(public zone: NgZone, private changeDetector: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    if (this.type === 'streaming') {
      this.video.nativeElement.muted = true;
    }

    this.video.nativeElement.autoplay = true;
    this.video.nativeElement.controls = false;

    this.setStream();
    this.setTime();
  }
  public onTimeUpdate() {
    if (this.video.nativeElement.currentTime >= this._range[1]) {
      this.video.nativeElement.currentTime = this._range[0];
    } else if (this.video.nativeElement.currentTime < this._range[0]) {
      this.video.nativeElement.currentTime = this._range[0];
    }
  }
  setTime() {
    this.zone.run(() => {
      if (this._time && this.video) {
        this.video.nativeElement.currentTime = this._time;
        this.video.nativeElement.play();
      }
      this.changeDetector.markForCheck();
    });
  }
  setStream() {
    this.zone.run(() => {
      if (this._stream && this.video) {
        if (this.forceSrcObj || this._stream instanceof MediaStream) {
          this.video.nativeElement.srcObject = this._stream;
        } else {
          this.video.nativeElement.src = this._stream;
        }
      }
      this.changeDetector.markForCheck();
    });
  }
}
