<div
  class="flex flex-align-center flex-justify-between cursor-pointer"
  (click)="toggle()"
>
  <div class="flex flex-align-center select-none">
    <div class="color m-right-8" [style.background-color]="selectedColor"></div>
    <div class="text-12">{{ uiControl.label }}</div>
  </div>
  <div class="text-12">{{ selectedColor }}</div>
</div>
<div class="details">
  <div class="flex flex-align-center my-12">
    <div
      *ngFor="let color of colors"
      class="color cursor-pointer m-right-16"
      [style.background-color]="color.value"
      tabindex="0"
      (click)="selectColorFromAssets(color)"
    ></div>
  </div>
  <div class="color-picker">
    <sketch-picker
      class="layer-color"
      [control]="customColorControl"
    ></sketch-picker>
  </div>
</div>
