import { BehaviorSubject, Observable } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';

import { Cleanupable } from '../../../../../../../libs/common/src';
import { EditorFrame } from '../../editor-frame.interface';
import { ProjectFacade } from '../../../store/facades/project.facade';

@Component({
  selector: 'openreel-editor-frame-render',
  templateUrl: './editor-frame-render.component.html',
  styleUrls: ['./editor-frame-render.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorFrameRenderComponent
  extends Cleanupable
  implements EditorFrame, OnInit {
  private progress = new BehaviorSubject<number>(0);

  progress$ = this.progress.asObservable();
  cancelling$: Observable<boolean>;

  constructor(private readonly projectFacade: ProjectFacade) {
    super();
  }

  ngOnInit() {
    this.projectFacade.renderProgress$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((p) => !!p)
      )
      .subscribe(({ progress, state }) => {
        if (state === 'rendering') {
          this.progress.next(progress);
        }
      });
  }

  onCancel() {
    this.projectFacade.cancelProjectRender();
  }

  save() {
    this.projectFacade.closeProject();
  }
}
