import { Injectable } from '@angular/core';
import { DirectorSelfRecordData } from '../../interfaces';
import { DirectorSessionService } from './director-session.service';
import { ILocalRecorderService } from '../subject/local-recording/local-recorder-base.service';
import { SessionConfigService } from '../session/session-config.service';
import { Cleanupable } from '../../classes/cleanupable';
import { UploadService } from '../upload/upload.service';
import { SocketDirectorExtensionRecordingService } from './socket-extensions/socket-director-extension-recording.service';
import { RecordingMetadata } from '../upload/dto/upload.dto';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { take } from 'rxjs/operators';

@Injectable()
export class DirectorRecordingService extends Cleanupable {
  private directorRecordingChange = new BehaviorSubject<boolean>(null);
  directorRecordingChange$ = this.directorRecordingChange.asObservable();
  constructor(
    private session: DirectorSessionService,
    private recorder: ILocalRecorderService,
    private sessionConfigService: SessionConfigService,
    private upload: UploadService,
    private socketRecord: SocketDirectorExtensionRecordingService,
    private auth: AuthService
  ) {
    super();
    this.subscriptions.push(
      //on stop subject recording if any director recording is going on, stop the recording
      this.socketRecord.listenToRecordingStop().subscribe(async (data) => {
        const isRecording = await this.isRecording();
        if (isRecording && data.data) {
          this.handleDirectorStopRecording();
        }
      })
    );
  }
  selfRecordData: DirectorSelfRecordData;
  async startRecording(selfRecordData: DirectorSelfRecordData) {
    this.selfRecordData = selfRecordData;
    try {
      await this.recorder.startRecording(
        parseInt(selfRecordData.resolution, 10),
        30,
        this.sessionConfigService.selectedAudioSource$.value,
        this.sessionConfigService.selectedVideoSource$.value
      );
      this.socketRecord.sendAckDirectorRecord({
        SessionID: this.session.session.session_id,
        identity: this.selfRecordData.identity,
        videoid: this.selfRecordData.videoId,
        video_name: this.selfRecordData.videoName,
        stat: '1',
      });
      this.directorRecordingChange.next(true);
    } catch (err) {
      this.socketRecord.sendAckDirectorRecord({
        SessionID: this.session.session.session_id,
        identity: this.selfRecordData.identity,
        videoid: this.selfRecordData.videoId,
        video_name: this.selfRecordData.videoName,
        stat: '0',
      });
      this.recorder.stopRecording();
      throw err;
    }
  }
  async handleDirectorStopRecording() {
    await this.recorder.stopRecording();
    this.directorRecordingChange.next(false);
    await this.startDirectorUploading();
  }
  async startDirectorUploading() {
    const user = this.auth.getUserDetails();
    const metadata: RecordingMetadata = {
      sessionId: this.session.session.session_id,
      identity: this.selfRecordData.identity,
      localFileName: this.recorder.lastFileName,
      resolution: this.recorder.lastFileResolution,
      videoId: this.selfRecordData.videoId,
      fileNameForUpload: this.recorder.getFileNameForUpload(),
    };
    if (this.auth.isInternalUser()) {
      metadata.accessToken = user.auth_token;
    } else {
      metadata.emailToken = user.auth_token;
    }
    this.upload.saveRecordingMetadata(metadata);
    await this.upload.uploadVideoRecording(metadata);
  }
  private async isRecording() {
    return await this.directorRecordingChange.pipe(take(1)).toPromise();
  }
}
