import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { NotificationsGroup } from './dtos/notifications-group.dto';
import { tap } from 'rxjs/operators';
import { commonenv } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private urls = {
    fetch: `${commonenv.nextGenApiUrl}notifications`,
    markAsRead: (id: number) => `${commonenv.nextGenApiUrl}notifications/${id}/mark-read`,
  };

  public groups$: BehaviorSubject<NotificationsGroup[]> = new BehaviorSubject([]);

  constructor(private httpClient: HttpClient) {}
  fetch() {
    return this.httpClient.get<NotificationsGroup[]>(this.urls.fetch).pipe(
      tap((response) => {
        this.groups$.next(response);
      })
    );
  }
  markAsRead(id: number) {
    return this.httpClient.put(this.urls.markAsRead(id), {}).pipe(
        tap(() => {
          this.fetch().subscribe();
            // const groups = this.groups$.getValue();
            // for (const group of groups) {
            //   for (const [index, notification] of group.notifications.entries()) {
            //     if (notification.id === id) {
            //       group.notifications.slice(index);
            //     }
            //   }
            // }
            // this.groups$.next(groups);
        })
      );

  }
}
