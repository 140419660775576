import { createReducer, on } from '@ngrx/store';
import * as fromActions from '../actions/clip-selection.actions';
import { ClipSelectionFeatureState } from '../interfaces/clip-selection.interfaces';

export const key = 'select-clips';

export const initialState: ClipSelectionFeatureState = {
  options: {},
  projects: [],
  selectedProjectId: null,
  recordings: [],
  selectedRecordings: [],
};

const reducer = createReducer(
  initialState,
  on(fromActions.cleanupSelection, (state) => ({
    ...state,
    selectedProjectId: null,
    selectedRecordings: [],
  })),
  on(fromActions.setDialogOptions, (state, { options }) => ({
    ...state,
    options,
  })),
  on(fromActions.loadProjects, (state) => ({
    ...state,
    selectedProjectId: null,
    selectedRecordings: [],
  })),
  on(fromActions.loadProjectsSuccess, (state, { projects }) => ({
    ...state,
    projects,
  })),
  on(fromActions.selectProject, (state, { selectedProjectId }) => ({
    ...state,
    selectedProjectId,
  })),

  on(fromActions.loadProjectRecordings, (state) => ({
    ...state,
    recordings: [],
  })),
  on(fromActions.loadProjectRecordingsSuccess, (state, { recordings }) => {
    const excludedRecordings = state.options?.selectedType?.excludeRecordings;
    return {
      ...state,
      recordings: recordings.filter(
        (r) =>
          !excludedRecordings ||
          excludedRecordings.indexOf(r.ovra_session_videos_id) === -1
      ),
    };
  }),
  on(fromActions.selectProjectRecording, (state, { recording, selected }) => {
    let selectedRecordings = selected
      ? [...state.selectedRecordings, recording]
      : state.selectedRecordings.filter(
          (sr) => sr.ovra_session_videos_id !== recording.ovra_session_videos_id
        );

    const maxSelected = state.options?.maxSelectedClips;
    if (maxSelected) {
      selectedRecordings = selectedRecordings.slice(
        selectedRecordings.length - maxSelected
      );
    }
    return {
      ...state,
      selectedRecordings,
    };
  })
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
