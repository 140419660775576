import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import { BaseCommand } from './base.command';

export class AddClipsCommand extends BaseCommand<
  TimelineInterfaces.ClipAddEvent[]
> {
  run(event: TimelineInterfaces.ClipAddEvent[]) {
    this.createMainSectionIfNotexists();

    const timeline = this.getTimeline('main', true);
    this.addClips(timeline, event);

    this.recalculateVisibility(timeline);

    return {
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
