import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';

import {
  AuthService,
  Cleanupable,
  WorkflowProjectSocketService,
} from '@openreel/common';
import { EditorFacade } from '../../../store/facades/editor.facade';
import { EditorFrame } from '../../editor-frame.interface';
import { Observable } from 'rxjs';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { AfterUpdate } from '../../../store/interfaces/editor.interface';

@Component({
  selector: 'openreel-wf-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent extends Cleanupable implements OnInit {
  private editorFrameComponentRef: EditorFrame;

  showSidebar$: Observable<boolean>;

  isWorkflowLoaded$ = this.projectFacade.isWorkflowLoaded$;

  constructor(
    private readonly editorFacade: EditorFacade,
    private readonly projectFacade: ProjectFacade,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly workflowProjectSocketService: WorkflowProjectSocketService,
    private readonly authService: AuthService
  ) {
    super();
  }

  connect() {
    this.projectFacade.captureProjectId$
      .pipe(filter(p => !!p), first())
      .subscribe(captureProjectId => {

      const sessionInfo = {
        deviceType: 'WEB',
        userType: this.authService.role,
        token: this.authService.token$.value,
        captureProjectId: captureProjectId
      };
      this.workflowProjectSocketService.connect(sessionInfo);
    });
  }

  ngOnInit() {

    this.connect();

    this.registerSidebarListeners();

    this.projectFacade.renderProgress$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((p) => !!p)
      )
      .subscribe(({ state }) => {
        if (state === 'rendering') {
          this.router.navigate(['render'], {
            relativeTo: this.route,
          });
        } else if (state === 'done') {
          this.router.navigate(['preview'], {
            relativeTo: this.route,
          });
        } else if (state === 'failed' || state === 'cancelled') {
          let steps = null;
          this.editorFacade.steps$
            .pipe(first())
            .subscribe((currentSteps) => (steps = currentSteps));
          this.router.navigate([steps[steps.length - 1].route], {
            relativeTo: this.route,
          });
        }
      });

    this.editorFacade.currentStep$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        withLatestFrom(this.projectFacade.renderProgress$),
        filter(([, rendering]) => rendering?.state !== 'rendering')
      )
      .subscribe(([{ step, childStep }]) => {
        let steps = null;
        this.editorFacade.steps$
          .pipe(first())
          .subscribe((currentSteps) => (steps = currentSteps));

        const goToStep = steps.find((s) => s.order === step);
        let route = goToStep.route;
        if (goToStep.childRoutes) {
          const childRoute = goToStep.childRoutes[childStep];
          if (childRoute) {
            route = `${route}/${childRoute}`;
          }
        }
        this.router.navigate([route], {
          relativeTo: this.route,
        });
      });
  }

  onActivate(componentRef: EditorFrame) {
    this.editorFrameComponentRef = componentRef;
  }

  saveAndClose() {
    this.editorFrameComponentRef.save(AfterUpdate.CloseProject);
  }

  private registerSidebarListeners() {
    this.showSidebar$ = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => {
        let route = this.route.snapshot.firstChild;
        let child = route;

        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
            route = child;
          } else {
            child = null;
          }
        }

        return route;
      }),
      map((route) => route.data.showSidebar),
      startWith(true),
      distinctUntilChanged()
    );
  }
}
