import { Component, Input } from '@angular/core';
import { LayerService, UiControl } from '../../services/layer.service';

import { FormControl } from '@angular/forms';

@Component({
  selector: 'openreel-wf-layer-item-image',
  templateUrl: './layer-item-image.component.html',
  styleUrls: ['./layer-item-image.component.scss'],
})
export class LayerItemImageComponent {
  @Input() uiControl: UiControl;
  @Input() control: FormControl;

  constructor(private readonly layerService: LayerService) {}

  onImageRemove() {
    const existingAssetId = this.control.value.assetId;
    this.control.patchValue({
      assetId: null,
      assetFileId: null,
      newAssetFileId: null,
      removedAssetId: existingAssetId,
    });
  }

  onImageUpload(sourceId: number) {
    const existingAssetId = this.control.value.assetId;

    this.control.patchValue({
      assetId: null,
      assetFileId: sourceId,
      newAssetFileId: sourceId,
      removedAssetId: existingAssetId,
    });
  }

  validationErrors = () =>
    this.layerService.getValidationErrors(this.control.errors);
}
