<button
  [@sidebarButton]="state === ButtonState.SELECTED ? 'expanded' : 'collapsed'"
  mat-flat-button
  disableRipple
  color="primary"
  class="mat-button-pill-lg t-align-left or-sidebar-button"
  [class.expanded]="state === ButtonState.SELECTED"
  [disabled]="state === ButtonState.INACTIVE"
  (click)="onClick()"
>
  <mat-icon svgIcon="{{ icon }}" class="mat-icon-20"></mat-icon>
  <span class="or-sidebar-btn-content m-left-4"> {{ text }} </span>
</button>
