import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { AudioStreamService, VideoStreamService } from '../../../media';
import { LocalRecorderService } from './local-recorder.service';

@Injectable()
export class LocalRecorderMediaRecorder extends LocalRecorderService {
  constructor(
    private audioSource: AudioStreamService,
    private videoSource: VideoStreamService
  ) {
    super();
  }

  // eslint-disable-next-line max-lines-per-function
  protected async doStartRecording() {
    const audioStream = await this.audioSource.audio$.pipe(take(1)).toPromise();
    const videoStream = await this.videoSource.video$.pipe(take(1)).toPromise();

    if (audioStream.error || videoStream.error) {
      console.error(
        'Could not start recording: ',
        audioStream.error,
        videoStream.error
      );
      return;
    }

    /**
     * Case 0: Use the same tracks:
     *  */

    const newMediaStream = new MediaStream([
      audioStream.track,
      videoStream.track
    ]);

    /**
     * First Trial: Use the same track and apply Constraints:
     * */
    // const constraints = {
    //   width: {min: 1920, ideal: 1920},
    //   height: {min: 1080, ideal: 1080},
    // };
    // const videoTrack=videoStream.track.clone();
    // await videoTrack.applyConstraints(constraints);
    // const newMediaStream = new MediaStream([
    //   audioStream.track,
    //   videoTrack
    // ]);

    // Second Trial: Request new media stream

    /**
     * This doesn't work in Chrome because chrome seems to use the same stream
     */
    // const constraints = {
    //   video: {
    //     deviceId: videoStream.device.id,
    //     width: { ideal: 4096 },
    //     height: { ideal: 2160 },
    //   },
    //   audio: { deviceId: audioStream.device.id},
    // };

    // const newMediaStream = await navigator.mediaDevices.getUserMedia(constraints);

    // this.handleStart();

    this.doStartRecordingStream(newMediaStream);
  }
}
