import { SubjectTeleprompterOptions } from './subject-teleprompter/subject-teleprompter.component';
import { TeleprompterSocketRequest } from '../../interfaces/teleprompter.interface';
import { take, map } from 'rxjs/operators';
import { timer } from 'rxjs';
export const teleprompterOptions = {
  speeds: [
    {
      value: 100,
      viewValue: '1X (100 words/minute)',
      wordsPerMinute: 100,
    },
    {
      value: 120,
      viewValue: '2X (120 words/minute)',
      wordsPerMinute: 120,
    },
    {
      value: 130,
      viewValue: '3X (130 words/minute)',
      wordsPerMinute: 130,
    },
    {
      value: 140,
      viewValue: '4X (140 words/minute)',
      wordsPerMinute: 140,
    },
    {
      value: 150,
      viewValue: '5X (150 words/minute)',
      wordsPerMinute: 150,
    },
    {
      value: 160,
      viewValue: '6X (160 words/minute)',
      wordsPerMinute: 160,
    },
    {
      value: 200,
      viewValue: '7X (200 words/minute)',
      wordsPerMinute: 200,
    },
    {
      value: 250,
      viewValue: '8X (250 words/minute)',
      wordsPerMinute: 250,
    },
    {
      value: 300,
      viewValue: '9X (300 words/minute)',
      wordsPerMinute: 300,
    },
  ],
  fontSizes: [
    {
      value: 20,
      viewValue: '20px',
    },
    {
      value: 30,
      viewValue: '30px',
    },
    {
      value: 40,
      viewValue: '40px',
    },
  ],
  splits: [
    {
      value: 'fullscreen',
      viewValue: 'Fullscreen',
      fxLayout: 'row',
      fxFlexForTeleprompter: 100,
      fxFlexForVideo: 0,
    },
    {
      value: 'top',
      viewValue: 'Teleprompter Top',
      fxLayout: 'column',
      fxFlexForTeleprompter: 50,
      fxFlexForVideo: 50,
    },
    {
      value: 'bottom',
      viewValue: 'Teleprompter Bottom',
      fxLayout: 'column-reverse',
      fxFlexForTeleprompter: 50,
      fxFlexForVideo: 50,
    },
    {
      value: 'right',
      viewValue: 'Teleprompter Right',
      fxLayout: 'row-reverse',
      fxFlexForTeleprompter: 50,
      fxFlexForVideo: 50,
    },
    {
      value: 'left',
      viewValue: 'Teleprompter Left',
      fxLayout: 'row',
      fxFlexForTeleprompter: 50,
      fxFlexForVideo: 50,
    },
  ],
  backgrounds: [
    {
      value: 'white_in_black',
      viewValue: 'White - text in black',
      cls: 'white-in-black',
    },
    {
      value: 'black_in_white',
      viewValue: 'Black - text in white',
      cls: 'black-in-white',
    },
  ],
};

export function createCounterFor(value: number) {
  return timer(0, 1000).pipe(
    take(value + 1),
    map((i) => value - i)
  );
}

export function transformToOptions(
  req: TeleprompterSocketRequest
): SubjectTeleprompterOptions {
  return {
    content: req.script_content.scriptData[0],
    countDown: req.countDown,
    fontSize: req.fontSize,
    speed: req.speed,
    split: req.split,
    background: req.background,
    keepOnScreen: req.keepOnScreen,
    contentChange: req.content_change === 1,
  };
}
