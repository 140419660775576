import * as fromActions from '../actions/template.actions';

import { createReducer, on } from '@ngrx/store';

import { Template } from '../interfaces/template.interface';

export const initialState: Partial<Template> = {
  data: null,
};

const reducer = createReducer(
  initialState,
  on(fromActions.loadTemplateSuccess, (_, { data }) => ({
    data,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
