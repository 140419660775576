import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnChanges,
  Output,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { asDecimalString, asIntString } from '../../helpers/number.helpers';

import { Options, ChangeContext } from 'ng5-slider';
import { TrimmerOptions } from './workflow-trimmer.interfaces';
import { Trimming } from '../../../../../../../libs/common/src/components/video-editor/transformation.interface';

const DEFAULT_OPTIONS: Options = {
  floor: 0,
  ceil: 100,
  noSwitching: true,
  showOuterSelectionBars: true,
  hideLimitLabels: true,
  showSelectionBar: false,
  translate: (value: number): string => {
    let output = '';

    const totalSeconds = value / 1000;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;

    if (hours > 0) {
      output = `${asIntString(hours)}:`;
    }

    return output + `${asIntString(minutes)}:${asDecimalString(seconds)}`;
  },
};

@Component({
  selector: 'openreel-wf-trimmer',
  templateUrl: './workflow-trimmer.component.html',
  styleUrls: ['./workflow-trimmer.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowTrimmerComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input()
  options: TrimmerOptions;

  @Output()
  trimStart = new EventEmitter<void>();

  @Output()
  trimEnd = new EventEmitter<void>();

  @Output()
  trimming = new EventEmitter<Trimming>();

  @ViewChild('framesContainer') framesContainer: ElementRef<HTMLDivElement>;

  frames: string[];
  sliderOptions: Options;
  minValue = 0;
  maxValue = 100;

  paddingRight = 0;

  private readonly timelineResizeObserver = new ResizeObserver(() =>
    this.calculatePaddingForDurationDiff()
  );

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.setOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('options' in changes && !changes['options'].isFirstChange()) {
      this.minValue =
        typeof this.options.startTime === 'number'
          ? this.options.startTime
          : this.minValue;
      this.maxValue =
        typeof this.options.endTime === 'number'
          ? this.options.endTime
          : this.maxValue;
    }
  }

  ngAfterViewInit() {
    this.timelineResizeObserver.observe(this.framesContainer.nativeElement);
    this.calculatePaddingForDurationDiff();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.timelineResizeObserver.disconnect();
  }

  handleUserChangeStart() {
    this.trimStart.emit();
  }

  handleUserChangeEnd({ value, highValue }: ChangeContext) {
    this.trimming.emit({
      startTime: value,
      endTime: highValue,
    });
    this.trimEnd.emit();
  }

  handleHighValueChange(highValue: number) {
    this.maxValue = highValue;
    this.trimming.emit({
      startTime: this.minValue,
      endTime: this.maxValue,
    });
  }

  handleLowValueChange(lowValue: number) {
    this.minValue = lowValue;
    this.trimming.emit({
      startTime: this.minValue,
      endTime: this.maxValue,
    });
  }

  private setOptions() {
    this.frames = this.options.frames;
    this.minValue = this.options.startTime || 0;
    this.maxValue = this.options.endTime || this.options.duration;
    this.sliderOptions = {
      ...DEFAULT_OPTIONS,
      ceil: this.options.duration,
      disabled: this.options.disabled,
      animate: false,
    };
  }

  private calculatePaddingForDurationDiff() {
    const containerBounds = this.framesContainer.nativeElement.getBoundingClientRect();
    const durationRatio = this.options.videoDuration / this.options.duration;

    this.paddingRight =
      durationRatio === 1
        ? 0
        : containerBounds.width - containerBounds.width * durationRatio;
  }
}
