import * as fromActions from '../actions/brand-kits.actions';

import { createReducer, on } from '@ngrx/store';

import { BrandKits } from '../interfaces/brand-kits.interface';

export const initialState: BrandKits = {
  all: [],
  loadingAll: false,
};

const reducer = createReducer(
  initialState,

  on(fromActions.listBrandKitsAPI, (state) => ({
    ...state,
    loadingAll: true,
  })),

  on(fromActions.listBrandKitsSuccess, (state, { all }) => ({
    ...state,
    all,
    loadingAll: false,
  })),

  on(fromActions.loadCurrentBrandKitSuccess, (state, { current }) => ({
    ...state,
    current,
  })),

  on(fromActions.removeCurrentBrandKit, (state) => ({
    ...state,
    current: null,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
