import {
  CreateProjectRequest,
  ProjectDto,
  ProjectVideoDto,
  UpdateProjectRequest,
} from './project.interfaces';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/projects`,
    byId: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}`,
    byIdRender: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/render`,
    byIdCancelRender: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/render/cancel`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getProjects() {
    return this.httpClient.get<ProjectDto[]>(this.urls.root);
  }

  getProjectById(projectId: number) {
    return this.httpClient.get<ProjectDto>(this.urls.byId(projectId));
  }

  createProject(data: CreateProjectRequest) {
    return this.httpClient.post<ProjectDto>(this.urls.root, data);
  }

  updateProjectById(data: UpdateProjectRequest) {
    return this.httpClient.put<ProjectDto>(this.urls.byId(data.id), data);
  }

  renderProject(projectId: number) {
    return this.httpClient.post<ProjectVideoDto>(
      this.urls.byIdRender(projectId),
      {}
    );
  }

  cancelProjectRender(projectId: number) {
    return this.httpClient.post<void>(
      this.urls.byIdCancelRender(projectId),
      {}
    );
  }
}
