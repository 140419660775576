import { NgModule } from '@angular/core';

import { NotificationsComponent } from './components/notifications.component';
import { NotificationsService } from './notifications.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material.module';
import { TimeAgoPipe } from './time-ago.pipe';

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule],
  exports: [NotificationsComponent, TimeAgoPipe],
  declarations: [NotificationsComponent, TimeAgoPipe],
  providers: [NotificationsService],
})
export class NotificationsModule {}
