import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { Recording } from '../../../../../../../libs/common/src';
import { AssetsCacheFacade } from '../../../store/facades/asset-cache.facade';

@Component({
  selector: 'openreel-wf-recording',
  templateUrl: './recording.component.html',
  styleUrls: ['./recording.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingComponent implements OnInit {
  @Input() recording: Recording;
  @Input() selected: boolean;

  @Output() recordingSelected = new EventEmitter();

  @ViewChild('videoPlayer') videoplayer: ElementRef<HTMLVideoElement>;

  showPreview = false;
  videoUrl: string;

  constructor(
    private readonly assetsCacheFacade: AssetsCacheFacade,
    public readonly sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.assetsCacheFacade
      .getAssetUrlById('or-recordings', this.recording.ovra_session_videos_id)
      .pipe(first())
      .subscribe((url) => (this.videoUrl = url));
  }

  onSelect() {
    this.recordingSelected.emit(!this.selected);
  }

  onMouseOver() {
    if (this.videoplayer) {
      this.showPreview = true;
      setTimeout(() => this.videoplayer.nativeElement.play());
    }
  }

  onMouseOut() {
    if (this.videoplayer) {
      this.showPreview = false;
      this.videoplayer.nativeElement.pause();
      this.videoplayer.nativeElement.currentTime = 0;
    }
  }
}
