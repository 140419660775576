import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularResizedEventModule } from 'angular-resize-event';
import { AvatarModule } from 'ngx-avatar';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxStronglyTypedFormsModule } from 'ngx-strongly-typed-forms';
// import {
//   AnimationModule,
//   CaptureTeleprompterComponent,
//   CollaboratorLoginComponent,
//   CountdownComponent,
//   DirectorLoginComponent,
//   DirectorForgotPasswordComponent,
//   DirectorForgotPasswordResetComponent,
//   MicWavesComponent,
//   PerformanceScreenPopupComponent,
//   SingleVideoComponent,
//   SubjectTeleprompterComponent,
//   VideoEditorModule,
//   VideoParticipantComponent,
//   HighlightableTextareaComponent,
//   TeleprompterPresetsFormComponent,
// } from './components';
import { FlatChatComponent } from './components/chat/flat-chat/flat-chat.component';
import { TeleprompterPreviewPopupComponent } from './components/teleprompter/teleprompter-preview-popup/teleprompter-preview-popup.component';
import { GenericLoadingComponent } from './components/generic-loading/generic-loading.component';
import { LobbyComponent } from './components/lobby/lobby.component';
import { MiniLobbyComponent } from './components/mini-lobby/mini-lobby.component';
import { OrInviteInternalComponent } from './components/or-invite-internal-user/or-invite-internal-user.component';
import { OrUserInfoUpdateComponent } from './components/or-user-info-update/or-user-info-update.component';
import { QuestionPopupComponent } from './components/question-popup/question-popup.component';
import { SessionAudioComponent } from './components/session-audio/session-audio.component';
import { TitleLineComponent } from './components/title-line/title-line.component';
import { UploadIndicatorComponent } from './components/upload-indicator/upload-indicator.component';
import { GenericReconnectSpinnerComponent } from './components/generic-reconnect-spinner/generic-reconnect.component';
import { NotificationsModule } from './director/notifications';
import { MaterialModule } from './material.module';
import { AudioMeterComponent } from './components/audio-meter/audio-meter/audio-meter.component';
import { OpenreelCommonDirectivesModule } from './directives/openreel-common-directives.module';
import { OpenreelCommonPipesModule } from './pipes/openreel-common-pipes.module';
import { AnimationModule } from './components/animation/animation.module';
import { CollaboratorLoginComponent } from './components/collaborator-login-form/collaborator-login-form.component';
import { CountdownComponent } from './components/countdown/countdown.component';
import { DirectorForgotPasswordComponent } from './components/director-forgot-password-form/director-forgot-password-form.component';
import { DirectorForgotPasswordResetComponent } from './components/director-forgot-reset-password-form/director-forgot-reset-password-form.component';
import { DirectorLoginComponent } from './components/director-login-form/director-login-form.component';
import { MicWavesComponent } from './components/mic-waves/mic-waves.component';
import { PerformanceScreenPopupComponent } from './components/performance-screen-popup/performance-screen-popup.component';
import { SingleVideoComponent } from './components/single-video/single-video.component';
import { CaptureTeleprompterComponent } from './components/teleprompter/capture-teleprompter/capture-teleprompter.component';
import { HighlightableTextareaComponent } from './components/teleprompter/highlightable-textarea/highlightable-textarea.component';
import { SubjectTeleprompterComponent } from './components/teleprompter/subject-teleprompter/subject-teleprompter.component';
import { TeleprompterPresetsFormComponent } from './components/teleprompter/teleprompter-presets-form/teleprompter-presets-form.component';
import { VideoEditorModule } from './components/video-editor/video-editor.module';
import { VideoParticipantComponent } from './components/video-participant/video-participant.component';
import { RichTextEditorComponent } from './components/rich-text-editor/rich-text-editor.component';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { GenericAlertComponent } from './components/generic-alert/generic-alert.component';
import { AudioGainControlComponent } from './components/audio-gain-control/audio-gain-control.component';
import { RecordingStatisticsComponent } from './components/recording-statistics/recording-statistics.component';

@NgModule({
  declarations: [
    GenericLoadingComponent,
    LobbyComponent,
    MiniLobbyComponent,
    VideoParticipantComponent,
    MicWavesComponent,
    DirectorLoginComponent,
    DirectorForgotPasswordComponent,
    DirectorForgotPasswordResetComponent,
    CollaboratorLoginComponent,
    SingleVideoComponent,
    AudioMeterComponent,
    SubjectTeleprompterComponent,
    CaptureTeleprompterComponent,
    HighlightableTextareaComponent,
    TeleprompterPresetsFormComponent,
    UploadIndicatorComponent,
    FlatChatComponent,
    OrInviteInternalComponent,
    TitleLineComponent,
    OrUserInfoUpdateComponent,
    CountdownComponent,
    SessionAudioComponent,
    GenericReconnectSpinnerComponent,
    RichTextEditorComponent,
    GenericAlertComponent,
    AudioGainControlComponent,
    RecordingStatisticsComponent,

    // Popups
    TeleprompterPreviewPopupComponent,
    QuestionPopupComponent,
    PerformanceScreenPopupComponent,
  ],
  entryComponents: [
    TeleprompterPreviewPopupComponent,
    PerformanceScreenPopupComponent,
    QuestionPopupComponent,
    GenericAlertComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    // ThemeModule,
    AvatarModule,
    AnimationModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
    }),
    NgxStronglyTypedFormsModule,
    AngularResizedEventModule,
    NotificationsModule,
    VideoEditorModule,
    OpenreelCommonDirectivesModule,
    OpenreelCommonPipesModule,
  ],
  exports: [
    NotificationsModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    AngularResizedEventModule,
    FlexLayoutModule,
    GenericLoadingComponent,
    LobbyComponent,
    MiniLobbyComponent,
    AudioMeterComponent,
    VideoParticipantComponent,
    MicWavesComponent,
    DirectorLoginComponent,
    DirectorForgotPasswordComponent,
    DirectorForgotPasswordResetComponent,
    CollaboratorLoginComponent,
    SingleVideoComponent,
    SubjectTeleprompterComponent,
    CaptureTeleprompterComponent,
    UploadIndicatorComponent,
    FlatChatComponent,
    OrInviteInternalComponent,
    TitleLineComponent,
    OrUserInfoUpdateComponent,
    CountdownComponent,
    SessionAudioComponent,
    VideoEditorModule,
    GenericReconnectSpinnerComponent,
    OpenreelCommonDirectivesModule,
    OpenreelCommonPipesModule,
    RichTextEditorComponent,
    AudioGainControlComponent,
    RecordingStatisticsComponent,
    
    // popups
    PerformanceScreenPopupComponent,
  ],
  providers: [],
})
export class OpenreelCommonModule {}
