import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { FormControlText, UiControl } from '../../services/layer.service';

import { AssetsFacade } from '../../../store/facades/assets.facade';
import { FormGroup } from '@angular/forms';
import { ColorAsset } from '../../../../api/workflow.interfaces';
import { Cleanupable } from '../../../../../../../libs/common/src';
import { takeUntil } from 'rxjs/operators';
import { ColorPickerControl } from '@iplab/ngx-color-picker';

@Component({
  selector: 'openreel-wf-layer-item-color-new',
  templateUrl: './layer-item-color-new.component.html',
  styleUrls: ['./layer-item-color-new.component.scss'],
})
export class LayerItemColorNewComponent extends Cleanupable implements OnInit {
  @Input() uiControl: UiControl;
  @Input() control: FormGroup;

  @HostBinding('class.expanded') isExpanded = false;

  selectedColor: string;
  colors: ColorAsset[];

  public customColorControl = new ColorPickerControl().hidePresets();

  constructor(private readonly assetsFacade: AssetsFacade) {
    super();
  }

  ngOnInit() {
    this.assetsFacade.colors$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((colors) => {
        this.colors = colors;
        this.selectedColor =
          this.controlValue.color !== null
            ? this.controlValue.color
            : this.colors[this.controlValue.colorIndex || 0].value;

        this.customColorControl.setValueFrom(this.selectedColor);
      });

    this.customColorControl.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pickedColor) => {
        if (this.selectedColor === pickedColor.toHexString()) {
          return;
        }

        this.selectedColor = pickedColor.toHexString();

        // NOTE: even if user selected a color manually through color picker, try to see if selected color exists in project color list
        // If it does, select it via colorIndex so that it seems that user selected it from predefined list
        // Doing this, we enable us to later replace the color when switching brand kits
        const assetColorIndex = this.colors.findIndex(
          (c) => c.value === this.selectedColor
        );
        if (assetColorIndex > -1) {
          this.updateControl(false, assetColorIndex);
        } else {
          this.updateControl(true);
        }
      });
  }

  get controlValue() {
    return this.control.value as FormControlText;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  selectColorFromAssets(color: ColorAsset) {
    if (this.colors.indexOf(color) === -1) {
      return;
    }

    this.selectedColor = color.value;
    this.customColorControl.setValueFrom(this.selectedColor);

    this.updateControl(
      false,
      this.colors.findIndex((c) => c.id === color.id)
    );
  }

  private updateControl(isCustomColor: boolean, colorIndex?: number) {
    this.control.patchValue({
      color: isCustomColor ? this.selectedColor : null,
      colorIndex: isCustomColor ? null : colorIndex,
    });
  }
}
