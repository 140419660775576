import { ColorAsset, FontAsset } from '../../../../api/workflow.interfaces';
import { Component, Input } from '@angular/core';
import { LayerService, UiControl } from '../../services/layer.service';
import { map, take } from 'rxjs/operators';

import { AssetsFacade } from '../../../store/facades/assets.facade';
import { FormGroup } from '@angular/forms';
import { ProjectFacade } from '../../../store/facades/project.facade';

@Component({
  selector: 'openreel-wf-layer-item-text',
  templateUrl: './layer-item-text.component.html',
})
export class LayerItemTextComponent {
  @Input() uiControl: UiControl;
  @Input() control: FormGroup;
  @Input() enableFontSelection = true;
  @Input() appearance: 'standard' | 'fill' | 'outline' = 'standard';

  colors$ = this.assetsFacade.colors$;
  fonts$ = this.assetsFacade.fonts$;
  isAnyBrandKitSelected$ = this.projectFacade.data$.pipe(
    map(({ data }) => !!data.brandKitId)
  );

  constructor(
    private readonly layerService: LayerService,
    private readonly assetsFacade: AssetsFacade,
    private readonly projectFacade: ProjectFacade
  ) {}

  onColorChange(color: ColorAsset) {
    this.colors$.pipe(take(1)).subscribe((colors) => {
      this.control.patchValue({
        colorIndex: colors.findIndex((c) => c.id === color.id),
      });
    });
  }

  onFontChange(font: FontAsset) {
    this.fonts$.pipe(take(1)).subscribe((fonts) => {
      this.control.patchValue({
        fontIndex: fonts.findIndex((f) => f.id === font.id),
      });
    });
  }

  validationErrors = () =>
    this.layerService.getValidationErrors(this.control.errors);
}
