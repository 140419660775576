import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import {
  Layer,
  LayerOptions,
  LottieLayer,
  Timeline,
  TimelineType,
  VideoLayer,
  WorkflowDataDto,
} from '../../../api/workflow.interfaces';

import { Injectable } from '@angular/core';
import {
  timelineItemFactory,
  TimelineTextItem,
  watermarkItemFactory,
} from '../../shared/element.interfaces';
import { TimelinesFacade } from '../../timelines/store/facades/timelines.facade';
import { Router } from '@angular/router';
import { ProjectFacade } from '../../store/facades/project.facade';
import { first } from 'rxjs/operators';
import { TemplateSettingsFacade } from '../../store/facades/template-settings.facade';

@Injectable({ providedIn: 'root' })
export class WorkflowReaderService {
  static getWatermarkLayer(source: WorkflowDataDto): TimelineTextItem | null {
    const section = source.sections.main;
    const hiddenTimeline = section?.timelines.find((t) => t.type === 'hidden');
    const layer = hiddenTimeline?.layers.find(
      (l) => l.layerId === 'watermark'
    ) as LayerOptions & LottieLayer;

    if (layer) {
      const asset = source.assets.find((a) => a.id === layer.assetId);
      return new TimelineTextItem(layer, asset, null);
    } else {
      const newLayer: Layer = {
        layerId: 'watermark',
        visibility: {
          startAt: 0,
        },
        data: {},
        ...source.features.watermark.properties,
      };

      const timelineTextItem = watermarkItemFactory(newLayer, source);

      const preset = timelineTextItem.preset;
      for (const presetKey in preset) {
        if (presetKey) {
          let assetId = null;
          if (preset[presetKey].type === 'logo') {
            assetId = source.assets.find((a) => a.id === 'watermark-logo')?.id;
            if (!assetId) {
              assetId = source.assets.find((a) => a.isLogoAsset)?.id;
            }
          }

          newLayer.data[presetKey] = {
            type: preset[presetKey].type,
            value: preset[presetKey].defaultValue,
            assetId: assetId,
          };
        }
      }

      return timelineTextItem;
    }
  }

  static getTimelineData(timeline: Timeline, workflow: WorkflowDataDto) {
    const data = [];
    timeline.layers.forEach(
      (layer: LayerOptions & (VideoLayer | LottieLayer)) => {
        data.push(timelineItemFactory(layer, timeline.type, workflow));
      }
    );

    return data;
  }

  static getEmptyCaption(type: TimelineType) {
    switch (type) {
      case 'main':
        return 'Add Main Clip';
      case 'b-roll':
        return 'Add Video Overlay';
      case 'overlays':
        return 'Add Text Overlay';
    }
  }

  static getCssClass(type: TimelineType) {
    switch (type) {
      case 'main':
        return 'main-clip';
      case 'b-roll':
        return 'video-overlay';
      case 'overlays':
        return 'text-overlay';
    }
  }

  constructor(
    private readonly timelinesFacade: TimelinesFacade,
    private readonly templateSettings: TemplateSettingsFacade,
    private readonly router: Router,
    private readonly projectFacade: ProjectFacade
  ) {}

  getTimelines(source: WorkflowDataDto) {
    const timelineDefinitions = this.templateSettings.getTimelines(source);

    return timelineDefinitions.map((timelineDef) => {
      const {
        timeline,
        parentLayer,
      } = this.templateSettings.getTimelineFromWorkflow(
        source,
        timelineDef.type,
        timelineDef.options.timelineIndex
      );

      return {
        type: timelineDef.type,
        options: {
          timelineIndex: timelineDef.options.timelineIndex,
          parentLayer,
        },
        fixed: this.templateSettings.isTimelineFixed(timelineDef.type),
        addMenuItems: this.getAddMenuItems(timelineDef.type, source),
        data: WorkflowReaderService.getTimelineData(timeline, source),
        cssClass: WorkflowReaderService.getCssClass(timelineDef.type),
        emptyCaption: WorkflowReaderService.getEmptyCaption(timelineDef.type),
      } as TimelineInterfaces.Timeline;
    });
  }

  getIntroOutroLayer(
    source: WorkflowDataDto,
    intro: boolean
  ): TimelineTextItem {
    const section = source.sections[intro ? 'intro' : 'outro'];
    const mainTimeline = section.timelines.find((t) => t.type === 'main');

    const layer = mainTimeline.layers[0] as LayerOptions & LottieLayer;
    const asset = source.assets.find((a) => a.id === layer.assetId);

    return new TimelineTextItem(layer, asset, null);
  }

  private getAddMenuItems(timelineType: TimelineType, source: WorkflowDataDto) {
    switch (timelineType) {
      case 'main':
        return this.getMainClipsMenuItems();
      case 'b-roll':
        return this.getVideoOverlaysMenuItems();
      case 'overlays':
        return this.getTextOverlayMenuItems(source);
    }
  }

  private getMainClipsMenuItems() {
    return [
      {
        title: 'My Folder',
        icon: 'folder',
        description: 'Select an existing clip from my files',
        visible: true,
        clickFn: () => this.timelinesFacade.openSelectFilesDialog('main'),
      },
      {
        title: 'Record a video',
        icon: 'record',
        description: 'Record a new video to place as a main video',
        visible: true,
        clickFn: () => {
          let projectId = null;
          this.projectFacade.id$
            .pipe(first())
            .subscribe((id) => (projectId = id));
          this.router.navigate(['project', projectId, 'recording']);
        },
      },
    ];
  }

  private getVideoOverlaysMenuItems() {
    return [
      {
        title: 'My Folder',
        icon: 'folder',
        description: 'Select an existing clip from my files',
        visible: true,
        clickFn: () => this.timelinesFacade.openSelectFilesDialog('b-roll'),
      },
      {
        title: 'Record a video',
        icon: 'record',
        description: 'Record a new video to place as a main video',
        visible: false,
        clickFn: () => alert('Not Implemented'),
      },
    ];
  }

  private getTextOverlayMenuItems(workflow: WorkflowDataDto) {
    const menuItems = [];

    workflow.features.textOverlays
      .filter((t) => t.enabled)
      .forEach((textOverlay) =>
        menuItems.push({
          title: textOverlay.title,
          icon: textOverlay.icon,
          description: textOverlay.description,
          visible: true,
          clickFn: () => this.timelinesFacade.addTextOverlay({ textOverlay }),
        })
      );

    return menuItems;
  }
}
