<ng-container *ngIf="isWorkflowLoaded$ | async">
  <openreel-wf-sidebar *ngIf="showSidebar$ | async"></openreel-wf-sidebar>
  <openreel-wf-header
    class="flex-none"
    (saveAndClose)="saveAndClose()"
  ></openreel-wf-header>
  <div
    class="content flex-auto flex flex-justify-center overflow-y-auto"
    [class.has-sidebar]="showSidebar$ | async"
  >
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</ng-container>
<ng-container *ngIf="!(isWorkflowLoaded$ | async)">
  <div class="h-full flex flex-align-center flex-justify-center">
    <openreel-wf-loader size="large"></openreel-wf-loader>
  </div>
</ng-container>
