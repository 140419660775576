export class TimelinesPlayerData {
  overlays?: ControllerData[][];
  mainPlayers: ControllerData[];

  constructor(partial: Partial<TimelinesPlayerData>) {
    Object.assign(this, partial);
  }
}

export class VideoPlayerData {
  source: string;
  type: string;
  startAt?: number;
  endAt?: number;
  muted?: boolean;
  styling?: {
    borderRadius?: number;
    backgroundColor?: string;
  };

  constructor(partial: Partial<VideoPlayerData>) {
    Object.assign(this, partial);
  }
}

export class LottiePlayerData {
  source?: string;
  content?: unknown;
  renderer: 'svg' | 'canvas' | 'html';
  duration?: number;

  constructor(partial: Partial<PlayerData>) {
    Object.assign(this, partial);
  }
}

export class FabricPlayerData {
  constructor(partial: Partial<FabricPlayerData>) {
    Object.assign(this, partial);
  }
}

export class ImagePlayerData {
  source: string;
  position?: Position;
  size?: Size;

  constructor(partial: Partial<ImagePlayerData>) {
    Object.assign(this, partial);
  }
}

export type PlayerData =
  | TimelinesPlayerData
  | VideoPlayerData
  | LottiePlayerData
  | FabricPlayerData;

export interface ControllerData {
  playerData: PlayerData;
  cue?: CueData;
  transitions?: Transitions;
  layout: Layout | 'fullscreen';
}

export interface Layout {
  position: Position;
  size: Size;
}

export interface CueData {
  startAt: number;
  endAt?: number;
  repeatableEvery?: number;
}

export type TransitionType = 'fade-out' | 'fade-in';

export interface Transitions {
  entrance?: Transition;
  exit?: Transition;
  cross?: Transition;
}

export interface Transition {
  type: TransitionType;
  duration: number;
}

export interface Position {
  x: number;
  y: number;
}

export interface Size {
  width: number;
  height: number;
}
