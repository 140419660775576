import { createSelector } from '@ngrx/store';
import { selectTimelinesFeatureState, TimelinesFeatureState } from '..';
import { Timeline } from '../interfaces/timelines.interfaces';
import { TimelinesState } from '../reducers/timelines.reducer';

export const selectTimelinesState = createSelector(
  selectTimelinesFeatureState,
  (state: TimelinesFeatureState) => state.timelines
);

export const selectTimelines = createSelector(
  selectTimelinesState,
  (state: TimelinesState) => state.timelines
);

export const selectMainTimeline = createSelector(
  selectTimelines,
  (timelines: Timeline[]) => timelines.find((t) => t.type === 'main')
);

export const selectTextOverlayTimeline = createSelector(
  selectTimelines,
  (timelines: Timeline[]) => timelines.find((t) => t.type === 'overlays')
);

export const selectVideoOverlayTimeline = createSelector(
  selectTimelines,
  (timelines: Timeline[]) => timelines.find((t) => t.type === 'b-roll')
);

export const selectDuration = createSelector(
  selectTimelinesState,
  (state: TimelinesState) => state.duration
);

export const selectIntroDuration = createSelector(
  selectTimelinesState,
  (state: TimelinesState) => state.introDuration
);

export const selectOutroDuration = createSelector(
  selectTimelinesState,
  (state: TimelinesState) => state.outroDuration
);

export const selectTotalDuration = createSelector(
  selectDuration,
  selectIntroDuration,
  selectOutroDuration,
  (main, intro, outro) => main + intro + outro
);

export const selectAllDurations = createSelector(
  selectDuration,
  selectIntroDuration,
  selectOutroDuration,
  selectTotalDuration,
  (main, intro, outro, total) => ({
    main,
    intro,
    outro,
    total,
  })
);

export const selectSendToTrimmer = createSelector(
  selectTimelinesState,
  (state: TimelinesState) => state.sentToTrimmer
);
