import { Component } from '@angular/core';
import { TemplateSettingsFacade } from '../../../../store/facades/template-settings.facade';
import { ItemSelectService } from '../../../services/item-select.service';
import { ItemSelectPanesActionsService } from '../../../services/panes-actions.service';
import { PaneBaseComponent } from '../pane-base.component';

@Component({
  selector: 'openreel-wf-pane-clip-options',
  templateUrl: './pane-clip-options.component.html',
  styleUrls: ['./pane-clip-options.component.scss'],
})
export class PaneClipOptionsComponent extends PaneBaseComponent {
  constructor(
    protected readonly itemSelectService: ItemSelectService,
    protected readonly itemSelectPanesActions: ItemSelectPanesActionsService,
    public readonly templateSettings: TemplateSettingsFacade
  ) {
    super();
  }

  swapVideoSides() {
    if (this.itemSelectPanesActions.swapVideoSides(this.item, this.timeline)) {
      this.itemSelectService.clearItem();
    }
  }

  duplicateClip() {
    if (this.itemSelectPanesActions.duplicateClip(this.item, this.timeline)) {
      this.itemSelectService.clearItem();
    }
  }

  editTrim() {
    if (this.itemSelectPanesActions.editTrim(this.item, this.timeline)) {
      this.itemSelectService.clearItem();
    }
  }

  async replaceClip() {
    if (
      await this.itemSelectPanesActions.replaceClip(this.item, this.timeline)
    ) {
      this.itemSelectService.clearItem();
    }
  }
}
