import { createAction, props } from '@ngrx/store';
import { TrimGroup, VideoSplit } from '../interfaces/trimmer.interfaces';

const actionType = '[Timelines, Trimmer]';

export const trimCleanup = createAction(`${actionType} Trim Cleanup`);

export const trimClipGroups = createAction(
  `${actionType} Trim/Split Clip Groups`,
  props<{
    allowSplits?: boolean;
    trimGroups: TrimGroup[];
  }>()
);

export const trimClipGroupsDone = createAction(
  `${actionType} Trim/Split Clip Groups - Group Done`,
  props<{ splits: VideoSplit[] }>()
);

export const trimClipGroupsFinished = createAction(
  `${actionType} Trim/Split Clips Groups - Fiished`
);
