import { AdvancedMediaSettings } from '../interfaces/advanced-media-settings.interface';
import {
  AdvancedMediaTrackConstraints,
  AdvancedMediaTrackSupportedConstraints,
} from '../interfaces/advanced-mediatrack-constraints.interface';

declare global {
  interface MediaDevices {
    getDisplayMedia?(
      constraints?: MediaStreamConstraints
    ): Promise<MediaStream>;
  }
}

export const getSupportedConstraints = (): AdvancedMediaTrackSupportedConstraints =>
  navigator.mediaDevices.getSupportedConstraints();

export const getTrackSettings = (
  track: MediaStreamTrack
): AdvancedMediaSettings =>
  track?.getSettings() || ({} as AdvancedMediaSettings);

export const stopStream = (stream: MediaStream) => {
  if (!stream) {
    return;
  }

  stream.getTracks().forEach((track) => {
    track.stop();
  });
};

export const openAudioStream = async (
  deviceId?: string
): Promise<MediaStream> => {
  const audio = deviceId ? { deviceId } : true;
  return await navigator.mediaDevices.getUserMedia({
    audio,
    video: false,
  });
};

export const openVideoStream = async (
  constraints?: AdvancedMediaTrackConstraints
): Promise<MediaStream> => {
  console.log('[openVideoStream] Get User Media', constraints);
  delete constraints.advanced;

  if (
    constraints.deviceId &&
    (typeof constraints.deviceId === 'string' ||
      constraints.deviceId instanceof String)
  ) {
    constraints.deviceId = {
      exact: constraints.deviceId as string,
    };
  }
  
  return await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: constraints ? constraints : true,
  });
};

export const startStream = async (
  constraints?: MediaStreamConstraints
): Promise<MediaStream> =>
  await navigator.mediaDevices.getUserMedia(constraints);

// To override with 240 for debugging:
// await navigator.mediaDevices.getUserMedia({
//   audio: false,
//   video: {
// height: 240
// }});

export const supportsScreenSharing = (): boolean =>
  !!navigator.mediaDevices.getDisplayMedia;

export const openScreenSharingStream = async (
  constraints: MediaTrackConstraints | boolean = {}
): Promise<MediaStream> =>
  await navigator.mediaDevices.getDisplayMedia({
    audio: false,
    video: true,
  });

export const applyConstraints = async (
  track: MediaStreamTrack,
  deviceId: string,
  constraints: AdvancedMediaTrackConstraints = {}
): Promise<void> => {
  console.log('[applyConstraints]', constraints);
  return await track.applyConstraints({
    deviceId,
    ...constraints,
  });
};
