import { Editor } from './../interfaces/editor.interface';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';

const editorRootState = (state: AppState) => state.editor;

export const selectSteps = createSelector(
  editorRootState,
  ({ steps }) => steps
);

export const selectEditor = createSelector(
  editorRootState,
  (editor: Editor) => editor
);

export const selectCurrentStep = createSelector(
  editorRootState,
  (editor: Editor) => ({
    step: editor?.step,
    childStep: editor?.childStep,
  })
);

export const selectMaxStep = createSelector(
  editorRootState,
  (editor: Editor) => editor?.maxStep
);

export const selectLastActionSaved = createSelector(
  editorRootState,
  (editor: Editor) => editor?.lastActionSaved
);
