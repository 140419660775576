import * as fromActions from './../actions/editor.actions';
import * as fromProjectActions from '../actions/project.actions';

import { Editor } from './../interfaces/editor.interface';
import { createReducer, on } from '@ngrx/store';
import { DEFAULT_STEPS } from '../interfaces/steps.interface';

export const initialState: Editor = {
  steps: DEFAULT_STEPS,
  step: 0,
  childStep: 0,
  maxStep: 0,
  lastActionSaved: false,
};

const reducer = createReducer(
  initialState,

  on(fromActions.resetSteps, () => initialState),

  on(fromActions.setStepsForTemplate, (state, { templateType }) => ({
    ...state,
    steps:
      templateType === 'podcast'
        ? DEFAULT_STEPS.filter((step) => step.route !== 'style')
        : DEFAULT_STEPS,
  })),

  on(fromActions.setStep, (state, { step }) => {
    if (step > state.maxStep) {
      return { ...state };
    }
    if (step > state.steps[state.steps.length - 1].order) {
      return { ...state };
    }

    return { ...state, step, childStep: 0, lastActionSaved: false };
  }),

  on(fromActions.setChildStep, (state, { childStep }) => ({
    ...state,
    childStep,
  })),

  on(fromActions.nextStep, (state) => {
    if (state.step === state.steps[state.steps.length - 1].order) {
      return { ...state };
    }

    const currentStepIndex = state.steps.findIndex(
      (step) => step.order === state.step
    );
    const nextStep = state.steps[currentStepIndex + 1].order;

    return {
      ...state,
      step: nextStep,
      childStep: 0,
      maxStep: Math.max(state.maxStep, nextStep),
      lastActionSaved: true,
    };
  }),

  on(fromProjectActions.createProjectSuccess, () => ({
    ...initialState,
    lastActionSaved: true,
  })),

  on(fromProjectActions.loadProjectSuccess, (state, action) => ({
    ...initialState,
    lastActionSaved: true,
    step: action.data.step,
    childStep: 0,
    maxStep: action.data.step,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
