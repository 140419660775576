import { Component, Input, OnInit } from '@angular/core';
import { Cleanupable } from '../../../../../../../libs/common/src';
import { ClipSelectionFacade } from '../../store/facades/clip-selection.facade';

@Component({
  selector: 'openreel-wf-select-files-projects',
  templateUrl: './select-files-projects.component.html',
  styleUrls: ['./select-files-projects.component.scss'],
})
export class SelectFilesProjectsComponent
  extends Cleanupable
  implements OnInit {
  @Input() initialProjectId: number;

  projects$ = this.clipSelectionFacade.projects$;
  selectedProject$ = this.clipSelectionFacade.selectedProject$;

  constructor(private readonly clipSelectionFacade: ClipSelectionFacade) {
    super();
  }

  ngOnInit(): void {
    this.clipSelectionFacade.loadProjects();

    if (this.initialProjectId !== null) {
      this.selectProject(this.initialProjectId);
    }
  }

  get canGoBack() {
    return this.initialProjectId === null;
  }

  selectProject(projectId: number) {
    this.clipSelectionFacade.selectProject(projectId);
  }
}
