import * as fromActions from '../actions/trimmer.actions';
import * as fromSelectors from '../selectors/trimmer.selectors';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { VideoSplit } from '../interfaces/trimmer.interfaces';

@Injectable({
  providedIn: 'root',
})
export class TrimmerFacade {
  data$ = this.store.select(fromSelectors.selectTrimmerState);

  trimmerActive$ = this.data$.pipe(map((data) => data.trimGroups.length > 0));

  allowSplits$ = this.store.select(fromSelectors.selectAllowSplits);

  trimGroups$ = this.store.select(fromSelectors.selectTrimGroups);
  finished$ = this.store.select(fromSelectors.selectFinished);

  finishedDuration$ = this.trimGroups$.pipe(
    map((trimGroups) => {
      let duration = 0;
      trimGroups.forEach((group) => {
        group.splits.forEach((split) => {
          duration += split.videos.reduce(
            (prev, curr) => Math.max(prev, curr.trimTo - curr.trimFrom),
            0
          );
        });
      });
      return duration;
    })
  );

  constructor(private store: Store) {}

  trimClipGroupsDone(splits: VideoSplit[]) {
    this.store.dispatch(fromActions.trimClipGroupsDone({ splits }));
  }
}
