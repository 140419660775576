import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  LayerService,
  UiControl,
  UiControlType,
} from '../../services/layer.service';

import { AssetsFacade } from '../../../store/facades/assets.facade';
import { first, takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Cleanupable } from '../../../../../../../libs/common/src';
import {
  LayerDataChangedEvent,
  TimelineTextItem,
} from '../../../shared/element.interfaces';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

export interface LayerFormPresetOptions {
  newUI?: {
    enabled: boolean;
    fields?: 'color' | 'text';
  };
  enableFontSelection?: boolean;
}

const DEFAULT_FORM_OPTIONS: LayerFormPresetOptions = {
  newUI: {
    enabled: false,
  },
  enableFontSelection: true,
};

@Component({
  selector: 'openreel-wf-layer-form-preset',
  templateUrl: './layer-form-preset.component.html',
  styleUrls: ['./layer-form-preset.component.scss'],
})
export class LayerFormPresetComponent
  extends Cleanupable
  implements OnInit, OnChanges {
  @Input() item: TimelineTextItem;
  @Input() appearance: 'standard' | 'fill' | 'outline' | 'legacy' = 'legacy';
  @Input() options: LayerFormPresetOptions;

  @Output() valueChanges = new EventEmitter<LayerDataChangedEvent>();

  uiControls: UiControl[];
  repeatableControl: FormGroup;
  form = this.formBuilder.group({
    groups: this.formBuilder.array([]),
  });

  UiControlTypeEnum = UiControlType;

  @HostBinding('class.new-ui') newUI = false;

  protected formChangesListener: Subscription;

  constructor(
    protected readonly layerService: LayerService,
    protected readonly formBuilder: FormBuilder,
    protected readonly assetsFacade: AssetsFacade
  ) {
    super();
  }

  ngOnInit() {
    this.options = Object.assign({}, DEFAULT_FORM_OPTIONS, this.options);
    this.newUI = this.options.newUI.enabled;
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('item' in changes && !changes['item'].isFirstChange()) {
      this.createForm();
    }
  }

  get groupArray() {
    return this.form.controls.groups as FormArray;
  }

  getGroupControl(index: number, fieldName: string) {
    return (this.groupArray.at(index) as FormGroup).controls[fieldName];
  }

  isValid = () => this.form.valid;

  getData = () => this.layerService.toData(this.item, this.groupArray);

  protected getItemData = () => this.item.data;

  protected createForm() {
    if (this.formChangesListener) {
      this.formChangesListener.unsubscribe();
    }
    this.assetsFacade.resources$.pipe(first()).subscribe((resources) => {
      this.form.controls.groups = this.layerService.toFormArray(
        this.item.preset,
        this.getItemData(),
        resources
      );
      this.uiControls = this.layerService.toUiControls(this.item.preset);

      this.formChangesListener = this.groupArray.valueChanges
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.valueChanges.emit(this.getData()));
    });
  }
}
