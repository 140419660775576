import {
  EditorRoutingModule,
  routableComponents,
} from './editor-routing.module';

import { EditorPreviewerModule } from '../editor-previewer/editor-previewer.module';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { components } from './components';
import { LoaderModule } from '../shared/modules/loader/loader.module';
import {WorkflowProjectSocketService, WorkflowsSocketService} from '@openreel/common';

@NgModule({
  declarations: [...components, ...routableComponents],
  imports: [
    EditorPreviewerModule,
    ReactiveFormsModule,
    EditorRoutingModule,
    SharedModule,
    LoaderModule,
  ],
  exports: [],
  providers: [WorkflowsSocketService, WorkflowProjectSocketService],
})
export class EditorModule {}
