import * as fromActions from './../actions/project.actions';
import * as fromBrandKitsActions from './../actions/brand-kits.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';

import { AssetsFacade } from '../facades/assets.facade';
import { BrandKitsFacade } from '../facades/brand-kits.facade';
import { Injectable } from '@angular/core';
import { createStyleSheetLink } from '../../shared/helpers/document.helpers';

@Injectable()
export class AssetsEffects {
  downloadFontsOnProjectLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.loadProjectSuccess),
        switchMap(() => this.assetsFacade.fonts$),
        filter((fonts) => fonts?.length > 0),
        map((fonts) => {
          fonts.forEach((font) => {
            createStyleSheetLink(font.file.path as string);
          });
        })
      ),
    { dispatch: false }
  );

  downloadFontsOnBrandKitsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromBrandKitsActions.listBrandKitsSuccess),
        switchMap(() => this.brandKitsFacade.fonts$),
        filter((fonts) => fonts?.length > 0),
        map((fonts) => {
          fonts.forEach((font) => {
            createStyleSheetLink(font.file.path as string);
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private assetsFacade: AssetsFacade,
    private brandKitsFacade: BrandKitsFacade,
    private actions$: Actions
  ) {}
}
