export const drawVideoFrame = (
  context: CanvasRenderingContext2D,
  video: HTMLVideoElement
) => {
  context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
};

export const resizeToVideo = (
  canvas: HTMLCanvasElement,
  video: HTMLVideoElement
) => {
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
};

export const newCrossOriginVideoElement = (): HTMLVideoElement => {
  const videoElem = document.createElement('video');
  videoElem.setAttribute('crossorigin', 'anonymous');
  return videoElem;
};

export const newCanvasWithContext = (): {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
} => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  return { canvas, context };
};

export const createStyleSheetLink = (href: string): HTMLLinkElement => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  return document.getElementsByTagName('head')[0].appendChild(link);
};
