import * as fromStore from './store';

import { AppRoutingModule, routableComponents } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from './core/core.module';
import { EditorModule } from './editor/editor.module';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { TimelinesModule } from './timelines/timelines.module';
import { EditorItemSelectionModule } from './editor-item-selection/editor-item-selection.module';

const appModules = [
  EditorModule,
  SharedModule,
  TimelinesModule,
  EditorItemSelectionModule,
];

@NgModule({
  declarations: [AppComponent, ...routableComponents],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(fromStore.rootReducers),
    EffectsModule.forRoot(fromStore.effects),
    StoreDevtoolsModule.instrument({ logOnly: environment.production }),
    HttpClientModule,
    CoreModule,
    ...appModules,
    // Has to be last for catch all route to work
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
