export interface SimpleChatMessage {
  created_at: Date;
  receiver: number;
  sender: number;
  sender_name: string;
  text: string;
  id?: string;
}

export interface SingleChatMessage extends SimpleChatMessage {
  is_read: '0' | '1';
  thumb_image: string;
}

export interface SingleChatMessageSend {
  created_at: Date;
  sender: string;
  receiver: string;
  sender_name: string;
  sender_type: string;
  receiver_type: string;
  senderdetails: {
    fullname: string;
  };
  text: string;
  file: string;
  thumb_image: string;
}

export interface ChatTypingInfo {
  name: string;
  sender: number;
  receiver: number;
}

export interface ChatIdAndName {
  id: number;
  name: string;
}

export const CHAT_MESSAGE_EVT = 'chat_message';
export const CHAT_TYPING_EVT = 'is_typing';
