import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
enum DragAxis {
  'X' = 'x',
  'Y' = 'y',
}
interface DragPosition {
  x: number;
  y: number;
}
const MaxGain = 20;
const MinGain = -20;
const IconSize = 24;
@Component({
  selector: 'openreel-audio-gain-control',
  templateUrl: './audio-gain-control.component.html',
  styleUrls: ['./audio-gain-control.component.scss'],
})
export class AudioGainControlComponent {
  @Input() dragAxis: DragAxis;
  @Output() gainValueChange = new EventEmitter<number>();
  DragAxis = DragAxis;
  position: DragPosition = { x: 0, y: 0 };
  @ViewChild('gainWrapper') gainWrapper: ElementRef<HTMLDivElement>;
  width: number;
  height: number;
  currentGain: number;

  @Input() set gain(value: number) {
    if (value !== this.currentGain) {
      this.currentGain = value;
      this.setDragPosition();
    }
  }

  onResizeWrapper() {
    this.height = this.gainWrapper.nativeElement.offsetHeight - IconSize;
    this.width = this.gainWrapper.nativeElement.offsetWidth - IconSize;
    this.setDragPosition();
  }

  onDragEnd(event) {
    const position = event.source.getFreeDragPosition();
    let currentDragValue: number;
    if (this.dragAxis === DragAxis.Y) {
      currentDragValue = this.getVerticalDragValue(position.y);
    } else {
      currentDragValue = this.getHorizontalDragValue(position.x);
    }
    const value = Math.round(this.getActualValue(currentDragValue));
    this.currentGain = value;
    this.gainValueChange.emit(value);
  }
  setDragPosition() {
    if (!this.currentGain) this.currentGain = 0;
    const percentage = (this.currentGain - MinGain) / (MaxGain - MinGain);
    if (this.dragAxis === DragAxis.Y) {
      this.setVerticalDrag(percentage);
    } else {
      this.setHorizontalDrag(percentage);
    }
  }
  private getActualValue(value: number) {
    return value * (MaxGain - MinGain) + MinGain;
  }
  private getHorizontalDragValue(value: number) {
    return value / this.width;
  }
  private getVerticalDragValue(value: number) {
    return (this.height - value) / this.height;
  }

  private setVerticalDrag(percenatge: number) {
    const dragValue = this.height - this.height * percenatge;
    this.position = { x: 0, y: dragValue };
  }
  private setHorizontalDrag(percenatge: number) {
    const dragValue = this.width * percenatge;
    this.position = { x: dragValue, y: 0 };
  }
}
