<div
  class="clip-container h-full relative b-radius-8"
  (click)="onSelect()"
  [class.selected]="selected"
  (mouseover)="onMouseOver()"
  (mouseout)="onMouseOut()"
>
  <div
    class="thumbnail absolute inset-0 w-full h-full b-radius-8"
    [style.background-image]="recording.thumb | backgroundImageFallback"
    [hidden]="showPreview"
  ></div>
  <mat-icon
    *ngIf="selected"
    class="absolute mat-icon-16 text-color-primary z-10 top-8 right-8"
    svgIcon="check-round"
  ></mat-icon>
  <div class="info text-10 text-weight-300 flex flex-justify-between z-10">
    <mat-icon class="mat-icon-14" svgIcon="video-edit"></mat-icon>
    <div>
      <span>{{ recording.resolution }}</span>
      <span class="m-left-8">{{ recording.video_length | clipDuration }}</span>
    </div>
  </div>
  <video
    #videoPlayer
    class="b-radius-8 inset-0 w-full h-full absolute z-1"
    preload="auto"
    [muted]="'muted'"
    *ngIf="videoUrl"
    [hidden]="!showPreview"
  >
    <source
      [src]="sanitizer.bypassSecurityTrustResourceUrl(videoUrl)"
      [type]="'video/mp4'"
    />
  </video>
</div>
