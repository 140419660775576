import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { TimelineTextItem } from '../../../../shared/element.interfaces';
import {
  ItemSelectPanesActionsService,
  SAVE_DEBOUNCE_TIME,
} from '../../../services/panes-actions.service';
import { PaneBaseComponent } from '../pane-base.component';

@Component({
  selector: 'openreel-wf-pane-overlay-duplicate',
  templateUrl: './pane-overlay-duplicate.component.html',
  styleUrls: ['./pane-overlay-duplicate.component.scss'],
})
export class PaneOverlayDuplicateComponent
  extends PaneBaseComponent<TimelineTextItem>
  implements OnInit, OnChanges, AfterViewInit {
  form: FormGroup;

  private minDuration: number;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly itemSelectPanesActions: ItemSelectPanesActionsService
  ) {
    super();
  }

  ngOnInit() {
    this.createForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('item' in changes && !changes['item'].isFirstChange()) {
      this.createForm();
    }
  }

  ngAfterViewInit() {
    this.form.valueChanges
      .pipe(
        debounceTime(SAVE_DEBOUNCE_TIME),
        filter((_) => this.form.valid)
      )
      .subscribe((values) => {
        const formData = { ...values };
        formData.duration = !isNaN(Number(formData.duration))
          ? Math.max(this.minDuration, Number(formData.duration))
          : this.minDuration;

        return this.itemSelectPanesActions.saveTextMetadata(
          this.item,
          this.timeline,
          this.secToMs(formData.duration),
          formData.repeatableEvery === 0 ? null : formData.repeatableEvery
        );
      });
  }

  private createForm() {
    let repeatableEvery = 0;
    if (this.item.template.repeatabilityOptions) {
      repeatableEvery = this.item.layer.visibility?.repeatableEvery ?? 0;
    }

    this.minDuration = this.msToSec(this.item.asset.data.duration);

    this.form = this.formBuilder.group({
      duration: [this.msToSec(this.item.duration), [Validators.required]],
      repeatableEvery: [repeatableEvery, [Validators.required]],
    });
  }

  private msToSec = (ms: number) => ms / 1000;
  private secToMs = (sec: number) => sec * 1000;
}
