import { LayerOptions, LottieLayer } from '../../../api/workflow.interfaces';
import { BaseCommand } from './base.command';
import { TimelineItem, timelineItemFactory } from '../element.interfaces';
import { UpdateTextOverlayMetadataEvent } from '../../timelines/store/interfaces/timelines.interfaces';

export class UpdateTextMetadataCommand extends BaseCommand<
  UpdateTextOverlayMetadataEvent,
  TimelineItem
> {
  run(event: UpdateTextOverlayMetadataEvent) {
    const timeline = this.getTimeline('overlays');

    const layerToUpdate = timeline.layers.find(
      (l) => l.layerId === event.item.layerId
    ) as LayerOptions & LottieLayer;

    layerToUpdate.visibility = {
      ...layerToUpdate.visibility,
      endAt: layerToUpdate.visibility.startAt + event.duration,
      repeatableEvery: event.repeatability,
    };

    return {
      result: timelineItemFactory(layerToUpdate, 'overlays', this.source),
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
