import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { EditorFacade } from '../../../store/facades/editor.facade';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { headerAnimation } from './header.animations';

@Component({
  selector: 'openreel-wf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [headerAnimation],
})
export class HeaderComponent {
  @Output() saveAndClose = new EventEmitter<void>();

  title$: Observable<string>;
  saved$: Observable<boolean>;

  constructor(
    private readonly editorFacade: EditorFacade,
    private readonly projectFacade: ProjectFacade
  ) {
    this.title$ = this.projectFacade.projectName$;
    this.saved$ = this.editorFacade.saved$;
  }

  save() {
    this.saveAndClose.emit();
  }
}
