import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AddTokenInterceptor,
  ApiUrlInterceptor,
  ILocalRecorderService,
  LocalRecorderService,
  ResponseParserInterceptor,
} from '@openreel/common';
import { ProjectLoadedGuard } from './guards/project-loaded.guard';
import { CaseConverterInterceptor } from './interceptors/case-converter.interceptor';

const guards = [ProjectLoadedGuard];

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [],
  providers: [
    ...guards,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseParserInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CaseConverterInterceptor,
      multi: true,
    },
    {
      provide: ILocalRecorderService,
      useFactory: () => new LocalRecorderService(),
      deps: [],
    }
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule has already been loaded. Core module should only be imported in the AppModule.'
      );
    }
  }
}
