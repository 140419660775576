import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Cleanupable } from '../../../classes';
import { AudioDevice, VideoDevice } from '../../../media';

export enum RecordingState {
  IDLE,
  STARTING_RECORDING,
  RECORDING,
  STOPPING_RECORDING,
}

// is the raw recording process. There may be different implementations of the
// recorder service. It can provide you with raw data that is recorded.
// It is also "filename-based" meaning that, it should be able to recover any
// previous video recorded with that particular name. You can delete any
// previous videos as well.
export abstract class ILocalRecorderService extends Cleanupable
  implements OnDestroy {
  lastFileName: string;
  lastFileResolution: number;
  recordingState$ = new BehaviorSubject<RecordingState>(RecordingState.IDLE);
  recordingStartedAt$ = new BehaviorSubject<Date>(null);
  lastError$ = new BehaviorSubject<Error>(null);

  constructor() {
    super();
    this.recordingState$.subscribe((state) => {
      console.log('Recording state is now: ' + RecordingState[state]);
    });
    window.addEventListener('beforeunload', () => {
      this.ngOnDestroy();
    });
  }

  async ngOnDestroy() {
    super.ngOnDestroy();
    await this.stopRecording();
  }

  async startRecordingStream(stream: MediaStream) {
    // first try to stop recording
    await this.stopRecording();
    this.lastError$.next(null);
    this.lastFileName = await this.getNewFileName();
    this.recordingStartedAt$.next(new Date());
    this.recordingState$.next(RecordingState.STARTING_RECORDING);
    await this.doStartRecordingStream(stream);
    await this.waitForState(RecordingState.RECORDING);
  }

  async startRecording(
    resolution: number,
    fps: number,
    audioDevice: AudioDevice,
    videoDevice: VideoDevice
  ) {
    // first try to stop recording
    await this.stopRecording();
    this.lastError$.next(null);
    this.lastFileResolution = resolution;
    this.lastFileName = await this.getNewFileName();
    this.recordingStartedAt$.next(new Date());
    this.recordingState$.next(RecordingState.STARTING_RECORDING);
    await this.doStartRecording(fps, audioDevice, videoDevice);
    await this.waitForState(RecordingState.RECORDING);
  }
  async stopRecording() {
    if (this.recordingState$.value === RecordingState.RECORDING) {
      this.recordingStartedAt$.next(null);
      this.recordingState$.next(RecordingState.STOPPING_RECORDING);
      await this.doStopRecording();
      await this.waitForState(RecordingState.IDLE);
    }
  }
  protected async waitForState(requestedState: RecordingState) {
    // Wait for recording state to be idle
    await this.recordingState$
      .pipe(
        filter((state) => state === requestedState),
        take(1)
      )
      .toPromise();
  }
  protected abstract doStartRecording(
    fps: number,
    audioDevice: AudioDevice,
    videoDevice: VideoDevice
  );
  protected abstract doStartRecordingStream(stream: MediaStream);
  protected abstract getNewFileName(): Promise<string>;
  protected abstract doStopRecording();
  abstract getFileSizeMB(): Promise<number>;
  abstract getFileLengthSeconds(): Promise<number>;
  abstract getFileData(localFileName: string): Promise<Blob>;
  abstract removeFileData(localFileName: string): Promise<void>;
  abstract getFileNameForUpload(): string;
}
