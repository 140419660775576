<input
  #fileInput
  [hidden]="true"
  type="file"
  accept=".png,.jpg,.jpeg"
  (change)="closeFileDialog($event)"
/>
<div
  class="container relative w-full h-full flex flex-align-center flex-justify-center"
  [class.dark]="darkBackround"
>
  <div class="background absolute inset-0 b-radius-8"></div>
  <button
    *ngIf="!isUploading && !isUploaded"
    type="button"
    class="z-1 w-full h-full flex-col flex-align-center flex-justify-center"
    (click)="selectImage()"
  >
    <div>{{ uploadCaption }}</div>
  </button>
  <div
    *ngIf="isUploading"
    class="z-1 flex-col flex-align-center flex-justify-center"
  >
    <div class="m-bottom-8 f-light-14">Uploading...</div>
    <div class="progress-bar-container b-radius-4">
      <div
        class="progress-bar b-radius-4"
        [ngStyle]="{ width: uploadProgress + 'px' }"
      ></div>
    </div>
  </div>
  <div
    *ngIf="isUploaded"
    class="z-1 uploaded flex-col flex-align-center flex-justify-center"
  >
    <img [openreelAssetImage]="sourceId" alt="Uploaded Image" />
    <button type="button" (click)="removeImage()">
      <mat-icon svgIcon="close" class="mat-icon-14"></mat-icon>
    </button>
  </div>
</div>
