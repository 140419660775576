import * as fromActions from './../actions/timelines.actions';
import * as fromProjectActions from './../../../store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { map, withLatestFrom } from 'rxjs/operators';
import { PreviewFacade } from '../../../store/facades/preview.facade';
import { getIntroOutroDurations } from '../helpers/timelines.helpers';
import { MatDialog } from '@angular/material/dialog';
import {
  SelectFilesDialogComponent,
  SelectFilesDialogResult,
} from '../../../select-files/components/select-files-dialog/select-files-dialog.component';
import { TimelinesFacade } from '../facades/timelines.facade';
import { ClipAddEvent } from '../interfaces/timelines.interfaces';
import { WorkflowReaderService } from '../../../core/services/workflow-reader.service';
import { ClipTypes } from '../../../select-files/store/interfaces/clip-selection.interfaces';
import { ProjectFacade } from '../../../store/facades/project.facade';

@Injectable()
export class TimelinesEffects {
  loadTimelines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromProjectActions.loadProjectSuccess,
        fromProjectActions.updateProjectSuccess
      ),
      withLatestFrom(
        this.previewFacade.draftData$.pipe(map((p) => p?.workflow))
      ),
      map(([_, workflow]) => {
        const timelines = this.workflowReaderService.getTimelines(workflow);
        const [introDuration, outroDuration] = getIntroOutroDurations(workflow);

        return fromActions.loadTimelinesSuccess({
          timelines,
          introDuration,
          outroDuration,
        });
      })
    )
  );

  openSelectFilesDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.openSelectFilesDialog),
        withLatestFrom(this.projectFacade.captureProjectId$),
        map(([{ timelineType }, captureProjectId]) => {
          const dialogRef = this.dialog.open(SelectFilesDialogComponent, {
            panelClass: 'no-p',
            data: {
              hideSidebar: true,
              selectedType: {
                type: ClipTypes.MyProjects,
                selectedProjectId: captureProjectId,
              },
            },
          });

          dialogRef
            .afterClosed()
            .subscribe((result: SelectFilesDialogResult) => {
              if (!result || result.selectedRecordings.length === 0) {
                return;
              }

              const selection = result.selectedRecordings.map(
                (r): ClipAddEvent => ({
                  assetFileId: r.ovra_session_videos_id,
                  assetProviderType: 'or-recordings',
                  duration: r.video_length * 1000,
                  name: r.video_name,
                })
              );

              if (timelineType === 'main') {
                this.timelinesFacade.addMainClip(selection);
              } else {
                this.timelinesFacade.addVideoOverlay(selection);
              }
            });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private previewFacade: PreviewFacade,
    private projectFacade: ProjectFacade,
    private timelinesFacade: TimelinesFacade,
    private dialog: MatDialog,
    private workflowReaderService: WorkflowReaderService
  ) {}
}
