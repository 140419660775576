import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { commonenv } from '../../environments/environment';
import { Recording } from '../../interfaces/interfaces';
import { ProjectLevelSessionSettings } from '../../interfaces/settings.interface';
import {
  CreateProjectData,
  CreateProjectRequest,
  CreateProjectResponse,
  ProjectData,
  ProjectDetails,
  ProjectDetailsResponse,
  ProjectListRequest,
  ProjectListResponse,
  ProjectRecordingsResponse,
  ProjectUpdateRequest,
  ProjectUpdateResponse,
} from './project.interface';

const throwIfUnsuccessful = <T>(
  request$: Observable<{ status: number; message: string; data?: T }>
): Observable<T> =>
  request$.pipe(
    map((project) => {
      if (project.status === 1) {
        return project.data;
      } else {
        throw new Error(project.message);
      }
    })
  );

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  public urls = {
    projectCreate: `${commonenv.nextGenApiUrl}projects/create`,
    projectList: `${commonenv.nextGenApiUrl}projects`,
    projectDetails: `${commonenv.nextGenApiUrl}projects/details`,
    projectUpdate: `${commonenv.nextGenApiUrl}projects/update`,
    getProjectLevelSessionSettings: (projectId) =>
      `${commonenv.nextGenApiUrl}projects/${projectId}/session-settings`,
    getProjectRecordings: (projectId) =>
      `${commonenv.nextGenApiUrl}projects/${projectId}/recordings`,
    getProjectFavouriteRecordings: (projectId) =>
      `${commonenv.nextGenApiUrl}projects/${projectId}/favourite-recordings`,
    getProjectDetails: (projectId) =>
      `${commonenv.nextGenApiUrl}projects/details/?project_id=${projectId}`,
  };

  constructor(public httpClient: HttpClient) {}

  createProject(data: CreateProjectRequest): Observable<CreateProjectData> {
    return throwIfUnsuccessful(
      this.httpClient.post<CreateProjectResponse>(this.urls.projectCreate, data)
    );
  }

  getProjectList({
    limit,
    page,
    orderField,
    orderSort,
    searchString,
    startDate,
    endDate,
  }: ProjectListRequest): Observable<Array<ProjectData>> {
    const params: {
      [param: string]: string | string[];
    } = {
      limit: limit.toString(),
      page: page.toString(),
      order_field: orderField,
      order_sort: orderSort,
    };

    if (searchString) {
      params.projectName = searchString;
    }

    if (startDate && endDate) {
      params.start_date = startDate;
      params.end_date = endDate;
    }

    return throwIfUnsuccessful(
      this.httpClient.get<ProjectListResponse>(this.urls.projectList, {
        params,
      })
    );
  }

  getProjectDetails(id: number): Observable<ProjectDetails> {
    return throwIfUnsuccessful(
      this.httpClient.get<ProjectDetailsResponse>(
        this.urls.getProjectDetails(id)
      )
    );
  }

  updateProject(data: ProjectUpdateRequest): Observable<void> {
    return throwIfUnsuccessful(
      this.httpClient.put<ProjectUpdateResponse>(this.urls.projectUpdate, data)
    );
  }

  getProjectLevelSessionSettings(
    projectId: number
  ): Promise<ProjectLevelSessionSettings> {
    return this.httpClient
      .get<ProjectLevelSessionSettings>(
        this.urls.getProjectLevelSessionSettings(projectId)
      )
      .toPromise();
  }

  updateProjectLevelSessionSettings(
    projectId: number,
    payload: ProjectLevelSessionSettings
  ): Promise<ProjectLevelSessionSettings> {
    return this.httpClient
      .post<ProjectLevelSessionSettings>(
        this.urls.getProjectLevelSessionSettings(projectId),
        payload
      )
      .toPromise();
  }

  getProjectRecordings(
    projectId: number,
    params: HttpParams
  ): Promise<ProjectRecordingsResponse> {
    return this.httpClient
      .get<ProjectRecordingsResponse>(
        this.urls.getProjectRecordings(projectId),
        { params }
      )
      .toPromise();
  }

  getProjectFavouriteRecordings(
    projectId: number,
    params: HttpParams
  ): Promise<Recording[]> {
    return this.httpClient
      .get<Recording[]>(this.urls.getProjectFavouriteRecordings(projectId), {
        params,
      })
      .toPromise();
  }
}
