import * as fromActions from './../actions/timelines.actions';
import * as fromProjectActions from '../../../store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { TimelinesFacade } from '../facades/timelines.facade';
import { WorkflowBuilder } from '../../../shared/builders/workflow.builder';
import { cloneDeep } from 'lodash-es';
import { TemplateSettingsFacade } from '../../../store/facades/template-settings.facade';
import { updateTimelineData } from '../helpers/effects.helpers';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { WorkflowSteps } from '../../../store/interfaces/steps.interface';
import { UpdateVideoOverlayPipCommand } from '../../../shared/builders/update-video-overlay-pip.command';

@Injectable()
export class OverlaysEffects {
  changeVideoOverlayType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changeVideoOverlayType),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ event }, workflow]) => {
        const { result, updatedWorkflow } = new UpdateVideoOverlayPipCommand(
          workflow
        ).run(event);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelineItems({
            items: [{ timelineIndex: 0, item: result }],
          }),
        ];
      })
    )
  );

  moveOverlay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.moveItem),
      filter(({ event }) => event.item.type !== 'main'),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ event }, workflow]) => {
        const workflowWriter = new WorkflowBuilder(workflow);
        const updatedItem = workflowWriter.moveOverlay(event, event.item.type);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: workflowWriter.toWorkflow(),
          }),
          fromActions.updateTimelineItems({ items: [updatedItem] }),
        ];
      })
    )
  );

  removeOverlay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.removeOverlay),
      withLatestFrom(
        this.timelinesFacade.timelines$,
        this.projectFacade.workflow$
      ),
      switchMap(([{ event }, timelines, workflow]) => {
        const workflowWriter = new WorkflowBuilder(workflow);
        workflowWriter.removeOverlay(event);

        const workflowAfterUpdate = workflowWriter.toWorkflow();

        const updatedTimelines = cloneDeep(timelines).filter(
          (t) => t.type === event.timeline.type
        );
        updateTimelineData(
          updatedTimelines,
          workflowAfterUpdate,
          this.templateSettings
        );

        return [
          fromActions.updateTimelines({ updatedTimelines }),
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: workflowWriter.toWorkflow(),
          }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly timelinesFacade: TimelinesFacade,
    private readonly projectFacade: ProjectFacade,
    private readonly templateSettings: TemplateSettingsFacade
  ) {}
}
