import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ClipSelectionFeatureState,
  featureKey,
  State,
} from '../interfaces/clip-selection.interfaces';

export const SelectClipSelectionFeatureState = createFeatureSelector<
  State,
  ClipSelectionFeatureState
>(featureKey);

export const selectOptions = createSelector(
  SelectClipSelectionFeatureState,
  (state: ClipSelectionFeatureState) => state.options
);

export const selectProjects = createSelector(
  SelectClipSelectionFeatureState,
  (state: ClipSelectionFeatureState) => state.projects
);

export const selectedProject = createSelector(
  SelectClipSelectionFeatureState,
  (state: ClipSelectionFeatureState) => state.selectedProjectId
);

export const selectRecordings = createSelector(
  SelectClipSelectionFeatureState,
  (state: ClipSelectionFeatureState) => state.recordings
);

export const selectedRecordings = createSelector(
  SelectClipSelectionFeatureState,
  (state: ClipSelectionFeatureState) => state.selectedRecordings
);
