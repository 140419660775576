import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { containsOther } from '../../utils';
import { newUnsupportedSource } from '../helpers/error.helper';
import { getTrackSettings, stopStream } from '../helpers/media-devices.helper';
import { isChrome } from '../helpers/ua.helper';
import { VideoConstraints } from '../interfaces/video-constraints.interface';
import {
  VideoDevice,
  VideoDeviceOptions,
} from '../interfaces/video-device.interface';
import { VideoSource } from '../interfaces/video-source.interface';
import { VideoStream } from '../interfaces/video-stream.interface';
import { MediaDevicesService } from './media-devices.service';
import { ScreenShareService } from './screenshare.service';
import { WebcamService } from './webcam.service';

/**
 * Service for enumerating, manipulating and acquiring video devices. This
 * service contains instance of currently acquired stream, and when you are
 * done with this service, you should call closeStream() function. Whenever you
 * need a stream, you can do so by getting through currentStream$ subject. At
 * the moment only one video stream can be manipulated, but in the future we
 * could extend this functionality to include multiple streams at the same
 * time, at the cost of huge refactoring of course.
 */
@Injectable()
export class VideoStreamService {
  /**
   * Currently acquired stream. Don't call .next manually on this subject,
   * instead you should use openStream(...) function.
   */
  video$ = new BehaviorSubject<VideoStream>(null);
  /**
   * An up-to-date list of video devices connected to the current machine.
   */
  devices$: Observable<VideoDevice[]>;
  /**
   * Currently acquired stream's constraints. This will be null only when there
   * is no stream running. This can however change in runtime. Don't call .next
   * on this subject manually, you should use use changeStreamParameters(...)
   * instead.
   */
  currentConstraints$ = new BehaviorSubject<VideoConstraints>(null);
  streams: VideoStream[] = [];

  constructor(
    protected readonly webcamService: WebcamService,
    protected readonly screenShareService: ScreenShareService,
    protected readonly mediaDevicesService: MediaDevicesService
  ) {
    this.devices$ = combineLatest([
      webcamService.devices$,
      screenShareService.devices$,
    ]).pipe(
      map(([cameraDevices, screenDevices]) => [
        ...cameraDevices,
        ...screenDevices,
      ])
    );
  }

  /**
   * Gets a list of all supported video sources.
   */
  getSupportedSources(): VideoSource[] {
    const ret = [VideoSource.WEBCAM];
    if (this.screenShareService.isSupported()) {
      ret.push(VideoSource.DESKTOP);
    }
    return ret;
  }

  /**
   * This is called from SessionConfig service, so don't call this manually,
   * unless you know what you are doing (refer to SessionConfigService for
   * details)
   * Open the stream with default parameters (this usually means in highest fps
   * and resolution available). Here we also test for all stream's capabilities
   * (such as fps, resolution, contrast, exposure, etc). If there is a stream
   * that is already opened, previous stream is closed.
   * @param device
   */
  async openStream({ source, id }: VideoDeviceOptions): Promise<VideoStream> {
    console.log('Opening new stream: ', id ? id : 'any');

    if (
      !id &&
      source === this.video$.value?.device.source &&
      this.isCurrentStreamActive()
    ) {
      console.log('A stream is already open and active');
      return null;
    } else if (
      id &&
      this.video$.value?.device.id === id &&
      this.video$.value?.device.source === source &&
      this.isCurrentStreamActive()
    ) {
      console.log(`Stream ${id} is already open and active`);
      return null;
    }

    if (source !== VideoSource.DESKTOP && source !== VideoSource.WEBCAM) {
      throw newUnsupportedSource('The requested source is not supported');
    }

    const stream = await this.doOpenStream({ source, id });
    this.updateStreamConstraints(stream.track);

    return stream;
  }

  closeStream() {
    if (this.streams.length) {
      console.log(`Closing stream ${this.video$.value.device.name}.`);
      this.stopActiveStream();
      this.video$.next(null);
      this.currentConstraints$.next(null);
    }
  }

  private stopActiveStream() {
    this.streams.forEach((stream) => {
      if (stream.stream.active) stopStream(stream.stream);
    });
    this.streams = [];
  }

  async changeStreamParameters(
    pConstraints: VideoConstraints
  ): Promise<VideoConstraints> {
    if (containsOther(this.currentConstraints$.value, pConstraints)) {
      console.log(
        'Attempted to set existing constraints:',
        this.currentConstraints$.value,
        pConstraints
      );
      return this.currentConstraints$.value;
    }

    console.log('New passed constraints', pConstraints);

    // await new Promise((resolve) => setTimeout(resolve, 1000));
    let currentStream = this.video$.value;
    if (!currentStream || !currentStream.stream.active) {
      throw new Error('Video stream not running');
    }

    let constraints = pConstraints;
    if (currentStream.device.source === VideoSource.WEBCAM) {
      constraints = {
        fps: constraints.fps,
        width: constraints.width,
        height: constraints.height,
        colorTemperature: constraints.colorTemperature,
      }
    }

    console.log('New constraints', constraints);

    // In chrome we'll need to create a new video stream
    if (isChrome() && currentStream.device.source === VideoSource.WEBCAM) {
      currentStream = await this.doOpenStream(
        currentStream.device,
        constraints
      );
    } else {
      await this.applyConstraintsToStream(currentStream, constraints);
    }

    return this.updateStreamConstraints(currentStream.track);
  }

  private async doOpenStream(
    { source, id }: VideoDeviceOptions,
    constraints?: VideoConstraints
  ): Promise<VideoStream> {
    try {
      this.stopActiveStream();
      const video =
        source === VideoSource.WEBCAM
          ? await this.webcamService.openStream(id, constraints)
          : await this.screenShareService.openStream(id, constraints);

      this.video$.next(video);
      this.streams.push(video);
    } catch (error) {
      console.error(error);
      this.video$.next({
        device: { id, source },
        error,
      });
    }

    return this.video$.value;
  }

  private async applyConstraintsToStream(
    video: VideoStream,
    constraints: VideoConstraints
  ) {
    if (video.device.source === VideoSource.WEBCAM) {
      await this.webcamService.applyConstraintsToStream(video, constraints);
    } else {
      await this.screenShareService.applyConstraintsToStream(
        video,
        constraints
      );
    }
  }

  private updateStreamConstraints(track?: MediaStreamTrack): VideoConstraints {
    let constraints: VideoConstraints = null;
    if (track) {
      const settings = getTrackSettings(track);
      console.log('Track settings: ', settings);
      constraints = {
        fps: Math.floor(settings?.frameRate || 0),
        height: settings?.height || 0,
        width: settings?.width || 0,
        exposure: settings?.exposureTime,
        iso: settings?.exposureCompensation,
        contrast: settings?.contrast,
        colorTemperature: settings?.colorTemperature,
      };
    }

    this.currentConstraints$.next(constraints);
    return constraints;
  }

  private isCurrentStreamActive() {
    return this.video$.value?.stream?.active;
  }
}
