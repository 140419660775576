import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cleanupable, Recording } from '../../../../../../../libs/common/src';
import { ClipSelectionFacade } from '../../store/facades/clip-selection.facade';

@Component({
  selector: 'openreel-select-files-projects-clips',
  templateUrl: './select-files-projects-clips.component.html',
  styleUrls: ['./select-files-projects-clips.component.scss'],
})
export class SelectFilesProjectsClipsComponent
  extends Cleanupable
  implements OnInit {
  @Input() canGoBack = true;
  @Output() back = new EventEmitter();

  selectedProject$ = this.clipSelectionFacade.selectedProject$;
  recordings$ = this.clipSelectionFacade.recordings$;

  private selectedRecordings: Recording[];

  constructor(private readonly clipSelectionFacade: ClipSelectionFacade) {
    super();
  }

  ngOnInit() {
    this.clipSelectionFacade.selectedRecordings$.subscribe(
      (data) => (this.selectedRecordings = data)
    );
  }

  goBack() {
    this.back.emit();
  }

  isRecordingSelected(recording: Recording) {
    return this.selectedRecordings.find(
      (r) => r.ovra_session_videos_id === recording.ovra_session_videos_id
    );
  }

  selectRecording(recording: Recording, selected: boolean) {
    this.clipSelectionFacade.selectRecording(recording, selected);
  }
}
