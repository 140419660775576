import { Injectable, Input } from '@angular/core';
import { Cleanupable } from '../../../../../../../libs/common/src';
import { TimelineItem } from '../../../shared/element.interfaces';
import { Timeline } from '../../../timelines/store/interfaces/timelines.interfaces';

@Injectable()
export class PaneBaseComponent<
  T extends TimelineItem = TimelineItem
> extends Cleanupable {
  @Input() timeline: Timeline;
  @Input() item: T;

  onPaneClose() {
    // Method called when pane is closed
  }
}
