import { BehaviorSubject } from 'rxjs';
import { Cleanupable } from '../classes';
import { Recording, VideoType } from '../interfaces';
import { ClipboardService, DirectorApiService } from '../services';

export class BaseRecordingActions extends Cleanupable {
  recordingItems$ = new BehaviorSubject<Recording[]>([]);

  constructor(
    public directorApiService: DirectorApiService,
    public clipboardService: ClipboardService
  ) {
    super();
  }

  protected async onRecordingFavouriteClicked(recording: Recording) {
    await this.directorApiService.shortlist(recording.ovra_session_videos_id);
    recording.favourite = !recording.favourite;
  }

  protected async deleteRecording(recording: Recording) {
    await this.directorApiService.deleteVideo(recording.ovra_session_videos_id);
  }

  protected async downloadRecording(recording: Recording, type: VideoType) {
    const downloadUrl = await this.directorApiService.getVideoDownloadUrl(
      recording.ovra_session_videos_id,
      type,
      'download'
    );

    // const url = window.URL.createObjectURL(downloadUrl);
    const url = downloadUrl;

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    // TODO: Change format as needed
    a.download = recording.video_name;
    a.target = '_blank';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  protected async copyRecording(recording: Recording, type: VideoType) {
    const downloadUrl = await this.directorApiService.getVideoDownloadUrl(
      recording.ovra_session_videos_id,
      type,
      'download'
    );

    if (!(await this.clipboardService.copy(downloadUrl))) {
      // Copying failed
    }
  }

  protected async transcodeRecording(recording: Recording, type: VideoType) {
    await this.directorApiService.transcodeVideo(
      recording.ovra_session_videos_id,
      type
    );
  }

  protected formatRecordings(recordings: Recording[]) {
    return recordings.map((recording) => {
      recording.favourite = recording.shortlisted === '0' ? false : true;
      return recording;
    });
  }
}
