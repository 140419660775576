import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions } from '@ngrx/effects';
import {
  selectProjectDraftAssets,
  selectProjectDraftIntroOutroTimelines,
  selectProjectWorkflowDraftData,
} from '../selectors/project.selectors';

@Injectable({
  providedIn: 'root',
})
export class PreviewFacade {
  draftData$ = this.store.select(selectProjectWorkflowDraftData);

  assets$ = this.store.select(selectProjectDraftAssets);

  constructor(private store: Store, private actions$: Actions) {}

  introOutroTimelines$(intro: boolean) {
    return this.store.select(selectProjectDraftIntroOutroTimelines, {
      intro,
    });
  }
}
