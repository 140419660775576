<div class="text-toolbar flex flex-align-center">
  <div class="text-color flex flex-align-center px-8">
    <button
      type="button"
      mat-button
      #menuTriggerColor="matMenuTrigger"
      [matMenuTriggerFor]="colorsMenu"
    >
      <div
        class="m-right-4"
        [ngStyle]="{ 'background-color': selectedColor?.value }"
      ></div>
      <mat-icon
        class="mat-icon-12"
        [class.expanded]="menuTriggerColor.menuOpen"
        svgIcon="caret"
      ></mat-icon>
    </button>
    <mat-menu
      #colorsMenu="matMenu"
      class="mat-menu-flat mat-menu-toolbar mat-menu-toolbar-colors w-min-30"
    >
      <button
        mat-menu-item
        *ngFor="let color of colors"
        [ngStyle]="{ 'background-color': color.value }"
        (click)="selectFontColor(color)"
      ></button>
    </mat-menu>
  </div>
  <div
    class="text-alignment flex flex-align-center px-8"
    *ngIf="enableAlignment"
  >
    <button
      type="button"
      [class.selected]="selectedAlignment === AlignmentEnum.Left"
      (click)="selectAlignment(AlignmentEnum.Left)"
    >
      <mat-icon class="mat-icon-14" svgIcon="text-left"></mat-icon>
    </button>
    <button
      type="button"
      [class.selected]="selectedAlignment === AlignmentEnum.Center"
      (click)="selectAlignment(AlignmentEnum.Center)"
    >
      <mat-icon class="mat-icon-14" svgIcon="text-centre"></mat-icon>
    </button>
    <button
      type="button"
      [class.selected]="selectedAlignment === AlignmentEnum.Right"
      (click)="selectAlignment(AlignmentEnum.Right)"
    >
      <mat-icon class="mat-icon-14" svgIcon="text-right"></mat-icon>
    </button>
  </div>
  <div class="text-font flex-auto flex flex-align-center px-8">
    <button
      type="button"
      mat-button
      #menuTriggerFonts="matMenuTrigger"
      [matMenuTriggerFor]="fontMenu"
    >
      <div class="m-right-4">
        <span
          *ngIf="selectedFont"
          [style.font-family]="selectedFont.family"
          [style.font-weight]="selectedFont.weight || 400"
        >
          {{ selectedFont.name }}
        </span>
      </div>
      <mat-icon
        class="mat-icon-12"
        [class.expanded]="menuTriggerFonts.menuOpen"
        svgIcon="caret"
      ></mat-icon>
    </button>
    <mat-menu
      #fontMenu="matMenu"
      class="mat-menu-flat mat-menu-toolbar mat-menu-toolbar-fonts"
    >
      <button
        mat-menu-item
        *ngFor="let font of fonts"
        [style.font-family]="font.family"
        [style.font-weight]="font.weight || 400"
        (click)="selectFont(font)"
      >
        {{ font.name }}
      </button>
    </mat-menu>
  </div>
</div>
