import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { commonenv } from '../../environments/environment';
import {
  FroalaCommand,
  initFroalaCustomIconTemplate,
  registerFrolaCommand,
} from './rich-text-custom-command';

enum FloaraTheme {
  'LIGHT' = 'royal',
  'GRAY' = 'gray',
  'DARK' = 'dark',
}

@Component({
  selector: 'openreel-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent implements OnChanges {
  @Input() editorContent: string;
  @Input() height: number;
  @Input() isDark: boolean;
  @Input() customEditorCommands: FroalaCommand[];
  @Input() isPlayable: boolean;
  @Output() editorContentChange = new EventEmitter<string>();
  @Output() cursorPositionChange = new EventEmitter<string>();
  @Output() highlightedTextChange = new EventEmitter<string>();
  editor = null;
  options = {
    key: commonenv.froalaKey,
    placeholderText: 'Edit Your Content Here!',
    theme: FloaraTheme.LIGHT,
    charCounterCount: false,
    toolbarInline: true,
    toolbarVisibleWithoutSelection: true,
    height: 250,
    attribution: false,
    pasteDeniedAttrs: ['style', 'id', 'class'],
    paragraphFormat: {
      N: 'Paragraph',
      H1: 'Heading 1',
      H2: 'Heading 2',
      H3: 'Heading 3',
      H4: 'Heading 4',
    },
    paragraphFormatSelection: true,
    toolbarButtons: ['bold', 'italic', 'paragraphFormat'],
    events: {
      initialized: () => {
        this.setEditorContent(this.editorContent);
      },
      contentChanged: () => {
        this.contentChanged();
      },
      mouseup: () => {
        this.checkSelectionText();
      },
      mousedown: () => {
        this.clearSelection();
      },
    },
  };
  constructor() {
    initFroalaCustomIconTemplate();
    registerFrolaCommand(
      FroalaCommand.SELECTION_PLAY,
      () => this.onSelectionPlay(),
      ($btn) => this.onRefreshSelectionPlay($btn)
    );
    registerFrolaCommand(
      FroalaCommand.CURSOR_PLAY,
      () => this.onCursorPlay(),
      ($btn) => this.onRefreshCursorPlay($btn)
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    const options = { ...this.options };
    if (changes.height && this.height) options.height = this.height;
    if (changes.isDark && this.isDark) options.theme = FloaraTheme.DARK;
    if (changes.customEditorCommands && this.customEditorCommands)
      options.toolbarButtons = [
        ...this.customEditorCommands,
        ...options.toolbarButtons,
      ];
    this.options = options;
  }
  initFroala(e) {
    e.initialize();
    this.editor = e.getEditor();
  }
  setEditorContent(content: string) {
    if (this.editor) {
      this.editor.html.set(content);
    }
  }
  contentChanged() {
    this.editorContent = this.editor.html.get();
    this.editorContentChange.emit(this.editorContent);
  }
  checkSelectionText() {
    const selectedText: string = this.editor.selection.text().trim();
    if (!selectedText) {
      this.highlightedTextChange.emit(null);
    }
  }
  clearSelection() {
    this.editor.selection.clear();
  }
  onCursorPlay() {
    //insert the marker
    this.editor.markers.insert();
    //get the HTML With marker
    const htmlWithMarker = this.editor.html.get(true);
    //remove the marker
    this.editor.markers.remove();
    this.cursorPositionChange.emit(htmlWithMarker);
  }
  onRefreshCursorPlay($btn) {
    if (this.isPlayable) {
      $btn.show();
    } else {
      $btn.hide();
    }
  }

  onSelectionPlay() {
    this.editor.markers.insert();
    const selectedHtml = this.editor.html.getSelected();
    this.editor.markers.remove();
    this.highlightedTextChange.emit(selectedHtml);
  }
  onRefreshSelectionPlay($btn) {
    const selectedText: string = this.editor.selection.text().trim();
    if (selectedText.length > 0 && this.isPlayable) {
      $btn.show();
    } else {
      $btn.hide();
    }
  }
}
