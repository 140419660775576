import { filter } from 'rxjs/operators';
import {
  EVT_D2S_SCRIPT_CONTENT,
  EVT_S2D_SCRIPT_CONTENT_ACK,
  EVT_D2S_TELEPROMPTER_INFO,
  EVT_S2D_TELEPROMPTER_INFO_ACK,
} from './../../../interfaces/socket-events';
import {
  TeleprompterSocketRequest,
  AckTeleprompterInfo,
  CaptureTeleprompterCommand,
  TeleprompterInfo,
} from './../../../interfaces/teleprompter.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectSocketService } from '../../socket/subject-socket.service';

@Injectable()
export class SocketExtensionTeleprompterService {
  loadTeleprompter$: Observable<TeleprompterSocketRequest>;
  playTeleprompter$: Observable<TeleprompterSocketRequest>;
  pauseTeleprompter$: Observable<TeleprompterSocketRequest>;
  resumeTeleprompter$: Observable<TeleprompterSocketRequest>;
  closeTeleprompter$: Observable<TeleprompterSocketRequest>;
  restartTeleprompter$: Observable<TeleprompterSocketRequest>;
  teleprompterInfo$: Observable<TeleprompterInfo>;

  constructor(private socket: SubjectSocketService) {
    const scriptContent$ = this.socket.getMySocketEventByName<
      TeleprompterSocketRequest
    >(EVT_D2S_SCRIPT_CONTENT);

    this.playTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.PLAY)
    );
    this.loadTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.LOAD)
    );
    this.pauseTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.PAUSE)
    );
    this.resumeTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.RESUME)
    );
    this.closeTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.CLOSE)
    );
    this.restartTeleprompter$ = scriptContent$.pipe(
      filter((req) => req.command === CaptureTeleprompterCommand.RESTART)
    );
    this.teleprompterInfo$ = socket.getMySocketEventByName(
      EVT_D2S_TELEPROMPTER_INFO
    );
  }

  sendAckForTeleprompterInfo(data: AckTeleprompterInfo) {
    this.socket.emitSocket({
      eventName: EVT_S2D_TELEPROMPTER_INFO_ACK,
      data: data,
    });
  }

  sendAckForTeleprompterPlay() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.PLAY,
      },
    });
  }

  sendAckForTeleprompterLoad() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.LOAD,
      },
    });
  }

  sendAckForTeleprompterPause() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.PAUSE,
      },
    });
  }

  sendAckForTeleprompterResume() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.RESUME,
      },
    });
  }

  sendAckForTeleprompterClose() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.CLOSE,
      },
    });
  }

  sendAckForTeleprompterRestart() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.RESTART,
      },
    });
  }

  // todo: this should not be an acknowlegement
  // because teleprompter goes idle on its own over time
  // thus, it should be different socket message
  // for example, teleprompter_notify_idle
  sendAckForTeleprompterIdle() {
    this.socket.emitSocket({
      eventName: EVT_S2D_SCRIPT_CONTENT_ACK,
      data: {
        command: CaptureTeleprompterCommand.IDLE,
      },
    });
  }
}
