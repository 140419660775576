import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { commonenv } from '../../environments/environment';
import {
  AppUser,
  ILoginRespDto,
  InvitationsValidateDto,
  InvitationsValidateResponseDto,
  IUserDetails,
} from '../../interfaces/interfaces';

import { CookieService } from 'ngx-cookie-service';

interface ForgotPasswordResponse {
  status: number;
  message: string;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * Cookie Data
   */
  private cookieName = 'openreel-user';
  private expireAfter = 30;

  /**
   * urls
   */
  public urls = {
    login: commonenv.nextGenApiUrl + 'login',
    collaborator_login: commonenv.nextGenApiUrl + 'invitations/validate',
    forgotPasswordEmail: commonenv.nextGenApiUrl + 'forgot-password-send-link',
    updateUserPassword: commonenv.nextGenApiUrl + 'user/password-reset',
    forgotPasswordVerify: (email, token) =>
      `${commonenv.nextGenApiUrl}user/verify-user-email/${email}/${token}`,
  };

  public isAuthenticated$ = new BehaviorSubject(false);
  public token$ = new BehaviorSubject(null);
  public data$ = new BehaviorSubject(null);
  public role: 'director' | 'collaborator';

  constructor(
    private cookieService: CookieService,

    public httpClient: HttpClient,
    public router: Router
  ) {
    this.restore();
  }

  /**
   * Restore token from cookies
   */
  restore() {
    const user = this.getUserDetails();
    if (user) {
      this.isAuthenticated$.next(true);
      this.token$.next(user.auth_token);
      this.role = user.role;
      this.data$.next(user.data);
    } else {
      this.isAuthenticated$.next(false);
      this.token$.next(null);
      this.role = null;
      this.data$.next(null);
    }
  }

  /**
   *
   */
  isLoggedIn() {
    return this.isAuthenticated$.getValue();
  }

  isInternalUser() {
    if (this.isAuthenticated$.getValue() && this.role === 'director') {
      return true;
    } else {
      return false;
    }
  }

  getCurrentToken(): string {
    return this.token$.value;
  }

  injectAuthData(user: AppUser<IUserDetails>): void {
    this.setCookie(JSON.stringify(user));

    this.restore();
  }

  collaborator_login(email, token, password) {
    const data: InvitationsValidateDto = {
      email,
      token,
      password,
    };
    return this.httpClient.post(this.urls.collaborator_login, data).pipe(
      tap((res: InvitationsValidateResponseDto) => {
        const data = {
          account_id: res.account_id,
          session_id: res.session_id,
          user_email: res.user_email,
        };

        const user: AppUser<typeof data> = {
          auth_token: res.email_token,
          role: 'collaborator',
          data,
        };
        this.isAuthenticated$.next(true);
        this.token$.next(user.auth_token);
        this.role = user.role;
        this.data$.next(res.data);
        this.setCookie(JSON.stringify(user));
      })
    );
  }

  /**
   * Submit the login request
   *
   * @username
   * @password
   * @remember
   */
  login(username, password, remember) {
    const data = {
      email: username,
      password,
      remember,
      ovra_access: true,
      source: 2,
      product: 3,
    };
    return this.httpClient.post(this.urls.login, data).pipe(
      tap((res: ILoginRespDto) => {
        if (res.status === 1) {
          // Userpilot.identify(res.data.user_id, {name: res.data.loggedin_fullname, email: res.data.email});
          this.onLoginSuccess(res);
        }
      })
    );
  }
  onLoginSuccess(res: ILoginRespDto) {
    const data = {
      loggedin_fullname: res.data.loggedin_fullname,
      account_id: res.data.account_id,
      email: res.data.email,
      user_id: res.data.user_id,
      company_name: res.data.company_details.name,
      site_user_slug: res.data.company_details.site_user_slug,
      image: res.data.image,
      is_root_admin: res.data.is_root_admin,
    };
    const user: AppUser<typeof data> = {
      auth_token: res.data.token || res.data.auth_token,
      role: 'director',
      data,
    };
    this.isAuthenticated$.next(true);
    this.token$.next(user.auth_token);
    this.role = user.role;
    this.data$.next(res.data);
    this.setCookie(JSON.stringify(user));
  }

  getCookie() {
    const cookie = this.cookieService.get(this.cookieName);
    return (cookie.length ? cookie : null);
  }
  unsetCookie() {
    const host = this.getCookieHost();

    /**
     * The cookie service *delete* doesn't work for some reason!
     * So let's expire it manually.
     */
    const expiresDate = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    this.cookieService.set(this.cookieName, '', {
      expires: expiresDate,
      secure: commonenv.production ? true : false,
      domain: host,
    });
  }

  getCookieHost() {
    /**
     * The Cookie should be available for *.host.com.
     * To support both capture and workflows
     */
    let host = window.location.host;
    const hostParts = host.split('.');
    if (hostParts.length > 2) {
      /**
       * Not localhost and not host.com, at least subdomain.host.com
       *  */
      hostParts.shift();
    }
    host = hostParts.join('.');

    /**
     * Remove port
     *  */
    host = host.split(':')[0];
    return host;
  }
  setCookie(token) {
    const host = this.getCookieHost();
    /**
     * Set Cookie
     */
    this.cookieService.set(this.cookieName, token, {
      expires: this.expireAfter,
      secure: commonenv.production ? true : false,
      domain: host,
    });
  }
  logout() {
    this.invalidateToken();
    localStorage.removeItem('teleprompterPreviewPosition');
  }

  /**
   * Invalidate token, usually when you get 403
   */
  invalidateToken() {
    console.log('invalidate token');
    this.token$.next(null);
    this.isAuthenticated$.next(false);
    this.role = null;
    this.data$.next(null);
    this.unsetCookie();
  }

  sendForgotPasswordEmail(email: string) {
    return this.httpClient.post<ForgotPasswordResponse>(
      this.urls.forgotPasswordEmail,
      {
        email: email,
      }
    );
  }

  verifyForgotPasswordToken(email: string, token: string) {
    return this.httpClient.get<ForgotPasswordResponse>(
      this.urls.forgotPasswordVerify(email, token)
    );
  }

  updateUserPassword(email: string, token: string, password: string) {
    return this.httpClient.post<ForgotPasswordResponse>(
      this.urls.updateUserPassword,
      {
        email: email,
        token: token,
        pass: password,
      }
    );
  }

  getUserDetails(): AppUser<IUserDetails> {
    return JSON.parse(this.getCookie());
  }
}
