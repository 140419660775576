import { createSelector } from '@ngrx/store';
import { selectTimelinesFeatureState, TimelinesFeatureState } from '..';
import { TrimmerState } from '../reducers/trimmer.reducer';

export const selectTrimmerState = createSelector(
  selectTimelinesFeatureState,
  (state: TimelinesFeatureState) => state.trimmer
);

export const selectTrimGroups = createSelector(
  selectTrimmerState,
  (state: TrimmerState) => state.trimGroups
);

export const selectAllowSplits = createSelector(
  selectTrimmerState,
  (state: TrimmerState) => state.allowSplits
);

export const selectFinished = createSelector(
  selectTrimmerState,
  (state: TrimmerState) => state.finished
);
