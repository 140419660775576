import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { interval } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Cleanupable } from '../../classes';
import {
  AudioDevice,
  AudioStreamService,
  VideoDevice,
  VideoSource,
  VideoStream,
  VideoStreamService,
} from '../../media';
import { AudioStream } from '../../media/interfaces/audio-stream.interface';

/**
 * Output interface that describes what is result of this screen, which devices
 * user selected.
 */
export interface LobbyDeviceSelection {
  /**
   * Selected video device
   */
  video: VideoDevice;
  /**
   * Selected audio device
   */
  audio: AudioDevice;
}

// TODO: In the future, this component should also offer ability to make a test
// recording of self, and preview the recording, similar how Skype has test
// call feature.
/**
 * This is a small component that lets user change camera/microphone. The data
 * (selected mic/webcam) is stored in SessionConfig service. From this service
 *  all other services can pick up what is currently selected mic/webcam.
 */
@Component({
  selector: 'openreel-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss'],
})
export class LobbyComponent extends Cleanupable implements OnInit, OnDestroy {
  @Output()
  nextPressed = new EventEmitter<LobbyDeviceSelection>();
  /**
   * If this is true, the lobby will automatically select first available
   * streams, so user can get done with this step sooner
   */
  @Input()
  quickSelect = false;
  /**
   * Used to manually update video element's video feed
   */
  @ViewChild('videoElement') videoElement: ElementRef<HTMLVideoElement>;
  /**
   * This system's possible video sources. Right now, we show webcam/desktop in
   * this list
   */
  supportedVideoSources: VideoSource[] = [];
  /**
   * The currently selected video source
   */
  selectedVideoSource: VideoSource;
  /**
   * All the possible video devices.
   */
  supportedVideoDevices: VideoDevice[] = [];
  /**
   * Video devices for the selected video source.
   */
  videoDevices: VideoDevice[] = [];
  /**
   * Selected video device identifier. This is used to uniquely identify this
   * device in our system
   */
  selectedVideoDevice: VideoDevice;
  /**
   * A full list of audio devices.
   */
  audioDevices: AudioDevice[] = [];
  /**
   * The selected audio device.
   */
  selectedAudioDevice: AudioDevice;
  /**
   * Enum used for html
   */
  VideoSource = VideoSource;
  /**
   * Whether there is a transition between video devices.
   */
  isTransitioningVideo = false;
  /**
   * Whether there is a transition between audio devices.
   */
  isTransitioningAudio = false;
  /**
   * Currently selected audio device's track. It is passed into the audio meter.
   */
  audioStreamTrack: MediaStreamTrack;
  /**
   * Currently selected video device's track. It is passed into the video component.
   */
  videoStreamTrack: MediaStreamTrack;
  /**
   * The audio error string to show in the interface.
   */
  audioStreamError: string;
  /**
   * The video error string to show in the interface.
   */
  videoStreamError: string;
  /**
   * Flag to manage streams remaining open after pressing next.
   */
  keepStreamsOpen = false;

  /**
   * Can proceed to after the lobby or not
   */
  canPressNext = false;

  /**
   * An Interval to calculate if it's OK to press Next
   * The reason why
   */
  private canPressNextInterval;

  constructor(
    private readonly videoStreamService: VideoStreamService,
    private readonly audioStreamService: AudioStreamService,    
  ) {
    super();
    this.canPressNextInterval = interval(1000)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.calculateCanPressNext();
      });
  }

  async ngOnDestroy() {
    super.ngOnDestroy();
    this.videoStreamService.closeStream();
    this.audioStreamService.closeStream();
  }

  async ngOnInit() {
    this.keepStreamsOpen = false;

    this.supportedVideoSources = this.videoStreamService.getSupportedSources();
    if (this.supportedVideoSources.length > 0) {
      this.selectedVideoSource = this.supportedVideoSources[0];
    }

    await this.videoStreamService.openStream({ source: VideoSource.WEBCAM });
    await this.audioStreamService.openStream({});

    this.subscriptions.push(
      this.videoStreamService.devices$.subscribe((videoDevices) => {
        this.onVideoDeviceListChange(videoDevices);
      }),
      this.audioStreamService.devices$.subscribe((audioDevices) => {
        this.onAudioDeviceListChange(audioDevices);
      }),
      this.audioStreamService.audio$.subscribe((audioStream) => {
        this.onAudioStreamChange(audioStream);
      }),
      this.videoStreamService.video$
        .pipe(debounceTime(300)) // To avoid the play/pause issue on chrome. Should eventually be replaced for a more long term fix
        .subscribe((videoStream) => this.onVideoStreamChange(videoStream))
    );
  }

  /* Video-related code */

  private onVideoDeviceListChange(videoDevices: VideoDevice[]) {
    this.supportedVideoDevices = videoDevices;
    this.changeVideoSource();
  }

  async changeVideoSource() {
    this.videoDevices = this.supportedVideoDevices.filter(
      (device) => device.source === this.selectedVideoSource
    );

    if (
      this.selectedVideoDevice &&
      !this.videoDevices.some(
        (device) => device.id === this.selectedVideoDevice.id
      )
    ) {
      this.selectedVideoDevice =
        this.videoDevices.length > 0 ? this.videoDevices[0] : null;
      await this.changeVideoDevice();
    }
  }

  private async onVideoStreamChange(videoStream: VideoStream) {
    this.selectedVideoDevice = this.videoDevices.find(
      (device) => device.id === videoStream.device.id
    );
    this.selectedVideoSource = videoStream.device.source;
    this.videoStreamTrack = videoStream?.track;

    if (!this.videoElement) {
      this.isTransitioningVideo = false;
      return;
    }

    if (videoStream) {
      if (!videoStream.error) {
        this.videoElement.nativeElement.srcObject = new MediaStream([
          this.videoStreamTrack,
        ]);
        this.videoElement.nativeElement.onplaying = () => {
          this.isTransitioningVideo = false;
        };
        this.videoElement.nativeElement.onerror = () => {
          this.isTransitioningVideo = false;
          this.videoStreamError = 'Could not start the stream';
        };

        this.checkQuickSelect();
      } else {
        console.error('Error getting the video', videoStream.error);
        this.handleVideoStreamError(videoStream);
        this.isTransitioningVideo = false;
      }
    }

    this.calculateCanPressNext();
  }

  private handleVideoStreamError(videoStream: VideoStream) {
    if (videoStream.device.source === VideoSource.DESKTOP) {
      if (videoStream.error.name === 'NotAllowedError') {
        this.videoStreamError =
          "Can't share your screen. You must grant permissions.";
      }
    } else {
      if (videoStream.error.name === 'NotAllowedError') {
        this.videoStreamError = 'You must grant permissions to use the camera.';
      }
    }

    if (!this.videoStreamError) {
      this.videoStreamError = videoStream.error?.message;
    }
  }

  async changeVideoDevice() {
    if (this.isTransitioningVideo) {
      return;
    }

    this.isTransitioningVideo = true;
    this.videoStreamError = null;

    try {
      const newStream = await this.videoStreamService.openStream(
        this.selectedVideoDevice
      );
      if (!newStream) {
        this.isTransitioningVideo = false;
      }
    } catch (error) {
      this.isTransitioningVideo = false;
      this.videoStreamError = 'Could not open the selected video stream.';
    }
    this.calculateCanPressNext();
  }

  /* Audio-related code */

  private async onAudioDeviceListChange(audioDevices: AudioDevice[]) {
    this.audioDevices = audioDevices;
    await this.refreshAudioDeviceList();
  }

  private async refreshAudioDeviceList() {
    if (
      this.selectedAudioDevice &&
      !this.audioDevices.some(
        (device) => device.id === this.selectedAudioDevice.id
      )
    ) {
      if (this.audioDevices.length > 0) {
        const defaultDevice = this.audioDevices.find(
          (device) => device.isDefault
        );
        this.selectedAudioDevice = defaultDevice
          ? defaultDevice
          : this.audioDevices[0];
      } else {
        this.selectedAudioDevice = null;
      }

      await this.changeAudioDevice();
    }
  }

  async changeAudioDevice() {
    if (this.isTransitioningAudio) {
      return;
    }

    this.isTransitioningAudio = true;
    this.audioStreamError = null;

    try {
      await this.audioStreamService.openStream(this.selectedAudioDevice);
    } catch (error) {
      this.isTransitioningAudio = false;
      this.videoStreamError = 'Could not open the selected audio stream.';
    }
    this.calculateCanPressNext();
  }

  private onAudioStreamChange(audioStream: AudioStream) {
    this.selectedAudioDevice = this.audioDevices.find(
      (device) => device.id === audioStream.device.id
    );

    this.audioStreamTrack = audioStream?.track;
    this.isTransitioningAudio = false;

    if (!audioStream?.error) {
      this.checkQuickSelect();
    } else {
      if (audioStream.error.name === 'NotAllowedError') {
        this.audioStreamError =
          'You must grant permissions to use the microphone.';
      } else {
        this.audioStreamError = audioStream?.error.message;
      }
    }

    this.calculateCanPressNext();
  }

  calculateCanPressNext() {
    this.canPressNext =
      !this.isTransitioningAudio &&
      !this.isTransitioningVideo &&
      this.selectedAudioDevice &&
      this.selectedVideoDevice &&
      !this.audioStreamError &&
      !this.videoStreamError &&
      this.audioStreamTrack?.readyState === 'live' &&
      this.videoStreamTrack?.readyState === 'live';
  }

  /**
   * Check if all streams are configured and ready so we can get out of this
   * step, if we want to quickly get rid of this step.
   */
  private checkQuickSelect() {
    if (this.quickSelect && this.canPressNext) {
      this.confirmed();
    }
  }

  /**
   * Confirm button pressed from UI
   */
  confirmed() {
    this.nextPressed.next({
      audio: this.selectedAudioDevice,
      video: this.selectedVideoDevice,
    });
  }
}
