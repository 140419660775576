import { BehaviorSubject, Observable } from 'rxjs';
import { CountdownComponent } from './../../countdown/countdown.component';
import { SocketDirectorExtensionTeleprompterService } from './../../../services/director/socket-extensions/socket-director-extension-teleprompter.service';
import { Cleanupable } from '../../../classes/cleanupable';
import { RemoteOpenreelParticipant } from './../../../services/session/openreel-participant';
import { TelepromterApiService } from './../../../services/director/director-api/telepromter-api.service';
import { MatDialog } from '@angular/material/dialog';
import {
  TeleprompterScript,
  TeleprompterScriptCreate,
  TeleprompterScriptEdit,
  TeleprompterScriptProgressStatus,
  UserRoleType,
} from './../../../interfaces/interfaces';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { createCounterFor } from './../teleprompter-options';
import { TeleprompterPreviewPopupComponent } from '../teleprompter-preview-popup/teleprompter-preview-popup.component';
import { ToastrService } from 'ngx-toastr';
import {
  TeleprompterSocketRequest,
  CaptureTeleprompterStatus,
  CaptureTeleprompterCommand,
  TeleprompterUpdateInfo,
  TeleprompterPresetsDto,
  POPUP_BASE_WIDTH,
  POPUP_BASE_HEIGHT,
} from '../../../interfaces/teleprompter.interface';
import { TeleprompterPresetsFormComponent } from '../teleprompter-presets-form/teleprompter-presets-form.component';
import { SessionSettingsDto } from 'libs/common/src/services/session/session.interfaces';
import { SessionApiService } from 'libs/common/src/services/session/session-api.service';
import { DirectorSessionService } from 'libs/common/src/services/director/director-session.service';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { RichTextEditorComponent } from '../../rich-text-editor/rich-text-editor.component';
import { FroalaCommand } from '../../rich-text-editor/rich-text-custom-command';

const CURSOR_HTML = '<span class="currentPosition"></span>';
const MAIN_PREVIEW_ID = -1;

/**
 * A director-side component, used for setting up, previewing, and remotely controlling
 * subject's teleprompter. This component supports controlling multiple subjects, however only
 * in the same state. For example, when paused all selected subjects are paused
 *
 * We have two scenarios:
 * - script is selected from the dropdown (can update)
 * - no script is selected (can create)
 *
 * In both scenarios, text in the textarea can be sent to the subject or be previewed
 */
@Component({
  selector: 'openreel-capture-teleprompter',
  templateUrl: './capture-teleprompter.component.html',
  styleUrls: ['./capture-teleprompter.component.scss'],
})
export class CaptureTeleprompterComponent
  extends Cleanupable
  implements OnInit, OnChanges, OnDestroy {
  // reference to countdown component
  @ViewChild(CountdownComponent) countdown: CountdownComponent;
  @Input() projectId: number;
  @Input() sessionId: number;
  @Input() devices: RemoteOpenreelParticipant[] = [];
  // settings such as countdown value, should close script box once have been played
  @Input() sessionSettings: SessionSettingsDto;
  // settings such as speed, font size, screen setup, background color
  settings: TeleprompterPresetsDto;
  // list of teleprompter scripts for the project
  scripts: TeleprompterScript[] = [];
  selectedScript = new FormControl();
  // current state of teleprompter
  // TODO: convert it to an observable (similarly to subject-teleprompter.component)
  status: CaptureTeleprompterStatus = CaptureTeleprompterStatus.STOPPED;
  // current text contained by textarea
  text: string;
  // highlighted part of the textarea
  // when this property is set, it is used for sending/previewing instead of text property
  highlightedText: string;
  CaptureTeleprompterStatus = CaptureTeleprompterStatus;
  cursorHtml: string;
  private contentChangeDetect = new BehaviorSubject<string>(null);
  pendingTitleControl = new FormControl();
  // track all the preview windows open by user id
  previewPopupComponents = new Map<number, TeleprompterPreviewPopupComponent>();
  @ViewChild('teleprompterPreset')
  teleprompterPreset: TeleprompterPresetsFormComponent;
  @ViewChild('richTextEditor')
  richTextEditor: RichTextEditorComponent;
  hasSubjects$: Observable<boolean>;
  requestTeleprompterInfo = false;
  globalShowHide = new FormControl(false);
  loadedTeleprompter: string[] = [];
  // TODO document what changeToggle is. This is not clear at all
  // Why doesn't selectedIdentities reflect selected devices? When we uncheck one it just gets put into changeToggle
  changeToggle: string[] = [];
  isScreenChange = false;
  isCountDown = false;
  creatingScript = false;
  FroalaCommand = FroalaCommand;

  get selectedIdentities(): string[] {
    return this.devices
      .filter((device) => this.canEnableDisable(device.identity))
      .map((subject) => subject.identity);
  }

  constructor(
    private dialog: MatDialog,
    private socketTeleprompter: SocketDirectorExtensionTeleprompterService,
    private telepromterApiService: TelepromterApiService,
    public sessionApiService: SessionApiService,
    private session: DirectorSessionService,
    private toastr: ToastrService
  ) {
    super();
    this.subscriptions.push(
      this.socketTeleprompter.listenToTeleprompterInfo().subscribe((data) => {
        const [device] = this.devices.filter(
          (participant) => participant.identity === data.from
        );
        if (
          this.requestTeleprompterInfo &&
          !this.previewPopupComponents.has(device.loginId)
        ) {
          this.requestTeleprompterInfo = false;
          this.openTeleprompterPreviewPopup(
            device.loginId,
            data.data.teleprompterCurrentLine,
            data.data.teleprompterTotalLines,
            data.data.teleprompterWidth
              ? data.data.teleprompterWidth
              : POPUP_BASE_WIDTH,
            data.data.teleprompterHeight
              ? data.data.teleprompterHeight
              : POPUP_BASE_HEIGHT,
            device
          );
        } else if (
          !device.isIosDevice &&
          this.previewPopupComponents.has(device.loginId)
        ) {
          //if preview window already open & device is not ios & device is selected, resize it
          const component = this.previewPopupComponents.get(device.loginId);
          component.onSubjectResize(data.data);
        }
      })
    );
    this.subscriptions.push(
      this.contentChangeDetect.debounceTime(500).subscribe((text: string) => {
        //if text changed while playing
        if (text) {
          this.sendFlyChanges();
        }
      })
    );

    this.socketTeleprompter.directorScriptUpdate$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: TeleprompterUpdateInfo) => {
        this.updateTeleprompterInfo(data);
        if (data.commandRequest) {
          this.updatePreviewPopups(data.commandRequest);
        }
      });
  }

  async ngOnInit() {
    // fetch settings (speed, font-size, etc) from backend
    this.settings = await this.sessionApiService.getTeleprompterPresets(
      this.sessionId
    );
  }
  ngOnDestroy() {
    this.closeAllPreviewPopups();
  }
  //persist teleprompter status if director refresh
  async checkTeleprompterStatus(identity: string) {
    //device.selected would not change for the director who sent the teleprompter request to avoid toggle flickring issue
    const participants = this.session.getRemoteOnlineParticipants();
    const toggleIndex = this.changeToggle.indexOf(identity);
    this.devices = participants.filter(
      (participant) => participant.role === UserRoleType.Subject
    );
    let teleprompterPlay = false;
    let telePrompterPause = false;
    let teleprompterId = 0;
    this.loadedTeleprompter = [];
    this.devices.forEach((device) => {
      if (toggleIndex === -1)
        device.selected = device.teleprompterProperties.isTeleprompterVisible;
      if (device.teleprompterProperties.teleprompterId) {
        teleprompterPlay = device.teleprompterProperties.isTeleprompterPlay;
        telePrompterPause = device.teleprompterProperties.isTeleprompterPause;
        teleprompterId = device.teleprompterProperties.teleprompterId;
        this.loadedTeleprompter.push(device.identity);
      }
    });
    //select the script if subject send the script ID
    if (teleprompterId && !this.selectedScript.value) {
      this.selectedScript.setValue(teleprompterId);
      const script = this.getCurrentScript();
      this.text = script ? script.content : '';
      this.pendingTitleControl.setValue(script?.title);
      this.richTextEditor.setEditorContent(this.text);
    }
    if (teleprompterPlay) {
      this.status = CaptureTeleprompterStatus.PLAYING;
    } else if (telePrompterPause) {
      this.status = CaptureTeleprompterStatus.PAUSED;
    } else {
      this.status = CaptureTeleprompterStatus.STOPPED;
    }
    this.checkAllDeviceSelected();
    this.changeToggle.splice(toggleIndex, 1);
  }
  async sendFlyChanges() {
    // we would like to update the teleprompter with new presets
    // if it is currently active (either playing or paused)
    let data: TeleprompterSocketRequest;
    if (
      this.status === CaptureTeleprompterStatus.PLAYING ||
      this.status === CaptureTeleprompterStatus.PAUSED
    ) {
      data = this.getScriptRequest(CaptureTeleprompterCommand.PLAY, true);
    } else {
      data = this.getScriptRequest(CaptureTeleprompterCommand.LOAD);
    }
    if (this.selectedIdentities.length > 0) {
      await this.socketTeleprompter.updateTeleprompter(
        data,
        this.selectedIdentities
      );
      if (this.isScreenChange) {
        //if screen setup change during the fly changes request subject for updated width & height
        this.socketTeleprompter.getTeleprompterInfo(
          {},
          this.selectedIdentities
        );
        this.isScreenChange = false;
      }
    }
    this.updatePreviewPopups(data);
    this.sendUpdateTeleprompterInfo(data);
  }

  updatePreviewPopups(data: TeleprompterSocketRequest) {
    for (const component of this.previewPopupComponents.values()) {
      component.updateTeleprompter(data);
    }
  }

  closeAllPreviewPopups() {
    for (const component of this.previewPopupComponents.values()) {
      component.onDialogCloseClicked();
    }
  }

  onPresetsChange(settings: TeleprompterPresetsDto) {
    if (this.settings && this.settings.screen_setup !== settings.screen_setup) {
      this.isScreenChange = true;
    }
    this.settings = settings;
    // persist presets to session level settings
    // note the absence of await, as we don't want to wait until API call succeeds
    this.sessionApiService.updateTeleprompterPresets(this.sessionId, settings);
    this.cursorHtml = null;
    this.sendFlyChanges();
  }

  onScriptChanged() {
    const script = this.getCurrentScript();
    this.text = script ? script.content : '';
    this.pendingTitleControl.setValue(script?.title);
    this.richTextEditor.setEditorContent(this.text);
    this.highlightedText = null;
    this.sendUpdateTeleprompterInfo();
  }

  async loadScripts() {
    const allScripts = await this.telepromterApiService.getScriptsInProject(
      this.projectId
    );
    this.scripts = allScripts.filter(
      (script) =>
        script.progress_status === TeleprompterScriptProgressStatus.Ready
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('projectId' in changes) {
      this.loadScripts();
    }
  }

  getCurrentScript(): TeleprompterScript {
    let returnScript: TeleprompterScript;
    this.scripts.forEach((script) => {
      if (script.ovra_teleprompter_script_id === this.selectedScript.value) {
        returnScript = script;
      }
    });
    return returnScript;
  }

  onTextChanged(text: string) {
    this.text = text;
    this.highlightedText = null;
    this.cursorHtml = null;
    if (this.selectedScript.value) {
      this.contentChangeDetect.next(this.text);
      //update the main teleprompter script set when textarea updated
      this.scripts.forEach((script) => {
        if (script.ovra_teleprompter_script_id === this.selectedScript.value) {
          script.content = this.text;
        }
      });
    }
  }
  onHighlightedTextChanged(text: string) {
    this.cursorHtml = null;
    if (text) {
      this.highlightedText = this.replaceEditorMarker(text, '');
      this.contentChangeDetect.next(this.highlightedText);
    } else {
      this.highlightedText = null;
    }
  }

  onCursorPositionChanged(event: string) {
    this.highlightedText = null;
    //on cursor click send cursor position if teleprompter play/preview window open
    if (
      event !== this.cursorHtml &&
      (this.canStop || this.previewPopupComponents.size)
    ) {
      this.cursorHtml = event;
      this.sendFlyChanges();
    }
  }

  createCounter(): Observable<number> {
    const value = this.sessionSettings.teleprompter_countdown_enabled
      ? this.sessionSettings.countdown_value
      : 0;
    return createCounterFor(value);
  }

  async createUpdateScript(backgroundUpdate = true) {
    if (!this.text || !this.text.trim()) {
      return;
    } else if (this.selectedScript.value) {
      await this.onUpdateButtonClicked();
      if (!backgroundUpdate) {
        this.toastr.success('The script was updated successfully.', 'Success!');
      }
    } else {
      await this.onCreateButtonClicked();
      if (!backgroundUpdate) {
        this.toastr.success('New script created!');
      }
    }
  }

  // teleprompter control buttons
  // click event handlers
  async onUpdateButtonClicked() {
    // in oldgen, script settings (speed, fontsize, etc) were part of teleprompter script record
    // in nextgen, these settings are part of session settings
    // thus, some of the following properties are just to comply with oldgen columns
    // and ignored
    const script = this.getCurrentScript();
    if (this.pendingTitleControl.value) {
      script.title = this.pendingTitleControl.value;
    }
    const request: TeleprompterScriptEdit = {
      title: script.title,
      content: this.text,
      scroll_speed: 50, // never used, just to be able to save a script
      session_id: script.session_id,
      ts_backgroundColor: '#ffffff', // never used, just to be able to save a script
      ts_backgroundOption: 'solid', // never used, just to be able to save a script
      ts_fontsize: 14, // never used, just to be able to save a script
    };
    const scriptId = script.ovra_teleprompter_script_id;
    const res = await this.telepromterApiService.editScript(scriptId, request);
    this.sendUpdateTeleprompterInfo();
    return res;
  }

  async onCreateButtonClicked() {
    // since we don't have an input for script title
    // default title + random number is used for creating a script

    // in oldgen, script settings (speed, fontsize, etc) were part of teleprompter script record
    // in nextgen, these settings are part of session settings
    // thus, some of the following properties are to comply with oldgen columns
    // and ignored
    const request: TeleprompterScriptCreate = {
      title:
        this.pendingTitleControl.value ||
        'Default title ' + Math.round(10000 * Math.random()),
      content: this.text,
      scroll_speed: 50, // never used, just to be able to save a script
      session_id: this.sessionId, // not really needed since scripts are attached to projects (not sessions)
      ts_backgroundColor: '#ffffff', // never used, just to be able to save a script
      ts_backgroundOption: 'solid', // never used, just to be able to save a script
      ts_fontsize: 14, // never used, just to be able to save a script
      project_id: this.projectId,
      progress_status: TeleprompterScriptProgressStatus.Ready,
    };
    this.creatingScript = true;
    const res = await this.telepromterApiService.createScript(request);
    await this.loadScripts();
    this.selectedScript.setValue(res.ovra_teleprompter_script_id);
    this.pendingTitleControl.setValue(res.title);
    this.creatingScript = false;
    this.sendUpdateTeleprompterInfo();
    return res;
  }

  async onStartButtonClicked() {
    //create or update the script before send
    await this.createUpdateScript();
    await this.startTeleprompter();
  }

  async onRestartButtonClicked() {
    this.createUpdateScript();
    await this.restartTeleprompter();
  }

  async onPauseButtonClicked() {
    await this.pauseTeleprompter();
  }

  async onStopButtonClicked() {
    await this.stopTeleprompter();
  }

  async onPreviewButtonClicked(
    previewLoginId = MAIN_PREVIEW_ID,
    requestSubject = false
  ) {
    if (!requestSubject) {
      this.openTeleprompterPreviewPopup(previewLoginId);
    } else {
      //if teleprompter not stopped get the teleprompter position from subject
      this.requestTeleprompterInfo = true;
      const identities = this.devices
        .filter((device) => device.loginId === previewLoginId)
        .map((device) => device.identity);
      const isFullScreen = this.settings.screen_setup === 'fullscreen';
      this.socketTeleprompter.getTeleprompterInfo(
        { isFullScreen: isFullScreen },
        identities
      );
    }
  }

  openTeleprompterPreviewPopup(
    previewId: number,
    teleprompterCurrentLine = 0,
    teleprompterTotalLines = 0,
    width = 0,
    height = 0,
    device: RemoteOpenreelParticipant = null
  ) {
    if (this.previewPopupComponents.has(previewId)) {
      return;
    }
    const scriptData = this.getScriptRequest(CaptureTeleprompterCommand.PLAY);
    const dialogRef = this.dialog.open(TeleprompterPreviewPopupComponent, {
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        script: scriptData,
        teleprompterCurrentLine: teleprompterCurrentLine,
        teleprompterTotalLines: teleprompterTotalLines,
        width: width,
        height: height,
        device: device,
        isPlay:
          this.status === CaptureTeleprompterStatus.PLAYING ||
          this.status === CaptureTeleprompterStatus.STOPPED,
      },
      disableClose: true,
      hasBackdrop: false,
      panelClass: 'teleprompter-popup',
    });
    this.previewPopupComponents.set(previewId, dialogRef.componentInstance);
    dialogRef.afterClosed().subscribe(() => {
      this.previewPopupComponents.delete(previewId);
      //stop the teleprompter if preview close & there are no subjects
      if (this.selectedIdentities.length === 0) {
        this.status = CaptureTeleprompterStatus.STOPPED;
      }
    });
  }

  // teleprompter socket requests
  async startTeleprompter(countdown$?: Observable<number>) {
    countdown$ = countdown$ || this.createCounter();
    const showCountDown =
      !this.canStop && this.sessionSettings.teleprompter_countdown_enabled;

    const data = this.getScriptRequest(CaptureTeleprompterCommand.PLAY);
    await this.socketTeleprompter.playTeleprompter(
      data,
      this.selectedIdentities
    );
    if (this.selectedIdentities.length > 0)
      this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data);
    if (showCountDown) {
      //show countdown only for first time
      this.isCountDown = true;
      await this.countdown.countDown(countdown$);
      this.isCountDown = false;
    }
    this.socketTeleprompter.getTeleprompterInfo({}, this.selectedIdentities);
    this.status = CaptureTeleprompterStatus.PLAYING;
  }

  async restartTeleprompter() {
    const countdown$ = this.createCounter();
    const data = this.getScriptRequest(CaptureTeleprompterCommand.RESTART);
    await this.socketTeleprompter.restartTeleprompter(
      data,
      this.selectedIdentities
    );
    if (this.selectedIdentities.length > 0)
      this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data);
    if (this.sessionSettings.teleprompter_countdown_enabled) {
      this.isCountDown = true;
      await this.countdown.countDown(countdown$);
      this.isCountDown = false;
    }
    this.status = CaptureTeleprompterStatus.PLAYING;
  }

  private async stopTeleprompter() {
    const data = this.getScriptRequest(CaptureTeleprompterCommand.CLOSE);
    await this.socketTeleprompter.closeTeleprompter(
      data,
      this.selectedIdentities
    );
    this.status = CaptureTeleprompterStatus.STOPPED;
    if (this.selectedIdentities.length > 0)
      this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data);
  }

  private async pauseTeleprompter() {
    const data = this.getScriptRequest(CaptureTeleprompterCommand.PAUSE);
    await this.socketTeleprompter.pauseTeleprompter(
      data,
      this.selectedIdentities
    );
    this.status = CaptureTeleprompterStatus.PAUSED;
    if (this.selectedIdentities.length > 0)
      this.sendUpdateTeleprompterInfo(data);
    this.updatePreviewPopups(data);
  }

  private isMainPreviewOpen() {
    return this.previewPopupComponents.has(MAIN_PREVIEW_ID);
  }

  get canStart(): boolean {
    return (
      (!!this.text &&
        !!this.text.trim() &&
        !this.creatingScript &&
        this.devices.some((device) => device.selected) &&
        this.status !== CaptureTeleprompterStatus.PLAYING) ||
      this.isMainPreviewOpen()
    );
  }

  get canStartDuringRecording() {
    return (
      !!this.text &&
      !!this.text.trim() &&
      this.status !== CaptureTeleprompterStatus.PLAYING
    );
  }

  get canPreview(): boolean {
    return !!this.text && !!this.text.trim() && !this.isCountDown;
  }

  get canUpdate(): boolean {
    return !!this.selectedScript;
  }

  get canCreate(): boolean {
    return !this.selectedScript && !!this.text && !!this.text.trim();
  }

  get canStop(): boolean {
    return (
      this.status === CaptureTeleprompterStatus.PLAYING ||
      this.status === CaptureTeleprompterStatus.PAUSED ||
      this.status === CaptureTeleprompterStatus.IDLE
    );
  }

  get canPause(): boolean {
    return this.status === CaptureTeleprompterStatus.PLAYING;
  }

  get canResume(): boolean {
    return this.status === CaptureTeleprompterStatus.PAUSED;
  }

  /*
   * Notify other directors of a script change
   */
  private async sendUpdateTeleprompterInfo(
    data: TeleprompterSocketRequest = null
  ) {
    this.socketTeleprompter.sendDirectorScriptUpdate({
      script: this.getCurrentScript(),
      settings: this.settings,
      commandRequest: data,
    });
  }

  // websocket message payload helpers
  private getScriptRequest(
    command: CaptureTeleprompterCommand,
    contentChange: boolean = false
  ): TeleprompterSocketRequest {
    const script = this.getCurrentScript();
    const settings = this.settings;
    const textToSend = this.getTextWithCursor(
      this.highlightedText || this.text,
      contentChange
    );
    return {
      command,
      SessionID: script?.session_id,
      script_content: {
        scriptData: [textToSend],
        timerData: [],
      },
      script_title: script?.title,
      // oldgen properties
      scriptContentLink: '',
      scroll_speed: parseInt(this.getScrollSpeed(), 10),
      font_size: settings.font_size + '',
      content_change: contentChange ? 1 : 0,
      tele_script_id: script?.ovra_teleprompter_script_id,
      txtColor: this.getTextColor(),
      value: this.getBackgroundColor(),
      // nextgen properties
      countDown:
        contentChange || !this.sessionSettings.teleprompter_countdown_enabled
          ? 0
          : this.sessionSettings.countdown_value,
      fontSize: settings.font_size,
      split: settings.screen_setup,
      speed: settings.speed,
      background: settings.background,
      keepOnScreen: this.sessionSettings.keep_teleprompter_on_screen,
      enabledStatus: this.getEnabledStatus(),
    };
  }

  private getTextWithCursor(text: string, contentChange: boolean) {
    if (text && contentChange && this.cursorHtml) {
      //replace editor cursor with custom cursor
      text = this.replaceEditorMarker(this.cursorHtml, CURSOR_HTML);
    }
    return text;
  }

  private getEnabledStatus() {
    const returnData = {};
    this.devices.forEach((device) => {
      returnData[device.identity] = device.selected;
    });
    return returnData;
  }

  private replaceEditorMarker(text: string, replaceText: string) {
    return text.replace(/<span(\s)+class="fr-marker".*?<\/span>/g, replaceText);
  }

  // needed for IOS subjects
  private getBackgroundColor() {
    const bckValue = this.settings.background;
    return bckValue === 'white_in_black'
      ? '#FFFFFF'
      : bckValue === 'black_in_white'
      ? '#000000'
      : '-1';
  }

  // needed for IOS subjects
  private getTextColor() {
    const bckValue = this.settings.background;
    return bckValue === 'white_in_black'
      ? '#000000'
      : bckValue === 'black_in_white'
      ? '#FFFFFF'
      : '-1';
  }

  // needed for IOS subjects
  private getScrollSpeed() {
    const speedValue = this.settings.speed;
    return speedValue ? speedValue.toString() : '100';
  }

  // Handle teleprompter script/preset update from web sockets
  private async updateTeleprompterInfo(data: TeleprompterUpdateInfo) {
    if (data.settings) {
      this.settings = data.settings;
      //no update api call during websocket update
      this.teleprompterPreset.needUpdate = false;
      this.teleprompterPreset.form.setValue(this.settings);
    }
    if (data.script) {
      const scriptData = data.script;
      let scriptFound = false;
      this.scripts.forEach((script, index) => {
        if (
          script.ovra_teleprompter_script_id ===
          scriptData.ovra_teleprompter_script_id
        ) {
          this.scripts[index] = scriptData;
          scriptFound = true;
        }
      });
      if (!scriptFound) {
        this.scripts.push(scriptData);
      }
      this.selectedScript.setValue(scriptData?.ovra_teleprompter_script_id);
      this.text = scriptData ? scriptData.content : '';
      this.pendingTitleControl.setValue(scriptData?.title);
      this.richTextEditor.setEditorContent(this.text);
    }
  }
  toggleGlobalTeleprompterScreen() {
    const changeToggle = [];
    this.devices.forEach((device) => {
      if (device.selected !== this.globalShowHide.value) {
        changeToggle.push(device.identity);
      }
      device.selected = this.globalShowHide.value;
    });
    this.changeToggle = [...changeToggle];
    this.loadTeleprompterToAllDevices();
  }
  toggleTeleprompter(identity: string) {
    this.changeToggle = [identity];
    this.loadTeleprompterToAllDevices();
  }
  async loadTeleprompterToAllDevices() {
    this.checkAllDeviceSelected();
    if (!this.selectedScript.value) {
      //if user does not select any script,
      //create the script first before send to the subjects as subject needs the script id
      await this.createUpdateScript();
    }
    const data = this.getScriptRequest(CaptureTeleprompterCommand.LOAD);
    await this.socketTeleprompter.loadTeleprompter(
      data,
      this.selectedIdentities
    );
  }
  checkAllDeviceSelected() {
    const enabledDevices = this.devices.filter((device) => device.selected);
    if (this.devices.length === enabledDevices.length) {
      this.globalShowHide.setValue(true);
    } else {
      this.globalShowHide.setValue(false);
    }
  }
  canEnableDisable(identity: string): boolean {
    return (
      (this.loadedTeleprompter.includes(identity) ||
        this.status === CaptureTeleprompterStatus.STOPPED) &&
      !this.creatingScript
    );
  }
  canGlobalEnableDisable() {
    if (this.devices.length === 0 || !this.canPreview) return false;
    let globalAllow = true;
    this.devices.forEach((device) => {
      if (!this.canEnableDisable(device.identity)) globalAllow = false;
    });
    return globalAllow;
  }
}
