<div class="option-container" *ngIf="templateSettings.canSwapSides(timeline)">
  <button mat-raised-button type="button" (click)="swapVideoSides()">
    Swap Video Sides
  </button>
</div>
<div class="option-container">
  <button mat-raised-button type="button" (click)="duplicateClip()">
    Duplicate Video
  </button>
</div>
<div class="option-container">
  <button mat-raised-button type="button" (click)="editTrim()">
    Edit Trim
  </button>
</div>
<div class="option-container" *ngIf="templateSettings.canReplaceClip(timeline)">
  <button mat-raised-button type="button" (click)="replaceClip()">
    Replace Video Clip
  </button>
</div>
