import { NgModule } from '@angular/core';
import { LayersModule } from '../layers/layers.module';
import { SharedModule } from '../shared/shared.module';
import { components, exportComponents, entryComponents } from './components';
import { ItemSelectPanesService } from './services/panes.service';
import { SelectFilesModule } from '../select-files/select-files.module';
import { ItemSelectPanesActionsService } from './services/panes-actions.service';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
  declarations: [...components],
  imports: [
    A11yModule,
    ReactiveFormsModule,
    SharedModule,
    LayersModule,
    SelectFilesModule,
  ],
  exports: [...exportComponents],
  providers: [ItemSelectPanesService, ItemSelectPanesActionsService],
  entryComponents: [...entryComponents],
})
export class EditorItemSelectionModule {}
