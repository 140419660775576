import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

// Handler type for any event received from socket
export type AnySocketDataHandler = (
  eventName: string,
  data: SocketData
) => void;

export type SocketIoLibOn = (
  eventName: '*any*',
  handler: AnySocketDataHandler
) => void;

export interface SocketConnectionStatus {
  connected: boolean;
}

// Our socket.io wrapper. We actually don't use .on('eventname') like you would
// usually do on socket.io interface, we rather transmit all messages when you
// subscribe to .on('*any*'), and then, usually through rxjs, we sort out and
// filter message to whom it needs to be delivered. This gives us ability to
// listen to acknowledgement from somebody only once, more easily then if we
// used .on() and .off().
// By default socket is not connected, you have to call .connect(url) to
// connect it.
export interface ISocketIoLib {
  connectionStatus$: BehaviorSubject<SocketConnectionStatus>;
  // Currently only .on('*any*') is supported
  on: SocketIoLibOn;
  // Send data through websocket
  emit: (event: string, data: unknown) => void;
  disconnect();
  connect(url: string, namespace?: string);
}

@Injectable()
export abstract class ISocketIoLibService {
  abstract createSocket(): ISocketIoLib;
}

// TODO: Move this out of this file
export interface DirectorSessionInfo {
  session: string;
  userType: 'director' | 'collaborator';
  ovraId: string;
  accountId?: string;
  token: string;
  deviceType: 'WEB';
  identity: string;
}

// TODO: Move this out of this file
export interface DirectorOnlineInfo {
  session: string;
  ovraId: string;
}

// This type is used only in case of director. Gives some extra info about
// message, such as, who sent this message. Used only in director sockets.
// TODO: Move this out of this file
export interface ReceiveSocketMessageInfo<DataType> {
  from: string | undefined;
  session: number;
  data: DataType;
  eventName: string;
}

// When sending sockets to multiple participants, and waiting for
// acknowledgements from multiple participants, this interface gives us ack
// info from each of those participants. Used only in director sockets.
// TODO: Move this out of this file
export interface MultiSendSocketCompleteInfo {
  from: string;
  success: boolean;
  errorMessage?: string;
}

// How to send my identity to others. Used only in director sockets.
// TODO: Move this out of this file
export enum MyIdentitySendType {
  IDENTITY,
  NO_IDENTITY,
  ACCOUNT_ID,
  OVRA_ID,
  OVRA_USER_ID,
  OVRA_ID_OVRA_USER_ID,
}

export interface SocketData {
  identity: string;
  SessionID: number;
}
