import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import {
  Layer,
  LayerOptions,
  TimelinesLayer,
  VideoLayer,
} from '../../../api/workflow.interfaces';
import { timelineItemFactory } from '../element.interfaces';

import { v4 as uuidv4 } from 'uuid';
import { VideoSplit } from '../../timelines/store/interfaces/trimmer.interfaces';
import { WorkflowBaseBuilder } from './workflow-base.builder';

const SHORTER_VIDEO_FADE_TIME = 500;
const SHORTER_VIDEO_FADE_MAX_OFFSET = 100;

export class PodcastWorkflowBuilder extends WorkflowBaseBuilder {
  switchMainClipTimelines(event: TimelineInterfaces.TimelineItemEvent) {
    const mainTimeline = this.getTimeline('main');
    if (!mainTimeline || mainTimeline.layers.length === 0) {
      return;
    }

    const layerToUpdate = mainTimeline.layers.find(
      (l) => l.layerId === event.timeline.options.parentLayer.layerId
    ) as TimelinesLayer;
    layerToUpdate.children.reverse();
    this.updateTransitions(layerToUpdate);
  }

  replaceMainClip(
    event: TimelineInterfaces.TimelineItemEvent,
    newClip: TimelineInterfaces.ClipAddEvent
  ) {
    const mainTimeline = this.getTimeline('main');
    const timelinesLayer = mainTimeline.layers.find(
      (l) => l.layerId === event.timeline.options.parentLayer.layerId
    ) as TimelinesLayer;

    const clips = timelinesLayer.children.map((timeline) => {
      const layer = timeline.layers[0] as VideoLayer;
      const asset = this.getAsset(layer.assetId);
      return {
        assetFileId: asset.file.path,
        assetProviderType: asset.file.provider,
        duration: asset.data.duration,
        name: asset.data.name,
      } as TimelineInterfaces.ClipAddEvent;
    });

    clips[event.timeline.options.timelineIndex] = newClip;

    // Remove everything
    this.clearTimelinesLayer(timelinesLayer);

    const maxDuration = clips.reduce(
      (prev, curr) => Math.max(prev, curr.duration),
      0
    );

    clips.forEach((clip, index) => {
      const newAsset = this.addClipAsset(
        clip.assetFileId,
        clip.assetProviderType,
        null,
        null,
        { duration: clip.duration, name: clip.name }
      );
      timelinesLayer.children[index].layers.push({
        type: 'video',
        assetId: newAsset.id,
        layerId: uuidv4(),
        visibility: {
          startAt: 0,
          endAt: clip.duration,
        },
      } as LayerOptions & VideoLayer);
    });

    this.removeLayersOutOfBounds();

    return { timelinesLayer, maxDuration };
  }

  updateSplitsOnMainTimeline(splits: VideoSplit[]) {
    const mainTimeline = this.getTimeline('main');
    if (!mainTimeline || mainTimeline.layers.length === 0) {
      return;
    }

    const timelinesLayer = mainTimeline.layers[0] as LayerOptions &
      TimelinesLayer;

    // Remove everything
    this.clearTimelinesLayer(timelinesLayer);

    const visibility = [0, 0];

    splits.forEach((split) => {
      split.videos.forEach((video, index) => {
        const newAsset = this.addClipAsset(
          video.video.assetFileId,
          video.video.assetProviderType,
          video.trimFrom,
          video.trimTo,
          { duration: video.video.duration, name: video.video.name }
        );

        const trimmedDuration = video.trimTo - video.trimFrom;
        const newLayer: LayerOptions & VideoLayer = {
          type: 'video',
          visibility: {
            startAt: visibility[index],
            endAt: visibility[index] + trimmedDuration,
          },
          layerId: uuidv4(),
          assetId: newAsset.id,
        };

        timelinesLayer.children[index].layers.push(newLayer);
        visibility[index] += trimmedDuration + 1;
      });
    });

    this.removeLayersOutOfBounds();
    this.updateTransitions(timelinesLayer);
  }

  moveMainClipInsideChildTimeline(event: TimelineInterfaces.ItemMovedEvent) {
    const mainTimeline = this.getTimeline('main');
    if (!mainTimeline || mainTimeline.layers.length === 0) {
      return;
    }

    const timelinesLayer = mainTimeline.layers.find(
      (l) => l.layerId === event.timeline.options.parentLayer.layerId
    ) as TimelinesLayer;
    const timeline =
      timelinesLayer.children[event.timeline.options.timelineIndex];

    const draggingLayer = timeline.layers.find(
      (l) => l.layerId === event.item.layerId
    );
    const diff = event.startAt - draggingLayer.visibility.startAt;

    const items: TimelineInterfaces.UpdatedItem[] = [];
    timeline.layers.forEach((clipLayer) => {
      clipLayer.visibility.startAt += diff;
      clipLayer.visibility.endAt += diff;

      items.push({
        timelineIndex: event.timeline.options.timelineIndex,
        item: timelineItemFactory(clipLayer, event.timeline.type, this.source),
      });
    });

    this.updateTransitions(timelinesLayer);

    return items;
  }

  removeMainClipPair(event: TimelineInterfaces.TimelineItemEvent) {
    const mainTimeline = this.getTimeline('main');
    if (!mainTimeline || mainTimeline.layers.length === 0) {
      return;
    }

    const timelinesLayer = mainTimeline.layers.find(
      (l) => l.layerId === event.timeline.options.parentLayer.layerId
    ) as TimelinesLayer;

    const timelineWithItem =
      timelinesLayer.children[event.timeline.options.timelineIndex];

    const indexOfItemToRemove = timelineWithItem.layers.findIndex(
      (l) => l.layerId === event.item.layerId
    );
    timelinesLayer.children.forEach((timeline) => {
      timeline.layers.splice(indexOfItemToRemove, 1);
    });

    timelinesLayer.children.forEach((timeline) => {
      for (let i = indexOfItemToRemove; i < timeline.layers.length; i++) {
        const newStartAt =
          i === 0 ? 0 : timeline.layers[i - 1].visibility.endAt + 1;
        const diff = timeline.layers[i].visibility.startAt - newStartAt;

        timeline.layers[i].visibility = {
          startAt: newStartAt,
          endAt: timeline.layers[i].visibility.endAt - diff,
        };
      }
    });

    this.removeLayersOutOfBounds();
    this.updateTransitions(timelinesLayer);
  }

  private updateTransitions(timelinesLayer: TimelinesLayer) {
    const mainTimeline = this.getTimeline('main');
    if (!mainTimeline || mainTimeline.layers.length === 0) {
      return;
    }

    const mainTimelineDuration = this.getTimelineDuration(mainTimeline);
    const shorterTimelines = timelinesLayer.children.filter(
      (timeline) => this.getTimelineDuration(timeline) < mainTimelineDuration
    );

    shorterTimelines.forEach((timeline) => {
      timeline.layers.sort(
        (a: Layer, b: Layer) => a.visibility.startAt - b.visibility.startAt
      );
      timeline.layers.forEach((clipLayer, index) => {
        clipLayer.transitions = {};

        if (
          index === 0 &&
          clipLayer.visibility.startAt > SHORTER_VIDEO_FADE_MAX_OFFSET
        ) {
          clipLayer.transitions.entrance = {
            duration: SHORTER_VIDEO_FADE_TIME,
            type: 'fade-in',
          };
        }

        if (
          index === timeline.layers.length - 1 &&
          clipLayer.visibility.endAt + SHORTER_VIDEO_FADE_MAX_OFFSET <
            mainTimelineDuration
        ) {
          clipLayer.transitions.exit = {
            duration: SHORTER_VIDEO_FADE_TIME,
            type: 'fade-out',
          };
        }
      });
    });
  }
}
