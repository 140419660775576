import { map, takeUntil } from 'rxjs/operators';

import { Cleanupable } from '../../classes';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { WorkflowsSocketService } from './workflows-socket.service';
import {commonenv} from '@openreel/common/env/environment';
import {
  EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID,
  EVT_WORKFLOW_RENDER_PROGRESS,
  RenderProgress
} from './workflow-project.interfaces';

@Injectable()
export class WorkflowProjectSocketService extends Cleanupable {

  renderProgress$: Observable<RenderProgress>;

  constructor(private readonly socket: WorkflowsSocketService) {
    super();
    this.observeRenderProgress();
  }

  connect(sessionInfo) {
    const payload = this.getSessionInfoPayload(sessionInfo);
    this.socket.connect(commonenv.websocketUrl + '?access-token=' + payload.token);
    this.socket.emit(EVT_WORKFLOW_JOIN_BY_CAPTURE_PROJECT_ID, payload);
  }

  observeRenderProgress() {
    this.renderProgress$ = this.socket
      .getSocketEventByName<RenderProgress>(EVT_WORKFLOW_RENDER_PROGRESS)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map((event) => event.data)
      );
  }

   getSessionInfoPayload(sessionInfo) {
    return {
       device_type: 'WEB',
       user_type: sessionInfo.userType,
       token: sessionInfo.token,
       captureProjectId: sessionInfo.captureProjectId
     };
   }

}
