<div class="m-top-40 p-bottom-40 flex-col flex-align-center">
  <h1>Name Your Video</h1>
  <div class="m-top-12 verbiage">
    First up, what should we call this video? You can edit this in the top left
    at any point of the editing process.
  </div>
  <form
    class="flex-col flex-align-center"
    [formGroup]="form"
    (ngSubmit)="submitForm()"
  >
    <div class="text-input">
      <openreel-text-input
        [textControl]="form.controls.name"
        placeholder="Type project name here"
      ></openreel-text-input>
    </div>
    <button
      type="submit"
      [disabled]="form.invalid || saving"
      mat-flat-button
      class="next-step m-top-40"
      color="primary"
    >
      Next
    </button>
  </form>
</div>
