<div class="option-container" *ngIf="pipEnabled">
  <mat-checkbox class="inverse" [formControl]="pipControl">
    Picture-in-Picture
  </mat-checkbox>
</div>
<div class="option-container">
  <button mat-raised-button type="button" (click)="duplicateClip()">
    Duplicate Video
  </button>
</div>
<div class="option-container">
  <button mat-raised-button type="button" (click)="editTrim()">
    Edit Trim
  </button>
</div>
