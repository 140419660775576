import { CommonModule } from '@angular/common';
import { MaterialModule } from '@openreel/common/material';
import { Ng5SliderModule } from 'ng5-slider';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { components, entryComponents } from './components';
import { directives } from './directives';
import { pipes } from './pipes';
import { OpenreelCommonDirectivesModule } from '../../../../../libs/common/src/directives/openreel-common-directives.module';

const declarations = [...components, ...directives, ...pipes];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    Ng5SliderModule,
    OpenreelCommonDirectivesModule,
  ],
  exports: [CommonModule, MaterialModule, ...declarations],
  entryComponents: [...entryComponents],
})
export class SharedModule {}
