<div mat-dialog-content class="m-top-20">
  <mat-icon
    *ngIf="data.type === AlertTypeEnum.Error"
    class="mat-icon-40 flex-none text-color-error m-right-20"
    svgIcon="cancel-process"
  ></mat-icon>
  <mat-icon
    *ngIf="data.type === AlertTypeEnum.Info"
    class="mat-icon-40 flex-none m-right-20"
    svgIcon="info-alt"
  ></mat-icon>
  <mat-icon
    *ngIf="data.type === AlertTypeEnum.Confirmation"
    class="mat-icon-40 flex-none m-right-20"
    svgIcon="info"
  ></mat-icon>
  <span> {{ data.message }} </span>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="data.type !== AlertTypeEnum.Confirmation">
    <button mat-raised-button color="primary" (click)="ok()">
      {{ data.confirmButtonText || 'Close' }}
    </button>
  </ng-container>
  <ng-container *ngIf="data.type === AlertTypeEnum.Confirmation">
    <button mat-raised-button color="primary" (click)="yes()">
      {{ data.confirmButtonText || 'Yes' }}
    </button>
    <button mat-raised-button (click)="no()">
      {{ data.rejectButtonText || 'No' }}
    </button>
  </ng-container>
</div>
