import * as fromActions from '../actions/asset-cache.actions';

import { createReducer, on } from '@ngrx/store';

import { AssetCache } from '../interfaces/asset-cache.interface';

export const initialState: AssetCache = {
  cachedAssets: [],
  cachedObjects: [],
};

const reducer = createReducer(
  initialState,

  on(fromActions.cacheAsset, (state, cachedAsset) => {
    const cachedAssets = state.cachedAssets.filter(
      (asset) =>
        asset.assetId !== cachedAsset.assetId ||
        asset.provider !== cachedAsset.provider
    );

    return { ...state, cachedAssets: [...cachedAssets, cachedAsset] };
  }),

  on(fromActions.removeCachedAsset, (state, { id, provider }) => {
    const cachedAssets = state.cachedAssets.filter(
      (asset) => asset.assetId !== id || asset.provider !== provider
    );

    return { ...state, cachedAssets };
  }),

  on(fromActions.cacheObject, (state, cachedObject) => {
    const cachedObjects = state.cachedObjects.filter(
      (object) => object.originalUrl !== cachedObject.originalUrl
    );

    return { ...state, cachedObjects: [...cachedObjects, cachedObject] };
  }),

  on(fromActions.removeCachedObject, (state, { url }) => {
    const cachedObjects = state.cachedObjects.filter(
      (object) => object.originalUrl !== url
    );

    return { ...state, cachedObjects };
  })
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
