import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UiControl } from '../../services/layer.service';

import { AssetsFacade } from '../../../store/facades/assets.facade';
import { FormGroup } from '@angular/forms';
import { ColorAsset } from '../../../../api/workflow.interfaces';
import { Cleanupable } from '../../../../../../../libs/common/src';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'openreel-wf-layer-item-shape',
  templateUrl: './layer-item-shape.component.html',
  styleUrls: ['./layer-item-shape.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LayerItemShapeComponent extends Cleanupable implements OnInit {
  @Input() uiControl: UiControl;
  @Input() control: FormGroup;

  selectedColor: ColorAsset;
  colors: ColorAsset[];

  constructor(private readonly assetsFacade: AssetsFacade) {
    super();
  }

  ngOnInit() {
    this.assetsFacade.colors$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((colors) => {
        this.colors = colors;
        this.selectedColor = this.colors[this.control.value.colorIndex || 0];
      });
  }

  selectColor(color: ColorAsset) {
    if (this.colors.indexOf(color) === -1) {
      return;
    }

    this.selectedColor = color;

    this.control.patchValue({
      colorIndex: this.colors.findIndex((c) => c.id === color.id),
    });
  }
}
