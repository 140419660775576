import { createAction, props } from '@ngrx/store';
import { TemplateType } from '../../../api/workflow.interfaces';

const actionType = '[Editor]';

export const resetSteps = createAction(`${actionType} Reset Steps`);

export const nextStep = createAction(`${actionType} Next Step`);

export const setStep = createAction(
  `${actionType} Set Step`,
  props<{ step: number }>()
);

export const setChildStep = createAction(
  `${actionType} Set Child Step`,
  props<{ childStep: number }>()
);

export const setStepsForTemplate = createAction(
  `${actionType} Set Steps For Template`,
  props<{ templateType: TemplateType }>()
);
