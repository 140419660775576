import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import {
  DirectorSession,
  IStreamingLibService,
  IStreamingParticipant,
  TwilioTokenRequest,
  TwilioTokenResponse,
} from '../../interfaces';
import { AudioStreamService, VideoStreamService } from '../../media';
import { CommonApiService } from '../common-api/common-api.service';
import { NetworkQualityService } from '../network-quality/network-quality.service';
import { SessionConfigService } from '../session/session-config.service';
import { BaseStreamingService } from '../streaming/base-streaming.service';
import { DirectorSessionService } from './director-session.service';

@Injectable()
export class DirectorStreamingService extends BaseStreamingService<
  DirectorSession
> {

  public remoteParticipants$: Observable<IStreamingParticipant[]>

  constructor(
    audioSource: AudioStreamService,
    videoSource: VideoStreamService,
    twilioProvider: IStreamingLibService,
    session: DirectorSessionService,
    sessionConfig: SessionConfigService,
    networkQualityService: NetworkQualityService,
    private api: CommonApiService
  ) {
    super(
      audioSource,
      videoSource,
      twilioProvider,
      session,
      sessionConfig,
      networkQualityService
    );

    this.remoteParticipants$ = this.videoStreamingInstance$.asObservable().pipe(
      filter(streaming => streaming != null),
      switchMap(streaming => streaming.remoteParticipants$)
    );
  }
  async getTokenInfo(sessionId: number): Promise<TwilioTokenResponse> {
    const tokenReq: TwilioTokenRequest = {
      session_id: sessionId.toString(),
    };
    return await this.api.getTwilioToken(tokenReq).toPromise();
  }
}
