import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
  providedIn: 'root',
})
export class NetworkQualityService {
  networkQuality = 0;
  networkQuality$:BehaviorSubject<number> = new BehaviorSubject(0);

  // this is called from base streaming service when network level changed
  setNetworkQuality(networkLevel:number){
    this.networkQuality = networkLevel;
    this.networkQuality$.next(networkLevel);
  }
}
