import { createAction, props } from '@ngrx/store';

import { TemplateDto } from '../../../api/template/template.interfaces';

const actionType = '[Template]';

export const loadTemplateSuccess = createAction(
  `${actionType} Load Template Success`,
  props<{ data: TemplateDto }>()
);
