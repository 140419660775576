import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Cleanupable } from '@openreel/common/utils';
import { sidebarButtonAnimation } from '../sidebar.animations';

enum ButtonState {
  INACTIVE,
  SELECTABLE,
  SELECTED,
}

@Component({
  selector: 'openreel-wf-sidebar-button',
  templateUrl: './sidebar-button.component.html',
  styleUrls: ['./sidebar-button.component.scss'],
  animations: [sidebarButtonAnimation],
  /* To be able to contain specialized
    changes to material design to this
    component */
  encapsulation: ViewEncapsulation.None,
})
export class SidebarButtonComponent extends Cleanupable implements OnChanges {
  @Input() icon: string;
  @Input() text: string;
  @Input() index: number;
  @Input() step: number;
  @Input() maxStep: number;
  @Output() stepSelect = new EventEmitter<number>();

  state = ButtonState.INACTIVE;
  ButtonState = ButtonState;

  constructor() {
    super();
  }

  ngOnChanges() {
    if (this.index > this.maxStep) {
      this.state = ButtonState.INACTIVE;
    } else if (this.index === this.step) {
      this.state = ButtonState.SELECTED;
    } else if (this.index <= this.maxStep) {
      this.state = ButtonState.SELECTABLE;
    }
  }

  onClick() {
    this.stepSelect.emit(this.index);
  }
}
