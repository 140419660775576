// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const commonenv = {
  apiUrl: 'https://dev.remote.openreel.com:4002/',
  hostUrl: 'https://dev.remote.openreel.com/dist/',
  appUrl: 'https://capture.playground.openreel.cloud/',
  nextGenAppUrl: 'https://capture.playground.openreel.cloud/',
  workflowsAppUrl: 'https://workflows.playground.openreel.cloud',
  nextGenApiUrl: 'https://capture.playground.openreel.cloud/api/',
  // nextGenApiUrl: 'http://localhost:3000/api/',
  assetsUrl: 'https://static.dev.openreel.com/uploads/static-upload/',
  awsStreambaseUrl: 'https://stream.openreel.com/openreel-videos/',
  liveSessionTrackID: '36db86a9.71b6bdda',
  websocketUrl: 'https://capture.playground.openreel.cloud/',
  production: false,
  sentryDSN:
    'https://f24b2145d1c24eba9f8f4398d4fd6030@o215724.ingest.sentry.io/5549142',
  froalaKey: 'oGLGTI1DMJc1BWLg1PO==',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
