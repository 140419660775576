import * as TimelineInterfaces from '../../timelines/store/interfaces/timelines.interfaces';

import {
  Asset,
  AssetMetadata,
  AssetsFileProviderType,
  LayerOptions,
  LottieLayerData,
  Style,
  Timeline,
  TimelinesLayer,
  TimelineType,
  VideoLayer,
  WorkflowDataDto,
} from '../../../api/workflow.interfaces';
import { LayerDataChangedEvent, TimelineItem } from '../element.interfaces';

import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export abstract class WorkflowBaseBuilder {
  protected source: WorkflowDataDto;

  constructor(original: WorkflowDataDto) {
    this.source = cloneDeep(original);
  }

  toWorkflow = () => this.source;

  protected addClipAsset(
    assetId: number,
    assetProvider: AssetsFileProviderType,
    trimFrom: number,
    trimTo: number,
    data: AssetMetadata
  ) {
    if (!this.source.assets) {
      this.source.assets = [];
    }

    const newAsset: Asset = {
      id: uuidv4(),
      type: 'clip',
      file: {
        path: assetId,
        provider: assetProvider,
      },
      data,
      trimFrom,
      trimTo,
    };

    this.source.assets.push(newAsset);

    return newAsset;
  }

  protected addImageAsset(newAssetFileId: number) {
    const newAsset: Asset = {
      id: uuidv4(),
      type: 'image',
      file: {
        path: newAssetFileId,
        provider: 'or-assets',
      },
    };

    this.source.assets.push(newAsset);

    return newAsset;
  }

  protected removeAssets(assetIdsToRemove: string | string[]) {
    this.source.assets = this.source.assets.filter(
      (asset) => assetIdsToRemove.indexOf(asset.id) === -1
    );
  }

  protected getAsset(assetId: string) {
    return this.source.assets.find((a) => a.id === assetId);
  }

  protected createMainSectionIfNotexists() {
    if (!this.source.sections.main) {
      this.source.sections.main = {
        enabled: true,
        timelines: [],
      };
    }
    this.source.sections.main.enabled = true;
  }

  protected getTimeline(
    type: TimelineType,
    createIfNOtExists: boolean = false
  ) {
    if (!this.source.sections.main) {
      if (createIfNOtExists) {
        this.createMainSectionIfNotexists();
      } else {
        return null;
      }
    }

    let timeline = this.source.sections.main.timelines.find(
      (t) => t.type === type
    );
    if (!timeline && createIfNOtExists) {
      timeline = { type: type, layers: [] };
      this.source.sections.main.timelines.push(timeline);
    }
    return timeline;
  }

  protected getAssetDuration(asset: Asset) {
    let duration = asset.data?.duration ?? 0;
    if (asset.trimFrom || asset.trimTo) {
      duration = (asset.trimTo || duration) - (asset.trimFrom || 0);
    }

    return duration;
  }

  protected getDefaultPresetData(
    asset: Asset
  ): { data: { [key: string]: LottieLayerData }; styles: Style[] } {
    const styles = [];
    const data = {};

    Object.keys(asset.preset).forEach((key) => {
      const style: Style = {
        id: uuidv4(),
        colorIndex: asset.preset[key].colorIndex,
        defaultColorIndex: asset.preset[key].colorIndex,
      };
      styles.push(style);

      data[key] = {
        type: asset.preset[key].type,
        value: asset.preset[key].defaultValue,
        styleId: style.id,
      };
    });

    return { data, styles };
  }

  protected addClips(
    timeline: Timeline,
    events: TimelineInterfaces.ClipAddEvent[]
  ) {
    return this.insertClips(timeline, events, timeline.layers.length);
  }

  protected insertClips(
    timeline: Timeline,
    events: TimelineInterfaces.ClipAddEvent[],
    insertIndex: number
  ) {
    this.createMainSectionIfNotexists();

    const createdItems: TimelineItem[] = [];

    events.forEach((clip) => {
      const newAsset = this.addClipAsset(
        clip.assetFileId,
        clip.assetProviderType,
        clip.trimFrom,
        clip.trimTo,
        {
          name: clip.name,
          duration: clip.duration,
        }
      );

      const newLayer: VideoLayer & LayerOptions = {
        layerId: uuidv4(),
        type: 'video',
        assetId: newAsset.id,
        visibility: {
          startAt: clip.startAt,
          endAt: clip.endAt,
        },
        isPip: clip.isPip,
        bounds: clip.bounds,
      };

      createdItems.push(new TimelineItem(newLayer, newAsset, timeline.type));
    });

    timeline.layers.splice(
      insertIndex,
      0,
      ...createdItems.map((item) => item.layer)
    );

    return createdItems;
  }

  protected updateLottieLayerAssetStyles(data: LayerDataChangedEvent) {
    const multipleLayerValues = cloneDeep(data.values);

    multipleLayerValues.forEach((layerValues, layerIndex) => {
      const layerAssetChanges = data.assetChanges[layerIndex];
      const layerStyleChanges = data.styleChanges[layerIndex];

      Object.keys(layerValues).forEach((key) => {
        if (
          layerValues[key].type === 'image' ||
          layerValues[key].type === 'logo'
        ) {
          if (layerAssetChanges[key].removedAssetId) {
            this.removeAssets(layerAssetChanges[key].removedAssetId);
          }
          if (layerAssetChanges[key].newAssetFileId) {
            const addedAsset = this.addImageAsset(
              layerAssetChanges[key].newAssetFileId
            );
            addedAsset.isLogoAsset = layerValues[key].type === 'logo';
            addedAsset.isBrandKitSelected =
              layerAssetChanges[key].isBrandKitSelected;

            layerValues[key].assetId = addedAsset.id;
          }
        }

        if (layerValues[key].type === 'text') {
          if (data.styleChanges[layerIndex][key]) {
            const style = this.source.styles.find(
              (s) => s.id === layerValues[key].styleId
            );
            if (style) {
              style.fontIndex = layerStyleChanges[key].fontIndex || 0;
              style.colorIndex = layerStyleChanges[key].colorIndex || 0;
              style.color = layerStyleChanges[key].color;
            }
          }
        }

        if (layerValues[key].type === 'shape') {
          if (data.styleChanges[layerIndex][key]) {
            const style = this.source.styles.find(
              (s) => s.id === layerValues[key].styleId
            );
            if (style) {
              style.colorIndex = layerStyleChanges[key].colorIndex || 0;
              style.color = layerStyleChanges[key].color;
            }
          }
        }
      });
    });
  }

  protected removeLayersOutOfBounds() {
    const mainTimeline = this.getTimeline('main', false);
    const mainTimelineDuration = this.getTimelineDuration(mainTimeline);

    const checkTimelines: Timeline[] = [];
    const videoOverlayTimeline = this.getTimeline('overlays', false);
    if (videoOverlayTimeline) {
      checkTimelines.push(videoOverlayTimeline);
    }
    const textOverlayTimeline = this.getTimeline('overlays', false);
    if (textOverlayTimeline) {
      checkTimelines.push(textOverlayTimeline);
    }

    const assetsToRemove = [];
    checkTimelines.forEach((timeline) => {
      const filteredLayers = timeline.layers.filter(
        (layer: LayerOptions & VideoLayer) => {
          if (layer.visibility.endAt > mainTimelineDuration) {
            assetsToRemove.push(layer.assetId);
          }
          return layer.visibility.endAt <= mainTimelineDuration;
        }
      );
      timeline.layers = filteredLayers;
    });

    if (assetsToRemove) {
      this.removeAssets(assetsToRemove);
    }
  }

  protected recalculateVisibility(timeline: Timeline) {
    let startAt = 0;
    timeline.layers.forEach((layer: LayerOptions & VideoLayer) => {
      const asset = this.getAsset(layer.assetId);
      const assetDuration = this.getAssetDuration(asset);
      const layerDuration =
        (asset.trimTo ?? assetDuration) - (asset.trimFrom ?? 0);

      layer.visibility = {
        startAt,
        endAt: startAt + layerDuration,
      };

      startAt += layerDuration + 1;
    });
  }

  protected getTimelineDuration(timeline: Timeline): number {
    const endAt = this.getTimelineEndAt(0, timeline);
    const startAt = this.getTimelineStartAt(endAt, timeline);
    return endAt - startAt;
  }

  protected clearTimelinesLayer(timelinesLayer: TimelinesLayer) {
    timelinesLayer.children.forEach((timeline) => {
      this.removeAssets(
        timeline.layers.map((layer: VideoLayer) => layer.assetId)
      );
      timeline.layers = [];
    });
  }

  protected getItemsForUpdate(event: TimelineInterfaces.TimelineItemEvent) {
    const timeline = this.getTimeline(event.timeline.type);
    let clipIndex: number = null;

    const timelinesToUpdate: Timeline[] = [];
    if (event.timeline.options.parentLayer) {
      const timelinesLayer = timeline.layers.find(
        (l) => l.layerId === event.timeline.options.parentLayer.layerId
      ) as TimelinesLayer;
      clipIndex = timelinesLayer.children[
        event.timeline.options.timelineIndex
      ].layers.findIndex((l) => l.layerId === event.item.layerId);
      timelinesToUpdate.push(...timelinesLayer.children);
    } else {
      clipIndex = timeline.layers.findIndex(
        (l) => l.layerId === event.item.layerId
      );
      timelinesToUpdate.push(timeline);
    }

    return { clipIndex, timelinesToUpdate };
  }

  private getTimelineEndAt(endAt: number, timeline: Timeline): number {
    for (const layer of timeline.layers) {
      if (layer.type === 'timelines') {
        for (const child of layer.children) {
          endAt = Math.max(this.getTimelineEndAt(endAt, child), endAt);
        }
      } else {
        endAt = Math.max(layer.visibility.endAt, endAt);
      }
    }

    return endAt;
  }

  private getTimelineStartAt(startAt: number, timeline: Timeline): number {
    for (const layer of timeline.layers) {
      if (layer.type === 'timelines') {
        for (const child of layer.children) {
          startAt = Math.min(this.getTimelineStartAt(startAt, child), startAt);
        }
      } else {
        startAt = Math.min(layer.visibility.startAt, startAt);
      }
    }

    return startAt;
  }
}
