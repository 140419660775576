import { Injectable, OnDestroy } from '@angular/core';
import {
  IStreamingParticipant,
  IStreamingLib,
} from '../../interfaces/streaming-lib-service.interface';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import {
  getIdForInvitedUser,
  InvitedUser,
  isSubjectSession,
  SessionBase,
} from '../../interfaces/interfaces';
import { CommonApiService } from '../common-api/common-api.service';
import { DirectorSocketService } from '../socket/director-socket.service';
import {
  OpenreelParticipant,
  OpenreelParticipantStatus,
  RemoteOpenreelParticipant,
} from './openreel-participant';
import { SessionApiService } from './session-api.service';
import { SessionConfigService } from './session-config.service';
import { ToastrService } from 'ngx-toastr';

// This service contains all necessary details that are session-specific. This
// also manages local versions of each participant (for example, each time a
// participant changes resolution, it is managed either here or in
// OpenreelParticipant class specifically). Session is initialized through
// SessionConfigService first, and then after calling initialize() function
@Injectable()
export class SessionBaseService<SessionClass extends SessionBase>
  extends Cleanupable
  implements OnDestroy {
  constructor(
    private commonApi: CommonApiService,
    private sessionConfig: SessionConfigService,
    private sessionApiService: SessionApiService,
    private toastr?: ToastrService
  ) {
    super();
  }

  protected directorSocket?: DirectorSocketService;

  session: SessionClass | null;
  // This is used to for resource links, such as video download links
  companySlug: string;

  private invitedUsers$ = new BehaviorSubject<InvitedUser[]>(null);

  // List of remote participants. May contain offline participants and
  // participants which never entered the session (but were invited to the
  // session)
  participants$ = new BehaviorSubject<RemoteOpenreelParticipant[]>([]);
  myParticipant$ = new BehaviorSubject<OpenreelParticipant>(null);
  // Emitted every time new participant enters the session.
  newParticipant$ = new Subject<RemoteOpenreelParticipant>();

  // Whether or not the session is initialized
  initialized$ = new BehaviorSubject<boolean>(false);

  private isFetchingInvitedUsers = false;

  // Call this function before
  async initialize() {
    await this.setSession(
      this.sessionConfig.storedSessionInfo$.value as SessionClass
    );
  }

  // Utility function that resolves when session is initialized
  async waitForInitialize() {
    await this.cancelablePromise(
      this.initialized$.pipe(
        filter((v) => v),
        first()
      )
    );
  }

  protected createMeParticipant() {
    return new OpenreelParticipant(
      this.session.session_id,
      this,
      this.commonApi
    );
  }

  protected createRemoteParticipant() {
    return new RemoteOpenreelParticipant(
      this.session.session_id,
      this,
      this.commonApi,
      this.directorSocket
    );
  }

  private createParticipant(isRemote: boolean): OpenreelParticipant {
    const ret = isRemote
      ? this.createRemoteParticipant()
      : this.createMeParticipant();
    return ret;
  }

  private async setSession(session: SessionClass) {
    this.session = session;
    if (this.session) {
      this.startFetchInvitedUsers();
      this.initialized$.next(true);
    } else {
      this.initialized$.next(false);
    }
    await this.refreshInvitedUsers(false);
  }
  private async refreshInvitedUsers(forceRefresh: boolean) {
    const invitedUsers = await this.waitForInvitedUsers(forceRefresh);
    if (this.session) {
      const myMapping = OpenreelParticipant.getMappingFromIdentity(
        this.session.identity
      );
      for (const invited of invitedUsers) {
        const id = getIdForInvitedUser(invited);
        if (!this.getParticipantByLoginId(id)) {
          const newParticipant = this.createParticipant(
            myMapping === undefined ||
              invited.ovra_sessionUser_mapping_id !== myMapping
          );
          await newParticipant.setLoginId(id);
          await this.admitNewParticipant(newParticipant);
        }
      }
    }
  }
  // this is called from streaming service
  async setNewParticipants(
    myParticipant: IStreamingParticipant,
    otherParticipants: IStreamingParticipant[],
    roomService: IStreamingLib
  ) {
    this.refreshParticipant(otherParticipants);
    for (const streamParticipant of [myParticipant, ...otherParticipants]) {
      const orParticipant = this.getParticipantByIdentity(
        streamParticipant.identity
      );
      if (!orParticipant) {
        console.log(
          'Creating participant with identity ' +
            streamParticipant.identity +
            ' remote: ' +
            (streamParticipant !== myParticipant)
        );
        const newParticipant = this.createParticipant(
          streamParticipant !== myParticipant
        );
        await newParticipant.setIdentity(streamParticipant.identity);

        const existingParticipant = this.getParticipantByLoginId(
          newParticipant.loginId
        );

        if (existingParticipant) {
          newParticipant.ngOnDestroy();
          await existingParticipant.setIdentity(streamParticipant.identity);
          existingParticipant.subscribeVideo(roomService);
          this.admitNewParticipant(existingParticipant);
        } else {
          newParticipant.subscribeVideo(roomService);
          this.admitNewParticipant(newParticipant);
        }
      }
    }
  }

  // Common utility function that sets appropriate values to appropriate
  // observables. You may pass a duplicate participant, it will be ignored
  private async admitNewParticipant(participant: OpenreelParticipant) {
    if (
      !this.getParticipantByIdentity(participant.identity) &&
      !this.getParticipantByLoginId(participant.loginId)
    ) {
      if (participant instanceof RemoteOpenreelParticipant) {
        this.participants$.next([...this.participants$.value, participant]);
        this.newParticipant$.next(participant);
      } else {
        this.myParticipant$.next(participant);
      }
    }
  }

  updateParticipant(id: number, changes: Partial<OpenreelParticipant>) {
    combineLatest([this.participants$, this.myParticipant$])
      .pipe(take(1))
      .subscribe(([participants, myParticipant]) => {
        if (
          OpenreelParticipant.getMappingFromIdentity(myParticipant.identity) ===
          id
        ) {
          console.log(myParticipant, changes);
          if (myParticipant.isPinned !== changes.isPinned) {
            if (changes.isPinned === true)
              this.toastr.success(
                'The subject can hear and see you',
                'You have been pinned'
              );
            else if (changes.isPinned === false)
              this.toastr.error(
                'You will no longer be seen by the subject',
                'You have been unpinned'
              );
          }
          this.myParticipant$.next(Object.assign(myParticipant, changes));
        } else {
          const newList: RemoteOpenreelParticipant[] = participants.map(
            (participant) => {
              if (
                OpenreelParticipant.getMappingFromIdentity(
                  participant.identity
                ) === id
              )
                return Object.assign(participant, changes);
              return participant;
            }
          );
          this.participants$.next(newList);
        }
      });
  }

  private _checkParticipant(
    firstParticipant: IStreamingParticipant,
    secondParticipant: RemoteOpenreelParticipant
  ) {
    if (firstParticipant.identity === secondParticipant.identity) return true;
    return (
      RemoteOpenreelParticipant.getMappingFromIdentity(
        firstParticipant.identity
      ) === secondParticipant.initialIdentity
    );
  }

  refreshParticipant(newParticipants: IStreamingParticipant[]) {
    const existPartcipants = this.getRemoteOnlineParticipants();
    const newList: RemoteOpenreelParticipant[] = [];
    existPartcipants.forEach((participant) => {
      if (
        newParticipants.some((newPart) =>
          this._checkParticipant(newPart, participant)
        )
      )
        newList.push(participant);
      else participant.ngOnDestroy();
    });

    this.participants$.next(newList);
  }

  getParticipantByLoginId(loginId: number): OpenreelParticipant {
    if (loginId == null) {
      return null;
    }
    return this.getAllParticipants().find((p) => p.loginId === loginId);
  }

  getRemoteParticipantByMapping(mapping: number): RemoteOpenreelParticipant {
    if (mapping == null) {
      return null;
    }
    return this.participants$.value.find(
      (p) => OpenreelParticipant.getMappingFromIdentity(p.identity) === mapping
    );
  }

  getParticipantByMapping(mapping: number): OpenreelParticipant {
    if (mapping == null) {
      return null;
    }
    return this.getAllParticipants().find(
      (p) => OpenreelParticipant.getMappingFromIdentity(p.identity) === mapping
    );
  }

  getRemoteParticipantByLoginId(loginId: number): RemoteOpenreelParticipant {
    if (loginId == null) {
      return null;
    }
    if (loginId != null) {
      return this.participants$.value.find(
        (participant) => participant.loginId === loginId
      );
    }
  }

  getParticipantByIdentity(identity: string): OpenreelParticipant {
    if (!identity) {
      return null;
    }
    if (
      this.myParticipant$.value &&
      this.myParticipant$.value.identity === identity
    ) {
      return this.myParticipant$.value;
    }
    return this.getRemoteParticipantByIdentity(identity);
  }

  getRemoteParticipantByIdentity(identity: string): RemoteOpenreelParticipant {
    if (!identity) {
      return null;
    }
    return this.participants$.value.find(
      (participant) => participant.identity === identity
    );
  }

  getAllParticipants() {
    return [this.myParticipant$.value, ...this.participants$.value].filter(
      (p) => !!p
    );
  }

  getOnlineParticipants() {
    return this.getAllParticipants().filter(
      (p) => p.status$.value === OpenreelParticipantStatus.Connected
    );
  }

  getRemoteOnlineParticipants() {
    return this.participants$.value.filter(
      (p) => p.status$.value === OpenreelParticipantStatus.Connected
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.removeAllParticipants();
  }

  removeAllParticipants() {
    for (const participant of this.participants$.value) {
      participant.ngOnDestroy();
    }

    this.myParticipant$.next(null);
    this.participants$.next([]);
  }

  // Utility function that resolves when invited users are available.
  // Optionally you may force refresh of invited users.
  waitForInvitedUsers(forceRefresh: boolean): Promise<InvitedUser[]> {
    if (
      !this.isFetchingInvitedUsers &&
      (!this.invitedUsers$.value || forceRefresh)
    ) {
      this.startFetchInvitedUsers();
    }
    return this.invitedUsers$
      .pipe(
        filter((data) => !!data),
        take(1)
      )
      .toPromise();
  }

  private startFetchInvitedUsers() {
    this.isFetchingInvitedUsers = true;
    this.invitedUsers$.next(null);
    if (this.session) {
      if (isSubjectSession(this.session)) {
        this.subscriptions.push(
          this.commonApi
            .getSessionDetails({
              identity: this.session.identity,
              session_id: this.session.session_id,
              login_id: this.session.login_id,
            })
            .subscribe((data) => {
              this.invitedUsers$.next(data.sessionresult.invited_users);
              this.isFetchingInvitedUsers = false;
            })
        );
      } else {
        this.sessionApiService
          .getSessionData(this.session.session_id)
          .subscribe((resp) => {
            this.invitedUsers$.next(
              resp?.data?.sessiondetails?.invited_users || []
            );
            this.isFetchingInvitedUsers = false;
          });
      }
    }
  }
}
