<div
  class="flex-col flex-align-center flex-justify-evenly h-full"
  *ngIf="{
    progress: progress$ | async,
    cancelling: cancelling$ | async
  } as render"
>
  <div></div>
  <div class="m-bottom-60 text-center">
    <p class="m-bottom-20 f-light-14">
      Please be patient as we are
    </p>
    <h1 class="m-bottom-20">Building your video...</h1>
    <progress
      class="m-bottom-20 progress"
      max="100"
      [value]="render.progress"
    ></progress>
    <div class="f-light-12">We are {{ render.progress }}% done</div>
  </div>

  <button
    type="button"
    mat-stroked-button
    class="mat-button-action"
    color="primary"
    [disabled]="render.cancelling"
    (click)="onCancel()"
  >
    Cancel build
  </button>
</div>
