import * as fromActions from '../actions/project.actions';
import * as fromSelectors from '../selectors/project.selectors';
import * as fromTemplateSelectors from '../selectors/template.selectors';

import { Actions, ofType } from '@ngrx/effects';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WorkflowDataDto } from '../../../api/workflow.interfaces';
import { map } from 'rxjs/operators';
import { AfterUpdate } from '../interfaces/editor.interface';

@Injectable({
  providedIn: 'root',
})
export class ProjectFacade {
  projectCreated$ = this.actions$.pipe(
    ofType(fromActions.createProjectSuccess)
  );

  projectUpdated$ = this.actions$.pipe(
    ofType(fromActions.updateProjectSuccess)
  );

  id$ = this.store.select(fromSelectors.selectProjectId);
  templateType$ = this.store.select(fromSelectors.selectProjectTemplateType);

  projectName$ = this.store
    .select(fromSelectors.selectProject)
    .pipe(map((p) => p?.data?.name));

  captureProjectId$ = this.store
    .select(fromSelectors.selectProject)
    .pipe(map((p) => p?.data?.captureProjectId));

  data$ = this.store.select(fromSelectors.selectProject);
  workflow$ = this.store.select(fromSelectors.selectProjectWorkflow);

  features$ = this.store.select(fromSelectors.selectProjectFeatures);

  mainTimeline$ = this.store.select(fromSelectors.selectProjectMainTimeline);

  renderProgress$ = this.store.select(fromSelectors.selectRenderProgress);
  isRenderInProgress$ = this.store.select(
    fromSelectors.selectIsRenderInProgress
  );

  isWorkflowLoaded$ = this.store.select(
    fromSelectors.selectProjectLoadFinished
  );

  mainClipsCount$ = this.store
    .select(fromSelectors.selectProjectSections)
    .pipe(
      map(
        (sections) =>
          sections?.main?.timelines?.find((t) => t.type === 'main').layers
            .length || 0
      )
    );

  // Template
  templateWorkflow$ = this.store.select(
    fromTemplateSelectors.selectTemplateWorkflow
  );
  templateColors$ = this.store.select(
    fromTemplateSelectors.selectTemplateColors
  );

  constructor(private store: Store, private actions$: Actions) {}

  introOutroTimelines$(intro: boolean) {
    return this.store.select(fromSelectors.selectProjectIntroOutroTimelines, {
      intro,
    });
  }

  loadProject(projectId: number) {
    this.store.dispatch(fromActions.loadProjectAPI({ projectId }));
  }

  closeProject() {
    this.store.dispatch(fromActions.closeProject());
  }

  createProject(
    captureProjectId: number,
    templateId: number,
    captureVideoIds: number[]
  ) {
    this.store.dispatch(
      fromActions.createProjectAPI({
        captureProjectId,
        templateId,
        captureVideoIds,
      })
    );
  }

  updateProjectName(name: string, afterUpdate = AfterUpdate.NextStep) {
    this.store.dispatch(
      fromActions.updateProjectNameAPI({ name, afterUpdate })
    );
  }

  updateProjectStyling(afterUpdate = AfterUpdate.NextStep) {
    this.store.dispatch(fromActions.updateProjectStylingAPI({ afterUpdate }));
  }

  updateProjectBrandKit(
    brandKitId: number | null,
    afterUpdate = AfterUpdate.NextStep
  ) {
    this.store.dispatch(
      fromActions.updateProjectBrandKitAPI({ brandKitId, afterUpdate })
    );
  }

  updateProjectIntroOutro(
    intro: boolean,
    enabled: boolean,
    afterUpdate = AfterUpdate.NextStep
  ) {
    this.store.dispatch(
      fromActions.updateProjectIntroOutroAPI({ intro, enabled, afterUpdate })
    );
  }

  updateProjectVideoElements(afterUpdate = AfterUpdate.NextStep) {
    this.store.dispatch(
      fromActions.updateProjectVideoElementsAPI({ afterUpdate })
    );
  }

  saveDraftWorkflowData(draftWorkflowData: WorkflowDataDto) {
    this.store.dispatch(
      fromActions.saveDraftWorkflowData({ draftWorkflowData })
    );
  }

  renderProject() {
    this.store.dispatch(fromActions.renderProject());
  }

  cancelProjectRender() {
    this.store.dispatch(fromActions.cancelProjectRender());
  }
}
