import { BehaviorSubject } from 'rxjs';
import { VideoConstraints } from '../media';

export interface IStreamingParticipant {
  audioStream$: BehaviorSubject<MediaStreamTrack>;
  videoStream$: BehaviorSubject<MediaStreamTrack>;
  audioMuted$: BehaviorSubject<boolean>;
  videoMuted$: BehaviorSubject<boolean>;
  identity: string;
  id: string;
}

export interface IStreamingLib {
  remoteParticipants$: BehaviorSubject<IStreamingParticipant[]>;
  myParticipant$: BehaviorSubject<IStreamingParticipant>;
  networkQuality$: BehaviorSubject<number>;
  connectionStatus$: BehaviorSubject<boolean>;
  broadcastVideo(stream: MediaStreamTrack);
  changeVideoParameters(constraints: VideoConstraints);
  broadcastAudio(stream: MediaStreamTrack);
  muteVideo();
  muteAudio();
  unmuteVideo();
  unmuteAudio();
  disconnect();
}
export abstract class IStreamingLibService {
  abstract getStreaming(
    token: string,
    roomName: string
  ): Promise<IStreamingLib>;
}
