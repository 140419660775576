import * as fromActions from './../actions/template.actions';
import * as fromProjectActions from './../actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TemplateService } from '../../../api/template/template.service';
import { of } from 'rxjs';

@Injectable()
export class TemplateEffects {
  loadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromProjectActions.loadProjectSuccess),
      switchMap(({ data }) =>
        this.templateService
          .getTemplateById(data.templateId)
          .pipe(map((data) => fromActions.loadTemplateSuccess({ data })))
      ),
      catchError((error) => of(error))
    )
  );

  constructor(
    private readonly templateService: TemplateService,
    private readonly actions$: Actions
  ) {}
}
