import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { components, entryComponents } from './components';
import { LayerService } from './services/layer.service';
import { ColorPickerModule } from '@iplab/ngx-color-picker';

@NgModule({
  declarations: [...components],
  imports: [ReactiveFormsModule, SharedModule, ColorPickerModule],
  exports: [...components],
  entryComponents: [...entryComponents],
  providers: [LayerService],
})
export class LayersModule {}
