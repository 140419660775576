import { SessionBase, isSubjectSession, SubjectSession } from './interfaces';
import { SessionBaseService } from '../services/session/session-base.service';

export interface SessionHolder<SessionClass extends SessionBase> {
  session: SessionBaseService<SessionClass>;
}

export function isSessionHolder(
  component: SessionHolder<SessionBase>
): component is SessionHolder<SessionBase> {
  return component && !!(component as SessionHolder<SessionBase>).session;
}

export function isSubjectSessionHolder(
  component: SessionHolder<SubjectSession>
): component is SessionHolder<SubjectSession> {
  return (
    isSessionHolder(component) && isSubjectSession(component.session.session)
  );
}
