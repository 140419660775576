import { NgModule } from '@angular/core';
import { AngularResizedEventModule } from 'angular-resize-event';
import { AspectRatioDirective } from './aspect-ratio.directive';
import { ImgFallbackDirective } from './img-fallback.directive';

@NgModule({
  declarations: [ImgFallbackDirective, AspectRatioDirective],
  imports: [AngularResizedEventModule],
  exports: [ImgFallbackDirective, AspectRatioDirective],
})
export class OpenreelCommonDirectivesModule {}
