import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    public router: Router,
    private location: Location
  ) {}

  /**
   * TODO: use isAuthenticated$ observable instead
   */
  canActivate() {
    if (this.authService.isInternalUser()) {
      return true;
    } else {
      const redirect = this.location.path(true);

      this.router.navigate(['login'], {
        queryParams: {
          redirect: redirect,
        },
      });
    }
  }
}
