import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  ClipTypes,
  Option,
} from '../../store/interfaces/clip-selection.interfaces';

const OPTIONS: Option[] = [{ type: ClipTypes.MyProjects, name: 'My Projects' }];

@Component({
  selector: 'openreel-wf-select-files-sidebar',
  templateUrl: './select-files-sidebar.component.html',
  styleUrls: ['./select-files-sidebar.component.scss'],
})
export class SelectFilesSidebarComponent implements OnInit {
  @Output() selected = new EventEmitter<ClipTypes>();

  selectedType: ClipTypes = ClipTypes.MyProjects;
  ClipTypesEnum = ClipTypes;

  options = OPTIONS;

  ngOnInit(): void {
    this.selected.emit(this.selectedType);
  }

  onSelect(option: Option) {
    this.selectedType = option.type;
    this.selected.emit(this.selectedType);
  }
}
