import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TimelineType } from '../../../../api/workflow.interfaces';
import {
  ClipAddEvent,
  TextOverlayAddEvent,
  ClipMoveEvent,
  ItemMovedEvent,
  TimelineItemEvent,
  OpenTextOverlayDataDialogEvent,
  StretchTextOverlayEvent,
  UpdateTextOverlayDataEvent,
  UpdateTextOverlayMetadataEvent,
} from '../interfaces/timelines.interfaces';
import * as fromActions from './../actions/timelines.actions';
import * as fromSelectors from './../selectors/timelines.selectors';

@Injectable({
  providedIn: 'root',
})
export class TimelinesFacade {
  timelines$ = this.store.select(fromSelectors.selectTimelines);

  textOverlaysTimeline$ = this.store.select(
    fromSelectors.selectTextOverlayTimeline
  );
  videoOverlaysTimeline$ = this.store.select(
    fromSelectors.selectVideoOverlayTimeline
  );

  duration$ = this.store.select(fromSelectors.selectDuration);
  introDuration$ = this.store.select(fromSelectors.selectIntroDuration);
  outroDuration$ = this.store.select(fromSelectors.selectOutroDuration);
  totalDuration$ = this.store.select(fromSelectors.selectTotalDuration);
  allDurations$ = this.store.select(fromSelectors.selectAllDurations);

  sentToTrimmer$ = this.store.select(fromSelectors.selectSendToTrimmer);

  constructor(private store: Store, private actions$: Actions) {}

  openSelectFilesDialog(timelineType: TimelineType) {
    this.store.dispatch(fromActions.openSelectFilesDialog({ timelineType }));
  }

  addMainClip(event: ClipAddEvent[]) {
    this.store.dispatch(
      fromActions.addMainClip({ timelineType: 'main', event })
    );
  }

  removeMainClip(event: TimelineItemEvent) {
    this.store.dispatch(fromActions.removeMainClip({ event }));
  }

  moveMainClip(event: ClipMoveEvent) {
    this.store.dispatch(fromActions.moveMainClip({ event }));
  }

  addVideoOverlay(event: ClipAddEvent[]) {
    this.store.dispatch(
      fromActions.addVideoOverlay({ timelineType: 'b-roll', event })
    );
  }

  changeVideoOverlayType(event: TimelineItemEvent) {
    this.store.dispatch(fromActions.changeVideoOverlayType({ event }));
  }

  addTextOverlay(event: TextOverlayAddEvent) {
    this.store.dispatch(fromActions.addTextOverlay({ event }));
  }

  openTextOverlayDataDialog(event: OpenTextOverlayDataDialogEvent) {
    this.store.dispatch(fromActions.openTextOverlayDataDialog({ event }));
  }

  stretchTextOverlay(event: StretchTextOverlayEvent) {
    this.store.dispatch(fromActions.stretchTextOverlay({ event }));
  }

  updateTextOverlayData(event: UpdateTextOverlayDataEvent) {
    this.store.dispatch(fromActions.updateTextOverlayData({ event }));
  }

  updateTextOverlayMetadata(event: UpdateTextOverlayMetadataEvent) {
    this.store.dispatch(fromActions.updateTextOverlayMetadata({ event }));
  }

  moveItem(event: ItemMovedEvent) {
    this.store.dispatch(fromActions.moveItem({ event }));
  }

  removeOverlay(event: TimelineItemEvent) {
    this.store.dispatch(fromActions.removeOverlay({ event }));
  }

  duplicateClip(event: TimelineItemEvent) {
    this.store.dispatch(fromActions.duplicateClip({ event }));
  }

  // Timeline actions
  switchTimelines(event: TimelineItemEvent) {
    this.store.dispatch(fromActions.switchTimelines({ event }));
  }

  replaceMainClips(event: TimelineItemEvent) {
    this.store.dispatch(fromActions.replaceMainClips({ event }));
  }

  editClipTrim(event: TimelineItemEvent) {
    this.store.dispatch(fromActions.editClipTrim({ event }));
  }
}
