import * as Sentry from '@sentry/browser';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    console.error('Captured error from sentry: ', error);
    throw error;
  }
}
