import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SingleInvitation, UserRoleType } from '../../interfaces/interfaces';

@Injectable({
  providedIn: 'root',
})
export class InvitationsService {
  public urls = {
    subject: commonenv.nextGenApiUrl + 'invitations/send-appinvite-link',
    collaborator: commonenv.nextGenApiUrl + 'invitations/send-invite-link',
    director: commonenv.nextGenApiUrl + 'company/invite-directors',
  };
  constructor(public httpClient: HttpClient) {}

  inviteCollaborator(
    session_id: number,
    email: string,
    role: UserRoleType,
    name?: string
  ) {
    const data = {
      session_id,
      user_data: JSON.stringify([
        {
          email,
          role,
          name,
        },
      ]),
    };
    return this.httpClient.post(this.urls.collaborator, data);
  }
  inviteSubject(session_id: number, email: string, name?: string) {
    const data = {
      session_id,
      invite_type: 'email',
      user_data: JSON.stringify([
        {
          email,
          name,
        },
      ]),
    };
    return this.httpClient.post(this.urls.subject, data);
  }
  async inviteFromListCreator(
    session_id: number,
    invitations: SingleInvitation[]
  ): Promise<number> {
    const promises: Promise<unknown>[] = [];
    let sent = 0;
    for (const inv of invitations) {
      if (!!inv.email && !!inv.name) {
        if (inv.type === UserRoleType.Subject) {
          promises.push(
            this.inviteSubject(session_id, inv.email, inv.name).toPromise()
          );
        } else {
          promises.push(
            this.inviteCollaborator(
              session_id,
              inv.email,
              inv.type,
              inv.name
            ).toPromise()
          );
        }
        sent++;
      }
    }
    await Promise.all(promises);
    return sent;
  }

  inviteDirectors(invitations: SingleInvitation[]) {
    return this.httpClient.post(this.urls.director, { directors: invitations });
  }
}
