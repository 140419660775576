import * as fromStore from './store';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { EffectsModule } from '@ngrx/effects';
import { LayersModule } from '../layers/layers.module';
import { LoaderModule } from '../shared/modules/loader/loader.module';
import { MainClipsEffects } from './store/effects/main-clips.effects';
import { MainClipsTimelinesEffects } from './store/effects/main-clips-timelines.effects';
import { NgModule } from '@angular/core';
import { OverlaysEffects } from './store/effects/overlays.effects';
import { SharedModule } from '../shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { TextOverlaysEffects } from './store/effects/text-overlays.effects';
import { TimelineItemPositionsService } from './services/timeline-item-positions.service';
import { TimelinesEffects } from './store/effects/timelines.effects';
import { components } from './components';
import { pipes } from './pipes';
import { TrimmerEffects } from './store/effects/trimmer.effects';

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    SharedModule,
    LayersModule,
    DragDropModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers),
    EffectsModule.forFeature([
      TimelinesEffects,
      MainClipsEffects,
      MainClipsTimelinesEffects,
      OverlaysEffects,
      TextOverlaysEffects,
      TrimmerEffects,
    ]),
    LoaderModule,
  ],
  exports: [...components],
  providers: [TimelineItemPositionsService],
})
export class TimelinesModule {}
