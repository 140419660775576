import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
  CameraPresetCreate,
  DirectorSession,
} from '../../interfaces/interfaces';
import {
  EVT_TOGGLE_PIN_STATUS,
  TogglePinStatus,
} from '../../interfaces/socket-events';
import { CommonApiService } from '../common-api/common-api.service';
import { SessionApiService } from '../session/session-api.service';
import { SessionBaseService } from '../session/session-base.service';
import { SessionConfigService } from '../session/session-config.service';
import { DirectorSocketService } from '../socket/director-socket.service';
import { DirectorApiService } from './director-api/director-api.service';

@Injectable()
export class DirectorSessionService extends SessionBaseService<DirectorSession> {
  constructor(
    commonApi: CommonApiService,
    sessionConfig: SessionConfigService,
    private directorApi: DirectorApiService,
    protected directorSocket: DirectorSocketService,
    sessionApiService: SessionApiService,
    toastr: ToastrService
  ) {
    super(commonApi, sessionConfig, sessionApiService, toastr);

    this.listenPinStatus();
  }
  private listenPinStatus() {
    this.directorSocket
      .getSocketEventByName<TogglePinStatus>(EVT_TOGGLE_PIN_STATUS)
      .subscribe((data) => {
        this.updateParticipant(+data.data.ovra_user_id, {
          isPinned: data.data.status,
        });
      });
  }

  async removeCameraPreset(presetId: number) {
    await this.directorApi.removeCameraPreset(
      presetId,
      this.session.session_id
    );
    this.session.camera_presets = this.session.camera_presets.filter(
      (preset) => preset.ovra_cameraSetting_presets_id !== presetId
    );
  }
  async addCameraPreset(preset: CameraPresetCreate) {
    const response = await this.directorApi.addCameraPreset(preset);
    this.session.camera_presets.push({
      ...preset,
      ovra_cameraSetting_presets_id: response.ovra_cameraSetting_presets_id,
    });
  }
  async initialize() {
    await super.initialize();
  }
}
