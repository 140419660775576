import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  asWebcamConstraints,
  defaultWebcamConstraints,
  findTrackCapabilities,
} from '../helpers/capabilities.helper';
import { applyConstraints } from '../helpers/media-devices.helper';
import { VideoConstraints } from '../interfaces/video-constraints.interface';
import { VideoDevice } from '../interfaces/video-device.interface';
import { VideoSource } from '../interfaces/video-source.interface';
import { VideoStream } from '../interfaces/video-stream.interface';
import { MediaDevicesService } from './media-devices.service';

@Injectable()
export class WebcamService {
  devices$: Observable<VideoDevice[]>;

  constructor(readonly mediaDevicesService: MediaDevicesService) {
    this.devices$ = mediaDevicesService.devices$.pipe(
      map((devices) =>
        devices.filter((device) => device.kind === 'videoinput')
      ),
      map((devices) =>
        devices.map(({ deviceId, label }) => ({
          name: label,
          id: deviceId,
          source: VideoSource.WEBCAM,
        }))
      )
    );
  }

  async openStream(
    deviceId?: string,
    constraints?: VideoConstraints
  ): Promise<VideoStream> {
    // If no specific constraints were requested, applies the default ones
    const constraintsToApply = constraints
      ? asWebcamConstraints(constraints)
      : defaultWebcamConstraints;

    if (deviceId) {
      constraintsToApply.deviceId = deviceId;
    }

    const stream = await this.mediaDevicesService.openVideoStream(
      constraintsToApply
    );
    const track = stream.getTracks()[0];
    const settings = track.getSettings();
    const capabilities = await findTrackCapabilities(track);

    return {
      device: {
        id: settings.deviceId,
        name: track.label,
        source: VideoSource.WEBCAM,
      },
      track,
      stream,
      ...capabilities,
    };
  }

  async applyConstraintsToStream(
    video: VideoStream,
    constraints: VideoConstraints
  ) {
    try {
      await applyConstraints(
        video.track,
        video.device.id,
        asWebcamConstraints(constraints)
      );
    } catch (err) {
      console.error(err);
    }
  }
}
