import { Step } from './steps.interface';

export enum AfterUpdate {
  None = 0,
  NextStep,
  CloseProject,
}

export interface Editor {
  steps: Step[];
  step: number;
  childStep: number;
  maxStep: number;
  lastActionSaved: boolean;
}
