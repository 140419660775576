import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RedirectToCaptureGuard implements CanActivate {
  constructor(public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const link = route.queryParams?.redirect
      ? `${environment.nextGenAppUrl}#/login?redirect=${environment.workflowsAppUrl}/#${route.queryParams.redirect}`
      : `${environment.nextGenAppUrl}#/dashboard`;

    window.location.href = link;
    return true;
  }
}
