import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Cleanupable } from '../../../classes/cleanupable';
import {
  mapStreamSettingCapability,
  mapStreamSettingCapabilityFromZero,
  mapStreamSettingCapabilityToZero,
  StreamSettingCapability,
} from '../../../interfaces';
import {
  AppInputAudio,
  AppMicrophoneRange,
  AudioIdentity,
  DeviceHardwarePerformanceAck,
  DeviceJoinRequest,
  DeviceNetworkSpeedAck,
  EVT_D2S_AUDIO_IDENTITY,
  EVT_D2S_DEVICE_HARDWARE_PERFORMANCE,
  EVT_D2S_DEVICE_NETWORK_SPEED,
  EVT_D2S_INPUT_AUDIO,
  EVT_D2S_SET_ALL_CAMERA_SETTINGS,
  EVT_D2S_SET_AUTO_EXPOSURE,
  EVT_D2S_SET_COLOR_TEMPERATURE,
  EVT_D2S_SET_CONTRAST,
  EVT_D2S_SET_EXPOSURE,
  EVT_D2S_SET_FPS,
  EVT_D2S_SET_ISO,
  EVT_D2S_SET_RESOLUTION,
  EVT_S2D_APP_MICROPHONE_RANGE,
  EVT_S2D_AUDIO_IDENTITY_ACK,
  EVT_S2D_DEVICE_HARDWARE_PERFORMANCE_ACK,
  EVT_S2D_DEVICE_JOIN,
  EVT_S2D_DEVICE_NETWORK_SPEED_ACK,
  EVT_S2D_INPUT_AUDIO_ACK,
  EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
  EVT_S2D_SET_AUTO_EXPOSURE_ACK,
  EVT_S2D_SET_COLOR_TEMPERATURE_ACK,
  EVT_S2D_SET_CONTRAST_ACK,
  EVT_S2D_SET_EXPOSURE_ACK,
  EVT_S2D_SET_FRAMERATE_ACK,
  EVT_S2D_SET_ISO_ACK,
  EVT_S2D_SET_RESOLUTION_ACK,
  EVT_D2S_SHOW_GRID,
  SetShowGridRequest,
  EVT_S2D_SHOW_GRID_ACK,
  FrameRateSetAck,
  ResolutionSetAck,
  SetAllCameraSettingsRequest,
  SetAllCameraSettingsResponse,
  SetAutoExposureRequest,
  SetColorTemperatureRequest,
  SetContrastRequest,
  SetExposureRequest,
  SetFramerateRequest,
  SetIsoRequest,
  SetResolutionRequest,
  TogglePinStatus,
  EVT_TOGGLE_PIN_STATUS,
} from '../../../interfaces/socket-events';
import {
  VideoConstraints,
  VideoStream,
  VideoStreamService,
} from '../../../media';
import {
  SocketAckTimeout,
  SubjectSocketService,
} from '../../socket/subject-socket.service';

const ERR_DIRECTOR_NOT_CONNECTED =
  'Error joining the room. Maybe the director has not joined yet?';

interface SettingHandler {
  turnOffSetting?: (constraints: VideoConstraints) => void;
  turnOnSetting?: (constraints: VideoConstraints) => void;
  currentValue: number;
  value: number | string;
  type?: 'plus' | 'minus' | 'set';
  directorSliderMapping: StreamSettingCapability;
  hardwareMapping: StreamSettingCapability;
  videoConstraintKey: keyof VideoConstraints;
  doApply: boolean;
  valueChecker: (hardwareValue: number) => void;
}

@Injectable()
export class SocketExtensionDeviceService extends Cleanupable {
  setResolution$: Observable<SetResolutionRequest>;
  setFramerate$: Observable<SetFramerateRequest>;
  audioIdentity$: Observable<AudioIdentity>;
  inputAudio$: Observable<AppInputAudio>;
  deviceHardwarePerformance$: Observable<void>;
  deviceNetworkSpeed$: Observable<void>;
  setShowGrid$: Observable<SetShowGridRequest>;
  togglePinStatus$: Observable<TogglePinStatus>;

  private setAllCameraSettings$: Observable<SetAllCameraSettingsRequest>;
  private setExposureRequest$: Observable<SetExposureRequest>;
  private setAutoExposureRequest$: Observable<SetAutoExposureRequest>;
  private setIsoRequest$: Observable<SetIsoRequest>;
  private setContrastRequest$: Observable<SetContrastRequest>;
  private setColorTemperatureRequest$: Observable<SetColorTemperatureRequest>;

  constructor(
    private socket: SubjectSocketService,
    private video: VideoStreamService
  ) {
    super();

    this.setResolution$ = socket.getMySocketEventByName(EVT_D2S_SET_RESOLUTION);
    this.audioIdentity$ = socket.getMySocketEventByName(EVT_D2S_AUDIO_IDENTITY);
    this.inputAudio$ = socket.getMySocketEventByName(EVT_D2S_INPUT_AUDIO);
    this.setFramerate$ = socket.getMySocketEventByName(EVT_D2S_SET_FPS);
    this.setShowGrid$ = socket.getMySocketEventByName(EVT_D2S_SHOW_GRID);
    this.togglePinStatus$ = socket.getSocketEventByName(EVT_TOGGLE_PIN_STATUS);
    this.deviceHardwarePerformance$ = socket.getMySocketEventByName(
      EVT_D2S_DEVICE_HARDWARE_PERFORMANCE
    );
    this.deviceNetworkSpeed$ = socket.getMySocketEventByName(
      EVT_D2S_DEVICE_NETWORK_SPEED
    );

    this.setAllCameraSettings$ = socket.getMySocketEventByName(
      EVT_D2S_SET_ALL_CAMERA_SETTINGS
    );
    this.setExposureRequest$ = socket.getMySocketEventByName(
      EVT_D2S_SET_EXPOSURE
    );
    this.setAutoExposureRequest$ = socket.getMySocketEventByName(
      EVT_D2S_SET_AUTO_EXPOSURE
    );
    this.setIsoRequest$ = socket.getMySocketEventByName(EVT_D2S_SET_ISO);
    this.setContrastRequest$ = socket.getMySocketEventByName(
      EVT_D2S_SET_CONTRAST
    );
    this.setColorTemperatureRequest$ = socket.getMySocketEventByName(
      EVT_D2S_SET_COLOR_TEMPERATURE
    );

    this.listenToSubscriptions();
  }

  private listenToSubscriptions() {
    this.subscriptions.push(
      this.setExposureRequest$.subscribe((req) => {
        this.handleMessageCommon(
          () => this.changeExposure(req),
          EVT_S2D_SET_EXPOSURE_ACK
        );
      })
    );
    this.subscriptions.push(
      this.setAutoExposureRequest$.subscribe((req) => {
        this.handleMessageCommon(
          () => this.handleAutoExposure(req),
          EVT_S2D_SET_AUTO_EXPOSURE_ACK
        );
      })
    );
    this.subscriptions.push(
      this.setIsoRequest$.subscribe((req) => {
        this.handleMessageCommon(
          () => this.changeIso(req),
          EVT_S2D_SET_ISO_ACK
        );
      })
    );
    this.subscriptions.push(
      this.setContrastRequest$.subscribe((req) => {
        this.handleMessageCommon(
          () => this.changeContrast(req),
          EVT_S2D_SET_CONTRAST_ACK
        );
      })
    );
    this.subscriptions.push(
      this.setColorTemperatureRequest$.subscribe((req) => {
        this.handleMessageCommon(
          () => this.changeColorTemperature(req),
          EVT_S2D_SET_COLOR_TEMPERATURE_ACK
        );
      })
    );
    this.subscriptions.push(
      this.setAllCameraSettings$.subscribe((req) => {
        this.handleSetAllCameraParametersSoft(req);
      })
    );
  }

  async joinDevice(deviceInfo: DeviceJoinRequest) {
    // If this call times out, it means that director has not joined the room.
    try {
      await this.socket.sendAndWaitAck({
        eventName: EVT_S2D_DEVICE_JOIN,
        data: deviceInfo,
      });
    } catch (err) {
      if (err instanceof SocketAckTimeout) {
        throw new Error(ERR_DIRECTOR_NOT_CONNECTED);
      }
      throw err;
    }
  }
  sendSetResolutionAck(
    width: number,
    height: number,
    fps: number,
    stat: '0' | '1'
  ) {
    const resp: ResolutionSetAck = {
      stat,
      fps: fps.toString(),
      value: height.toString(),
      width: width.toString(),
    };
    this.socket.emitSocket({
      eventName: EVT_S2D_SET_RESOLUTION_ACK,
      data: resp,
    });
  }
  sendSetFrameRateAck(fps: number, stat: '0' | '1') {
    const resp: FrameRateSetAck = {
      stat,
      frame_rate: fps.toString(),
    };
    this.socket.emitSocket({
      eventName: EVT_S2D_SET_FRAMERATE_ACK,
      data: resp,
    });
  }
  sendShowGridAck(showGridLines: boolean) {
    const data = { showGridLines };
    this.socket.emitSocket({
      eventName: EVT_S2D_SHOW_GRID_ACK,
      data,
    });
  }
  sendAudioIdentityAck(stat: number) {
    this.socket.emitSocket({
      data: {
        stat: stat.toString(),
      },
      eventName: EVT_S2D_AUDIO_IDENTITY_ACK,
    });
  }
  sendInputAudioAck(stat: number) {
    this.socket.emitSocket({
      data: {
        stat: stat.toString(),
      },
      eventName: EVT_S2D_INPUT_AUDIO_ACK,
    });
  }
  sendAppMicrophoneRange(micRange: AppMicrophoneRange) {
    this.socket.emitSocket({
      data: micRange,
      eventName: EVT_S2D_APP_MICROPHONE_RANGE,
    });
  }
  sendDeviceHardwarePerformanceAck(performance: DeviceHardwarePerformanceAck) {
    this.socket.emitSocket({
      data: performance,
      eventName: EVT_S2D_DEVICE_HARDWARE_PERFORMANCE_ACK,
    });
  }
  sendDeviceNetworkSpeedAck(speed: DeviceNetworkSpeedAck) {
    this.socket.emitSocket({
      data: speed,
      eventName: EVT_S2D_DEVICE_NETWORK_SPEED_ACK,
    });
  }

  private async handleAutoExposure(req: SetAutoExposureRequest) {
    if (req.value === '0') {
      await this.turnOffExposure();
    } else {
      return await this.turnOnExposure();
    }
    return -1;
  }

  private async turnOffExposure() {
    const constraints = await this.getCurrentConstraints();
    await this.video.changeStreamParameters({
      ...constraints,
      exposure: undefined,
    });
  }

  private async turnOnExposure() {
    return await this.changeExposure({
      value: '0',
      type: 'plus',
    });
  }

  private async handleMessageCommon(
    handler: () => Promise<number | VideoConstraints>,
    eventName: string
  ) {
    try {
      const value = await handler();
      this.socket.emitSocket({
        data: {
          stat: '1',
          value: value.toString(),
        },
        eventName,
      });
    } catch (err) {
      this.socket.emitSocket({
        data: {
          stat: '0',
          message: err.message,
        },
        eventName,
      });
    }
  }

  private async changeExposure(req: SetExposureRequest, doApply = true) {
    const stream = await this.getCurrentVideoStream();
    const constraints = await this.getCurrentConstraints();
    return await this.changeAnyValue({
      currentValue: constraints.exposure,
      directorSliderMapping: stream?.supportedManualExposureRangeDirector,
      hardwareMapping: stream?.supportedManualExposureRange,
      ...req,
      valueChecker: (value) =>
        this.checkCapabilityRange(
          stream.supportedManualExposureRange,
          value,
          true
        ),
      videoConstraintKey: 'exposure',
      doApply,
    });
  }

  private async changeIso(req: SetIsoRequest, doApply = true) {
    const stream = await this.getCurrentVideoStream();
    const constraints = await this.getCurrentConstraints();
    return await this.changeAnyValue({
      currentValue: constraints.iso,
      directorSliderMapping: stream?.supportedManualIsoRangeDirector,
      hardwareMapping: stream?.supportedManualIsoRange,
      turnOffSetting: (constraints) => delete constraints['exposure'],
      ...req,
      valueChecker: (value) =>
        this.checkCapabilityRange(stream.supportedManualIsoRange, value, true),
      videoConstraintKey: 'iso',
      turnOnSetting: (constraints) => (constraints.exposure = 0),
      doApply,
    });
  }

  private async changeContrast(req: SetContrastRequest, doApply = true) {
    const stream = await this.getCurrentVideoStream();
    const constraints = await this.getCurrentConstraints();
    return await this.changeAnyValue({
      currentValue: constraints.contrast,
      directorSliderMapping: stream?.supportedManualContrastRangeDirector,
      hardwareMapping: stream?.supportedManualContrastRange,
      ...req,
      valueChecker: (value) =>
        this.checkCapabilityRange(
          stream.supportedManualContrastRange,
          value,
          true
        ),
      videoConstraintKey: 'contrast',
      doApply,
    });
  }

  private async changeColorTemperature(
    req: SetColorTemperatureRequest,
    doApply = true
  ) {
    const stream = await this.getCurrentVideoStream();
    const constraints = await this.getCurrentConstraints();
    return await this.changeAnyValue({
      currentValue: constraints.colorTemperature,
      directorSliderMapping: {
        min: 1,
        max: 100,
        step: 1,
      },
      hardwareMapping: stream.supportedManualColorTemperatureRange,
      ...req,
      valueChecker: (value) =>
        this.checkCapabilityRange(
          stream.supportedManualColorTemperatureRange,
          value,
          true
        ),
      videoConstraintKey: 'colorTemperature',
      doApply,
    });
  }

  private async changeAnyValue(
    handler: SettingHandler
  ): Promise<VideoConstraints> {
    let value =
      typeof handler.value === 'string'
        ? parseFloat(handler.value)
        : handler.value;
    const constraints = await this.getCurrentConstraints();
    const params = {
      ...constraints,
    };
    Object.keys(params).forEach(
      (key) => params[key] === undefined && delete params[key]
    );

    if (!handler.type && value === -1) {
      delete params[handler.videoConstraintKey];

      if (handler.doApply) {
        if (handler.turnOffSetting) {
          handler.turnOffSetting(params);
        }
        await this.video.changeStreamParameters(params);
      }
    } else {
      // convert -4 - +4 to 0-9
      const currentConvertedValue = mapStreamSettingCapabilityToZero(
        handler.directorSliderMapping,
        // map hardware value to -4 - +4
        mapStreamSettingCapability(
          handler.hardwareMapping,
          handler.directorSliderMapping,
          handler.currentValue
        )
      );
      if (currentConvertedValue === undefined) {
        throw new Error(
          `Current ${handler.videoConstraintKey} value is undefined. Maybe it is not supported?`
        );
      }
      if (handler.type === 'minus') {
        value = currentConvertedValue - value;
      }
      if (handler.type === 'plus') {
        value = currentConvertedValue + value;
      }
      // map -4 - +4 to hardware value
      const hardwareValue = mapStreamSettingCapability(
        handler.directorSliderMapping,
        handler.hardwareMapping,
        // map 0 - 9 to -4 - +4
        mapStreamSettingCapabilityFromZero(handler.directorSliderMapping, value)
      );
      handler.valueChecker(hardwareValue);
      params[handler.videoConstraintKey] = hardwareValue;
      if (handler.doApply) {
        if (handler.turnOnSetting) {
          handler.turnOnSetting(params);
        }
        await this.video.changeStreamParameters(params);
      }
    }
    return params;
  }

  private async handleSetAllCameraParametersSoft(
    req: SetAllCameraSettingsRequest
  ) {
    try {
      const errors: string[] = [];
      const constraints = await this.getCurrentConstraints();
      const otherConstraints = await this.tryChangeConstraints(req, errors);

      await this.video.changeStreamParameters({
        ...constraints,
        ...otherConstraints,
        fps: parseInt(req.fps, 10),
        height: parseInt(req.resolution, 10),
        width: parseInt(req.width, 10),
      });

      const data = await this.getAllCameraSettings();
      if (errors) {
        data.message = errors.join('\n');
      }
      this.socket.emitSocket({
        data,
        eventName: EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
      });
    } catch (err) {
      this.socket.emitSocket({
        data: {
          stat: '0',
          message: err.message,
        },
        eventName: EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
      });
    }
  }

  private async tryChangeConstraints(
    req: SetAllCameraSettingsRequest,
    errors: string[]
  ): Promise<VideoConstraints> {
    const constraints = {};
    try {
      const exposure = await this.changeExposure(
        {
          value: req.autoExposure || '-1',
        },
        false
      );
      Object.assign(constraints, exposure);
    } catch (err) {
      errors.push('Exposure: ' + err.message);
    }
    try {
      const iso = await this.changeIso(
        {
          value: req.iso || '-1',
        },
        false
      );
      Object.assign(constraints, iso);
    } catch (err) {
      errors.push('ISO: ' + err.message);
    }
    try {
      const contrast = await this.changeContrast(
        {
          value: req.contrast || '1',
        },
        false
      );
      Object.assign(constraints, contrast);
    } catch (err) {
      errors.push('Contrast: ' + err.message);
    }
    try {
      const temp = await this.changeColorTemperature(
        {
          value: req.white_balance?.toString() || '-1',
        },
        false
      );
      Object.assign(constraints, temp);
    } catch (err) {
      errors.push('Color temperature: ' + err.message);
    }

    return constraints as VideoConstraints;
  }

  /**
   * Get all camera settings in a form that is compatible with api so it can be
   * sent over network.
   */
  private async getAllCameraSettings(): Promise<SetAllCameraSettingsResponse> {
    const currentConstraints = await this.getCurrentConstraints();
    return {
      audio_sample_rate: '44.1',
      autoExposure: this.getAsString(currentConstraints?.exposure),
      autoFocus: '',
      contrast: this.getAsString(currentConstraints?.contrast),
      crop_factor: 'Ratio169',
      filter_camera_data: {
        saturation: '-1',
        vibrance: '-1',
        shadow: '-1',
        highlight: '-1',
        color_overlay: {
          color: '-1',
          value: '-1',
        },
        gamma: -1,
      },
      flashlight: 0,
      focus: -1,
      focus_peaking: 0,
      fps: currentConstraints?.fps.toString(),
      iso: this.getAsString(currentConstraints?.iso),
      mbit: '12',
      mobile_feed: 0,
      mobile_mic_options: 0,
      potrait: '',
      resolution: currentConstraints?.height.toString(),
      width: currentConstraints?.width.toString(),
      stabilize: '0',
      tint: '-1',
      white_balance: this.getOrDefault(
        currentConstraints?.colorTemperature,
        -1
      ),
      zoom: 0,
      stat: '1',
    };
  }

  private getAsString(value: number): string {
    return value == null || isNaN(value) ? '' : value.toString();
  }

  private getOrDefault(value: number, defaultValue: number): number {
    return value == null || isNaN(value) ? defaultValue : value;
  }

  private async getCurrentConstraints(): Promise<VideoConstraints> {
    return await this.video.currentConstraints$.pipe(take(1)).toPromise();
  }

  private async getCurrentVideoStream(): Promise<VideoStream> {
    return await this.video.video$.pipe(take(1)).toPromise();
  }

  private checkCapabilityRange(
    range: StreamSettingCapability | undefined,
    requestedValue: number,
    throwOnUnsupported = false
  ) {
    if (range) {
      const ret = requestedValue >= range.min && requestedValue <= range.max;
      if (!ret && throwOnUnsupported) {
        throw new Error(
          `Value is out of range for this stream: ${requestedValue} range: ${range.min},${range.max}`
        );
      }
      return ret;
    }
    if (throwOnUnsupported) {
      throw new Error('This value cannot be edited on this stream');
    } else {
      return false;
    }
  }
}
