import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {  Subject, Subscription, timer } from 'rxjs';
// import { } from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import { IStreamingParticipant } from '../../interfaces/streaming-lib-service.interface';

/**
 * An extension to video element that allows the use of captureStream function
 *
 * @interface HTMLVideoElementWithCapture
 * @extends {HTMLVideoElement}
 */
interface HTMLVideoElementWithCapture extends HTMLVideoElement {
  /**
   * Capture media stream from the video element.
   *
   * @returns {MediaStream}
   * @memberof HTMLVideoElementWithCapture
   */
  captureStream(): MediaStream;
}

/**
 * Try to play audio/video element. This is useful in case of unit testing
 *
 * @param {HTMLMediaElement} element element to play
 */
function tryPlayElement(element: HTMLMediaElement) {
  try {
    if (navigator && navigator.getUserMedia) {
      element.play();
    }
  } catch (err) {
    console.warn('Unable to play element: ' + err.message);
  }
}

/**
 * A generic video component that plays video from different sources. It can
 * play videos from {@link IStreamingParticipant}, Blobs, or strings (urls).
 * It also comes with a {@link AudioMeterComponent} automatically to show
 * audio levels of stream. If video is from cross-site (i.e. the domain of
 * video is different from the frontend domain) then audio meter won't be
 * shown because browsers don't allow capturing audio from videos from other
 * sites.
 *
 * @export
 * @class VideoParticipantComponent
 * @extends {Cleanupable}
 * @implements {AfterViewInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'openreel-video-participant',
  templateUrl: './video-participant.component.html',
  styleUrls: ['./video-participant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoParticipantComponent
  extends Cleanupable
  implements AfterViewInit, OnChanges {
  /**
   * Local video element
   *
   * @private
   * @type {ElementRef<HTMLVideoElementWithCapture>}
   * @memberof VideoParticipantComponent
   */
  @ViewChild('videoElement')
  private localVideo: ElementRef<HTMLVideoElementWithCapture>;

  /**
   * The video stream/source. Can be changed in runtime (i hope). If provided
   * value is string, then it is considered to be a URL to a video.
   *
   * @type {(IStreamingParticipant | Blob | string | undefined)}
   * @memberof VideoParticipantComponent
   */
  private _source: IStreamingParticipant | Blob | string | undefined;

  @Input()
  set source(source) {
    this._source = source;
    this.changeStream();
  }

  get source() {
    return this._source;
  }

  get sourceAsParticipant() {
    if (this.playbackType === 'participant') {
      return this.source as IStreamingParticipant;
    }
  }

  /**
   * If you want to force audio to be muted (for example for local stream, to
   * aviod audio loopback).
   *
   * @type {boolean}
   * @memberof VideoParticipantComponent
   */
  @Input()
  muteAudio = false;

  /**
   * If you want to have border radius only on the left corners
   *
   * @type {boolean}
   * @memberof VideoParticipantComponent
   */
  @Input()
  halfBorderCurved = false;

  /**
   * Customize border radius
   *
   * @type {number}
   * @memberof VideoParticipantComponent
   */
  @Input()
  borderRadius = 4;

  /**
   * Gets currently active audio stream
   *
   * @type {MediaStreamTrack}
   * @memberof VideoParticipantComponent
   */
  get audioStream(): MediaStreamTrack {
    return this._audioStream;
  }
  /**
   * Sets new audio stream. Also passes the new stream to audio meter.
   *
   * @memberof VideoParticipantComponent
   */
  set audioStream(newStream: MediaStreamTrack) {
    this._audioStream = newStream;
  }
  /**
   * Currently active audio stream. It is changed when new audio is available.
   * This is passed to audio level service.
   *
   * @type {MediaStreamTrack}
   * @memberof VideoParticipantComponent
   */
  _audioStream: MediaStreamTrack;
  /**
   * When audio is above this level, the video border will become blue
   *
   * @memberof VideoParticipantComponent
   */
  sensitivity = 0.06;

  /**
   * If user pressed "mute" button.
   *
   * @type {boolean}
   * @memberof VideoParticipantComponent
   */
  isAudioMuteButtonPressed = false;

  /**
   * Input/output subject that gives you an update about the current time, and
   * if you call {@link Subject#next|next} on this param, you can update the time of
   * current video.
   *
   * @memberof VideoParticipantComponent
   */
  currentTimeSeconds$: Subject<number>;

  /**
   * Minimum time for video to have. You can use this to control where the video
   * starts. Whenver video time is <
   * {@link VideoParticipantComponent#startTime|startTime} video will skip to
   * {@link VideoParticipantComponent#startTime|startTime} and if video was paused,
   * it will be unpaused.
   *
   * @type {number}
   * @memberof VideoParticipantComponent
   */
  @Input()
  startTime: number;

  /**
   * Maximum time for video to have. You can use this to control where the video
   * stops. Whenever video time is >
   * {@link VideoParticipantComponent#stopTime|stopTime} video is rewinded to
   * {@link VideoParticipantComponent#stopTime|stopTime} and it gets paused.
   *
   * @type {number}
   * @memberof VideoParticipantComponent
   */
  @Input()
  stopTime: number;

  /**
   * Current playback type. Used for html.
   *
   * @type {('participant' | 'blob' | 'url' | 'none')}
   * @memberof VideoParticipantComponent
   */
  playbackType: 'participant' | 'blob' | 'url' | 'none';

  enabledVideo = true;

  /**
   * Subscription that monitors current participant's change in audio/video
   * stream.
   *
   * @private
   * @type {Subscription}
   * @memberof VideoParticipantComponent
   */
  private currentStreamSubscription: Subscription;
  /**
   * Subscription that monitors change in video of native video element. When
   * new audio is available, it populates the
   * {@link VideoParticipantComponent#audioStream|audioStream} when possible.
   *
   * @private
   * @type {Subscription}
   * @memberof VideoParticipantComponent
   */
  private audioStreamWaiterSubscription: Subscription;
  /**
   * Subscription that listens to
   * {@link {@link VideoParticipantComponent#currentTimeSeconds$|currentTimeSeconds$}
   * when it is available. When triggered from outside, video will skip to the
   * new time specified by this subject.
   *
   * @private
   * @type {Subscription}
   * @memberof VideoParticipantComponent
   */
  private videoTimeSubscription: Subscription;

  /**
   * Is time update from
   * {@link {@link VideoParticipantComponent#currentTimeSeconds$|currentTimeSeconds$}
   * or it is comming from HTML video time update (natural video progression).
   *
   * @private
   * @memberof VideoParticipantComponent
   */
  private isUpdateFromHTML = false;

  /**
   * Is mouse inside the video element
   *
   * @public
   * @memberof VideoParticipantComponent
   */
  public isMouseInside = false;
  /**
   * Is this video shown in fullscreen at the moment
   *
   * @public
   * @memberof VideoParticipantComponent
   */
  public isFullscreen = false;

  /**
   * Creates an instance of VideoParticipantComponent.
   * @param {NgZone} zone
   * @memberof VideoParticipantComponent
   */
  constructor(
    private zone: NgZone,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  /**
   * When mouse enters video element. Main purpuse is to show 'fullscreen' button
   *
   * @memberof VideoParticipantComponent
   */
  mouseEnter() {
    this.isMouseInside = true;
  }

  /**
   * When mouse leaves video element. Main purpuse is to hide 'fullscreen' button
   *
   * @memberof VideoParticipantComponent
   */
  mouseLeave() {
    this.isMouseInside = false;
  }

  /**
   * Toggle fullscreen. When leaving fullscreen mode, we force component to think
   * that mouse is not in the component.
   *
   * @memberof VideoParticipantComponent
   */
  toggleFullscreen() {
    this.isFullscreen = !this.isFullscreen;
    if (!this.isFullscreen) {
      this.isMouseInside = false;
    }
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Utility function that returns true if audio should be heard at all.
   *
   * @returns {boolean}
   * @memberof VideoParticipantComponent
   */
  isMuted(): boolean {
    return this.isAudioMuteButtonPressed || this.muteAudio;
  }

  /**
   * Start any current streams and watch for changes in video source selection.
   *
   * @memberof VideoParticipantComponent
   */
  ngAfterViewInit() {
    console.log('Video Participant Init');
    this.startCurrentStream();
  }

  /**
   * Clean any previous stream and start new stream
   *
   * @private
   * @memberof VideoParticipantComponent
   */
  private async changeStream() {
    if (this.currentStreamSubscription) {
      this.currentStreamSubscription.unsubscribe();
    }
    if (this.audioStreamWaiterSubscription) {
      this.audioStreamWaiterSubscription.unsubscribe();
    }

    if (this.localVideo) {
      delete this.localVideo.nativeElement.srcObject;
      delete this.localVideo.nativeElement.src;
      this.startCurrentStream();
    }
    if(this.source) {
      this.detectVideoMutedChanges(this.source as IStreamingParticipant);
    }
  }

  /**
   * Wait video element to provide us with audio stream. this is needed in
   * scenarios where we don't have direct access to audio stream, for example
   * when selected {@link VideoParticipantComponetn#source|source} is Blob or
   * string/url.
   *
   * @private
   * @memberof VideoParticipantComponent
   */
  private waitForAudioStream() {
    this.audioStreamWaiterSubscription = timer(0, 1000).subscribe(() => {
      const stream: MediaStream = this.localVideo.nativeElement.captureStream();
      this.audioStream = stream.getAudioTracks()[0];
      if (this.audioStream) {
        console.log('Audio stream available');
        this.audioStreamWaiterSubscription.unsubscribe();
      }
    });
    this.subscriptions.push(this.audioStreamWaiterSubscription);
  }

  /**
   * Start the stream with current settings. If there is another stream playing
   * this function doesn't take care of that, so previous stream should be
   * cleaned before calling this function.
   *
   * @private
   * @memberof VideoParticipantComponent
   */
  private startCurrentStream() {
    delete this.audioStream;
    if (this.source) {
      if (typeof this.source === 'string') {
        this.localVideo.nativeElement.src = this.source;
        this.playbackType = 'url';
        this.waitForAudioStream();
      } else if (this.source instanceof Blob) {
        this.localVideo.nativeElement.srcObject = this.source;
        this.playbackType = 'blob';
        this.waitForAudioStream();
      } else {
        this.currentStreamSubscription = this.source.videoStream$.subscribe(
          (videoStream) => {
            // this.audioStream = audioStream;
            const participantName = (this.source as IStreamingParticipant)
              ?.identity;
            console.log(
              'Set new streams for ' +
                participantName +
                ' Height: ' +
                videoStream?.getSettings().height +
                ' FPS: ' +
                videoStream?.getSettings().frameRate
            );
            if (videoStream) {
              const currentStream = this.localVideo.nativeElement.srcObject;
              if (
                !currentStream ||
                (currentStream as MediaStream).getVideoTracks()[0] !==
                  videoStream
              ) {
                this.localVideo.nativeElement.srcObject = new MediaStream([
                  videoStream,
                ]);
              }
            } else {
              this.localVideo.nativeElement.srcObject = null;
              console.warn('This stream has no video');
            }
            this.playbackType = 'participant';
          }
        );
        this.subscriptions.push(this.currentStreamSubscription);
      }
      tryPlayElement(this.localVideo.nativeElement);
    } else {
      this.playbackType = 'none';
    }
  }

  detectVideoMutedChanges(source: IStreamingParticipant) {
    if (source.audioMuted$) {
      this.subscriptions.push(
        source.videoMuted$.subscribe((videoMuted) => {
          this.enabledVideo = !videoMuted;
          this.changeDetectorRef.markForCheck();
        })
      );
    }
  }

  /**
   * Called when toggle audio button is pressed. Note that audio won't be heard
   * if {@link VideoParticipantComponent#muteAudio|muteAudio} is true
   *
   * @memberof VideoParticipantComponent
   */
  toggleMute() {
    this.isAudioMuteButtonPressed = !this.isAudioMuteButtonPressed;
  }

  /**
   * if {@link VideoParticipantComponent#currentTimeSeconds$|currentTimeSeconds$}
   * is changed, we should unsubscribe from any previous subscriptions to it and
   * subscribe to the new provided subject if it is necessary
   *
   * @param {SimpleChanges} changes
   * @memberof VideoParticipantComponent
   */
  ngOnChanges(changes: SimpleChanges) {
    if ('currentTimeSeconds$' in changes) {
      if (this.videoTimeSubscription) {
        this.videoTimeSubscription.unsubscribe();
      }
      if (this.currentTimeSeconds$) {
        this.videoTimeSubscription = this.currentTimeSeconds$.subscribe(
          (newTime) => {
            this.handleNewVideoTime(newTime);
          }
        );
        this.subscriptions.push(this.videoTimeSubscription);
      }
    }
  }

  /**
   * Called when we manually update current time secconds through
   * {@link VideoParticipantComponent#currentTimeSeconds$|currentTimeSeconds$}
   *
   * @private
   * @param {number} newTime
   * @memberof VideoParticipantComponent
   */
  private handleNewVideoTime(newTime: number) {
    if (!this.isUpdateFromHTML) {
      this.localVideo.nativeElement.currentTime = newTime;
      if (this.localVideo.nativeElement.paused) {
        tryPlayElement(this.localVideo.nativeElement);
      }
    }
    this.isUpdateFromHTML = false;
  }

  /**
   * Called when native video element changes time so if video is playing this
   * function will be called on each frame of video. For that reason we only
   * run zonned stuff only if necessary, that is, if
   * {@link VideoParticipantComponent#currentTimeSeconds$|currentTimeSeconds$}
   * is not undefined.
   *
   * @private
   * @memberof VideoParticipantComponent
   */
  private onTimeUpdated() {
    if (this.currentTimeSeconds$) {
      this.zone.run(() => {
        this.isUpdateFromHTML = true;
        this.currentTimeSeconds$.next(
          this.localVideo.nativeElement.currentTime
        );
        this.isUpdateFromHTML = false;
      });
    }
    if (this.startTime !== undefined) {
      if (this.localVideo.nativeElement.currentTime < this.startTime) {
        this.localVideo.nativeElement.currentTime = this.startTime;
      }
    }
    if (this.stopTime !== undefined) {
      if (this.localVideo.nativeElement.currentTime > this.stopTime) {
        this.localVideo.nativeElement.currentTime = this.stopTime;
        this.localVideo.nativeElement.pause();
      }
    }
  }
}
