import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { skip } from 'rxjs/operators';
import {
  AudioTrackPublication,
  connect,
  LocalAudioTrackPublication,
  LocalTrack,
  LocalTrackPublication,
  Participant,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  VideoTrackPublication,
} from 'twilio-video';
import {
  IStreamingLib,
  IStreamingLibService,
  IStreamingParticipant,
} from '../../interfaces';
import {
  VideoConstraints,
  openAudioStream,
  getTrackSettings,
} from '../../media';
import { NetworkService } from '../network/network.service';
import {
  isRemoteAudioTrack,
  isVideoTrack,
  isVideoTrackPublication,
} from './twilio-utils';

function getTwilioTracks(
  pubs: Map<string, AudioTrackPublication | VideoTrackPublication>
): MediaStreamTrack[] {
  return Array.from(pubs.values())
    .filter((p) => p.track)
    .map((p) => p.track.mediaStreamTrack);
}

export class TwilioVideoParticipant implements IStreamingParticipant {
  audioStream$: BehaviorSubject<MediaStreamTrack>;
  videoStream$: BehaviorSubject<MediaStreamTrack>;
  audioMuted$: BehaviorSubject<boolean>;
  videoMuted$: BehaviorSubject<boolean>;
  identity: string;
  id: string;
  audioContext = new AudioContext();
  sourceNode;
  constructor(private myParticipant: Participant) {
    this.audioStream$ = new BehaviorSubject<MediaStreamTrack>(
      this.getAudioTrack()
    );
    this.videoStream$ = new BehaviorSubject<MediaStreamTrack>(
      this.getVideoTrack()
    );
    this.audioMuted$ = new BehaviorSubject<boolean>(false);
    this.videoMuted$ = new BehaviorSubject<boolean>(false);
    this.identity = myParticipant.identity;
    this.id = myParticipant.sid;

    myParticipant.on('trackEnabled', (track: LocalTrack | RemoteTrack) => {
      console.log('trackEnabled', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackDisabled', (track: LocalTrack | RemoteTrack) => {
      console.log('trackDisabled', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackStarted', (track: LocalTrack | RemoteTrack) => {
      console.log('trackStarted', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackStopped', (track: LocalTrack) => {
      console.log('trackStopped', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackPublished', (track: LocalTrack | RemoteTrack) => {
      console.log('trackPublished', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackSubscribed', (track: RemoteTrack) => {
      console.log('trackSubscribed', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackUnsubscribed', (track: RemoteTrack) => {
      console.log('trackUnsubscribed', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackSwitchedOn', (track: RemoteTrack) => {
      console.log('trackSwitchedOn', track);
      this.refreshTrack(track);
    });
    myParticipant.on('trackSwitchedOff', (track: RemoteTrack) => {
      console.log('trackSwitchedOff', track);
      this.refreshTrack(track);
    });
  }
  getVideoTrack(): MediaStreamTrack | null {
    return getTwilioTracks(this.myParticipant.videoTracks)[0] || null;
  }
  getAudioTrack(): MediaStreamTrack | null {
    return getTwilioTracks(this.myParticipant.audioTracks)[0] || null;
  }
  refreshTrack(
    track:
      | LocalTrack
      | RemoteTrack
      | LocalTrackPublication
      | RemoteTrackPublication
  ) {
    if (track.kind === 'audio') {
      if ((track as LocalAudioTrackPublication).trackSid) {
        this.audioMuted$.next(!(track as LocalAudioTrackPublication).isTrackEnabled);
      } 
      else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.audioMuted$.next(!(track as any).isEnabled);
      }
      this.audioStream$.next(this.getAudioTrack());

      // TODO: HACK: If remote, play it
      if (isRemoteAudioTrack(track)) {
        this.replayAudio(this.getAudioTrack(), this.audioMuted$.value);
      }

      /////////////////////////////////////////
    } else if (track.kind === 'video') {
      if (isVideoTrack(track)) {
        this.videoMuted$.next(!track.isEnabled);
      } else if (isVideoTrackPublication(track)) {
        this.videoMuted$.next(!track.isTrackEnabled);
      }
      this.videoStream$.next(this.getVideoTrack());
    }
  }

  /**
   *
   * @param track
   * @param muted
   */
  public replayAudio(track, muted) {
    // Override muted
    if (!track) return;
    if (muted) return;
    if (!muted) {
      // this.audioContext.close();

      const noiseCancellation = true;
      if (noiseCancellation) {
      }
    }
  }
}

export class TwilioVideoLib implements IStreamingLib {
  private networkSubscription: Subscription;
  networkQuality$: BehaviorSubject<number>;
  connectionStatus$: BehaviorSubject<boolean>;

  constructor(private myRoom: Room, networkService: NetworkService) {
    console.log(myRoom);
    this.remoteParticipants$ = new BehaviorSubject<IStreamingParticipant[]>(
      Array.from(myRoom.participants.values()).map(
        (p) => new TwilioVideoParticipant(p)
      )
    );
    this.myParticipant$ = new BehaviorSubject(
      new TwilioVideoParticipant(myRoom.localParticipant)
    );
    //Network quality
    this.networkQuality$ = new BehaviorSubject(
      myRoom.localParticipant.networkQualityLevel
    );
    //twilio connection status
    this.connectionStatus$ = new BehaviorSubject(myRoom.state === 'connected');
    myRoom.on('participantConnected', (participant: RemoteParticipant) => {
      console.log('Participant connected: ' + participant.identity);
      this.remoteParticipants$.next([
        ...this.remoteParticipants$.value,
        new TwilioVideoParticipant(participant),
      ]);
    });
    myRoom.on('participantDisconnected', (participant: RemoteParticipant) => {
      console.log('Participant disconnected: ' + participant.identity);
      this.remoteParticipants$.next(
        this.remoteParticipants$.value.filter((p) => p.id !== participant.sid)
      );
    });

    myRoom.on('disconnected', (room: Room, error: Error) => {
      console.log('Twilio disconnected');
      this.connectionStatus$.next(false);
      if (error) {
        console.log('Because of error: ' + error);
        myRoom.disconnect();
      }
    });
    //network quality change event
    myRoom.localParticipant.on(
      'networkQualityLevelChanged',
      (networklevel: number) => {
        this.networkQuality$.next(networklevel);
      }
    );
    this.networkSubscription = networkService.online$
      .pipe(skip(1))
      .subscribe((online) => {
        if (!online) {
          this.disconnect();
        }
      });
    window.addEventListener('beforeunload', () => {
      this.disconnect();
    });
  }
  remoteParticipants$: BehaviorSubject<IStreamingParticipant[]>;
  myParticipant$: BehaviorSubject<IStreamingParticipant>;

  private get localParticipant() {
    return this.myRoom.localParticipant;
  }
  private get localVideoTrack() {
    return (
      Array.from(this.localParticipant.videoTracks.values()).map(
        (pub) => pub.track
      )[0] || null
    );
  }
  private get localAudioTrack() {
    return (
      Array.from(this.localParticipant.audioTracks.values()).map(
        (pub) => pub.track
      )[0] || null
    );
  }
  private stopAllAudioTracks() {
    console.log(
      'Stopping ' + this.localParticipant.audioTracks.size + ' audio tracks'
    );
    Array.from(this.localParticipant.audioTracks.values()).forEach((t) => {
      t.track.stop();
    });
  }
  broadcastVideo(stream: MediaStreamTrack) {
    const hasTracks = this.localParticipant.videoTracks.size > 0;
    if (hasTracks) {
      this.localParticipant.unpublishTracks(
        Array.from(this.localParticipant.videoTracks.values()).map(
          (pub) => pub.track
        )
      );
      setTimeout(() => {
        this.publishStreamToParticipant(stream);
      }, 100);
    } else {
      this.publishStreamToParticipant(stream);
    }
  }

  publishStreamToParticipant(track) {
    const resize = false;
    if (!resize) {
      this.localParticipant.publishTrack(track);
    } else {
      const VideoStreamMerger = require('video-stream-merger');
      const merger = new VideoStreamMerger({
        width: 640,
        height: 360,
        fps: 15,
        mute: true,
      });

      // const merger = new VideoStreamMerger({
      //   width: 1920,
      //   height: 1080,
      //   fps: 7,
      //   mute: true,
      // });
      const stream = new MediaStream();
      stream.addTrack(track);

      merger.addStream(stream, {
        mute: true,
      });
      merger.start();
      const toPublish = merger.result;

      this.localParticipant.publishTrack(toPublish.getVideoTracks()[0]);
    }
  }

  async broadcastAudio(stream: MediaStreamTrack) {
    if (this.localParticipant.audioTracks.size > 0) {
      this.stopAllAudioTracks();
      this.localParticipant.unpublishTracks(
        Array.from(this.localParticipant.audioTracks.values()).map(
          (pub) => pub.track
        )
      );
    }
    //for streaming create a new audio stream so that mute/unmute does not affect the recording
    const deviceId = getTrackSettings(stream).deviceId;
    const newAudioStream = await openAudioStream(deviceId);
    await this.localParticipant.publishTrack(
      newAudioStream.getAudioTracks()[0]
    );
  }
  muteVideo() {
    console.log('Local video mute');
    if (this.localVideoTrack) {
      this.localVideoTrack.disable();
    }
  }
  muteAudio() {
    console.log('Local audio mute');
    if (this.localAudioTrack) {
      this.localAudioTrack.disable();
    }
  }
  unmuteVideo() {
    console.log('Local video unmute');
    if (this.localVideoTrack) {
      this.localVideoTrack.enable();
    }
  }
  unmuteAudio() {
    console.log('Local audio unmute');
    if (this.localAudioTrack) {
      this.localAudioTrack.enable();
    }
  }
  disconnect() {
    console.log('Twilio disconnected');
    this.connectionStatus$.next(false);
    this.networkSubscription.unsubscribe();
    this.stopAllAudioTracks();
    this.myRoom.disconnect();
  }

  changeVideoParameters(constraints: VideoConstraints) {
    this.localParticipant.videoTracks.forEach((pub) => {
      // TODO: Use the correct way, without needing to access private properties
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const publishedTrack = (pub as any)._signaling._trackTransceiver
        .track as MediaStreamTrack;
      publishedTrack.applyConstraints({
        height: constraints.height,
        frameRate: constraints.fps,
      });
    });
  }
}

@Injectable()
export class TwilioStreamingService extends IStreamingLibService {
  constructor(private networkService: NetworkService) {
    super();
  }
  async getStreaming(token: string, roomName: string): Promise<IStreamingLib> {
    const room = await connect(token, {
      name: roomName,
      audio: false,
      video: false,
      maxAudioBitrate: 16000,
      // logLevel: commonenv.production ? 'error' : 'debug',
      preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],

      // Uncomment to enable dominant speaker API
      dominantSpeaker: true,
      automaticSubscription: true,

      networkQuality: { local: 1, remote: 1 },
      bandwidthProfile: {
        video: {
          trackSwitchOffMode: 'disabled',

          // Use detected if disabled started to cause audio to drop 
          // trackSwitchOffMode: 'detected',
          mode: 'collaboration',
          maxTracks: 10,
          renderDimensions: {
            standard: { height: 360, width: 640 },
            low: { height: 240, width: 426 },
          },

          // Uncomment to control dominant speaker priority
          // dominantSpeakerPriority: 'standard',
        },
      },
    });
    return new TwilioVideoLib(room, this.networkService);
  }
}
