import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { VideoLayer } from '../../../../../api/workflow.interfaces';
import { ProjectFacade } from '../../../../store/facades/project.facade';
import { ItemSelectService } from '../../../services/item-select.service';
import {
  ItemSelectPanesActionsService,
  SAVE_DEBOUNCE_TIME,
} from '../../../services/panes-actions.service';
import { PaneBaseComponent } from '../pane-base.component';

@Component({
  selector: 'openreel-wf-pane-video-overlay-options',
  templateUrl: './pane-video-overlay-options.component.html',
  styleUrls: ['./pane-video-overlay-options.component.scss'],
})
export class PaneVideoOverlayOptionsComponent
  extends PaneBaseComponent
  implements OnInit, OnChanges {
  pipControl = new FormControl(false);

  pipEnabled = false;

  constructor(
    protected readonly projectFacade: ProjectFacade,
    protected readonly itemSelectService: ItemSelectService,
    protected readonly itemSelectPanesActions: ItemSelectPanesActionsService
  ) {
    super();
  }

  ngOnInit() {
    this.toForm();

    this.projectFacade.features$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        console.log(data);
        this.pipEnabled = data.videoOverlays?.pip?.enabled || false;
      });

    this.pipControl.valueChanges
      .pipe(debounceTime(SAVE_DEBOUNCE_TIME), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.itemSelectPanesActions.saveVideoOverlayPIP(
          this.item,
          this.timeline
        );
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('item' in changes && !changes['item'].isFirstChange()) {
      this.toForm();
    }
  }

  duplicateClip() {
    if (this.itemSelectPanesActions.duplicateClip(this.item, this.timeline)) {
      this.itemSelectService.clearItem();
    }
  }

  editTrim() {
    if (this.itemSelectPanesActions.editTrim(this.item, this.timeline)) {
      this.itemSelectService.clearItem();
    }
  }

  private toForm() {
    this.pipControl.patchValue((this.item.layer as VideoLayer).isPip || false, {
      emitEvent: false,
    });
  }
}
