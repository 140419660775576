import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@openreel/common';
import { EditorComponent } from './pages/editor/editor.component';
import { EditorFrameNameComponent } from './pages/editor-frame-name/editor-frame-name.component';
import { EditorFramePreviewComponent } from './pages/editor-frame-preview/editor-frame-preview.component';
import { EditorFrameRenderComponent } from './pages/editor-frame-render/editor-frame-render.component';
import { NgModule } from '@angular/core';
import { ProjectLoadedGuard } from '../core/guards/project-loaded.guard';

const routes: Routes = [
  {
    path: 'project',
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Project Editor',
    },
    children: [
      {
        path: ':id',
        component: EditorComponent,
        canActivate: [ProjectLoadedGuard],
        children: [
          {
            path: 'name',
            component: EditorFrameNameComponent,
            data: { pageTitle: 'Project Editor - Name', showSidebar: true },
          },
          {
            path: 'style',
            loadChildren: () =>
              import('./../editor-style/editor-style.module').then(
                (mod) => mod.EditorStyleModule
              ),
          },
          {
            path: 'intro-outro',
            loadChildren: () =>
              import('./../editor-intro-outro/editor-intro-outro.module').then(
                (mod) => mod.EditorIntroOutroModule
              ),
          },
          {
            path: 'elements',
            loadChildren: () =>
              import('./../editor-video-elements/editor-video-elements.module').then(
                (mod) => mod.EditorVideoElementsModule
              ),
          },
          {
            path: 'clips',
            loadChildren: () =>
              import('./../editor-clips/editor-clips.module').then(
                (mod) => mod.EditorClipsModule
              ),
          },
          {
            path: 'recording',
            loadChildren: () =>
              import('./../recording/recording.module').then(
                m => m.RecordingModule
              )
          },
          {
            path: 'render',
            component: EditorFrameRenderComponent,
            data: {
              pageTitle: 'Project Editor - Render',
              showSidebar: false,
            },
          },
          {
            path: 'preview',
            component: EditorFramePreviewComponent,
            data: {
              pageTitle: 'Project Editor - Preview',
              showSidebar: false,
            },
          },
          {
            path: '**',
            redirectTo: 'name',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full',
      },
    ],
  },
];

export const routableComponents = [
  EditorComponent,
  EditorFrameNameComponent,
  EditorFrameRenderComponent,
  EditorFramePreviewComponent,
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EditorRoutingModule {}
