import { AssetDto, CreateAssetRequest } from './assets.interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  getMimeTypeFromExtension,
  splitNameAndExtension,
} from '../../app/shared/helpers/file.helpers';

import { CaseConverterInterceptorSkipHeader } from '../../app/core/interceptors/case-converter.interceptor';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private urls = {
    byId: (assetId) => `${commonenv.nextGenApiUrl}assets/${assetId}`,
    wfAsset: (id) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${id}/assets`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  createAssetUrl(projectId: number, data: CreateAssetRequest) {
    return this.httpClient.post<AssetDto>(this.urls.wfAsset(projectId), data);
  }

  uploadAsset(signedUrl: string, file: File) {
    const [_, extension] = splitNameAndExtension(file.name);

    return this.httpClient.put(signedUrl, file, {
      headers: new HttpHeaders({
        [CaseConverterInterceptorSkipHeader]: '',
        'Content-Type': getMimeTypeFromExtension(extension),
      }),
      reportProgress: true,
      observe: 'events',
    });
  }

  getAssetUrlById(assetId: number) {
    return this.httpClient.get<AssetDto>(this.urls.byId(assetId));
  }
}
