import { Injectable } from '@angular/core';
import { AlertService } from '../../core/services/alert.service';
import {
  LayerDataChangedEvent,
  TimelineItem,
} from '../../shared/element.interfaces';
import { TemplateSettingsFacade } from '../../store/facades/template-settings.facade';
import { TimelinesFacade } from '../../timelines/store/facades/timelines.facade';
import { Timeline } from '../../timelines/store/interfaces/timelines.interfaces';

export const SAVE_DEBOUNCE_TIME = 500;

@Injectable()
export class ItemSelectPanesActionsService {
  constructor(
    private readonly timelinesFacade: TimelinesFacade,
    private readonly templateSettingsFacade: TemplateSettingsFacade,
    private readonly alertService: AlertService
  ) {}

  async removeMainClip(item: TimelineItem, timeline: Timeline) {
    if (!this.templateSettingsFacade.canDeleteItem(timeline, item)) {
      this.alertService.info('Last video cannot be deleted.');
      return false;
    }

    const result = await this.alertService.confirm(
      'Are you sure you want to delete this clip?'
    );
    if (!result) {
      return false;
    }

    this.timelinesFacade.removeMainClip({ item, timeline });
    return true;
  }

  async removeOverlay(item: TimelineItem, timeline: Timeline) {
    const result = await this.alertService.confirm(
      'Are you sure you want to delete this overlay?'
    );
    if (!result) {
      return false;
    }

    this.timelinesFacade.removeOverlay({ item, timeline });
    return true;
  }

  swapVideoSides(item: TimelineItem, timeline: Timeline) {
    this.timelinesFacade.switchTimelines({
      item,
      timeline,
    });
    return true;
  }

  duplicateClip(item: TimelineItem, timeline: Timeline) {
    this.timelinesFacade.duplicateClip({
      item: item,
      timeline: timeline,
    });
    return true;
  }

  editTrim(item: TimelineItem, timeline: Timeline) {
    this.timelinesFacade.editClipTrim({
      item,
      timeline,
    });
    return true;
  }

  async replaceClip(item: TimelineItem, timeline: Timeline) {
    const result = await this.alertService.confirm(
      'We will delete existing splits. Are you sure you want to continue?'
    );

    if (!result) {
      return false;
    }

    this.timelinesFacade.replaceMainClips({
      item,
      timeline,
    });
    return true;
  }

  saveTextData(
    item: TimelineItem,
    timeline: Timeline,
    data: LayerDataChangedEvent
  ) {
    this.timelinesFacade.updateTextOverlayData({
      item,
      timeline,
      data,
    });
  }

  saveTextMetadata(
    item: TimelineItem,
    timeline: Timeline,
    duration: number,
    repeatability: number
  ) {
    this.timelinesFacade.updateTextOverlayMetadata({
      item,
      timeline,
      duration,
      repeatability,
    });
  }

  saveVideoOverlayPIP(item: TimelineItem, timeline: Timeline) {
    this.timelinesFacade.changeVideoOverlayType({
      item,
      timeline,
    });
  }
}
