import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkflowDataDto } from '../../../../api/workflow.interfaces';
import { AlertService } from '../../../core/services/alert.service';
import { WorkflowReaderService } from '../../../core/services/workflow-reader.service';
import { TemplateSettingsFacade } from '../../../store/facades/template-settings.facade';
import { Timeline } from '../interfaces/timelines.interfaces';

export const updateTimelineData = (
  timelinesToUpdate: Timeline[],
  workflowAfterUpdate: WorkflowDataDto,
  templateSettings: TemplateSettingsFacade
) => {
  timelinesToUpdate.forEach((updateTimeline) => {
    const { timeline, parentLayer } = templateSettings.getTimelineFromWorkflow(
      workflowAfterUpdate,
      updateTimeline.type,
      updateTimeline.options.timelineIndex
    );

    updateTimeline.options.parentLayer = parentLayer;
    updateTimeline.data = WorkflowReaderService.getTimelineData(
      timeline,
      workflowAfterUpdate
    );
  });
};

export const checkOverlaysForElementsOutOfBounds = (
  newDuration: number,
  timelinesToCheck: Timeline[],
  alertService: AlertService
) => {
  let hasDataOutOfBounds = false;
  for (const timeline of timelinesToCheck) {
    hasDataOutOfBounds = timeline.data.some(
      (d) => d.startAt + d.duration > newDuration
    );
    if (hasDataOutOfBounds) {
      break;
    }
  }

  if (!hasDataOutOfBounds) {
    return of({ canAdd: true });
  }

  return from(
    alertService.confirm(
      'We will delete overlays that finish after last main clip. Are you sure you want to continue?'
    )
  ).pipe(map((result) => ({ canAdd: result })));
};
