import * as fromActions from '../actions/trimmer.actions';
import * as fromEditorActions from './../../../store/actions/editor.actions';

import { createReducer, on } from '@ngrx/store';
import { TrimGroup } from '../interfaces/trimmer.interfaces';
import { cloneDeep } from 'lodash-es';

export const key = 'trimmer';

export interface TrimmerState {
  allowSplits: boolean;
  currentGroupIndex: number;
  trimGroups: TrimGroup[];
  finished: boolean;
}

export const initialState: TrimmerState = {
  allowSplits: true,
  currentGroupIndex: null,
  trimGroups: [],
  finished: false,
};

const reducer = createReducer(
  initialState,
  on(
    fromEditorActions.setStep,
    fromEditorActions.setChildStep,
    fromEditorActions.nextStep,
    () => initialState
  ),
  on(fromActions.trimCleanup, () => initialState),

  on(
    fromActions.trimClipGroups,
    (state, { allowSplits = true, trimGroups }) => ({
      ...state,
      finished: false,
      allowSplits,
      currentGroupIndex: 0,
      trimGroups,
    })
  ),

  on(fromActions.trimClipGroupsDone, (state, { splits }) => {
    const newState = cloneDeep(state);
    newState.trimGroups[newState.currentGroupIndex].splits = [...splits];
    newState.finished =
      newState.currentGroupIndex === newState.trimGroups.length - 1;

    if (!newState.finished) {
      newState.currentGroupIndex += 1;
    }

    return {
      ...newState,
    };
  })
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
