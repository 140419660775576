import * as dots from './dots.lottie.json';
import * as loader from './loader.lottie.json';

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import Lottie, { AnimationConfigWithData, AnimationItem } from 'lottie-web';

import { v4 as uuid } from 'uuid';

export type LoaderSize = 'vsmall' | 'small' | 'medium' | 'large';

export type LoaderStyle = 'default' | 'dots';

const widthMap = new Map<LoaderSize, number>([
  ['vsmall', 40],
  ['small', 81], // divisible by 3 to not show space between animation squares on Mozilla
  ['medium', 160],
  ['large', 240],
]);

@Component({
  selector: 'openreel-wf-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent implements AfterViewInit, OnDestroy {
  @Input() size: LoaderSize = 'medium';
  @Input() style: LoaderStyle = 'default';

  @ViewChild('container')
  private readonly container: ElementRef<HTMLDivElement>;

  private animation: AnimationItem;

  private id = uuid();

  ngAfterViewInit() {
    this.animation = Lottie.loadAnimation(this.createOptions());
  }

  ngOnDestroy() {
    if (this.animation) {
      this.animation.destroy(this.id);
    }
  }

  get widthInPx() {
    return widthMap.get(this.size);
  }

  private createOptions(): AnimationConfigWithData {
    return {
      name: this.id,
      container: this.container.nativeElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid meet',
        imagePreserveAspectRatio: 'xMidYMid meet',
      },
      animationData: this.style === 'dots' ? dots.data : loader.data,
    };
  }
}
