import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Recording } from '../../../../../../../libs/common/src';
import { DialogOptions } from '../interfaces/clip-selection.interfaces';
import * as fromActions from './../actions/clip-selection.actions';
import * as fromSelectors from './../selectors/clip-selection.selectors';

@Injectable({
  providedIn: 'root',
})
export class ClipSelectionFacade {
  projects$ = this.store.select(fromSelectors.selectProjects);
  selectedProject$ = this.store.select(fromSelectors.selectedProject);
  recordings$ = this.store.select(fromSelectors.selectRecordings);
  selectedRecordings$ = this.store.select(fromSelectors.selectedRecordings);

  hideSidebar$ = this.store
    .select(fromSelectors.selectOptions)
    .pipe(map((options) => options?.hideSidebar ?? false));

  constructor(private store: Store) {}

  setDialogOptions(options: DialogOptions) {
    this.store.dispatch(fromActions.setDialogOptions({ options }));
  }

  cleanupSelection = () => this.store.dispatch(fromActions.cleanupSelection());

  loadProjects = () => this.store.dispatch(fromActions.loadProjects());

  selectProject(selectedProjectId: number) {
    this.store.dispatch(fromActions.selectProject({ selectedProjectId }));
  }

  loadRecordings(selectedProjectId: number) {
    this.store.dispatch(
      fromActions.loadProjectRecordings({ selectedProjectId })
    );
  }

  selectRecording(recording: Recording, selected: boolean) {
    this.store.dispatch(
      fromActions.selectProjectRecording({ recording, selected })
    );
  }
}
