import * as fromActions from './../actions/timelines.actions';
import * as fromGlobalActions from './../../../store/actions/global.actions';
import * as fromProjectActions from '../../../store/actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { findPlaceForNewOverlay } from '../helpers/timelines.helpers';
import { TimelinesFacade } from '../facades/timelines.facade';
import { AlertService } from '../../../core/services/alert.service';
import { cloneDeep } from 'lodash-es';
import { TemplateSettingsFacade } from '../../../store/facades/template-settings.facade';
import { updateTimelineData } from '../helpers/effects.helpers';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { WorkflowSteps } from '../../../store/interfaces/steps.interface';
import { UpdateTextMetadataCommand } from '../../../shared/builders/update-text-metadata.command';
import { StretchTextCommand } from '../../../shared/builders/stretch-text.command';
import { AddTextCommand } from '../../../shared/builders/add-text.command';
import { UpdateTextCommand } from '../../../shared/builders/update-text.command';

@Injectable()
export class TextOverlaysEffects {
  addTextOverlay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addTextOverlay),
      withLatestFrom(
        this.timelinesFacade.textOverlaysTimeline$,
        this.timelinesFacade.duration$,
        this.projectFacade.workflow$
      ),
      switchMap(([{ event }, textOverlaysTimeline, duration, workflow]) => {
        const assetId =
          event.textOverlay.properties.type === 'lottie'
            ? event.textOverlay.properties.assetId
            : event.textOverlay.properties.items[0].assetId;

        const animationAsset = workflow.assets.find(
          (a) => a.id === assetId.toString() && a.type === 'json'
        );

        const startAt = findPlaceForNewOverlay(
          textOverlaysTimeline?.data || [],
          duration,
          animationAsset.data.duration
        );

        if (startAt === null) {
          this.alertService.error('Not enough space to place text overlay.');
          return [fromGlobalActions.noOp()];
        }

        const { updatedWorkflow } = new AddTextCommand(workflow).run({
          ...event,
          startAt,
        });

        const updatedTimelines = [cloneDeep(textOverlaysTimeline)];
        updateTimelineData(
          updatedTimelines,
          updatedWorkflow,
          this.templateSettings
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelines({ updatedTimelines }),
        ];
      })
    )
  );

  updateTextOverlayData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTextOverlayData),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ event }, workflow]) => {
        const { result, updatedWorkflow } = new UpdateTextCommand(workflow).run(
          event
        );

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelineItems({
            items: [{ timelineIndex: 0, item: result }],
          }),
        ];
      })
    )
  );

  updateTextOverlayMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateTextOverlayMetadata),
      withLatestFrom(
        this.timelinesFacade.duration$,
        this.projectFacade.workflow$
      ),
      switchMap(([{ event }, duration, workflow]) => {
        if (event.item.startAt + event.duration > duration) {
          this.alertService.error('Not enough space to place text overlay.');
          return [fromGlobalActions.noOp()];
        }

        const { result, updatedWorkflow } = new UpdateTextMetadataCommand(
          workflow
        ).run(event);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelineItems({
            items: [{ timelineIndex: 0, item: result }],
          }),
        ];
      })
    )
  );

  stretchTextOverlay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.stretchTextOverlay),
      withLatestFrom(this.projectFacade.workflow$),
      switchMap(([{ event }, workflow]) => {
        const { result, updatedWorkflow } = new StretchTextCommand(
          workflow
        ).run(event);

        return [
          fromProjectActions.updateProjectWorkflowAPI({
            currentStep: WorkflowSteps.VideoClips,
            data: updatedWorkflow,
          }),
          fromActions.updateTimelineItems({
            items: [{ timelineIndex: 0, item: result }],
          }),
        ];
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly timelinesFacade: TimelinesFacade,
    private readonly projectFacade: ProjectFacade,
    private readonly alertService: AlertService,
    private readonly templateSettings: TemplateSettingsFacade
  ) {}
}
