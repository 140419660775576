import { OnDestroy } from '@angular/core';
import { ReceiveSocketMessageInfo } from '../../interfaces/socket-lib-service.interface';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Cleanupable } from '../../classes/cleanupable';
import {
  CameraPresetCreate,
  DeviceInfo,
  DeviceInfoResponse,
  getIdForInvitedUser,
  InvitedUser,
  SessionBase,
  UserRoleType,
} from '../../interfaces/interfaces';
import {
  AppMicrophoneRange,
  EVT_S2D_APP_MICROPHONE_RANGE,
  EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
  EVT_S2D_SET_AUTO_EXPOSURE_IOS_ACK,
  EVT_S2D_SET_COLOR_TEMPERATURE_ACK,
  EVT_S2D_SET_CONTRAST_ACK,
  EVT_S2D_SET_EXPOSURE_ACK,
  EVT_S2D_SET_FRAMERATE_ACK,
  EVT_S2D_SET_ISO_ACK,
  EVT_S2D_SET_RESOLUTION_ACK,
  EVT_S2D_SHOW_GRID_ACK,
  FrameRateSetAck,
  ResolutionSetAck,
  SetAllCameraSettingsRequest,
  SetAutoExposureRequest,
  SetColorTemperatureRequest,
  SetContrastRequest,
  SetExposureRequest,
  SetIsoRequest,
} from '../../interfaces/socket-events';
import {
  IStreamingLib,
  IStreamingParticipant,
} from '../../interfaces/streaming-lib-service.interface';
import {
  findResolution,
  heightFromString,
} from '../../media/helpers/capabilities.helper';
import { VideoStreamResolution } from '../../media/interfaces/video-stream-resolution.interface';
import { isIosDevice } from '../../utils';
import { CommonApiService } from '../common-api/common-api.service';
import { DirectorSocketService } from '../socket/director-socket.service';
import { SessionBaseService } from './session-base.service';

export enum OpenreelParticipantStatus {
  Connected,
  Disconnected,
}

// A session participant class that represents a local-copy of a session
// participant in session. This particular class is instantiated in case of
// local-copy of my participant, but it is extended in case of remote
// participant. All data here should be same across the network as well, for
// each participant.
export class OpenreelParticipant extends Cleanupable implements OnDestroy {
  videoParticipant$ = new BehaviorSubject<IStreamingParticipant>(null);
  // Twilio identity
  identity: string;
  // Person's name. May be email in case name wasn't entered.
  name: string;
  // Person's email
  email: string;
  // This participant's id in this session. This is used for example for chat
  // or for collaborator-collaborator communication.
  loginId: number;

  isIosDevice: boolean;
  role: UserRoleType;
  // is selected in recording popup.
  // TODO: Remove this from here
  selected: boolean;
  // video name for recording
  // TODO: Remove this from here
  videoName: string;
  // Is participant connected. Participant is considered as connected when all
  // its data is initialized and his video is connected to twilio
  status$ = new BehaviorSubject<OpenreelParticipantStatus>(
    OpenreelParticipantStatus.Disconnected
  );
  // Utility observable, catch any messages from this participant.
  messagesFromMe$ = new Subject<ReceiveSocketMessageInfo<unknown>>();

  // Has all info been initialized.
  infoInitialzied = false;
  initialIdentity: number;

  // Calculated audio level. May be an arbitrary float number. usually in range
  // 0 - 0.1.
  audioLevel$ = new BehaviorSubject<number>(0);

  // If participant is pinned or pinned (visible to subjects)
  isPinned = true;

  // Used to calculate audio level.
  private audioServiceSubscription: Subscription;

  // gets LoginID from identity. For example, web_434523_123 has login id of
  // 123. Some identites don't have this though.
  static getMappingFromIdentity(identity: string): number | undefined {
    if (identity && identity.startsWith('web_')) {
      const splits = identity.split('_');
      const lastPart = splits[splits.length - 1];
      return parseInt(lastPart, 10);
    }
  }

  constructor(
    protected sessionId: number,
    protected session: SessionBaseService<SessionBase>,
    protected commonApi: CommonApiService
  ) {
    super();
    this.subscriptions.push(
      this.videoParticipant$.subscribe((videoParticipant) => {
        this.handleNewVideoParticipant(videoParticipant);
      })
    );
  }

  private getInvitedUserFromMyInfo(invitedUsers: InvitedUser[]) {
    for (const invited of invitedUsers) {
      if (
        this.loginId != null &&
        this.loginId === getIdForInvitedUser(invited)
      ) {
        return invited;
      }
      if (this.identity && invited.ovra_sessionUser_mapping_id) {
        if (
          invited.ovra_sessionUser_mapping_id ===
          OpenreelParticipant.getMappingFromIdentity(this.identity)
        ) {
          return invited;
        }
      }
    }
  }

  private async trySetFromInvitedUser() {
    let invitedUsersInSession = await this.session.waitForInvitedUsers(false);
    let invited = this.getInvitedUserFromMyInfo(invitedUsersInSession);
    // Maybe the user can be found by refreshing cached info ?
    if (!invited && (!this.identity || this.identity.startsWith('web_'))) {
      invitedUsersInSession = await this.session.waitForInvitedUsers(true);
      invited = this.getInvitedUserFromMyInfo(invitedUsersInSession);
    }
    if (invited) {
      this.name =
        invited?.invited_users_details?.fullname || invited.app_user_name;
      this.email = invited.user_email;
      this.isPinned = !!invited.is_pinned;
      this.status$.next(
        invited.is_online
          ? OpenreelParticipantStatus.Connected
          : OpenreelParticipantStatus.Disconnected
      );
      this.loginId = getIdForInvitedUser(invited);
      this.initialIdentity = invited.ovra_sessionUser_mapping_id;
      // TEMPORARY FIX: sum_role is different from frontend roles (admin != director)
      this.role =
        <string>invited.sum_role === 'director'
          ? UserRoleType.Internal
          : invited.sum_role;
      this.infoInitialzied = true;
    }
    return this.infoInitialzied;
  }

  protected handleDeviceInfoResponse(deviceInfo: DeviceInfoResponse) {
    this.name = deviceInfo.result.ovra_session_device_log.name;
    this.role = UserRoleType.Subject;
    this.loginId = deviceInfo.result.ovra_session_device_log.id;
  }

  private async setFromDeviceInfoApi() {
    let deviceInfo: DeviceInfoResponse;
    try {
      deviceInfo = await this.commonApi
        .getDeviceInfo({
          device_id: this.identity,
          session_id: this.sessionId,
        })
        .toPromise();
      this.handleDeviceInfoResponse(deviceInfo);
    } catch (err) {
      console.warn(
        'Error getting the device info for: ' +
          this.identity +
          ': ' +
          err.message
      );
      this.name = 'Anonymous';
      this.role = UserRoleType.Subject;
    }
    this.infoInitialzied = true;
    return this.infoInitialzied;
  }

  // Set login id and try to fill info from invited users info
  async setLoginId(loginId: number) {
    this.loginId = loginId;
    await this.trySetFromInvitedUser();
  }

  // Set identity and try to fill info from apis
  async setIdentity(identity: string) {
    this.identity = identity;
    if (!this.infoInitialzied) {
      if (!(await this.trySetFromInvitedUser())) {
        await this.setFromDeviceInfoApi();
      }
    }
    this.isIosDevice = this.identity && isIosDevice(this.identity);
  }

  // Each time participants are changed in twilio, check if I am still
  // connected
  subscribeVideo(roomService: IStreamingLib) {
    this.subscriptions.push(
      combineLatest([
        roomService.myParticipant$,
        roomService.remoteParticipants$,
      ]).subscribe(([myParticipant, otherParticipants]) => {
        this.handleParticipantRefresh(myParticipant, otherParticipants);
      })
    );
  }

  // Check if I am still connected to twilio
  private handleParticipantRefresh(
    myParticipant: IStreamingParticipant,
    otherParticipants: IStreamingParticipant[]
  ) {
    const allParticipants = [myParticipant, ...otherParticipants];
    let found = false;
    for (const participant of allParticipants) {
      if (participant.identity === this.identity) {
        this.videoParticipant$.next(participant);
        found = true;
      }
    }
    if (!found) {
      this.videoParticipant$.next(null);
    }
    this.refreshConnected();
  }

  private refreshConnected() {
    const newValue =
      this.videoParticipant$.value && this.infoInitialzied
        ? OpenreelParticipantStatus.Connected
        : OpenreelParticipantStatus.Disconnected;
    if (this.status$.value !== newValue) {
      this.status$.next(newValue);
      console.log(
        'Openreel participant ' +
          this.name +
          ' (id: ' +
          this.loginId +
          ') is now ' +
          OpenreelParticipantStatus[newValue]
      );
      this.session.participants$.next(this.session.participants$.value);
    }
  }

  // Set audio meter track whenever new twilio video participant is available
  private handleNewVideoParticipant(videoParticipant: IStreamingParticipant) {
    if (this.audioServiceSubscription) {
      this.audioServiceSubscription.unsubscribe();
      delete this.audioServiceSubscription;
    }
    if (videoParticipant) {
      this.subscriptions.push(this.audioServiceSubscription);
    }
  }

  private handleNewAudioLevel(newLevel: number) {
    this.audioLevel$.next(newLevel);
  }
}

export interface RemoteOpenreelParticipantVideoProperties {
  fps: string | undefined;
  width: string | undefined;
  resolution: string | undefined;

  // 500 - 11000
  colorTemperature: number | undefined;
  // 500 - 11000
  colorBalance: number | undefined;
  // -150 - +150
  tint: number | undefined;

  // -4 - +4
  exposure: number | undefined;
  //0 or 1
  autoExposureLock: number | undefined;
  // 500 - 11000
  iso: number | undefined;
  // -100 - +100
  contrast: number | undefined;

  // ?? - ??
  colorBalanceLow: number | undefined;
  // ?? - ??
  colorBalanceMids: number | undefined;
  // ?? - ??
  colorBalanceHighs: number | undefined;
  guide: boolean; // show grid lines
}

export interface RemoteOpenreelParticipantAudioProperties {
  isAudioInputEnabled$: BehaviorSubject<boolean>;
  isAudioOutputEnabled$: BehaviorSubject<boolean>;
}

export interface RemoteOpenreelParticipantDeviceProperties {
  battery0_100: number | undefined;
  storageGB: number | undefined;
  speed: string | undefined;
  micSettings: string | undefined;
  externalMic: string | undefined;
  isFrontCamera: boolean | undefined;
}
export interface RemoteOpenreelParticipantTeleprompterProperties {
  isTeleprompterVisible: boolean;
  isTeleprompterPlay: boolean;
  isTeleprompterPause: boolean;
  teleprompterId: number;
}
// Remote version of participant. In case we are in director session, we will
// listen to some additional info as well.
export class RemoteOpenreelParticipant extends OpenreelParticipant {
  // this is used only for remote participants on director
  videoProperties: RemoteOpenreelParticipantVideoProperties = {
    colorBalance: undefined,
    colorBalanceHighs: undefined,
    colorBalanceLow: undefined,
    colorBalanceMids: undefined,
    contrast: undefined,
    exposure: undefined,
    autoExposureLock: undefined,
    iso: undefined,
    fps: undefined,
    width: undefined,
    resolution: undefined,
    tint: undefined,
    colorTemperature: undefined,
    guide: false,
  };
  audioProperties: RemoteOpenreelParticipantAudioProperties = {
    isAudioInputEnabled$: new BehaviorSubject<boolean>(true),
    isAudioOutputEnabled$: new BehaviorSubject<boolean>(true),
  };
  deviceProperties: RemoteOpenreelParticipantDeviceProperties = {
    battery0_100: undefined,
    storageGB: undefined,
    speed: undefined,
    micSettings: undefined,
    externalMic: undefined,
    isFrontCamera: undefined,
  };

  teleprompterProperties: RemoteOpenreelParticipantTeleprompterProperties = {
    isTeleprompterVisible: false,
    isTeleprompterPlay: false,
    isTeleprompterPause: false,
    teleprompterId: 0,
  };

  deviceName: string;
  // Currently streaming device's capabilities
  deviceSupport: DeviceInfo;

  //external mic detection for iOS
  externalMicDetect$ = new BehaviorSubject<boolean>(null);

  private teleprompterChangeDetect = new BehaviorSubject<boolean>(null);
  teleprompterChangeDetect$ = this.teleprompterChangeDetect.asObservable();

  // In case we are in director session, we will listen to sockets emitted from
  // this participant and change additional data accordingly.
  constructor(
    sessionId: number,
    session: SessionBaseService<SessionBase>,
    commonApi: CommonApiService,
    private directorSocket: DirectorSocketService
  ) {
    super(sessionId, session, commonApi);
    if (this.directorSocket) {
      this.connectSocketHandler(
        EVT_S2D_APP_MICROPHONE_RANGE,
        this.handleAppMicrophoneRange
      );
      this.connectSocketHandler(
        EVT_S2D_SET_RESOLUTION_ACK,
        this.handleResolutionChange
      );
      this.connectSocketHandler(EVT_S2D_SHOW_GRID_ACK, this.handleGridToggle);
      this.connectSocketHandler(
        EVT_S2D_SET_FRAMERATE_ACK,
        this.handleFpsChange
      );
      this.connectSocketHandler(
        EVT_S2D_SET_EXPOSURE_ACK,
        this.handleExposureChange
      );
      this.connectSocketHandler(
        EVT_S2D_SET_AUTO_EXPOSURE_IOS_ACK,
        this.handleAutoExposureLockChange
      );
      this.connectSocketHandler(EVT_S2D_SET_ISO_ACK, this.handleIsoChange);
      this.connectSocketHandler(
        EVT_S2D_SET_COLOR_TEMPERATURE_ACK,
        this.handleColorTemperatureChange
      );
      this.connectSocketHandler(
        EVT_S2D_SET_CONTRAST_ACK,
        this.handleContrastChange
      );
      this.connectSocketHandler(
        EVT_S2D_SET_ALL_CAMERA_SETTINGS_ACK,
        this.handleSetAllCameraSettings
      );
    }
  }

  getSupportedResolutions(
    camera_name: 'front_cam' | 'back_cam'
  ): VideoStreamResolution[] {
    return this.deviceSupport[camera_name].resolution_support;
  }

  getSupportedFPS(
    camera_name: 'front_cam' | 'back_cam',
    forResolution: string
  ): string[] {
    const fpsArr = this.deviceSupport[camera_name].resolution_support.find(
      (item) => item.value.toString() === forResolution
    ).fps;
    return fpsArr.map((item) => item.toString());
  }

  getIosSupportedFPS(camera_name: 'front_cam' | 'back_cam'): string[] {
    const fpsArr = this.deviceSupport[camera_name].fps_support.split(',');
    return fpsArr.map((item) => item);
  }

  // Utility function to connect websocket message and a handler
  private connectSocketHandler<T>(
    eventName: string,
    handler: (data: T) => void | Promise<void>
  ) {
    this.subscriptions.push(
      this.directorSocket.anySocketEvent$
        .pipe(
          filter(
            (evt) => evt.from === this.identity && evt.eventName === eventName
          ),
          map((evt) => evt.data)
        )
        .subscribe(handler)
    );
  }

  // Set battery value from string. If % is found, it is ignored. String value
  // should contain a 0-100 number. If it is not a valid number, it is ignored.
  private setBattery(value: string | undefined) {
    value = value || 'N/A';
    this.deviceProperties.battery0_100 = parseFloat(value.replace('%', ''));
    if (isNaN(this.deviceProperties.battery0_100)) {
      this.deviceProperties.battery0_100 = undefined;
    }
  }

  private handleExternalMic(value: string | undefined) {
    if (value === '1' && this.deviceProperties.externalMic === '0') {
      this.externalMicDetect$.next(true);
    }
    this.deviceProperties.externalMic = value;
  }

  private handleTelePrompter(data: AppMicrophoneRange) {
    let teleprompterChangeDetect = false;
    for (const key in this.teleprompterProperties) {
      if (
        key in this.teleprompterProperties &&
        key in data &&
        this.teleprompterProperties[key] !== data[key]
      ) {
        teleprompterChangeDetect = true;
      }
    }
    this.teleprompterProperties.isTeleprompterPlay = data.isTeleprompterPlay;
    this.teleprompterProperties.isTeleprompterPause = data.isTeleprompterPause;
    this.teleprompterProperties.isTeleprompterVisible =
      data.isTeleprompterVisible;
    this.teleprompterProperties.teleprompterId = data.tele_script_id
      ? parseInt(data.tele_script_id, 10)
      : 0;
    if (teleprompterChangeDetect) {
      this.teleprompterChangeDetect.next(true);
    }
  }

  // Set remaining amount of storage in GB. If string contains text 'GB', 'GB'
  // text is ignored. If it is not a valid number, everything is ignored.
  private setStorage(value: string | undefined) {
    value = value || 'N/A';
    this.deviceProperties.storageGB = parseFloat(value.replace('GB', ''));
    if (isNaN(this.deviceProperties.storageGB)) {
      this.deviceProperties.storageGB = undefined;
    }
  }

  protected handleDeviceInfoResponse(deviceInfo: DeviceInfoResponse) {
    super.handleDeviceInfoResponse(deviceInfo);
    this.deviceName = deviceInfo.result.ovra_session_device_log.device_model;
    this.deviceSupport = JSON.parse(deviceInfo.result.device_support);
    this.setBattery(this.deviceSupport.battery_level);
    this.setStorage(this.deviceSupport.storage);
    console.log('Resolution support', this.getSupportedResolutions('back_cam'));
    this.loginId = deviceInfo.result.device_log_id;
    if (deviceInfo.devicedata.selected_fps) {
      this.videoProperties.fps = deviceInfo.devicedata.selected_fps;
    }
    if (deviceInfo.devicedata.resolution) {
      const res = findResolution(
        heightFromString(deviceInfo.devicedata.resolution)
      );
      this.videoProperties.resolution = deviceInfo.devicedata.resolution;
      this.videoProperties.width = res.width.toString();
    }
  }

  protected handleSetAllCameraSettings(req: SetAllCameraSettingsRequest) {
    if (!this.videoProperties) return;

    this.videoProperties.fps = req.fps;
    this.videoProperties.width = req.width;
    this.videoProperties.resolution = req.resolution;
    this.videoProperties.exposure = req.autoExposure
      ? parseFloat(req.autoExposure)
      : undefined;
    this.videoProperties.iso = req.iso ? parseFloat(req.iso) : undefined;
    this.videoProperties.contrast = req.contrast
      ? parseFloat(req.contrast)
      : undefined;
    this.videoProperties.colorTemperature =
      req.white_balance === -1 ? undefined : req.white_balance;
  }

  // Convert current device settings to preset
  getSettingsAsPreset(
    presetName: string,
    sessionId: number
  ): CameraPresetCreate {
    const v = this.videoProperties;
    const autoFocus = 1;
    const autoExposure = v.exposure === undefined ? true : false;
    const autoTemperature = v.colorTemperature === undefined ? true : false;
    return {
      aspect_ratio: 'Ratio169',
      audio_sample_rate: '44.1',
      cSp_autoExposure_status: !autoExposure ? '1' : '0',
      cSp_autoFocus_status: autoFocus.toString() as '1',
      cSp_cameraPotrait_status: '0',
      cSp_exposure_value: !autoExposure ? v.exposure.toString() : '0',
      cSp_focus_value: '50',
      cSp_fps_value: v.fps.toString(),
      cSp_iso_value: !autoExposure ? v.iso.toString() : '0',
      cSp_title: presetName,
      cSp_whiteBalance_value: !autoTemperature
        ? v.colorTemperature.toString()
        : '0',
      color_overlay_color: '-1',
      color_overlay_value: '-1',
      flashlight: 0,
      focus_peaking: 0,
      highlight: '-1',
      is_autoexposure: autoExposure ? 1 : 0,
      is_autofocus: autoFocus,
      is_autoiso: autoExposure ? 1 : 0,
      is_autotemperature: autoTemperature ? 1 : 0,
      log_mode: -1,
      mbit: 12,
      resolution: v.resolution.toString(),
      saturation: '-1',
      session_id: sessionId,
      shadow: '-1',
      stabilize: '0',
      tint: '-1',
      vibrance: '-1',
      zoom: 0,
      cSp_contrast_value:
        v.contrast === undefined ? '-1' : v.contrast.toString(),
      is_autocontrast: v.contrast === undefined ? 1 : 0,
    };
  }

  ////////////////////////////////////
  /// Socket handlers
  ////////////////////////////////////
  private handleAppMicrophoneRange = (data: AppMicrophoneRange) => {
    this.videoProperties.resolution = data.resolution;
    this.videoProperties.width = data.width;
    this.videoProperties.fps = data.fps;
    this.videoProperties.guide = data.showGrid;
    this.audioProperties.isAudioInputEnabled$.next(data.inputaudio === '1');
    this.audioProperties.isAudioOutputEnabled$.next(
      data.is_app_listen_audio === '1'
    );
    this.deviceProperties.micSettings = data.mobile_mic_options || '0';
    this.deviceProperties.speed = data.speed;
    this.deviceProperties.isFrontCamera = data.is_front_camera === '1';
    this.setBattery(data.battery_level?.toString());
    this.setStorage(data.storage?.toString());
    this.handleExternalMic(data.sound_route);
    this.handleTelePrompter(data);
  };
  private handleResolutionChange = (data: ResolutionSetAck) => {
    this.videoProperties.width = data.width;
    this.videoProperties.resolution = data.value;
    this.videoProperties.fps = data.fps;
    this.videoPropertiesRefreshed();
  };
  private handleGridToggle = (data: { showGridLines: boolean }) => {
    console.log('grid lines now: ', data.showGridLines);
    this.videoProperties.guide = data.showGridLines;
    this.videoPropertiesRefreshed();
  };
  private handleFpsChange = (data: FrameRateSetAck) => {
    this.videoProperties.fps = data.frame_rate;
    this.videoPropertiesRefreshed();
  };
  private handleExposureChange = (data: SetExposureRequest) => {
    if (data.value) {
      this.videoProperties.exposure = parseFloat(data.value);
      this.videoPropertiesRefreshed();
    }
  };
  private handleAutoExposureLockChange = (data: SetAutoExposureRequest) => {
    if (data.data) {
      this.videoProperties.autoExposureLock = parseFloat(data.data);
      this.videoPropertiesRefreshed();
    }
  };
  private handleIsoChange = (data: SetIsoRequest) => {
    if (!data.type && data.value === '-1') {
      this.videoProperties.exposure = undefined;
      this.videoProperties.iso = undefined;
    } else {
      this.videoProperties.iso = parseFloat(data.value);
    }
    this.videoPropertiesRefreshed();
  };

  private handleColorTemperatureChange = (data: SetColorTemperatureRequest) => {
    console.log('*****************************', data);
    if (data.value === '-1') {
      this.videoProperties.colorTemperature = undefined;
    } else {
      this.videoProperties.colorTemperature = parseFloat(data.value);
    }
    this.videoPropertiesRefreshed();
  };
  private handleContrastChange = (data: SetContrastRequest) => {
    if (data.value === '-1') {
      this.videoProperties.contrast = undefined;
    } else {
      this.videoProperties.contrast = parseFloat(data.value);
    }
    this.videoPropertiesRefreshed();
  };
  private videoPropertiesRefreshed() {
    console.log(
      'Participant ' + this.name + ' has refreshed video properties: ',
      this.videoProperties
    );
  }
}
