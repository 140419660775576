import {
  Asset,
  Layer,
  LottieLayer,
  LottieLayerData,
  TextOverlayFeature,
  TimelineType,
  WorkflowDataDto,
} from '../../api/workflow.interfaces';

export const timelineItemFactory = (
  layer: Layer,
  type: TimelineType,
  workflow: WorkflowDataDto
) => {
  switch (layer.type) {
    case 'video': {
      const assetId = layer.assetId;
      const asset = workflow.assets.find((a) => a.id === assetId);

      return new TimelineItem(layer, asset, type);
    }
    case 'lottie': {
      const assetId = layer.assetId;
      const animationAsset = workflow.assets.find((a) => a.id === assetId);
      const template = workflow.features.textOverlays.find(
        (t) => t.id === layer.templateId
      );

      return new TimelineTextItem(layer, animationAsset, template);
    }
    case 'sublayers': {
      if (type === 'overlays') {
        const assetId = layer.children[0].assetId;
        const animationAsset = workflow.assets.find((a) => a.id === assetId);
        const template = workflow.features.textOverlays.find(
          (t) => t.id === layer.templateId
        );

        return new TimelineTextItem(layer, animationAsset, template);
      } else {
        throw new Error('Not implemented.');
      }
    }
  }
};

export const watermarkItemFactory = (
  layer: Layer,
  workflow: WorkflowDataDto
) => {
  switch (layer.type) {
    case 'lottie': {
      const animationAsset = workflow.assets.find(
        (a) => a.id === layer.assetId
      );

      return new TimelineTextItem(layer, animationAsset, null);
    }
    case 'image': {
      throw new Error('Not implemented.');
    }
  }
};

export abstract class TimelineBaseItem {
  originalInstance?: TimelineItem;

  constructor(
    public layer: Layer,
    public asset: Asset,
    public type: TimelineType
  ) {}

  get layerId() {
    return this.layer.layerId;
  }

  get layerType() {
    return this.layer.type;
  }

  get startAt() {
    if (typeof this.layer.visibility?.startAt === 'number') {
      return this.layer.visibility?.startAt;
    }
    return null;
  }

  get endAt() {
    if (typeof this.layer.visibility?.endAt === 'number') {
      return this.layer.visibility?.endAt;
    }
    return null;
  }

  get isVirtual() {
    return this.getOriginalInstance() !== this;
  }

  getOriginalInstance(): TimelineBaseItem {
    return this.originalInstance ?? this;
  }

  abstract duration: number;
}

export class TimelineItem extends TimelineBaseItem {
  get duration() {
    let duration = this.asset.data.duration;

    if (this.asset.trimFrom || this.asset.trimTo) {
      duration = (this.asset.trimTo || duration) - (this.asset.trimFrom || 0);
    }

    return duration;
  }
}

export class TimelineTextItem extends TimelineItem {
  constructor(
    public layer: Layer,
    public asset: Asset,
    public template: TextOverlayFeature
  ) {
    super(layer, asset, 'overlays');
  }

  get templateTitle() {
    return this.template.title;
  }

  get templateIcon() {
    return this.template.icon;
  }

  get duration() {
    return this.layer.visibility.endAt - this.layer.visibility.startAt;
  }

  get data() {
    if (this.layer.type === 'lottie') {
      return [this.layer.data];
    }

    if (this.layer.type === 'sublayers') {
      return this.layer.children.map((child: LottieLayer) => child.data);
    }

    return null;
  }

  get preset() {
    return this.asset.preset;
  }
}

export interface LayerDataChangedEvent {
  values: {
    [key: string]: LottieLayerData;
  }[];
  assetChanges: {
    [key: string]: {
      newAssetFileId: number;
      removedAssetId: string;
      isBrandKitSelected?: boolean;
    };
  }[];
  styleChanges: {
    [key: string]: {
      colorIndex: number;
      color?: string;
      fontIndex: number;
    };
  }[];
}

// TODO: old way of storing changes, still used on video elements
export interface ElementChanges<T> {
  data: T;
  assets?: {
    newAssets?: Asset[];
    removedAssetIds?: string[];
  };
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export interface ElementChangesBase extends ElementChanges<any> {}
