import * as fromActions from '../actions/asset-cache.actions';
import * as fromSelectors from '../selectors/asset-cache.selectors';

import { filter, map, take } from 'rxjs/operators';

import { Asset } from '../../../api/workflow.interfaces';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AssetsCacheFacade {
  cachedAssets$ = this.store.select(fromSelectors.cachedAssets);
  cachedObjects$ = this.store.select(fromSelectors.cachedObjects);

  constructor(private readonly store: Store) {}

  getAssetUrl = (asset: Asset) =>
    this.getAssetUrlById(asset.file.provider, asset.file.path);

  getAssetUrlById(
    provider: 'url' | 'or-assets' | 'or-recordings',
    id: number | string
  ): Observable<string> {
    this.store.dispatch(fromActions.loadAsset({ id, provider }));

    return this.cachedAssets$.pipe(
      map((assets) =>
        assets.find(
          (currentAsset) =>
            currentAsset.assetId === id.toString() &&
            currentAsset.provider === provider
        )
      ),
      filter((asset) => asset && !asset.isLoading),
      take(1),
      map((asset) => asset.url)
    );
  }

  getObjectUrl(
    url: string,
    dataType: string = 'video/mp4'
  ): Observable<string> {
    this.store.dispatch(fromActions.loadObject({ url, dataType }));

    return this.cachedObjects$.pipe(
      map((objects) => objects.find((object) => object.originalUrl === url)),
      filter((object) => object && !object.isLoading),
      take(1),
      map((object) => object.url)
    );
  }
}
