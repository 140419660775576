import {
  Asset,
  ColorAsset,
  ImageLayer,
  Layer,
  LottieLayer,
  Style,
  Timeline,
  VideoLayer,
  WorkflowDataDto,
} from '../../../api/workflow.interfaces';
import {
  ControllerData,
  ImagePlayerData,
  LottiePlayerData,
  PlayerData,
  TimelinesPlayerData,
  VideoPlayerData,
} from '../../cue-player/player-data.interfaces';
import { EMPTY, Observable, combineLatest, from, of } from 'rxjs';
import {
  LottieAnimation,
  LottieFont,
} from '../../shared/interfaces/lottie-animation.interface';
import { concatMap, filter, map, mergeMap, reduce } from 'rxjs/operators';

import { AssetsCacheFacade } from '../../store/facades/asset-cache.facade';
import { FileTemplatingService } from '../../core/services/file-templating.service';
import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash';
import { hexToRgbString } from '../../shared/helpers/color.helpers';

const DEFAULT_FONT = 'Default Font';
const DEFAULT_COLOR = '#000000';

export interface ControllerOptions {
  muted?: boolean;
}

@Injectable()
export class EditorPreviewerService {
  constructor(
    private readonly assetsCacheFacade: AssetsCacheFacade,
    private readonly fileTemplatingService: FileTemplatingService
  ) {}

  createPlayerData(
    workflow: WorkflowDataDto,
    assets: Asset[],
    timelines: Timeline[]
  ): Observable<TimelinesPlayerData> {
    return this.toTimelinePlayer(
      workflow,
      assets.reduce((acc, asset) => {
        acc[asset.id] = asset;
        return acc;
      }, {}),
      timelines
    );
  }

  private toTimelinePlayer(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    timelines: Timeline[]
  ): Observable<TimelinesPlayerData> {
    const mainsLayers = this.getMainTimelineLayers(timelines).sort(
      (layers1, layers2) =>
        this.getLastLayer(layers2)?.visibility?.endAt -
        this.getLastLayer(layers1)?.visibility?.endAt
    );

    const bRoll = this.getTimelineLayers(timelines, 'b-roll');
    const overlays = this.getTimelineLayers(timelines, 'overlays');
    const hidden = this.getTimelineLayers(timelines, 'hidden');

    return combineLatest(
      mainsLayers
        .map((mainLayers) =>
          this.toControllerDataList(
            workflow,
            assets,
            mainLayers ? mainLayers : []
          )
        )
        .concat([
          this.toControllerDataList(workflow, assets, bRoll, { muted: true }),
          this.toControllerDataList(workflow, assets, overlays, {
            muted: true,
          }),
          this.toControllerDataList(workflow, assets, hidden, { muted: true }),
        ])
    ).pipe(
      map(
        (controllers) =>
          new TimelinesPlayerData({
            mainPlayers: controllers[0],
            overlays: controllers.slice(1, controllers.length),
          })
      ),
      filter(
        (playerData) =>
          playerData.mainPlayers.length > 0 ||
          playerData.overlays?.filter((overlay) => overlay.length > 0).length >
            0
      )
    );
  }

  private getMainTimelineLayers(timelines: Timeline[]): Layer[][] {
    return timelines.filter((t) => t.type === 'main').map((t) => t.layers);
  }

  private getLastLayer(layers: Layer[]): Layer | undefined {
    let lastLayer: Layer | undefined;
    layers.forEach((layer) => {
      if (layer?.visibility?.endAt ?? 0 > lastLayer?.visibility?.endAt ?? 0) {
        lastLayer = layer;
      }
    });

    return lastLayer;
  }

  private getTimelineLayers(timelines: Timeline[], type: string): Layer[] {
    let layers = [];
    timelines
      .filter((t) => t.type === type)
      .forEach((t) => (layers = layers.concat(t.layers)));
    return layers;
  }

  private toControllerDataList(
    workflow,
    assets,
    layers: Layer[],
    options?: ControllerOptions
  ): Observable<ControllerData[]> {
    return from(layers).pipe(
      concatMap((layer) => {
        if (
          layer.type === 'sublayers' &&
          layer.children.length > 0 &&
          layer.children[0].type === 'lottie'
        ) {
          return layer.children.map((child, index) => {
            const visibility = { ...layer.visibility };
            visibility.startAt += child.visibility?.startAt || 0;
            visibility.endAt += child.visibility?.startAt || 0;
            return this.toControllerData(
              workflow,
              assets,
              { ...child, visibility },
              options
            );
          });
        } else {
          return [this.toControllerData(workflow, assets, layer, options)];
        }
      }),
      concatMap((data) => data),
      reduce((acc, val) => {
        acc.push(val);
        return acc;
      }, [] as ControllerData[])
    );
  }

  private toControllerData(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layer: Layer,
    options?: ControllerOptions
  ): Observable<ControllerData> {
    return this.toPlayerData(workflow, assets, layer, options).pipe(
      map((playerData) => {
        const data: ControllerData = { playerData, layout: 'fullscreen' };
        this.addVisibility(data, layer);
        this.addPosition(data, layer);
        this.addTransition(data, layer);
        return data;
      })
    );
  }

  private toPlayerData(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layer: Layer,
    options?: ControllerOptions
  ): Observable<PlayerData> {
    return of(layer).pipe(
      mergeMap((l) => {
        if (l.type === 'lottie') {
          return this.toLottiePlayer(workflow, assets, l);
        } else if (l.type === 'video') {
          return this.toVideoPlayer(assets, l, options?.muted);
        } else if (l.type === 'image') {
          return this.toImagePlayer(assets, l);
        } else if (l.type === 'section') {
          return this.toTimelinePlayer(
            workflow,
            assets,
            workflow.sections[l.sectionId].timelines
          );
        } else if (l.type === 'timelines') {
          const children = cloneDeep(l.children);
          children.forEach((child, index) => {
            if (index === 0) {
              child.layers.forEach((layer) => {
                layer.bounds = {
                  width: 48.5,
                  height: 48.5,
                  x: 1,
                  y: 24,
                };
              });
            } else if (index === 1) {
              child.layers.forEach((layer) => {
                layer.bounds = {
                  width: 48.5,
                  height: 48.5,
                  x: 50.5,
                  y: 24,
                };
              });
            } else {
              throw new Error(
                'Currenty a timeline layer can only have 2 children.'
              );
            }
          });

          return this.toTimelinePlayer(workflow, assets, children);
        }
        return EMPTY;
      })
    );
  }

  private toVideoPlayer(
    assets: { [key: string]: Asset },
    layer: VideoLayer,
    muted: boolean | null
  ): Observable<VideoPlayerData> {
    const asset = assets[layer.assetId];
    const type = 'video/mp4';
    return this.assetsCacheFacade.getAssetUrl(asset).pipe(
      mergeMap((url) => this.assetsCacheFacade.getObjectUrl(url, type)),
      map(
        (url) =>
          new VideoPlayerData({
            source: url,
            type,
            startAt: asset.trimFrom,
            endAt: asset.trimTo,
            muted: Boolean(muted),
            styling: {
              ...layer.styling,
            },
          })
      )
    );
  }

  private toImagePlayer(
    assets: { [key: string]: Asset },
    layer: ImageLayer
  ): Observable<ImagePlayerData> {
    return this.assetsCacheFacade.getAssetUrl(assets[layer.assetId]).pipe(
      map(
        (url) =>
          new ImagePlayerData({
            source: url,
          })
      )
    );
  }

  toLottiePlayer(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    layer: Layer & LottieLayer
  ): Observable<LottiePlayerData> {
    const asset = assets[layer.assetId];
    if (layer.data) {
      return this.getDataValues(workflow, assets, layer.data).pipe(
        concatMap((values) =>
          this.fileTemplatingService.apply<LottieAnimation>(asset, values)
        ),
        map((content) => ({
          ...content,
          fonts: { list: this.toLottieFonts(workflow) },
        })),
        map(
          (content) =>
            new LottiePlayerData({
              content,
              renderer: layer.renderer,
              duration:
                !isNaN(layer.visibility?.startAt) &&
                !isNaN(layer.visibility.endAt)
                  ? layer.visibility?.endAt - layer.visibility?.startAt
                  : undefined,
            })
        )
      );
    }
    return of(
      new LottiePlayerData({
        source: asset.file.path as string,
        renderer: layer.renderer,
      })
    );
  }

  private getDataValues(
    workflow: WorkflowDataDto,
    assets: { [key: string]: Asset },
    data: unknown
  ): Observable<{ [key: string]: string | number }> {
    return from(Object.keys(data)).pipe(
      map((key) => ({ key, obj: data[key] })),
      filter(
        ({ obj }) =>
          obj.assetId in assets ||
          ['text', 'shape', 'hidden'].indexOf(obj.type) > -1
      ),
      concatMap(({ key, obj }) => {
        if (obj.type === 'text') {
          return this.getTextValues(key, obj, workflow);
        } else if (obj.type === 'shape') {
          return this.getShapeValues(key, obj, workflow);
        } else if (obj.type === 'hidden') {
          return this.getHiddenValues(key, obj, workflow);
        } else {
          return this.getImageValues(key, obj, assets);
        }
      }),
      reduce((acc, val) => {
        Object.assign(acc, val);
        return acc;
      }, {})
    );
  }

  private addPosition(data: ControllerData, layer: Layer) {
    if (!layer.bounds) return;

    data.layout = {
      position: {
        x: layer.bounds.x,
        y: layer.bounds.y,
      },
      size: {
        width: layer.bounds.width,
        height: layer.bounds.height,
      },
    };
  }

  private addVisibility(data: ControllerData, layer: Layer) {
    if (layer.visibility) {
      data.cue = {
        startAt: layer.visibility.startAt,
        endAt: layer.visibility.endAt,
        repeatableEvery: layer.visibility.repeatableEvery,
      };
    }
  }

  private addTransition(data: ControllerData, layer: Layer) {
    if (layer.transitions) {
      data.transitions = cloneDeep(layer.transitions);
    }
  }

  private getTextValues(
    key: string,
    { value, styleId }: { value: string; styleId: string },
    { styles, colors, fonts }: WorkflowDataDto
  ): Observable<{ [key: string]: string }> {
    const style = styles.find((s) => s.id === styleId);
    const colorKey = `${key}Color`;
    const colorHexKey = `${key}ColorHex`;
    const fontKey = `${key}Font`;

    const color = this.getColor(style, colors);

    const fontIndex = style?.fontIndex ?? 0;
    let font = DEFAULT_FONT;
    if (fonts?.length) {
      font =
        fontIndex >= fonts?.length
          ? fonts[fonts?.length - 1].name
          : fonts[fontIndex].name;
    }

    return of({
      [key]: value,
      [colorKey]: hexToRgbString(color),
      [colorHexKey]: `"${color}"`,
      [fontKey]: font,
    });
  }

  private getShapeValues(
    key: string,
    { styleId }: { styleId: string },
    { styles, colors }: WorkflowDataDto
  ): Observable<{ [key: string]: string }> {
    const style = styles.find((s) => s.id === styleId);
    const colorKey = `${key}Color`;
    const colorHexKey = `${key}ColorHex`;

    const color = this.getColor(style, colors);

    return of({
      [colorKey]: hexToRgbString(color),
      [colorHexKey]: `"${color}"`,
    });
  }

  private getHiddenValues(
    key: string,
    { styleId }: { styleId: string },
    { styles, colors }: WorkflowDataDto
  ): Observable<{ [key: string]: string }> {
    const style = styles.find((s) => s.id === styleId);
    const colorKey = `${key}Color`;

    const color = this.getColor(style, colors);

    return of({
      [colorKey]: hexToRgbString(color),
    });
  }

  private getImageValues(
    key: string,
    obj: { assetId: string },
    assets: { [key: string]: Asset }
  ): Observable<{ [key: string]: string }> {
    const asset = assets[obj.assetId];
    return this.assetsCacheFacade
      .getAssetUrl(asset)
      .pipe(map((url) => ({ [key]: url })));
  }

  private toLottieFonts({ fonts }: WorkflowDataDto): LottieFont[] {
    if (!fonts?.length) {
      return [
        {
          origin: 0,
          fFamily: 'Arial',
          fName: DEFAULT_FONT,
          ascent: 75.9994506835938,
          fStyle: 'normal',
          fWeight: '400',
        },
      ];
    }

    return fonts.map((asset) => ({
      origin: 0,
      fFamily: asset.family,
      fName: asset.name,
      ascent: 75.9994506835938,
      fStyle: 'normal',
      fWeight: asset.weight.toString(),
    }));
  }

  private getColor(style: Style, colors: ColorAsset[]) {
    let color = DEFAULT_COLOR;
    if (style?.color) {
      color = style.color;
    } else {
      const colorIndex = style?.colorIndex ?? 0;
      if (colors?.length) {
        color =
          colorIndex >= colors?.length
            ? colors[colors?.length - 1].value
            : colors[colorIndex].value;
      }
    }

    return color;
  }
}
