import * as fromActions from '../actions/project.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ProjectFacade } from '../facades/project.facade';
import { ProjectService } from '../../../api/project/project.service';
import { of } from 'rxjs';
import {WorkflowProjectSocketService} from '@openreel/common';

@Injectable()
export class RenderEffects {
  renderProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.renderProject),
      withLatestFrom(this.projectFacade.data$),
      switchMap(([, { data }]) =>
        this.projectService.renderProject(data.id).pipe(
          map(() =>
            fromActions.setRenderProgress({
              renderProgress: {
                projectId: data.id,
                state: 'rendering',
                progress: 0,
              },
            })
          ),
          catchError(() =>
            of(
              fromActions.setRenderProgress({
                renderProgress: {
                  projectId: data.id,
                  state: 'failed',
                  progress: 0,
                },
              })
            )
          ) // TODO: how to handle errors
        )
      )
    )
  );

  cancelProjectRender$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.cancelProjectRender),
      withLatestFrom(this.projectFacade.data$),
      switchMap(([, { data }]) =>
        this.projectService.cancelProjectRender(data.id).pipe(
          map(() => fromActions.cancelProjectRenderAccepted()),
          catchError((error) => of(error)) // TODO: how to handle errors
        )
      )
    )
  );

  progressUpdate$ = createEffect(() =>
    this.workflowProjectSocketService.renderProgress$.pipe(
      withLatestFrom(this.projectFacade.data$),
      filter(
        ([renderProgress, data]) => data.data?.id === renderProgress.projectId
      ),
      switchMap(([renderProgress]) =>
        of(fromActions.setRenderProgress({ renderProgress }))
      )
    )
  );

  constructor(
    private projectService: ProjectService,
    private projectFacade: ProjectFacade,
    private actions$: Actions,
    private workflowProjectSocketService: WorkflowProjectSocketService
  ) {}
}
