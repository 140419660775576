import { Component, Input, OnInit } from '@angular/core';
import { LayerService, UiControl } from '../../services/layer.service';

import { AssetsFacade } from '../../../store/facades/assets.facade';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'openreel-wf-layer-item-text-new',
  templateUrl: './layer-item-text-new.component.html',
  styleUrls: ['./layer-item-text-new.component.scss'],
})
export class LayerItemTextNewComponent implements OnInit {
  @Input() uiControl: UiControl;
  @Input() control: FormGroup;

  colors$ = this.assetsFacade.colors$;
  fonts$ = this.assetsFacade.fonts$;

  selectedFontIndex: number;

  constructor(
    private readonly layerService: LayerService,
    private readonly assetsFacade: AssetsFacade
  ) {}

  ngOnInit() {
    this.selectedFontIndex = this.control.value.fontIndex || 0;
  }

  onFontChange(fontIndex: number) {
    this.selectedFontIndex = fontIndex;

    this.control.patchValue({
      fontIndex: this.selectedFontIndex,
    });
  }

  validationErrors = () =>
    this.layerService.getValidationErrors(this.control.errors);
}
