import {
  AssetsFileProviderType,
  Bounds,
  Layer,
  TextOverlayFeature,
  TimelinesLayer,
  TimelineType,
} from '../../../../api/workflow.interfaces';
import {
  LayerDataChangedEvent,
  TimelineItem,
  TimelineTextItem,
} from '../../../shared/element.interfaces';
import { VideoSplit } from './trimmer.interfaces';

export interface TimelineItemEvent<T extends TimelineItem = TimelineItem> {
  item: T;
  timeline: Timeline;
}

export interface AddMenuItem {
  title: string;
  icon: string;
  description: string;
  clickFn?: () => void;
  visible?: boolean;
}

export interface TimelineDefOptions {
  timelineIndex?: number;
  parentLayer?: Layer;
}

export interface TimelineDef {
  type: TimelineType;
  options: TimelineDefOptions;
}

export interface Timeline {
  type: TimelineType;
  options: TimelineDefOptions;
  fixed: boolean;
  addMenuItems: AddMenuItem[];
  data: TimelineItem[];
  cssClass: string;
  emptyCaption: string;
}

export interface Durations {
  main: number;
  intro: number;
  outro: number;
  total: number;
}

export enum TrimmerAction {
  EditSplits,
  EditTrim,
  AddClips,
}

// Events
export interface TimelineItemEvent<T extends TimelineItem = TimelineItem> {
  item: T;
  timeline: Timeline;
}

export interface UpdatedItem {
  item: TimelineItem;
  timelineIndex: number;
}

export interface ClipAddEvent {
  assetFileId: number;
  assetProviderType: AssetsFileProviderType;
  duration: number;
  name: string;
  trimFrom?: number;
  trimTo?: number;
  startAt?: number;
  endAt?: number;
  isPip?: boolean;
  bounds?: Bounds;
}

export interface ClipMoveEvent {
  item: TimelineItem;
  previousIndex: number;
  currentIndex: number;
}

export interface TextOverlayAddEvent {
  textOverlay: TextOverlayFeature;
  startAt?: number;
}

export interface TextOverlayAddSublayersEvent {
  templateId: string;
  properties: TimelinesLayer;
  timelineType: TimelineType;
}

export interface OpenTextOverlayDataDialogEvent
  extends TimelineItemEvent<TimelineTextItem> {
  initial?: boolean;
}

export interface StretchTextOverlayEvent extends TimelineItemEvent {
  newDuration: number;
}

export interface ItemMovedEvent extends TimelineItemEvent {
  startAt: number;
}

export interface UpdateTextOverlayDataEvent extends TimelineItemEvent {
  data: LayerDataChangedEvent;
}

export interface UpdateTextOverlayMetadataEvent extends TimelineItemEvent {
  duration: number;
  repeatability: number;
}

export interface UpdateClipTrims extends TimelineItemEvent {
  split: VideoSplit;
}
