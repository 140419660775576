import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { stopStream } from '../helpers/media-devices.helper';
import {
  AudioDevice,
  AudioDeviceOptions,
} from '../interfaces/audio-device.interface';
import { AudioStream } from '../interfaces/audio-stream.interface';
import { MediaDevicesService } from './media-devices.service';

/**
 * Service for enumerating and acquiring audio devices. Every time you acquire
 * a device, you have to stop it manually after you don't need it. This service
 * can provide multiple instances of same devices.
 */
@Injectable()
export class AudioStreamService {
  devices$: Observable<AudioDevice[]>;
  audio$ = new BehaviorSubject<AudioStream>(null);
  streams: MediaStream[] = [];

  constructor(readonly mediaDevicesService: MediaDevicesService) {
    this.devices$ = mediaDevicesService.devices$.pipe(
      map((devices) =>
        devices
          .filter((device) => device.kind === 'audioinput')
          .map(({ deviceId, label }) => ({
            id: deviceId,
            name: label,
            isDefault: this.checkIsDefault(deviceId, label),
          }))
      )
    );
  }

  async openStream({ id }: AudioDeviceOptions): Promise<void> {
    this.stopActiveStream();
    try {
      const stream = await this.mediaDevicesService.openAudioStream(id);
      const track = stream.getTracks()[0];
      const settings = track.getSettings();
      this.streams.push(stream);

      this.audio$.next({
        device: {
          id: settings.deviceId,
          name: track.label,
          isDefault: this.checkIsDefault(settings.deviceId, track.label),
        },
        stream,
        track,
      });
    } catch (error) {
      console.error(error);
      this.audio$.next({
        device: {
          id,
          isDefault: false,
        },
        error,
      });
    }
  }

  closeStream() {
    if (this.streams.length) {
      console.log(`Closing stream ${this.audio$.value.device.name}.`);
      this.stopActiveStream();
      this.audio$.next(null);
    }
  }

  private stopActiveStream() {
    try {
      this.streams.forEach(stream => {
        if (stream.active) stopStream(stream);
      });
      this.streams = [];
    } catch (err) {
      console.error(err);
    }
  }

  private checkIsDefault(id: string, name?: string): boolean {
    return (
      id === 'default' ||
      id === 'communications' ||
      name?.toLowerCase()?.indexOf('microphone') >= 0 ||
      name?.toLowerCase()?.indexOf('mic') >= 0
    );
  }
}
