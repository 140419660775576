import { CreateWorkflowRequest } from './workflow.interfaces';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  /**
   * TODO: replace once NgRx kick in!
   */
  public updateClipsListSubject = new Subject();

  private urls = {
    root: `${commonenv.nextGenApiUrl}workflows/projects`,
    byProjectId: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}`,
    signedUrl: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/signed-url`,
    duplicate: (projectId) =>
      `${commonenv.nextGenApiUrl}workflows/projects/${projectId}/copy`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  createWorkflowProject(data: CreateWorkflowRequest) {
    return this.httpClient.post(this.urls.root, data);
  }
  getSignedUrl(projectId, forceDownload) {
    const params = new HttpParams().set('forceDownload', forceDownload);

    return this.httpClient.get<{ signedUrl: string }>(
      this.urls.signedUrl(projectId),
      {
        params,
      }
    );
  }

  getCaptureProjectWorkflows(projectId) {
    const params = new HttpParams().set('projectId', projectId).set('includeClipEdits', 'true');

    return this.filterWorkflows(params);
  }
  delete(id: number, confirm: boolean = false) {
    const params = new HttpParams().set('confirm', confirm.toString());

    return this.httpClient
      .delete(this.urls.byProjectId(id), { params })
      .pipe(tap(() => this.updateClipsListSubject.next({})));
  }

  restore(id: number) {
    return this.httpClient.patch(this.urls.byProjectId(id), { restore: true });
  }

  duplicate(id: number) {
    return this.httpClient
      .post(this.urls.duplicate(id), {})
      .pipe(tap(() => this.updateClipsListSubject.next({})));
  }

  getProjectArchivedWorkflows(projectId: number) {
    const params = new HttpParams()
      .set('projectId', projectId.toString())
      .set('archived', 'true');

    return this.filterWorkflows(params);
  }

  private filterWorkflows(params: HttpParams) {
    return this.httpClient.get(this.urls.root, {
      params,
    });
  }
}
