<div>{{ uiControl.label }}</div>
<div class="color flex flex-align-center m-top-8">
  <button
    type="button"
    mat-button
    class="w-full"
    #menuTriggerColor="matMenuTrigger"
    [matMenuTriggerFor]="colorsMenu"
  >
    <div
      class="m-right-4"
      [ngStyle]="{ 'background-color': selectedColor?.value }"
    ></div>
    <mat-icon
      class="mat-icon-12"
      [class.expanded]="menuTriggerColor.menuOpen"
      svgIcon="caret"
    ></mat-icon>
  </button>
  <mat-menu
    #colorsMenu="matMenu"
    class="mat-menu-flat mat-menu-toolbar mat-menu-toolbar-colors w-min-200"
  >
    <button
      mat-menu-item
      *ngFor="let color of colors"
      [ngStyle]="{ 'background-color': color.value }"
      (click)="selectColor(color)"
    ></button>
  </mat-menu>
</div>
