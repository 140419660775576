import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { last, map, switchMap, take } from 'rxjs/operators';

import { AssetsService } from './../../../../api/assets/assets.service';
import { HttpEventType } from '@angular/common/http';
import { ProjectFacade } from '../../../store/facades/project.facade';
import { splitNameAndExtension } from '../../helpers/file.helpers';

@Component({
  selector: 'openreel-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit, OnChanges {
  @Input() uploadCaption: string;
  @Input() type: string;
  @Input() selectedSourceId: number;

  @Input() @HostBinding('class.dark') darkBackround = false;

  @Output() started = new EventEmitter();
  @Output() upload = new EventEmitter<number>();
  @Output() remove = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;

  sourceId: number;

  isUploaded = false;
  isUploading = false;
  uploadProgress = 0;

  constructor(
    private readonly assetsService: AssetsService,
    private readonly projectFacade: ProjectFacade
  ) {}

  ngOnInit() {
    this.sourceId = this.selectedSourceId;
    this.calculateState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedSourceId' in changes) {
      this.sourceId = this.selectedSourceId;
      this.isUploaded = false;
      this.isUploading = false;
      this.uploadProgress = 0;
      this.calculateState();
    }
  }

  selectImage() {
    this.fileInput.nativeElement.click();
  }

  removeImage() {
    this.fileInput.nativeElement.value = null;
    this.isUploaded = false;
    this.sourceId = null;
    this.remove.emit();
  }

  closeFileDialog($event: Event) {
    $event.stopPropagation();

    const target = $event.target as HTMLInputElement;
    if (target.files.length === 0) {
      return;
    }

    const fileToUpload = target.files.item(0);
    const [name, extension] = splitNameAndExtension(fileToUpload.name);
    if (!extension) {
      return;
    }

    this.started.emit();

    this.isUploading = true;
    this.projectFacade.id$
      .pipe(
        take(1),
        switchMap((id) =>
          this.assetsService
            .createAssetUrl(id, { name, type: this.type, extension })
            .pipe(
              switchMap((response) =>
                combineLatest([
                  of(response),
                  this.assetsService
                    .uploadAsset(response.uploadUrl.url, fileToUpload)
                    .pipe(
                      map((event) => {
                        if (event.type === HttpEventType.UploadProgress) {
                          this.uploadProgress = Math.round(
                            (event.loaded / event.total) * 100
                          );
                        }
                      }),
                      last()
                    ),
                ])
              )
            )
        )
      )
      .subscribe(([data]) => {
        this.sourceId = data.id;
        this.upload.emit(this.sourceId);
        this.calculateState();
      });
  }

  private calculateState() {
    if (this.sourceId) {
      this.isUploaded = true;
      this.isUploading = false;
      this.uploadProgress = 0;
    }
  }
}
