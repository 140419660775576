import { NgModule } from '@angular/core';
import { ClipDuration } from './clip-duration.pipe';
import { ClipSize } from './clip-size.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SecondsToHms } from './secondsToHms.pipe';
import { UserImgDataPipe } from './user-img-data.pipe';
import { WordCount } from './word-count.pipe';

const pipes = [
  SafeHtmlPipe,
  UserImgDataPipe,
  ClipDuration,
  ClipSize,
  SecondsToHms,
  WordCount,
];

@NgModule({
  declarations: [...pipes],
  imports: [],
  exports: [...pipes],
})
export class OpenreelCommonPipesModule {}
