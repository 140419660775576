<form [formGroup]="form">
  <div
    *ngFor="let group of groupArray.controls; let i = index"
    class="group-controls"
  >
    <ng-container *ngFor="let control of uiControls">
      <ng-container *ngIf="!options.newUI.enabled">
        <openreel-wf-layer-item-text
          *ngIf="control.type === UiControlTypeEnum.Text"
          [uiControl]="control"
          [control]="getGroupControl(i, control.field)"
          [appearance]="appearance"
          [enableFontSelection]="options.enableFontSelection"
        ></openreel-wf-layer-item-text>
        <openreel-wf-layer-item-shape
          *ngIf="control.type === UiControlTypeEnum.Shape"
          [uiControl]="control"
          [control]="getGroupControl(i, control.field)"
        ></openreel-wf-layer-item-shape>
        <openreel-wf-layer-item-image
          *ngIf="control.type === UiControlTypeEnum.Image"
          [uiControl]="control"
          [control]="getGroupControl(i, control.field)"
        ></openreel-wf-layer-item-image>
        <openreel-wf-layer-item-logo
          *ngIf="control.type === UiControlTypeEnum.Logo"
          [uiControl]="control"
          [control]="getGroupControl(i, control.field)"
        ></openreel-wf-layer-item-logo>
      </ng-container>
      <ng-container *ngIf="options.newUI.enabled">
        <openreel-wf-layer-item-text-new
          *ngIf="
            options.newUI.fields === 'text' &&
            control.type === UiControlTypeEnum.Text
          "
          [uiControl]="control"
          [control]="getGroupControl(i, control.field)"
        ></openreel-wf-layer-item-text-new>
        <openreel-wf-layer-item-color-new
          *ngIf="
            options.newUI.fields === 'color' &&
            (control.type === UiControlTypeEnum.Text ||
              control.type === UiControlTypeEnum.Shape)
          "
          [uiControl]="control"
          [control]="getGroupControl(i, control.field)"
        ></openreel-wf-layer-item-color-new>
      </ng-container>
    </ng-container>
  </div>
</form>
