import {
  Asset,
  LottieLayer,
  LottieLayerData,
  Preset,
  PresetField,
} from '../../../api/workflow.interfaces';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  LayerDataChangedEvent,
  TimelineTextItem,
} from '../../shared/element.interfaces';

import { Injectable } from '@angular/core';
import { Resources } from '../../store/interfaces/project.interface';
import { ValidationError } from 'aws-sdk/clients/datapipeline';

export enum UiControlType {
  Text = 'text',
  Image = 'image',
  Logo = 'logo',
  Shape = 'shape',
}

export interface UiControl {
  type: UiControlType;
  label: string;
  field: string;
}

export interface FormControlImage {
  assetId: string;
  newAssetFileId: number;
  removedAssetId: string;
  isBrandKitSelected?: boolean;
}

export interface FormControlText {
  value: string;
  styleId: string;
  colorIndex: number;
  color?: string;
  fontIndex: number;
}

@Injectable()
export class LayerService {
  constructor(private readonly formBuilder: FormBuilder) {}

  toUiControls(preset: Preset) {
    const uiControls: UiControl[] = [];

    this.getOrderedPresetFields(preset).forEach((key) => {
      const field = preset[key];
      if (field.type === 'hidden') return;

      uiControls.push({
        label: field.label,
        type: field.type as UiControlType,
        field: key,
      });
    });

    return uiControls;
  }

  toFormArray(
    preset: Preset,
    data: { [key: string]: LottieLayerData }[],
    resources: Resources
  ) {
    const formData = [];

    data.forEach((group) => {
      const groupData = {};

      this.getOrderedPresetFields(preset).forEach((key) => {
        const validators = this.getValidators(preset[key]);
        switch (preset[key].type) {
          case 'text':
            groupData[key] = this.toFormControlText(
              group[key],
              validators,
              resources
            );
            break;
          case 'image':
          case 'logo':
            groupData[key] = this.toFormControlImage(
              group[key],
              resources.assets
            );
            break;
          case 'shape':
            groupData[key] = this.toFormControlShape(
              group[key],
              validators,
              resources
            );
            break;
        }
      });

      formData.push(this.formBuilder.group(groupData));
    });

    return this.formBuilder.array(formData);
  }

  toData(item: TimelineTextItem, form: FormArray): LayerDataChangedEvent {
    const data = {
      values: [],
      assetChanges: [],
      styleChanges: [],
    };

    const presetFields = item.preset;

    form.controls.forEach((group: FormGroup) => {
      const groupData = {};
      const groupAssetChanges = {};
      const groupStyleChanges = {};

      this.getOrderedPresetFields(presetFields).forEach((key) => {
        switch (presetFields[key].type) {
          case 'text': {
            const { values, styleChanges } = this.toDataText(
              presetFields[key],
              group.controls[key].value
            );
            groupData[key] = values;
            groupStyleChanges[key] = styleChanges;
            break;
          }
          case 'image':
          case 'logo': {
            const { values, assetChanges } = this.toDataImage(
              presetFields[key],
              group.controls[key].value
            );
            groupData[key] = values;
            groupAssetChanges[key] = assetChanges;
            break;
          }
          case 'hidden': {
            const layer = item.layer as LottieLayer;
            groupData[key] = layer.data[key];
            break;
          }
          case 'shape': {
            const { values, styleChanges } = this.toDataShape(
              presetFields[key],
              group.controls[key].value
            );
            groupData[key] = values;
            groupStyleChanges[key] = styleChanges;
            break;
          }
        }
      });

      data.values.push(groupData);
      data.assetChanges.push(groupAssetChanges);
      data.styleChanges.push(groupStyleChanges);
    });

    return data;
  }

  getValidationErrors(errors: ValidationError | null) {
    if (errors === null) {
      return [];
    }

    const errorTexts = [];
    Object.keys(errors).forEach((key) => {
      switch (key) {
        case 'required':
          errorTexts.push('This field is required.');
          break;
        case 'maxlength':
          errorTexts.push(
            `This field has maximum length of ${errors[key].requiredLength} characters.`
          );
          break;
      }
    });

    return errorTexts;
  }

  private getValidators(preset: PresetField): ValidatorFn[] | undefined {
    if (!preset.validationRules) {
      return;
    }

    const validators = [];

    Object.keys(preset.validationRules).forEach((key) => {
      switch (key) {
        case 'required':
          validators.push(Validators.required);
          break;
        case 'maxlength':
          validators.push(
            Validators.maxLength(preset.validationRules[key] as number)
          );
          break;
      }
    });

    return validators;
  }

  private getOrderedPresetFields(preset: Preset) {
    return Object.keys(preset)
      .map((field) => ({ field, order: preset[field].order || 0 }))
      .sort((a, b) => a.order - b.order)
      .map((d) => d.field);
  }

  private toFormControlText(
    layerData: LottieLayerData,
    validators: ValidatorFn[],
    resources: Resources
  ): FormGroup {
    let colorIndex = null;
    let color = null;
    let fontIndex = null;

    if (layerData?.styleId) {
      const style = resources?.styles?.find((a) => a.id === layerData.styleId);
      if (style) {
        colorIndex = style.colorIndex;
        color = style.color;
        fontIndex = style.fontIndex;
      }
    }

    return this.formBuilder.group({
      value: [layerData?.value, validators],
      colorIndex,
      color,
      fontIndex,
      styleId: layerData.styleId,
    });
  }

  private toFormControlShape(
    layerData: LottieLayerData,
    validators: ValidatorFn[],
    resources: Resources
  ) {
    let colorIndex = null;
    let color = null;

    if (layerData?.styleId) {
      const style = resources?.styles?.find((a) => a.id === layerData.styleId);
      colorIndex = style.colorIndex;
      color = style.color;
    }

    return this.formBuilder.group({
      value: [layerData?.value, validators],
      colorIndex,
      color,
      styleId: layerData.styleId,
    });
  }

  private toFormControlImage(layerData: LottieLayerData, assets: Asset[]) {
    const asset = layerData?.assetId
      ? assets.find((a) => a.id === layerData.assetId)
      : null;

    return {
      assetId: layerData.assetId,
      assetFileId: asset?.file?.path,
      newAssetFileId: null,
      removedAssetId: null,
      isBrandKitSelected: asset?.isBrandKitSelected,
    };
  }

  private toDataText(preset: PresetField, formControlValue: FormControlText) {
    const { styleId, colorIndex, color, fontIndex, value } = formControlValue;

    return {
      values: {
        type: preset.type,
        styleId,
        value,
      },
      styleChanges: {
        colorIndex,
        color,
        fontIndex,
      },
    };
  }

  private toDataShape(preset: PresetField, formControlValue: FormControlText) {
    const { styleId, value, colorIndex, color } = formControlValue;

    return {
      values: {
        type: preset.type,
        styleId,
        value,
      },
      styleChanges: {
        colorIndex,
        color,
      },
    };
  }

  private toDataImage(
    presetField: PresetField,
    formControlValue: FormControlImage
  ) {
    const {
      assetId,
      newAssetFileId,
      removedAssetId,
      isBrandKitSelected,
    } = formControlValue;

    return {
      values: {
        type: presetField.type,
        assetId,
      },
      assetChanges: {
        newAssetFileId,
        removedAssetId,
        isBrandKitSelected,
      },
    };
  }
}
