import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'openreel-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  @Input() textControl: FormControl;
  @Input() formattingControls: FormGroup;
  @Input() placeholder = '';
  @Input() fonts: string[] = [];
  @Input() fontColors: string[] = [];
  @Input() type: 'text' | 'password' = 'text';

  isToolbarVisible = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
  }
}
