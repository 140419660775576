import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { ILoginRespDto } from '../../interfaces';
import {
  CompanySsoInfo,
  CreateSsoSetupData,
  SsoSetupData,
} from '../../interfaces/sso.interface';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  public urls = {
    setUp: `${commonenv.nextGenApiUrl}sso/set-up`,
    checkToken: `${commonenv.apiUrl}impersonate-login`,
    getSsoInfo: (slug: string) => `${commonenv.nextGenApiUrl}sso/info/${slug}`,
    getCallbackUrl: (slug: string) =>
      `${commonenv.nextGenApiUrl}sso/return/${slug}`,
    getLoginUrl: (slug: string) =>
      `${commonenv.nextGenApiUrl}sso/login/${slug}`,
  };

  constructor(public httpClient: HttpClient) {}

  getSsoInfo(slug: string): Promise<CompanySsoInfo> {
    return this.httpClient
      .get<CompanySsoInfo>(this.urls.getSsoInfo(slug))
      .toPromise();
  }
  getSsoSetup() {
    return this.httpClient.get<SsoSetupData>(this.urls.setUp).toPromise();
  }
  createSsoSetup(data: CreateSsoSetupData) {
    return this.httpClient.post(this.urls.setUp, data).toPromise();
  }
  checkSsoToken(token: string) {
    return this.httpClient
      .post<ILoginRespDto>(this.urls.checkToken, { token: token })
      .toPromise();
  }
}
