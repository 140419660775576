import { Injectable } from '@angular/core';
import { commonenv } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

export interface AccountRecordingHoursResponse {
  status: number;
  message: string;
  data: { total_hour: string; hour_used: number; percentage: number };
}

export interface AccountClipsCountResponse {
  status: number;
  message: string;
  data: number;
}

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  private urls = {
    recordHour: commonenv.nextGenApiUrl + 'company/statistics/total-video-hours',
    clipsCount: commonenv.nextGenApiUrl + 'company/statistics/total-clips',
  };

  constructor(public httpClient: HttpClient) {}

  getAccountRecordingTotalHours(): Observable<AccountRecordingHoursResponse> {
    return this.httpClient.get<AccountRecordingHoursResponse>(this.urls.recordHour);
  }

  getAccountClipsTotalCount(): Observable<AccountClipsCountResponse> {
    return this.httpClient.get<AccountClipsCountResponse>(this.urls.clipsCount);
  }

}
