import { createAction, props } from '@ngrx/store';

import { BrandKitDto } from '../../../api/brand-kit/brand-kit.interfaces';

const actionType = '[Brand Kit]';

export const listBrandKitsAPI = createAction(`${actionType} List API`);

export const listBrandKitsSuccess = createAction(
  `${actionType} List Success`,
  props<{ all: BrandKitDto[] }>()
);

export const loadCurrentBrandKitSuccess = createAction(
  `${actionType} Load Current Brand Kit Success`,
  props<{ current: BrandKitDto }>()
);

export const removeCurrentBrandKit = createAction(
  `${actionType} Remove Current Brand Kit`
);
