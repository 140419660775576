<form class="input-bg-white input-primary-underline" [formGroup]="form">
  <mat-form-field>
    <mat-label>Text Duration</mat-label>
    <input type="text" matInput formControlName="duration" />
  </mat-form-field>
  <mat-form-field>
    <mat-select
      placeholder="Repeat This Text"
      formControlName="repeatableEvery"
    >
      <mat-option [value]="0">Do Not Repeat</mat-option>
      <mat-option
        *ngFor="let repeat of item.template.repeatabilityOptions.options"
        [value]="repeat"
      >
        {{ repeat / 1000 | duration }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
