import { Injectable } from '@angular/core';
import { SubjectSession } from '../../interfaces';
import { SessionBaseService } from '../session/session-base.service';
import { BehaviorSubject } from 'rxjs';
import { RemoteOpenreelParticipant } from '../session/openreel-participant';
import { CommonApiService } from '../common-api/common-api.service';
import { SessionConfigService } from '../session/session-config.service';
import { SessionApiService } from '../session/session-api.service';

@Injectable()
export class SubjectSessionService extends SessionBaseService<SubjectSession> {
  loudestRemoteParticipant$ = new BehaviorSubject<RemoteOpenreelParticipant | null>(
    null
  );

  constructor(
    commonApi: CommonApiService,
    sessionConfig: SessionConfigService,
    sessionApiService: SessionApiService
  ) {
    super(commonApi, sessionConfig, sessionApiService);

    /**
     * TODO: Refactor the whole handleNewParticipant subscription
     * This function causes a high CPU usage.
     * For now will be replaced by an interval in the subject component: this.session.refreshLoudestParticipant
     *
     * */
    // this.subscriptions.push(
    //   this.newParticipant$.subscribe((newParticipant) => {
    //     this.handleNewParticipant(newParticipant);
    //   })
    // );
  }

  /**
   * [Disabled] Check the constructor subscription for more info
   * @param newParticipant
   */
  protected handleNewParticipant(newParticipant: RemoteOpenreelParticipant) {
    this.subscriptions.push(
      newParticipant.audioLevel$.subscribe(() => {
        const loudestParticipant = this.refreshLoudestParticipant();
        this.loudestRemoteParticipant$.next(loudestParticipant);
        
      })
    );
  }

  public refreshLoudestParticipant() {
    let loudestVolume = 0;
    let loudestParticipant: RemoteOpenreelParticipant = null;
    for (const participant of this.participants$.value) {
      if (participant.audioLevel$.value > loudestVolume) {
        loudestVolume = participant.audioLevel$.value;
        loudestParticipant = participant;
      }
    }
    return loudestParticipant;
  }
}
