// contains all the interfaces used by sessions or by the services or classes under current directory
import { ProjectData } from '../project/project.interface';
export interface SessionSettingsDto {
  recording_countdown_enabled: boolean;
  teleprompter_countdown_enabled: boolean;
  countdown_value: number;
  keep_teleprompter_on_screen: boolean;
  upload_during_recording: string[];
}

export class SessionProjectDto {
  data: ProjectData;
}

export enum SessionDeleteStatus {
  Trash = '1',
  PermanentDelete = '0',
}
export interface SessionArchivedData {
  session_id: number;
  session_name: string;
  deleted_at: Date;
  total_clips: number;
}
