import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RedirectToCaptureGuard } from './shared/guards/redirect-to-capture.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [RedirectToCaptureGuard],
    component: AppComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

export const routableComponents = [];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
