<div class="dialog-container flex-col">
  <div class="flex-auto flex h-min-0">
    <ng-container *ngIf="!(hideSidebar$ | async)">
      <div class="actions p-16">
        <mat-icon
          svgIcon="folder"
          class="mat-icon-20 text-color-primary"
        ></mat-icon>
      </div>
      <div class="folders flex-none p-32">
        <openreel-wf-select-files-sidebar
          (selected)="typeSelected($event)"
        ></openreel-wf-select-files-sidebar>
      </div>
    </ng-container>
    <div class="relative content-container flex-auto p-20 px-32">
      <button
        disableRipple
        mat-button
        class="absolute right-32 z-1 no-overlay p-0"
        (click)="close()"
      >
        Close
        <mat-icon svgIcon="cancel-process"></mat-icon>
      </button>
      <div class="overflow-y-hidden h-full">
        <openreel-wf-select-files-projects
          *ngIf="selectedType === ClipTypesEnum.MyProjects"
          [initialProjectId]="data?.selectedType?.selectedProjectId"
        ></openreel-wf-select-files-projects>
      </div>
    </div>
  </div>
  <div
    *ngIf="selectedRecordings.length > 0"
    class="selected flex flex-align-center p-20"
  >
    <span class="text-16 p-right-20 flex-none"
      >{{ selectedRecordings.length }} items selected</span
    >
    <div class="flex-auto flex overflow-hidden w-min-0 flex-nowrap mx-20">
      <img
        *ngFor="let recording of selectedRecordings"
        class="b-radius-4 m-right-20"
        [src]="recording.thumb"
        alt="Selected recording"
        openreelFallback="assets/common/images/placeholder.jpg"
      />
    </div>
    <button
      class="flex-none"
      type="button"
      mat-flat-button
      color="primary"
      (click)="addSelectedRecordings()"
    >
      Add Selected
    </button>
  </div>
</div>
