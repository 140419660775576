import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { commonenv } from '@openreel/common/env/environment';
import { Recording } from '../../../../../libs/common/src';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private urls = {
    getById: (videoId: number) => `${commonenv.nextGenApiUrl}videos/${videoId}`,
  };

  constructor(private readonly httpClient: HttpClient) {}

  getVideo(videoId: number) {
    return this.httpClient.get<Recording>(this.urls.getById(videoId));
  }
}
