<mat-tab-group
  *ngIf="brandKitHasLogo$ | async"
  mat-stretch-tabs
  class="tab-pill"
  [selectedIndex]="selectedIndex"
  (selectedIndexChange)="onSelectionChange($event)"
>
  <mat-tab>
    <ng-template mat-tab-label>
      {{ uiControl.label }}
    </ng-template>
    <openreel-wf-layer-item-image
      [uiControl]="uiControl"
      [control]="control"
    ></openreel-wf-layer-item-image>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon svgIcon="wf-brand-kit" class="highlight mat-icon-16"></mat-icon>
      Brand Kit
    </ng-template>
  </mat-tab>
</mat-tab-group>
<openreel-wf-layer-item-image
  *ngIf="!(brandKitHasLogo$ | async)"
  [uiControl]="uiControl"
  [control]="control"
></openreel-wf-layer-item-image>
