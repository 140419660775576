import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  TwilioTokenRequest,
  TwilioTokenResponse,
  DeviceInfoRequest,
  DeviceInfoResponse,
  RemoteSessionDetailsRequest,
  RemoteSessionDetailsResponse,
} from '../../interfaces/interfaces';

const URL_TWILIO_TOKEN = 'token';
const URL_DEVICE_INFO = 'get-device-details';
const URL_REMOTE_SESSION_DETAILS = 'get-remote-session-details';

@Injectable({
  providedIn: 'root',
})
export class CommonApiService {
  constructor(private httpClient: HttpClient) {}

  getTwilioToken(request: TwilioTokenRequest) {
    return this.httpClient.post<TwilioTokenResponse>(URL_TWILIO_TOKEN, request);
  }

  getDeviceInfo(request: DeviceInfoRequest) {
    return this.httpClient.post<DeviceInfoResponse>(URL_DEVICE_INFO, request);
  }

  getSessionDetails(sessionDetailsRequest: RemoteSessionDetailsRequest) {
    return this.httpClient.post<RemoteSessionDetailsResponse>(
      URL_REMOTE_SESSION_DETAILS,
      sessionDetailsRequest
    );
  }
}
