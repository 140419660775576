import { LayerOptions, VideoLayer } from '../../../api/workflow.interfaces';
import { TimelineItemEvent } from '../../timelines/store/interfaces/timelines.interfaces';
import { TimelineItem, timelineItemFactory } from '../element.interfaces';

import { BaseCommand } from './base.command';

export class UpdateVideoOverlayPipCommand extends BaseCommand<
  TimelineItemEvent,
  TimelineItem
> {
  run(event: TimelineItemEvent) {
    if (!this.source.features?.videoOverlays?.pip?.enabled) {
      return;
    }

    this.createMainSectionIfNotexists();
    const timeline = this.getTimeline('b-roll');

    const pipBounds = this.source.features.videoOverlays.pip.bounds;

    const layer = timeline.layers.find(
      (l) => l.layerId === event.item.layerId
    ) as LayerOptions & VideoLayer;

    if (layer.isPip) {
      layer.isPip = false;
      layer.bounds = null;
    } else {
      layer.isPip = true;
      layer.bounds = pipBounds;
    }

    return {
      result: timelineItemFactory(layer, 'b-roll', this.source),
      updatedWorkflow: this.toWorkflow(),
    };
  }
}
