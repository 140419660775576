import * as fromActions from './../actions/asset-cache.actions';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { HttpClient, HttpXhrBackend } from '@angular/common/http';
import {
  catchError,
  concatMap,
  delay,
  map,
  mergeMap,
  withLatestFrom,
} from 'rxjs/operators';

import { AlertService } from '../../core/services/alert.service';
import { AssetFetchService } from '../../core/services/asset-fetch.service';
import { AssetsCacheFacade } from '../facades/asset-cache.facade';
import { Injectable } from '@angular/core';

@Injectable()
export class AssetCacheEffects {
  loadAsset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadAsset),
      concatMap(({ id, provider }) => of({ id, provider }).pipe(delay(10))), // Rate limits to avoid concurrent requests
      withLatestFrom(this.assetsCacheFacade.cachedAssets$),
      mergeMap(([action, cachedAssets]) => {
        const { id, provider } = action;
        const cached = cachedAssets.find(
          (asset) =>
            asset.assetId === id.toString() && asset.provider === provider
        );

        if (
          cached &&
          (!cached.expiresAt || new Date(cached.expiresAt) > new Date())
        ) {
          return EMPTY;
        }

        return [
          fromActions.cacheAsset({
            assetId: id.toString(),
            provider,
            isLoading: true,
          }),
          fromActions.fetchAssetUrl({ id, provider }),
        ];
      })
    )
  );

  fetchAssetUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.fetchAssetUrl),
      mergeMap(({ id, provider }) =>
        this.assetFetchService.fetchUrl(provider, id).pipe(
          map(({ url, expiresAt }) =>
            fromActions.cacheAsset({
              assetId: id.toString(),
              provider,
              isLoading: false,
              url,
              expiresAt,
            })
          ),
          catchError((error) => {
            this.logError(error);
            return [fromActions.removeCachedAsset({ id, provider })];
          })
        )
      )
    )
  );

  loadObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadObject),
      concatMap(({ url, dataType }) => of({ url, dataType }).pipe(delay(10))), // Rate limits to avoid concurrent requests
      withLatestFrom(this.assetsCacheFacade.cachedObjects$),
      concatMap(([action, cachedObjects]) => {
        const { url, dataType } = action;
        const cached = cachedObjects.find((asset) => asset.originalUrl === url);

        if (cached) {
          return EMPTY;
        }
        return [
          fromActions.cacheObject({
            originalUrl: url,
            dataType,
            isLoading: true,
          }),
          fromActions.downloadObject({ url, dataType }),
        ];
      })
    )
  );

  downloadObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.downloadObject),
      mergeMap(({ url, dataType }) => {
        const httpClient = new HttpClient(
          new HttpXhrBackend({ build: () => new XMLHttpRequest() })
        );

        return httpClient.get(url, { responseType: 'arraybuffer' }).pipe(
          map((content: BlobPart) => new Blob([content], { type: dataType })),
          map((blob) => URL.createObjectURL(blob)),
          map((objUrl) =>
            fromActions.cacheObject({
              originalUrl: url,
              dataType,
              isLoading: false,
              url: objUrl,
            })
          ),
          catchError((error) => {
            this.logError(error);
            return [fromActions.removeCachedObject({ url })];
          })
        );
      })
    )
  );

  private logError(
    error,
    errorText: string = 'An error occurred while fetching assets for caching'
  ) {
    this.alertService.error(errorText);
    console.error(error);
  }

  constructor(
    private readonly alertService: AlertService,
    private readonly assetsCacheFacade: AssetsCacheFacade,
    private readonly assetFetchService: AssetFetchService,
    private readonly actions$: Actions
  ) {}
}
