import * as fromActions from '../actions/project.actions';
import * as fromEditorActions from './../actions/editor.actions';

import { createReducer, on } from '@ngrx/store';

import { Project } from './../interfaces/project.interface';

export const initialState: Partial<Project> = {
  loaded: false,
  loading: false,
  loadFinished: false,
  data: null,
  draftData: null,
};

const reducer = createReducer(
  initialState,
  on(fromActions.createProjectAPI, fromActions.loadProjectAPI, () => ({
    loaded: false,
    loading: true,
    loadFinished: false,
  })),
  on(fromActions.loadProjectSuccess, (state, { data }) => ({
    loaded: true,
    loading: false,
    data,
    draftData: data,
  })),
  on(fromActions.loadProjectFailure, () => ({
    loaded: false,
    loading: false,
  })),
  on(fromActions.loadProjectFinished, (state) => ({
    ...state,
    loadFinished: true,
  })),
  on(fromActions.createProjectSuccess, (state, { data }) => ({
    loaded: true,
    data,
    draftData: data,
  })),
  on(fromActions.createProjectFailure, () => ({ loaded: false })),
  on(fromActions.updateProjectSuccess, (state, action) => ({
    ...state,
    data: { ...action.data },
    draftData: { ...action.data },
  })),

  // Draft data manipulation
  on(fromActions.saveDraftWorkflowData, (state, { draftWorkflowData }) => ({
    ...state,
    draftData: {
      ...state.draftData,
      workflow: draftWorkflowData,
    },
  })),
  on(fromEditorActions.setStep, (state) => ({
    ...state,
    draftData: state.data,
  })),
  on(fromEditorActions.nextStep, (state) => ({
    ...state,
    draftData: state.data,
  })),
  on(fromActions.renderProjectAccepted, (state) => ({
    ...state,
  })),
  on(fromActions.setRenderProgress, (state, { renderProgress }) => ({
    ...state,
    data: {
      ...state.data,
      video: {
        state: renderProgress.state,
        signedUrl: renderProgress.signedUrl,
      },
    },
    renderProgress,
  }))
);

export function reducerFn(state, action) {
  return reducer(state, action);
}
