<div *ngIf="buttons" class="or-sidebar">
  <div class="or-sidebar-content z-10">
    <openreel-wf-sidebar-button
      *ngFor="let button of buttons; let i = index"
      class="m-bottom-24"
      [icon]="button.icon"
      [text]="button.text"
      [index]="button.order"
      [step]="editorState.step"
      [maxStep]="editorState.maxStep"
      (stepSelect)="onStepSelect($event)"
    >
    </openreel-wf-sidebar-button>
  </div>
</div>
