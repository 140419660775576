import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { ProjectFacade } from '../../store/facades/project.facade';

@Injectable()
export class ProjectLoadedGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly projectFacade: ProjectFacade
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const id = +route.params.id;

    if (!Number.isInteger(Number(id))) {
      this.router.navigate(['/']);
      return;
    }

    this.projectFacade.loadProject(id);

    return true;
  }
}
